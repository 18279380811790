import { Input } from 'antd'
import { get } from 'lodash'
import { FC } from 'react'
import { Text } from '../../Text'
import { ITextAreaProps } from './types'

const { TextArea } = Input

export const InputTextArea: FC<ITextAreaProps> = ({
  titleConfig,
  onChange,
  placeholder,
  value
}) => {
  const title = get(titleConfig, 'title', '')
  const variant = get(titleConfig, 'variant', 'h1')

  const changeHandler = (value: string) => {
    if (onChange) {
      onChange(value)
    }
  }
  return (
    <>
      {title && <Text text={title} variant={variant} />}
      <TextArea
        rows={6}
        placeholder={placeholder}
        maxLength={1000}
        onChange={(e) => changeHandler(e.target.value)}
        value={value}
      />
    </>
  )
}
