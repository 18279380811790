import { createAction } from '@reduxjs/toolkit'
import {
  ADMINISTRATION_CHECK_SUPPLIER,
  ADMINISTRATION_CREATE_ATTACHMENTS,
  ADMINISTRATION_CREATE_SUPPLIER,
  ADMINISTRATION_GET_ATTACHMENTS,
  ADMINISTRATION_GET_ATTACHMENTS_DETAIL,
  ADMINISTRATION_GET_CURRENCY_RATES,
  ADMINISTRATION_GET_GROUP_NAMES,
  ADMINISTRATION_GET_SUPPLIER_DETAIL,
  ADMINISTRATION_IMPORT_ANAGRAPHIC,
  ADMINISTRATION_IMPORT_PRICE,
  ADMINISTRATION_IMPORT_RELATIONS,
  ADMINISTRATION_IMPORT_SUPPLIER,
  ADMINISTRATION_SEARCH_SUPPLIER,
  ADMINISTRATION_SET_CURRENCY_RATE,
  ADMINISTRATION_UPDATE_ATTACHMENT,
  ADMINISTRATION_UPDATE_SUPPLIER
} from './constants'
import { INewAttachment, INewSupplier } from './model'
import { partialAdministrationActions } from './reducer'

export const administrationActions = {
  ...partialAdministrationActions,
  importSupplier: createAction(ADMINISTRATION_IMPORT_SUPPLIER),
  importAnagraphic: createAction(ADMINISTRATION_IMPORT_ANAGRAPHIC),
  importPrice: createAction(ADMINISTRATION_IMPORT_PRICE),
  importRelations: createAction(ADMINISTRATION_IMPORT_RELATIONS),
  getAttachments: createAction(ADMINISTRATION_GET_ATTACHMENTS),
  getAttachmentDetail: createAction<{ id: string }>(
    ADMINISTRATION_GET_ATTACHMENTS_DETAIL
  ),
  checkSupplierSapCode: createAction<{ value: string }>(ADMINISTRATION_CHECK_SUPPLIER),
  updateAttachment: createAction<{
    attachData: Record<string, any>
    successCb?: () => void
  }>(ADMINISTRATION_UPDATE_ATTACHMENT),
  createAttachment: createAction<{ attachData: INewAttachment }>(
    ADMINISTRATION_CREATE_ATTACHMENTS
  ),
  createSupplier: createAction<{ supplierData: INewSupplier }>(
    ADMINISTRATION_CREATE_SUPPLIER
  ),
  searchSuppliers: createAction(ADMINISTRATION_SEARCH_SUPPLIER),
  getSupplierDetail: createAction<{ id: string }>(ADMINISTRATION_GET_SUPPLIER_DETAIL),
  updateSupplier: createAction<{
    id: string
    data: Record<string, any>
    successCb?: () => void
  }>(ADMINISTRATION_UPDATE_SUPPLIER),
  getCurrencyRates: createAction(ADMINISTRATION_GET_CURRENCY_RATES),
  setCurrencyRate: createAction<{ id: string; data: string }>(
    ADMINISTRATION_SET_CURRENCY_RATE
  ),
  getGroupNames: createAction(ADMINISTRATION_GET_GROUP_NAMES)
}
