import { Form, Input } from 'antd'
import { get } from 'lodash'
import { Text } from '../../Text'
import { FakeBtnFile, StyledUpload, StyledUploadProps } from './styled'

type Props = {
  name: string
  label: string
  value: any
  onChange: (value: any) => void
  required?: boolean
  error?: boolean
  message: string
  description: string
  postFileSave?: (value: string) => void
} & StyledUploadProps

export const Upload = ({
  name,
  onChange,
  error = false,
  description,
  message,
  value,
  required,
  label,
  postFileSave
}: Props) => {
  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files
    if (fileList && fileList[0]) {
      window.tmpFiles = {
        ...(window.tmpFiles || {}),
        [name]: fileList[0] // cambiare in multiplo
      }
      postFileSave && postFileSave(fileList[0].name)
      const fileName = get(fileList[0], 'name', '').split('.')
      fileName.pop()
      const newFilename = fileName.toString().replaceAll(',', '.')
      return onChange({
        name,
        filename: newFilename,
        type: (fileList[0] as File).type
      })
    }
    onChange(undefined)
  }

  return (
    <Form.Item
      label={label}
      required={required}
      validateStatus={error ? 'error' : undefined}
    >
      <StyledUpload>
        <Input className="btn-style" type="file" onChange={onSelectFile} />
        <FakeBtnFile error={error} text={value?.filename || message} variant="h5" />
        <Text className="description" text={description} variant="h6" />
      </StyledUpload>
    </Form.Item>
  )
}
