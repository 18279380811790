import { FC } from 'react'
import { useSelector } from 'react-redux'
import { selectUserRole } from '../../../features/users/selectors'
import { AppRoutes } from '../../../pages/constants'
import { columnsGeneratorHelper, warningMessageGenerator } from '../../../utils'
import { filterFormulaByStatus } from '../../../utils/formulaSchemaHelpers'
import { cleanAndFlat, cleanAndFlatWithBackupKey } from '../../../utils/productHelper'
import Table from '../../NewTable'
import { TOP_RIGHT } from '../../NewTable/constants'
import { ITActionColumnConfig } from '../../NewTable/types'
import { PRODUCTS_RTG_TABLE_CONFIG } from './contants'
import { IProductsTable } from './types'

const ProductsTable: FC<IProductsTable> = ({
  dataList,
  isLoading,
  enablePagination = false,
  onChangePage = () => {},
  pageData = { page: 1, pageSize: 10, total: 10 }
}) => {
  const { page, pageSize, total } = pageData
  const userRoles = useSelector(selectUserRole)

  const columnGenerator = () => {
    let columnList = columnsGeneratorHelper(PRODUCTS_RTG_TABLE_CONFIG(userRoles))
    return columnList
  }

  const actionConfig: ITActionColumnConfig = {
    columnTitle: 'Actions',
    detail: {
      destinationPath: AppRoutes.VAULT_PRODUCT_DETAILS,
      btnLabel: 'Details',
      btnVariant: 'text'
    }
  }

  const rowsGenerator = () => {
    return dataList.map(({ productSides, warnings, warningsJSON, ...args }) => {
      const formulasMapped = productSides.map((side: any) => side.formulas).flat()
      const filteredIndustrialCodes = filterFormulaByStatus(formulasMapped)
        .map((f) => f.formulaCodeDEVEX)
        .filter((code: any) => code !== null)

      const filteredLabReferenceCode = filterFormulaByStatus(formulasMapped).map(
        (f) => f.labReferenceCode
      )

      return {
        ...args,
        image: args.thumbnail?.url,
        industrialCode: filteredIndustrialCodes,
        productCategory: cleanAndFlat(productSides, 'productCategory'),
        productForm: cleanAndFlat(productSides, 'productForm'),
        formulaSchemaCode: cleanAndFlat(productSides, 'formulaSchemaCode'),
        labReferenceCode: filteredLabReferenceCode,
        packagingCode:
          productSides[0]?.packageVariant?.packageFamily?.sideCollector?.packagingCode ||
          '',
        pdLaunchYear: cleanAndFlatWithBackupKey(
          productSides,
          'pdLaunchYear',
          'launchYear'
        ),
        pdCollection: cleanAndFlatWithBackupKey(
          productSides,
          'pdCollection',
          'collection'
        ),
        format: cleanAndFlat(productSides, 'format'),
        productCodeWarn: warningMessageGenerator(warnings, warningsJSON)
      }
    })
  }

  const columns = columnGenerator()
  const dataRow = dataList.length ? rowsGenerator() : []
  return (
    <Table
      pagination={{
        current: page,
        pageSize,
        total
      }}
      onChangePage={onChangePage}
      enablePagination={enablePagination}
      paginationPosition={TOP_RIGHT}
      rowKey="id"
      columns={columns}
      items={dataRow}
      actionColumnConfig={actionConfig}
      forceHeight
      isLoading={isLoading}
    />
  )
}

export default ProductsTable
