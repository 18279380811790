import moment from 'moment'
import { FC } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { SectionTitle } from '../../../components'
import { BoxInfo } from '../../../components/BoxInfo'
import { BoxWrapper } from '../../../components/BoxWrapper'
import { Divider } from '../../../components/Divider'
import { FlexSection } from '../../../components/FlexSection'
import { Text } from '../../../components/Text'
import {
  getCartItems,
  selectCartSuccess,
  selectCustomer,
  selectDetails
} from '../../../features/cart/selectors'
import { AppRoutes } from '../../../pages/constants'
import Success from '../../Success'
import { CartItemCard } from '../Step1Cart/CartItemCard'
import { CardItemWrapper } from './styled'
import { IStep4CartProps } from './types'

export const Step4Cart: FC<IStep4CartProps> = () => {
  const navigate = useNavigate()
  const products = useSelector(getCartItems)
  const deliveryDetails = useSelector(selectDetails)
  const customerDetails = useSelector(selectCustomer)
  const cartSuccess = useSelector(selectCartSuccess)

  const backHomeHandler = () => {
    navigate(AppRoutes.HOME)
  }

  const createNewOrder = () => {
    navigate(AppRoutes.STORE_PRODUCTS_SEARCH)
  }

  const { address, country, date } = deliveryDetails
  const { type, additionalNote, setRequired } = customerDetails
  return (
    <>
      {!cartSuccess ? (
        <FlexSection flexDirection="column">
          <SectionTitle value="Order Summary" isBold />
          <Text text="Shipping Information" variant="h5" />
          <FlexSection flexDirection="column">
            <BoxWrapper columnCount={4}>
              <BoxInfo
                label="Requested date of fullfilment"
                value={moment(date).format('DD-MM-YYYY')}
              />
              <BoxInfo label="Region/Country" value={country} />
              <BoxInfo label="Ship To" value={address} />
            </BoxWrapper>
          </FlexSection>
          <Divider disableBorder={true} />
          <Text text="Additional Information" variant="h4" />
          <Divider />
          <FlexSection flexDirection="column">
            <BoxWrapper columnCount={4}>
              <BoxInfo label="Addressed to" value={type} />
              <BoxInfo label="Set required" value={setRequired} />
            </BoxWrapper>
            <BoxWrapper columnCount={4}>
              <BoxInfo label="Note about client" value={additionalNote} />
            </BoxWrapper>
          </FlexSection>
          <Divider />
          {/* TODO - consider to substitute this list with ProductList */}
          {products.map((prod) => (
            <CardItemWrapper key={prod.id}>
              <CartItemCard data={prod} />
            </CardItemWrapper>
          ))}
          <Divider disableBorder={true} />
        </FlexSection>
      ) : (
        <Success
          data={cartSuccess}
          buttons={[
            { label: 'New order', onClick: createNewOrder },
            { label: 'Go to homepage', onClick: backHomeHandler, variant: 'ghost' }
          ]}
        />
      )}
    </>
  )
}
