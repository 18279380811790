import { Tabs } from 'antd'
import { get } from 'lodash'
import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router'
import { footerHeight, headerHeight } from '../../assets/mixins'
import { canUserEditProduct } from '../../auth-msal'
import { Button, FormulaSchemaTable, SectionTitle } from '../../components'
import { Breadcrumb } from '../../components/Breadcrumb'
import { FORMULA_TABLE_CONFIG } from '../../components/FormulaSchema/FormulaSchemaTable/constants'
import { Loader } from '../../components/Loader'
import AdditionalDataForm from '../../components/Product/AdditionalDataGeneralForm'
import AdditionalDataSideForm from '../../components/Product/AdditionalDataSideForm'
import { PageContent } from '../../containers'
import ProductDetail from '../../containers/Product/ProductDetail'
import Success from '../../containers/Success'
import { actions as appActions } from '../../features/app'
import { PACKAGE_TYPE } from '../../features/packaging/model'
import { productStatusDropdownValues } from '../../features/product/constants'
import { actions as productsActions } from '../../features/products'
import { ProductDto } from '../../features/products/model'
import {
  getProductTemp,
  getProductsDetail,
  getProductsDetailName,
  selectLoader,
  selectProductsError,
  selectSuccessInfo
} from '../../features/products/selectors'
import { selectUserRole } from '../../features/users/selectors'
import { GenericLayout } from '../../layouts'
import { getProductStatusMessage } from '../../utils/productHelper'
import { AppRoutes } from '../constants'
import { ButtonsWrapper, DeleteMessage, Asterisk } from './styled'

const ProductRTGDetail: FC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const userRoles = useSelector(selectUserRole)
  const productsError = useSelector(selectProductsError)
  const productDetail = useSelector(getProductsDetail)
  const successInfo = useSelector(selectSuccessInfo)
  const tempDetail = useSelector(getProductTemp)
  const isLoading = useSelector(selectLoader)
  const { id } = useParams()
  const productName = useSelector(getProductsDetailName)
  const [isOnEdit, setIsOnEdit] = useState(false)
  const isMulti = get(productDetail, 'type', '')?.toLowerCase() === PACKAGE_TYPE.MULTI
  const productTempSides = get(tempDetail, 'productSides', [])

  const status = get(tempDetail, 'status', '')
  const [hasLastDate, setHasLastDate] = useState(false)
  const updateHasLastDate = (yesno: boolean) => {
    setHasLastDate(yesno)
  }

  useEffect(() => {
    if (id) {
      dispatch(productsActions.getDetails({ id }))
    }
    return () => {
      dispatch(productsActions.clearError())
      dispatch(productsActions.clearSuccess())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const cancelHandler = () => {
    setIsOnEdit(false)
    dispatch(productsActions.clearTemp())
  }

  const saveHandler = () => {
    if (statusMessage && status === productStatusDropdownValues.READY_TO_GO) {
      dispatch(
        appActions.openPopUp({
          title: 'Error',
          key: 'product-status-popup',
          message: statusMessage
        })
      )
    } else {
      setIsOnEdit(false)
      dispatch(productsActions.updateProduct())
    }
  }

  const editHandler = () => {
    setIsOnEdit(true)
    dispatch(productsActions.initTemp())
  }

  const updateHandler = (key: string, value: any) => {
    dispatch(productsActions.updateTemp({ key: key as keyof ProductDto, value }))
  }

  const updateSideHandler = (sideIdx: number, key: string, value: any) => {
    const newSides = [...productTempSides]
    newSides[sideIdx] = {
      ...newSides[sideIdx],
      [key]: value
    }

    dispatch(productsActions.updateTemp({ key: 'productSides', value: newSides }))
  }

  const getFormulaInfo = (sideIdx: number) => ({
    formula: get(productTempSides[sideIdx], 'formulaSchema.formula', []),
    formulaSelected: get(productTempSides[sideIdx], 'formulaSelected', [])
  })

  const hasIndustrialCode = getFormulaInfo(0).formulaSelected.some(
    (formula: any) => !!formula.formulaCodeDEVEX
  )

  const statusMessage = getProductStatusMessage(hasIndustrialCode, hasLastDate)

  const selectHandler = (sideIdx: number, selectedRows: Record<string, any>[]) => {
    updateSideHandler(sideIdx, 'formulaSelected', selectedRows)
  }

  const openDeleteWarning = () => {
    dispatch(
      appActions.openPopUp({
        title: '',
        key: 'popup-delete-warning',
        message: (
          <DeleteMessage>
            Please proceed with permanent deletion of the product only in case it has been
            created by mistake. In the following cases instead please only change the
            product status:
            <ul>
              <li>Ready To Go: Sellable as-is by Sales and Marketing Teams</li>
              <li>
                Level 2 in progress: Product not RTG as testing process not completed
              </li>
              <li>
                Archive: RTG products that must not be promoted by Sales and Marketing
                Teams as obsolete
              </li>
              <li>
                Client Exclusive: Products that have been granted in exclusive to a client
              </li>
              <li>Not Stable: Products that have not passed stability tests</li>
              <li>
                Not Compatible or Industrializable: Products that do not report stability
                issues but have not passed compatibility tests or industrialization phase
              </li>
            </ul>
          </DeleteMessage>
        ),

        footer: [
          <Button
            key="cancel"
            label="Cancel"
            onClick={() => dispatch(appActions.closePopUp())}
            variant="ghost"
          />,
          <Button
            key="confirm"
            label="Confirm"
            onClick={() => {
              dispatch(appActions.closePopUp())
              dispatch(productsActions.deleteProduct())
            }}
          />
        ]
      })
    )
  }

  const openSaveWarning = () => {
    dispatch(
      appActions.openPopUp({
        title: 'Are you sure you want to save the changes?',
        key: 'popup-save-warning',
        footer: [
          <Button
            key="cancel"
            label="Cancel"
            onClick={() => dispatch(appActions.closePopUp())}
            variant="ghost"
          />,
          <Button
            key="confirm"
            label="Confirm"
            onClick={() => {
              dispatch(appActions.closePopUp())
              saveHandler()
            }}
          />
        ]
      })
    )
  }

  const checkFormulaValidation = () =>
    productTempSides.every((side: any) => get(side, 'formulaSelected', []).length)

  const isSaveDisable = !tempDetail?.status || !checkFormulaValidation()

  const canEdit = canUserEditProduct(userRoles)

  return (
    <GenericLayout error={productsError}>
      <PageContent
        footerHeight={footerHeight}
        headerHeight={headerHeight}
        justifyContent="flex-start"
      >
        <Breadcrumb
          items={[
            {
              name: 'Product',
              href: AppRoutes.VAULT_PRODUCTS_RESULTS
            },
            {
              name: `Product detail ${productName || '-'}`
            }
          ]}
        />
        {isLoading && <Loader />}
        {!isLoading && !successInfo && (
          <>
            {productDetail && !isOnEdit && (
              <>
                <ButtonsWrapper>
                  {canEdit && <Button label="Edit" onClick={editHandler} />}
                </ButtonsWrapper>
                <ProductDetail
                  updateHasLastDate={updateHasLastDate}
                  data={productDetail as Record<string, any>}
                />
              </>
            )}
            {tempDetail && isOnEdit && (
              <>
                <ButtonsWrapper>
                  <Button
                    key="cancel"
                    label="Cancel"
                    onClick={cancelHandler}
                    variant="ghost"
                  />
                  <Button
                    key="save"
                    label="Save"
                    onClick={openSaveWarning}
                    disabled={isSaveDisable}
                  />
                  <Button
                    key="delete"
                    label="Delete"
                    onClick={openDeleteWarning}
                    variant="text"
                    iconName="DeleteOutlined"
                  />
                </ButtonsWrapper>
                <AdditionalDataForm
                  data={tempDetail as ProductDto}
                  onUpdate={updateHandler}
                />
                {isMulti && (
                  <Tabs>
                    {productTempSides.map((sideData: any, sideIdx: number) => (
                      <Tabs.TabPane tab={`Side ${sideIdx + 1}`} key={sideIdx}>
                        <AdditionalDataSideForm
                          isReady={
                            get(tempDetail, 'status', '') ===
                            productStatusDropdownValues.READY_TO_GO
                          }
                          data={sideData}
                          onUpdate={(key, value) =>
                            updateSideHandler(sideIdx, key, value)
                          }
                          isMascara={
                            get(tempDetail, 'businessSegment', '')?.toLowerCase() ===
                            'mascara'
                          }
                        />
                      </Tabs.TabPane>
                    ))}
                  </Tabs>
                )}
                {!isMulti && (
                  <AdditionalDataSideForm
                    isReady={
                      get(tempDetail, 'status', '') ===
                      productStatusDropdownValues.READY_TO_GO
                    }
                    data={{
                      ...productTempSides[0],
                      stabilityStartDate:
                        productTempSides[0].stabilityStartDate ||
                        tempDetail.stabilityStartDate,
                      innovationLevel:
                        productTempSides[0].innovationLevel || tempDetail.innovationLevel,
                      bulkLevel: productTempSides[0].bulkLevel || tempDetail.bulkLevel,
                      compatibility:
                        productTempSides[0].compatibility || tempDetail.compatibility,
                      industrialLevel:
                        productTempSides[0].industrialLevel || tempDetail.industrialLevel,
                      packagingLevel:
                        productTempSides[0].packagingLevel || tempDetail.packagingLevel
                    }}
                    onUpdate={(key, value) => updateSideHandler(0, key, value)}
                    isMascara={
                      get(tempDetail, 'businessSegment', '')?.toLowerCase() === 'mascara'
                    }
                  />
                )}
                <SectionTitle
                  color={checkFormulaValidation() ? 'black' : 'red'}
                  value={
                    <>
                      <Asterisk>*</Asterisk> Formula Schema Data
                    </>
                  }
                />
                {!isMulti && (
                  <FormulaSchemaTable
                    tableConfig={FORMULA_TABLE_CONFIG}
                    dataList={getFormulaInfo(0).formula}
                    selectedRows={getFormulaInfo(0).formulaSelected}
                    enableSelect
                    onSelect={(selectedRows) => selectHandler(0, selectedRows)}
                  />
                )}
                {isMulti && (
                  <Tabs>
                    {productTempSides.map((_: any, sideIdx: number) => (
                      <Tabs.TabPane tab={`Side ${sideIdx + 1}`} key={sideIdx}>
                        <FormulaSchemaTable
                          tableConfig={FORMULA_TABLE_CONFIG}
                          dataList={getFormulaInfo(sideIdx).formula}
                          selectedRows={getFormulaInfo(sideIdx).formulaSelected}
                          enableSelect
                          onSelect={(selectedRows) =>
                            selectHandler(sideIdx, selectedRows)
                          }
                        />
                      </Tabs.TabPane>
                    ))}
                  </Tabs>
                )}
              </>
            )}
          </>
        )}
        {!isLoading && successInfo && (
          <Success
            data={successInfo}
            buttons={[
              {
                label: 'Back to the products',
                onClick: () => navigate(AppRoutes.VAULT_PRODUCTS_RESULTS)
              },
              {
                label: 'Back to home',
                onClick: () => navigate(AppRoutes.HOME),
                iconName: 'HomeOutlined',
                variant: 'text'
              }
            ]}
          />
        )}
      </PageContent>
    </GenericLayout>
  )
}

export default ProductRTGDetail
