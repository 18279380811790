import styled, { css } from 'styled-components'

type ColorProps = {
  color: string
}

export const ColorPreviewContainer = styled.div``

const colorPreviewHeight = 50
export const StyledColorPreview = styled.div<ColorProps>`
  width: 50px;
  height: ${colorPreviewHeight}px;
  ${({ color }) =>
    color &&
    css`
       {
        background: ${color};
      }
    `}
`

export const StyledImage = styled.img`
  width: 100%;
  height: ${colorPreviewHeight}px;
`
