import styled from 'styled-components'
import { largeSizeWidth, mediumSizeWidth, smallSizeWidth } from '../../assets/mixins'
import { FlexRowWrapper } from '../../style/styledCommonWrappers'

export const FlexColorWrapper = styled(FlexRowWrapper)`
  justify-content: space-between;
  align-items: end;
  margin-bottom: 10px;
  > {
    & div:first-child {
      width: 80%;
    }
  }
  > {
    & .ant-form-item {
      padding: 0 1%;
    }
  }
`
export const EntitiesStyledBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  z-index: 2;
  width: 100%;
  > .ant-form-item {
    @media (min-width: ${smallSizeWidth}px) {
      width: ${100 / 2}%;
    }
    @media (min-width: ${mediumSizeWidth}px) {
      width: ${100 / 3}%;
    }
    @media (min-width: ${largeSizeWidth}px) {
      width: ${100 / 4}%;
    }
    min-width: auto;
    padding: 0 1%;
  }
`
