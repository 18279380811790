import styled from 'styled-components'

export const StyledTagBox = styled.div`
  .custom-tag {
    display: flex;
    align-items: center;
    border-radius: 20px;
    height: 30px;
  }
`

export const TagValue = styled.div<{ closable: boolean }>`
  flex-grow: 1;
  padding-left: ${({ closable }) => `${closable ? 4 : 0}px`};
`
