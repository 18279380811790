import styled from 'styled-components'
import { headerHeight, logoWidth, menuAvatarWidth } from '../../assets/mixins'
import { AvatarDropdown } from '../Avatar/avatar-dropdown'
import { ICartIconWrapper } from './types'

export const StyledHeader = styled.header`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  height: ${headerHeight}px;
  background-color: #fff;
  padding: 0 26px;
  align-items: center;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
  z-index: 11;
`
export const StyledContentHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - ${logoWidth}px);
`

export const StyledAvatar = styled(AvatarDropdown)`
  width: ${menuAvatarWidth}px;
`

export const IconWrapper = styled.div<ICartIconWrapper>`
  cursor: pointer;
  svg {
    width: 22px;
    height: 22px;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-right: 16px;
  height: 47px;
  border-bottom: ${({ theme, showCart }) =>
    showCart ? `2px solid ${theme.palette.primary.main}` : 0};
`
export const CartItemsIndicator = styled.div`
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: red;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 1000;
`
