import { PackagingCreationType } from '../../features/packaging/model'
import { IFlowType } from '../CreationPackage/types'

export const CREATION_FLOW_TYPES: IFlowType[] = [
  {
    id: PackagingCreationType.VIAL,
    label: 'VIAL',
    subtitle: 'Create VIAL packaging'
  },
  {
    id: PackagingCreationType.NO_VIAL,
    label: 'STANDARD',
    subtitle: 'Create STANDARD packaging'
  },
  {
    id: PackagingCreationType.MULTI,
    label: 'MULTIPACKAGING',
    subtitle: 'Create Multipackaging'
  }
]
