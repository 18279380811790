import { get } from 'lodash'
import { EntityType, IEntity } from '../features/entity/model'
import { storeProdTypeToForm } from '../features/storeProducts/constants'
import {
  IStoreProd,
  IStoreProdChild,
  ProductFormType,
  StoreProdType
} from '../features/storeProducts/model'

export interface IEntityCheckFieldArgs {
  type: EntityType
  key: keyof IEntity
  isMulti: boolean
  isChild: boolean
}
export const fieldVisibilityByType: (args: IEntityCheckFieldArgs) => boolean = ({
  type,
  key,
  isMulti,
  isChild
}) => {
  const isPackageStandard = type === 'PRODUCT_WITH_PACKAGE' && !isMulti
  const isPackageMulti = type === 'PRODUCT_WITH_PACKAGE' && isMulti
  if (key === 'image') {
    return type === 'PACKAGE' || isPackageStandard || type === 'BULK_WITHOUT_PACKAGE'
  }
  if (key === 'finalName') {
    if (isChild) {
      return (
        isPackageMulti ||
        type === 'MULTIBULK_WITH_PACKAGE' ||
        type === 'PALETTE' ||
        type === 'MULTIBULK_WITHOUT_PACKAGE'
      )
    }
    return (
      type === 'PACKAGE' ||
      isPackageStandard ||
      type === 'BULK_WITHOUT_PACKAGE' ||
      isPackageMulti ||
      type === 'MULTIBULK_WITH_PACKAGE' ||
      type === 'PALETTE' ||
      type === 'MULTIBULK_WITHOUT_PACKAGE'
    )
  }
  if (key === 'applicationArea') {
    if (isChild) {
      return (
        isPackageMulti ||
        type === 'MULTIBULK_WITH_PACKAGE' ||
        type === 'PALETTE' ||
        type === 'MULTIBULK_WITHOUT_PACKAGE'
      )
    }
    return (
      isPackageStandard ||
      isPackageMulti ||
      type === 'BULK_WITHOUT_PACKAGE' ||
      type === 'MULTIBULK_WITH_PACKAGE' ||
      type === 'PALETTE' ||
      type === 'MULTIBULK_WITHOUT_PACKAGE'
    )
  }
  if (key === 'productForm') {
    return (
      isPackageStandard ||
      type === 'BULK_WITHOUT_PACKAGE' ||
      isPackageMulti ||
      type === 'MULTIBULK_WITH_PACKAGE' ||
      type === 'PALETTE' ||
      type === 'MULTIBULK_WITHOUT_PACKAGE'
    )
  }
  if (key === 'productCategory') {
    if (isChild) {
      return (
        isPackageMulti ||
        type === 'MULTIBULK_WITH_PACKAGE' ||
        type === 'PALETTE' ||
        type === 'MULTIBULK_WITHOUT_PACKAGE'
      )
    }
    return isPackageStandard || type === 'BULK_WITHOUT_PACKAGE'
  }
  if (key === 'launchYear') {
    if (isChild) {
      return (
        isPackageMulti ||
        type === 'MULTIBULK_WITH_PACKAGE' ||
        type === 'PALETTE' ||
        type === 'MULTIBULK_WITHOUT_PACKAGE'
      )
    }
    return isPackageStandard || type === 'BULK_WITHOUT_PACKAGE'
  }
  if (key === 'collection') {
    if (isChild) {
      return (
        isPackageMulti ||
        type === 'MULTIBULK_WITH_PACKAGE' ||
        type === 'PALETTE' ||
        type === 'MULTIBULK_WITHOUT_PACKAGE'
      )
    }
    return isPackageStandard || type === 'BULK_WITHOUT_PACKAGE'
  }
  if (key === 'family') {
    if (isChild) {
      return (
        isPackageMulti ||
        type === 'MULTIBULK_WITH_PACKAGE' ||
        type === 'PALETTE' ||
        type === 'MULTIBULK_WITHOUT_PACKAGE'
      )
    }
    return isPackageStandard || type === 'BULK_WITHOUT_PACKAGE'
  }
  if (key === 'labCode') {
    return type === 'PACKAGE'
  }
  if (key === 'finish') {
    return type === 'PACKAGE'
  }
  if (key === 'note') {
    if (isChild) {
      return (
        isPackageMulti ||
        type === 'MULTIBULK_WITH_PACKAGE' ||
        type === 'PALETTE' ||
        type === 'MULTIBULK_WITHOUT_PACKAGE'
      )
    }
    return type === 'PACKAGE' || isPackageStandard || type === 'BULK_WITHOUT_PACKAGE'
  }
  if (key === 'groupCode') {
    return (
      isPackageStandard ||
      type === 'BULK_WITHOUT_PACKAGE' ||
      isPackageMulti ||
      type === 'MULTIBULK_WITH_PACKAGE' ||
      type === 'PALETTE' ||
      type === 'MULTIBULK_WITHOUT_PACKAGE'
    )
  }
  if (key === 'colorNameOnLabel') {
    return (
      type === 'PACKAGE' ||
      isPackageStandard ||
      type === 'BULK_WITHOUT_PACKAGE' ||
      isPackageMulti ||
      type === 'MULTIBULK_WITH_PACKAGE' ||
      type === 'PALETTE' ||
      type === 'MULTIBULK_WITHOUT_PACKAGE'
    )
  }
  if (key === 'labCodeOnLabel') {
    return type === 'PACKAGE' || isPackageStandard || type === 'BULK_WITHOUT_PACKAGE'
  }
  if (key === 'shakeWell') {
    return type === 'PACKAGE' || isPackageStandard || type === 'BULK_WITHOUT_PACKAGE'
  }
  if (key === 'SPF') {
    return type === 'PACKAGE' || isPackageStandard || type === 'BULK_WITHOUT_PACKAGE'
  }
  return true
}

export const refactorDataForSummary = (data: IEntity) => {
  return {
    ...data,
    thumbnail: { url: data.image?.value || '' },
    productCode: data.packagingCode || data.packaging?.packagingCode || ''
  }
}

export const refactoredDataOnCreate = (data: IEntity) => {
  return {
    // TODO  - still not sure the aspect of the payload
    document: {
      SPF: data.SPF || null,
      collection: data.collection || null,
      colorNameOnLabel: data.colorNameOnLabel || null,
      family: data.family || null,
      finalName: data.finalName || null,
      labCodeOnLabel: data.labCodeOnLabel || null,
      launchYear: data.launchYear || null,
      groupCode: data.groupCode || null,
      note: data.note || null,
      packaging: data.packaging || null,
      shakeWell: data.shakeWell || null,
      sides: data.sides || null,
      type: data.type || null
    },
    image: !data.usePackageImage
      ? get(window, `tmpFiles.${data?.image?.value || ''}`, null)
      : null
  }
}

export const refactoredStoreProdNewData = (data: IStoreProd) => {
  const { type, children, ...payload } = data

  const childrenWithoutIds = children?.map((ch) => {
    const { id, ...rest } = ch
    return rest
  })

  // TODO: modify what comes from the form instead of taking in all the fields
  return {
    document: {
      ...payload,
      children: childrenWithoutIds,
      createdAt: undefined,
      createdBy: undefined,
      updatedAt: undefined,
      updatedBy: undefined,
      deletedAt: undefined,
      deletetBy: undefined,
      thumbnail: undefined,
      thumbnailld: undefined,
      availableQuantity: undefined,
      image: undefined,
      color: payload.color || null,
      applicationArea: payload.applicationArea || null
    },
    image: get(window, `tmpFiles.${payload?.image?.value || ''}`, null)
  }
}

export const refactoredStoreProdChildNewData = (data: IStoreProd) => {
  const { type, ...payload } = data
  return {
    ...payload,
    createdAt: undefined,
    createdBy: undefined,
    updatedAt: undefined,
    updatedBy: undefined,
    deletedAt: undefined,
    deletetBy: undefined,
    thumbnail: undefined,
    thumbnailld: undefined,
    availableQuantity: undefined,
    image: undefined,

    color: payload.color || null,
    applicationArea: payload.applicationArea || null,
    collection: payload.collection || null
  }
}

type K = keyof IStoreProd
const PRODUCT_WITH_PACKAGE_STANDARD_FIELDS: K[] = [
  'productCode',
  'availableQuantity',
  'noteSR',
  'productForm',
  'color',
  'labCode',
  'industrialCode',
  'finish',
  'applicationArea',
  'productCategory',
  'finalName',
  'panMold',
  'packagingRef',
  'pmBU',
  'noteBU',
  'formulaSchemaCode',
  'launchYear',
  'collection',
  'family',
  'colorNameOnLabel',
  'labCodeInLabel',
  'shakeWell',
  'SPF',
  'FPO'
]

const BULK_WITHOUT_PACKAGE_FIELDS: K[] = [
  'productCode',
  'availableQuantity',
  'noteSR',
  'productForm',
  'color',
  'labCode',
  'industrialCode',
  'finish',
  'applicationArea',
  'productCategory',
  'finalName',
  'panMold',
  'packagingRef',
  'pmBU',
  'noteBU',
  'formulaSchemaCode',
  'launchYear',
  'collection',
  'family',
  'colorNameOnLabel',
  'labCodeInLabel',
  'shakeWell',
  'SPF',
  'FPO'
]

const PACKAGE_FIELDS: K[] = [
  'productCode',
  'availableQuantity',
  'noteSR',
  'productForm',
  'color',
  'labCode',
  'industrialCode',
  'finish',
  'applicationArea',
  'productCategory',
  'finalName',
  'panMold',
  'packagingRef',
  'pmBU',
  'noteBU',
  'formulaSchemaCode',
  'launchYear',
  'collection',
  'family',
  'colorNameOnLabel',
  'labCodeInLabel',
  'shakeWell',
  'SPF',
  'FPO'
]

const PRODUCT_WITH_MULTIPACKAGE_FIELDS: K[] = [
  'productCode',
  'availableQuantity',
  'noteSR',
  'productForm',
  'groupCode',
  'applicationArea',
  'finalName',
  'panMold',
  'packagingRef',
  'launchYear',
  'collection',
  'family',
  'pmBU',
  'noteBU',
  'colorNameOnLabel',
  'labCodeInLabel',
  'shakeWell',
  'SPF',
  'children',
  'FPO'
]

const MULTIBULK_WITH_PACKAGE_FIELDS: K[] = [
  'productCode',
  'availableQuantity',
  'noteSR',
  'productForm',
  'groupCode',
  'applicationArea',
  'finalName',
  'panMold',
  'packagingRef',
  'launchYear',
  'collection',
  'family',
  'pmBU',
  'noteBU',
  'colorNameOnLabel',
  'labCodeInLabel',
  'shakeWell',
  'SPF',
  'children',
  'FPO'
]

const PALETTE_FIELDS: K[] = [
  'productCode',
  'availableQuantity',
  'noteSR',
  'productForm',
  'groupCode',
  'applicationArea',
  'finalName',
  'panMold',
  'packagingRef',
  'launchYear',
  'collection',
  'family',
  'pmBU',
  'noteBU',
  'colorNameOnLabel',
  'labCodeInLabel',
  'shakeWell',
  'SPF',
  'children',
  'FPO'
]

const MULTIBULK_WITHOUT_PACKAGE_FIELDS: K[] = [
  'productCode',
  'availableQuantity',
  'noteSR',
  'productForm',
  'groupCode',
  'applicationArea',
  'finalName',
  'panMold',
  'packagingRef',
  'launchYear',
  'collection',
  'family',
  'pmBU',
  'noteBU',
  'colorNameOnLabel',
  'labCodeInLabel',
  'shakeWell',
  'SPF',
  'children',
  'FPO'
]

export const entityFieldVisibileByType: (arg: {
  type?: StoreProdType
  key: keyof IStoreProd
}) => boolean = ({ type, key }) => {
  if (type === 'PRODUCT_WITH_PACKAGE_STANDARD') {
    return PRODUCT_WITH_PACKAGE_STANDARD_FIELDS.includes(key)
  }
  if (type === 'BULK_WITHOUT_PACKAGE') {
    return BULK_WITHOUT_PACKAGE_FIELDS.includes(key)
  }
  if (type === 'PACKAGE') {
    return PACKAGE_FIELDS.includes(key)
  }
  if (type === 'PRODUCT_WITH_MULTIPACKAGE') {
    return PRODUCT_WITH_MULTIPACKAGE_FIELDS.includes(key)
  }
  if (type === 'MULTIBULK_WITH_PACKAGE') {
    return MULTIBULK_WITH_PACKAGE_FIELDS.includes(key)
  }
  if (type === 'PALETTE') {
    return PALETTE_FIELDS.includes(key)
  }
  if (type === 'MULTIBULK_WITHOUT_PACKAGE') {
    return MULTIBULK_WITHOUT_PACKAGE_FIELDS.includes(key)
  }
  return true
}

const BULK_WITHOUT_PACKAGE_DISABLE_KEY: K[] = ['productForm']
const PACKAGE_DISABLE_KEY: K[] = ['productForm']
const PRODUCT_WITH_MULTIPACKAGE_DISABLE_KEY: K[] = ['productForm']
const MULTIBULK_WITH_PACKAGE_DISABLE_KEY: K[] = ['productForm']
const PALETTE_DISABLE_KEY: K[] = ['productForm']
const MULTIBULK_WITHOUT_PACKAGE_DISABLE_KEY: K[] = ['productForm']

export const entityFieldDisaleByType: (arg: {
  type: StoreProdType
  key: keyof IStoreProd
}) => boolean = ({ type, key }) => {
  if (type === 'BULK_WITHOUT_PACKAGE') {
    return BULK_WITHOUT_PACKAGE_DISABLE_KEY.includes(key)
  }
  if (type === 'PACKAGE') {
    return PACKAGE_DISABLE_KEY.includes(key)
  }
  if (type === 'PRODUCT_WITH_MULTIPACKAGE') {
    return PRODUCT_WITH_MULTIPACKAGE_DISABLE_KEY.includes(key)
  }
  if (type === 'MULTIBULK_WITH_PACKAGE') {
    return MULTIBULK_WITH_PACKAGE_DISABLE_KEY.includes(key)
  }
  if (type === 'PALETTE') {
    return PALETTE_DISABLE_KEY.includes(key)
  }
  if (type === 'MULTIBULK_WITHOUT_PACKAGE') {
    return MULTIBULK_WITHOUT_PACKAGE_DISABLE_KEY.includes(key)
  }
  return false
}

export const checkDisableFieldInUpdate: (arg: {
  data: IStoreProd
  type: StoreProdType
  key: keyof IStoreProd
}) => boolean = ({ data, type, key }) => {
  if (type === 'PRODUCT_WITH_PACKAGE_STANDARD') {
    if (PRODUCT_WITH_PACKAGE_STANDARD_MANDATORY_FIELDS.includes(key)) {
      if (key === 'industrialCode' || key === 'labCode') {
        if (data.industrialCode) {
          return key === 'industrialCode'
        } else {
          return key === 'labCode'
        }
      }
      return true
    }
  }
  if (type === 'BULK_WITHOUT_PACKAGE') {
    if (BULK_WITHOUT_PACKAGE_MANDATORY_FIELDS.includes(key) || key === 'productForm') {
      if (key === 'industrialCode' || key === 'labCode') {
        if (data.industrialCode) {
          return key === 'industrialCode'
        } else {
          return key === 'labCode'
        }
      }
      return true
    }
  }

  if (type === 'PACKAGE') {
    return PACKAGE_MANDATORY_FIELDS.includes(key) || key === 'productForm'
  }
  if (type === 'PRODUCT_WITH_MULTIPACKAGE') {
    return (
      PRODUCT_WITH_MULTIPACKAGE_MANDATORY_FIELDS.includes(key) || key === 'productForm'
    )
  }
  if (type === 'MULTIBULK_WITH_PACKAGE') {
    return MULTIBULK_WITH_PACKAGE_MANDATORY_FIELDS.includes(key) || key === 'productForm'
  }
  if (type === 'PALETTE') {
    return PALETTE_MANDATORY_FIELDS.includes(key) || key === 'productForm'
  }
  if (type === 'MULTIBULK_WITHOUT_PACKAGE') {
    return (
      MULTIBULK_WITHOUT_PACKAGE_MANDATORY_FIELDS.includes(key) || key === 'productForm'
    )
  }
  return false
}

export const PRODUCT_WITH_PACKAGE_STANDARD_MANDATORY_FIELDS = [
  'productForm',
  'labCode',
  'industrialCode',
  'productCategory',
  'packagingRef',
  'formulaSchemaCode',
  'launchYear',
  'collection'
]

export const BULK_WITHOUT_PACKAGE_MANDATORY_FIELDS = [
  'labCode',
  'industrialCode',
  'panMold',
  'launchYear',
  'collection'
]

export const PACKAGE_MANDATORY_FIELDS = [
  'finalName',
  'packagingRef',
  'launchYear',
  'collection'
]

export const PRODUCT_WITH_MULTIPACKAGE_MANDATORY_FIELDS = [
  'packagingRef',
  'launchYear',
  'collection',
  'children'
]

export const MULTIBULK_WITH_PACKAGE_MANDATORY_FIELDS = [
  'packagingRef',
  'launchYear',
  'collection',
  'children'
]

export const PALETTE_MANDATORY_FIELDS = [
  'finalName',
  'packagingRef',
  'launchYear',
  'collection',
  'family',
  'children'
]

export const MULTIBULK_WITHOUT_PACKAGE_MANDATORY_FIELDS = [
  'panMold',
  'launchYear',
  'collection',
  'children'
]

export const checkRequiredFieldByType: (arg: {
  data?: IStoreProd
  key: keyof IStoreProd
  type?: StoreProdType
}) => boolean = ({ data, key, type }) => {
  if (type === 'PRODUCT_WITH_PACKAGE_STANDARD') {
    if (PRODUCT_WITH_PACKAGE_STANDARD_MANDATORY_FIELDS.includes(key)) {
      if (key === 'industrialCode') {
        return !data?.labCode
      } else if (key === 'labCode') {
        return !data?.industrialCode
      }
      return !data?.[key]
    }
    return false
  }
  if (type === 'BULK_WITHOUT_PACKAGE') {
    return BULK_WITHOUT_PACKAGE_MANDATORY_FIELDS.includes(key)
  }
  if (type === 'MULTIBULK_WITHOUT_PACKAGE') {
    return MULTIBULK_WITHOUT_PACKAGE_MANDATORY_FIELDS.includes(key)
  }
  if (type === 'MULTIBULK_WITH_PACKAGE') {
    return MULTIBULK_WITH_PACKAGE_MANDATORY_FIELDS.includes(key)
  }
  if (type === 'PACKAGE') {
    return PACKAGE_MANDATORY_FIELDS.includes(key)
  }
  if (type === 'PALETTE') {
    return PALETTE_MANDATORY_FIELDS.includes(key)
  }
  if (type === 'PRODUCT_WITH_MULTIPACKAGE') {
    return PRODUCT_WITH_MULTIPACKAGE_MANDATORY_FIELDS.includes(key)
  }
  return false
}

export const checkFieldValidationByType: (arg: {
  data: IStoreProd
  key: keyof IStoreProd
  type?: StoreProdType
}) => boolean = ({ data, key, type }) => {
  if (!type) return false
  if (type === 'PRODUCT_WITH_PACKAGE_STANDARD') {
    if (PRODUCT_WITH_PACKAGE_STANDARD_MANDATORY_FIELDS.includes(key)) {
      if (key === 'industrialCode' || key === 'labCode') {
        return !data.labCode && !data.industrialCode
      }
      return !data[key]
    }
    return false
  }
  if (type === 'BULK_WITHOUT_PACKAGE') {
    if (BULK_WITHOUT_PACKAGE_MANDATORY_FIELDS.includes(key)) {
      if (key === 'industrialCode' || key === 'labCode') {
        return !data.labCode && !data.industrialCode
      }
      return !data[key]
    }
    return false
  }
  if (type === 'PACKAGE') {
    if (PACKAGE_MANDATORY_FIELDS.includes(key)) {
      return !data[key]
    }
    return false
  }
  if (type === 'PRODUCT_WITH_MULTIPACKAGE') {
    if (PRODUCT_WITH_MULTIPACKAGE_MANDATORY_FIELDS.includes(key)) {
      if (key === 'children') {
        return !(data.children || []).length
      }
      return !data[key]
    }
    return false
  }
  if (type === 'MULTIBULK_WITH_PACKAGE') {
    if (MULTIBULK_WITH_PACKAGE_MANDATORY_FIELDS.includes(key)) {
      if (key === 'children') {
        return !(data.children || []).length
      }
      return !data[key]
    }
    return false
  }
  if (type === 'PALETTE') {
    if (PALETTE_MANDATORY_FIELDS.includes(key)) {
      if (key === 'children') {
        return !(data.children || []).length
      }
      return !data[key]
    }
    return false
  }
  if (type === 'MULTIBULK_WITHOUT_PACKAGE') {
    if (MULTIBULK_WITHOUT_PACKAGE_MANDATORY_FIELDS.includes(key)) {
      if (key === 'children') {
        return !(data.children || []).length
      }
      return !data[key]
    }
    return false
  }
  return false
}

export const checkMantatoryFieldInCreate: (arg: {
  data: IStoreProd
  type?: StoreProdType
}) => boolean = ({ data, type }) => {
  if (type === 'PRODUCT_WITH_PACKAGE_STANDARD') {
    return !!(
      data.productForm &&
      (data.labCode || data.industrialCode) &&
      data.productCategory &&
      data.packagingRef &&
      data.formulaSchemaCode &&
      data.launchYear &&
      data.collection
    )
  }
  if (type === 'BULK_WITHOUT_PACKAGE') {
    return !!(
      (data.labCode || data.industrialCode) &&
      data.panMold &&
      data.launchYear &&
      data.collection
    )
  }
  if (type === 'PACKAGE') {
    return !!(data.finalName && data.packagingRef && data.launchYear && data.collection)
  }
  if (type === 'PRODUCT_WITH_MULTIPACKAGE') {
    return !!(
      data.packagingRef &&
      data.launchYear &&
      data.collection &&
      (data.children || []).length
    )
  }
  if (type === 'MULTIBULK_WITH_PACKAGE') {
    return !!(
      data.packagingRef &&
      data.launchYear &&
      data.collection &&
      (data.children || []).length
    )
  }
  if (type === 'PALETTE') {
    return !!(
      data.finalName &&
      data.packagingRef &&
      data.launchYear &&
      data.collection &&
      data.family &&
      (data.children || []).length
    )
  }
  if (type === 'MULTIBULK_WITHOUT_PACKAGE') {
    return !!(
      data.panMold &&
      data.launchYear &&
      data.collection &&
      (data.children || []).length
    )
  }

  return false
}

export const MULTIBULK_WITHOUT_PACKAGE_CHILD_MANDTR_FIELDS = ['industrialCode', 'labCode']

export const checkChildRequiredFieldByType: (arg: {
  key: keyof IStoreProdChild
  type?: StoreProdType
}) => boolean = ({ key, type }) => {
  if (type === 'MULTIBULK_WITHOUT_PACKAGE') {
    return MULTIBULK_WITHOUT_PACKAGE_CHILD_MANDTR_FIELDS.includes(key)
  }
  return false
}

export const checkChildMantatoryFieldInCreate: (arg: {
  data: IStoreProdChild
  type?: StoreProdType
}) => boolean = ({ data, type }) => {
  if (type === 'MULTIBULK_WITHOUT_PACKAGE') {
    return !!(data.industrialCode || data.labCode)
  }
  return Object.values(data).some((val) => !!val)
}

export const checkChildFieldValidationByType: (arg: {
  data: IStoreProdChild
  key: keyof IStoreProdChild
  type?: StoreProdType
}) => boolean = ({ data, key, type }) => {
  if (type === 'MULTIBULK_WITHOUT_PACKAGE') {
    if (MULTIBULK_WITHOUT_PACKAGE_CHILD_MANDTR_FIELDS.includes(key)) {
      if (key === 'industrialCode' || key === 'labCode') {
        return !data.labCode && !data.industrialCode
      }
      return !data[key]
    }
  }
  return false
}

export const checkChildMantatoryFieldInUpdate: (arg: {
  data: IStoreProdChild
  type: string
}) => boolean = ({ data, type }) => {
  if (type === 'MULTIBULK_WITHOUT_PACKAGE') {
    return !!(data.formulaSchemaCode && (data.industrialCode || data.labCode))
  }
  return true
}

export const getTypeFromProdForm: (arg: {
  productForm: ProductFormType
}) => StoreProdType = ({ productForm }) => {
  let type = Object.entries(storeProdTypeToForm).reduce((acc, [key, val]) => {
    if (val === productForm) {
      acc = key
    }
    return acc
  }, '')
  if (!type) type = 'PRODUCT_WITH_PACKAGE_STANDARD'
  return type as StoreProdType
}
