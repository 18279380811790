import { Empty } from 'antd'
import { ReactNode } from 'react'
import { StyledEmpty } from './styled'

type Props = {
  title: string
  children?: ReactNode
}

export const EmptyState = ({ children, title }: Props) => (
  <StyledEmpty image={Empty.PRESENTED_IMAGE_SIMPLE} description={title}>
    {children}
  </StyledEmpty>
)
