import { Form, Radio as RadioAnt, Space } from 'antd'
import { RadioChangeEvent } from 'antd/lib/radio'
import { Text } from '../../Text'

type Props = {
  items: Record<string, string>[]
  onChange: (e: string) => void
  defaultValue: string
  required?: boolean
  value: string
  placeholder?: string
  title?: string
  direction?: boolean
  tranformInBoolean?: boolean
}

export const RadioList = ({
  items,
  onChange,
  value,
  placeholder,
  defaultValue,
  required,
  direction,
  title,
  tranformInBoolean
}: Props) => {
  const onChangeVal = (e: RadioChangeEvent) => {
    const target = e.target.value
    return onChange(tranformInBoolean ? JSON.parse(target) : target)
  }
  const valueSelected = (value || '').toString()

  return (
    <Form.Item required={required} label={placeholder}>
      {title && <Text text={title} />}
      <RadioAnt.Group
        value={valueSelected}
        onChange={onChangeVal}
        defaultValue={defaultValue}
      >
        <Space direction={direction ? 'horizontal' : 'vertical'}>
          {items.map((rec) => (
            <RadioAnt key={rec.value} value={rec.value}>
              {rec.name}
            </RadioAnt>
          ))}
        </Space>
      </RadioAnt.Group>
    </Form.Item>
  )
}
