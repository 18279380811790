import { Form } from 'antd'
import React, { FC } from 'react'
import { InfoCell } from '../../../components'
import { BoxWrapper } from '../../../components/BoxWrapper'
import {
  applicationAreaDrpDwn,
  collectionDropDown,
  colorDropDown
} from '../../../features/storeProducts/constants'
import { IStoreProdChild } from '../../../features/storeProducts/model'
import { IDetailContentProps } from './types'

const DetailContent: FC<IDetailContentProps> = ({
  data,
  onUpdate = () => {},
  isEditing = false
}) => {
  const updateHandler = (key: keyof IStoreProdChild, value: any) => {
    onUpdate(key, value)
  }

  return (
    <Form layout="vertical">
      <BoxWrapper enablePadding>
        <InfoCell
          label="Formula Schema"
          value={data.formulaSchemaCode || ''}
          inputConfig={{
            onChange: (value: string) => updateHandler('formulaSchemaCode', value)
          }}
          isEditing={isEditing}
        />
        <InfoCell
          label="Industrial Code"
          value={data.industrialCode || ''}
          inputConfig={{
            onChange: (value: string) => updateHandler('industrialCode', value)
          }}
          isEditing={isEditing}
        />
        <InfoCell
          label="Lab Code"
          value={data.labCode || ''}
          inputConfig={{
            onChange: (value: string) => updateHandler('labCode', value)
          }}
          isEditing={isEditing}
        />
        <InfoCell
          label="Final Name"
          value={data.finalName || ''}
          inputConfig={{
            onChange: (value: string) => updateHandler('finalName', value)
          }}
          isEditing={isEditing}
        />
        <InfoCell
          label="Application Area"
          inputType="select"
          value={data.applicationArea || ''}
          inputConfig={{
            items: applicationAreaDrpDwn,
            onChange: ({ value }: { value: string }) =>
              updateHandler('applicationArea', value)
          }}
          isEditing={isEditing}
        />
        <InfoCell
          label="Product Category"
          value={data.productCategory || ''}
          inputConfig={{
            onChange: (value: string) => updateHandler('productCategory', value)
          }}
          isEditing={isEditing}
        />
        <InfoCell
          label="Color"
          inputType="select"
          value={data.color || ''}
          inputConfig={{
            onChange: ({ value }: { value: string }) => updateHandler('color', value),
            items: colorDropDown
          }}
          isEditing={isEditing}
        />
        <InfoCell
          label="Finish"
          value={data.finish || ''}
          inputConfig={{
            onChange: (value: string) => updateHandler('finish', value)
          }}
          isEditing={isEditing}
        />
        <InfoCell
          label="Launch Year"
          value={data.launchYear || ''}
          inputConfig={{
            onChange: (value: string) => updateHandler('launchYear', value)
          }}
          isEditing={isEditing}
        />
        <InfoCell
          label="Collection"
          value={data.collection || ''}
          inputType="select"
          inputConfig={{
            onChange: ({ value }: { value: string }) =>
              updateHandler('collection', value),
            items: collectionDropDown
          }}
          isEditing={isEditing}
        />
        <InfoCell
          label="Family"
          value={data.family || ''}
          inputConfig={{
            onChange: (value: string) => updateHandler('family', value)
          }}
          isEditing={isEditing}
        />
        <InfoCell
          label="Note SR"
          value={data.noteSR || ''}
          inputConfig={{ onChange: (value: string) => updateHandler('noteSR', value) }}
          isEditing={isEditing}
        />
      </BoxWrapper>
    </Form>
  )
}

export default DetailContent
