import { get } from 'lodash'
import { FC, useCallback } from 'react'
import { generatePath, useLocation, useNavigate } from 'react-router-dom'
import { AppRoutes } from '../../../../pages/constants'
import { flattenObj } from '../../../LinkTo'
import Button from '../../../NewButton'
import { IDetailBtn } from './types'

const DetailBtn: FC<IDetailBtn> = ({
  btnLabel,
  basePath,
  pathKey,
  rec,
  btnCallback,
  hasIcon,
  iconName,
  newPage,
  btnVariant,
  enableTooltip,
  tooltipLabel,
  destinationPath = ''
}) => {
  const navigate = useNavigate()
  const location = useLocation()

  const getPath = () => {
    const { pathname } = location
    const rowId = pathKey ? rec[pathKey] : get(rec, 'id', '')
    switch (pathname) {
      case AppRoutes.STORE_PRODUCTS:
        return `/store/products/${rowId}`
      case AppRoutes.STORE_ORDERS: {
        return `/store/orders/${rowId.replace('ORDER', '').trim()}`
      }
      default:
        console.warn(
          'try to add basePath and/or pathKey or change getPath function in DetailBtn'
        )
        return '/'
    }
  }

  const getDestinationPath = () => {
    const dataPathname = flattenObj(rec)
    return generatePath(destinationPath, dataPathname)
  }

  // TODO - OPTIMIZATION
  // getDestinationPath much more better, later getPath basePath and pathKey should be removed
  const path = destinationPath
    ? getDestinationPath()
    : basePath && pathKey
    ? `/${basePath}/${rec[pathKey]}`
    : getPath()

  const goToDetails = useCallback(() => {
    if (btnCallback) {
      btnCallback(rec)
    }
    if (newPage) {
      const url = `${window.location.origin}${path}`
      window.open(url, '_blank')
    } else {
      navigate(path)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, path, btnCallback, rec])

  return (
    <Button
      enableTooltip={enableTooltip}
      tooltipLabel={tooltipLabel}
      label={btnLabel}
      onClick={goToDetails}
      iconName={hasIcon ? iconName : ''}
      variant={btnVariant}
    />
  )
}

export default DetailBtn
