import styled from 'styled-components'

export const StyledTagsContainer = styled.div`
  display: flex;
  overflow-y: hidden;
  ::-webkit-scrollbar {
    display: none;
  }
`

export const StyledTagsContainerInternal = styled.div`
  display: flex;
`
