import { TablePaginationConfig } from 'antd'
import { isEmpty } from 'lodash'
import { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ProductSearchForm } from '../..'
import { Button, SectionTitle } from '../../../components'
import { Divider } from '../../../components/Divider'
import { FlexSection } from '../../../components/FlexSection'
import { Loader } from '../../../components/Loader'
import { Modal } from '../../../components/Modal'
import { ISortData } from '../../../components/NewTable/types'
import ProductsTable from '../../../components/Product/ProductsTable'
import { TagsContainer } from '../../../components/TagsContainer'
import { actions as prodActions } from '../../../features/product'
import { DYNAMIC_TAXONOMIES } from '../../../features/product/constants'
import { selectProdIsLoading } from '../../../features/product/selectors'
import { actions as productsActions } from '../../../features/products'
import {
  getFilters,
  selectLoader,
  selectProductPagination,
  selectProductsItem,
  selectTempFilter
} from '../../../features/products/selectors'
import { selectPermissions } from '../../../features/users/selectors'
import { unsetKeyByPath } from '../../../utils'
import { filterProdListByStatus } from '../../../utils/productHelper'
import { ButtonWrapper, ExportBtnWrapper, FilterBtnWrapper, TagsWrapper } from './styled'
import { filterWidth } from './styled'
import { exportBtn } from './styled'

const ResultSection: FC = () => {
  const dispatch = useDispatch()
  const permissions = useSelector(selectPermissions)
  const tempFilter = useSelector(selectTempFilter)
  const productList = useSelector(selectProductsItem)
  const pageData = useSelector(selectProductPagination)
  const isLoading = useSelector(selectLoader)
  const isFormLoading = useSelector(selectProdIsLoading)
  const filters = useSelector(getFilters)

  const removeTagHandler = (path: string) => {
    dispatch(productsActions.cancelFilter({ path }))
    dispatch(productsActions.searchProducts())
    const isDynamicTaxonomies = DYNAMIC_TAXONOMIES.some((val) => path.includes(val))
    if (isDynamicTaxonomies) {
      const refactorPath = path.replace('filters.', '')
      const newFilters = unsetKeyByPath(filters, refactorPath)
      dispatch(prodActions.filterTaxonomies({ filters: newFilters }))
    }
  }

  const changePageHandler = (pageData: TablePaginationConfig, sortData?: ISortData) => {
    dispatch(
      productsActions.changePage({
        page: pageData.current || 1,
        field: sortData?.field || '',
        order: sortData?.order || 'ascend'
      })
    )
  }

  const searchHandler = () => {
    dispatch({ type: 'app/closeModal', payload: { modalKey: 'products-filter' } })
    dispatch(productsActions.applyFilters())
    dispatch(productsActions.searchProducts())
  }

  const checkIfDisabled = () =>
    Object.values(tempFilter).every(
      (value) => value === null || value === '' || value === undefined
    ) || isLoading

  const filteredList = filterProdListByStatus(productList, permissions)

  const hasExport = !!filteredList.length && (permissions.vaultProduct?.canRead || false)

  return (
    <div>
      <SectionTitle value="Products" isBold />
      <Divider disableBorder />
      <FlexSection
        flexDirection="row"
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <FilterBtnWrapper>
          <Modal
            btnName="Filter"
            title="Filter Products"
            modalKey="products-filter"
            size="large"
            footer={[
              <ButtonWrapper>
                <Button
                  label="Search"
                  onClick={searchHandler}
                  enableKeyPress
                  disabled={isFormLoading || checkIfDisabled()}
                />
              </ButtonWrapper>
            ]}
            btnClicked={() => dispatch(productsActions.filterToTemp())}
          >
            {isFormLoading ? <Loader /> : <ProductSearchForm />}
          </Modal>
        </FilterBtnWrapper>
        <TagsWrapper
          width={`calc(100% - ${filterWidth}px - ${hasExport ? exportBtn : 0}px)`}
        >
          {!isEmpty(filters) && (
            <TagsContainer
              tags={filters}
              blocked={'applicationArea'}
              flowName={'products'}
              reducer={'products'}
              prefix="products"
              childId={'products'}
              basePath="filters"
              onRemoveTag={removeTagHandler}
              allowNoFilters
              inner={false}
            />
          )}
        </TagsWrapper>
        {hasExport && (
          <ExportBtnWrapper>
            <Button
              label="Export Results"
              variant="ghost"
              disabled={isLoading}
              onClick={() => dispatch(productsActions.exportExcel())}
            />
          </ExportBtnWrapper>
        )}
      </FlexSection>
      <ProductsTable
        dataList={filteredList}
        enablePagination
        isLoading={isLoading}
        pageData={pageData}
        onChangePage={changePageHandler}
      />
    </div>
  )
}

export default ResultSection
