import styled from 'styled-components'

export const ModalBodyContainer = styled.div`
  .ant-row {
    padding-left: 8px;
    padding-right: 8px;
  }
`

export const SwitchWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .ant-form-item {
    margin: 0;
  }
`

export const buttonWrapperHeight = 44
export const ButtonWrapper = styled.div`
  height: ${buttonWrapperHeight}px;
  width: 100%;
  padding: 5px 20px;
  background-color: white;
  border-top: 1px solid #eee;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`
