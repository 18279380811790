import { get, isArray, set, unset } from 'lodash'

export const unsetKeyByPath = (data: any, path: string) => {
  const pathArray = path.split('.')
  const pathKey = pathArray.pop()
  const parentPath = pathArray.toString().replaceAll(',', '.') || ''
  const dataCopy = { ...data }
  const parentValue = get(dataCopy, parentPath)
  if (isArray(parentValue) && pathKey) {
    const parentValCopy = [...parentValue]
    parentValCopy.splice(+pathKey, 1)
    set(dataCopy, parentPath, parentValCopy)
  } else {
    unset(dataCopy, path)
  }

  return dataCopy
}
