import incolor from '../../assets/images/incolor.jpg'
import magicolor from '../../assets/images/magicolor.jpg'
import multicolor from '../../assets/images/multicolor.jpg'
import { IHexColor, IMageColor } from './types'

export const HEX_COLOR: IHexColor = {
  BEIGE: '#D3A26C',
  BLACK: '#000000',
  'LIGHT BLUE': '#6EC6D9',
  'GRAY/SILVER': '#C0C0C0',
  BROWN: '#462F18',
  BLUE: '#282E69',
  WHITE: '#FFFFFF',
  ORANGE: '#EC681C',
  PINK: '#FFC0CB',
  VIOLET: '#554596',
  RED: '#E52421',
  'YELLOW / GOLD': '#FFD700',
  GREEN: '#078136'
}

export const IMAGE_COLOR: IMageColor = {
  MULTICOLOR: multicolor,
  INCOLOR: incolor,
  MAGICOLOR: magicolor,
  'GENERIC PIGMENT / PEARLS': magicolor
}
