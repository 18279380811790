import { Button } from 'antd'
import styled from 'styled-components'
import { IStyledButton } from './types'

export const StyledButton = styled(Button)<IStyledButton>`
  min-width: ${({ label }) => (label ? '50px' : '30px')};
  ${({ label }) => !label && { padding: '0px' }};
  ${({ type, theme }) => type === 'text' && { color: theme.palette.primary.main }}
`

export const IconContainer = styled.span<{ label?: string }>`
  ${({ label }) => label && { marginLeft: '8px' }}
`
