import { InfoCircleOutlined } from '@ant-design/icons'
import { Form, Radio, RadioChangeEvent, Space } from 'antd'
import { FC } from 'react'
import { IRadioInputProps } from './types'

const RadioInput: FC<IRadioInputProps> = ({
  label,
  options,
  value,
  onChange = () => {},
  disabled,
  direction = 'horizontal',
  enableTooltip,
  tooltipMessage
}) => {
  const changeHandler = (value: RadioChangeEvent) => {
    onChange(value.target.value)
  }
  const baseDataCy = `radio-${label}-value`

  return (
    <Form.Item
      label={label}
      tooltip={enableTooltip && { title: tooltipMessage, icon: <InfoCircleOutlined /> }}
    >
      <Radio.Group onChange={changeHandler} value={value} disabled={disabled}>
        <Space direction={direction}>
          {options.map(({ label, value }, idx) => (
            <Radio
              value={value}
              key={`${label}-${idx}`}
              data-cy={`${baseDataCy}-${label}`}
            >
              {label}
            </Radio>
          ))}
        </Space>
      </Radio.Group>
    </Form.Item>
  )
}

export default RadioInput
