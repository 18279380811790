import { Steps as StepsAntd } from 'antd'
import React, { ForwardedRef, forwardRef, useEffect, useState } from 'react'
import { StiledSteps, StiledStepsContainer } from './styled'

const { Step } = StepsAntd

type Props = {
  steps: Record<string, string>[]
  container_variables?: Record<string, any>
  progressDot?: boolean
  ref?: ForwardedRef<HTMLDivElement>
}

export const Steps = React.memo(
  forwardRef<HTMLDivElement, Props>(
    ({ steps, container_variables, progressDot = false, ...a }: Props, ref) => {
      const [width, setWidth] = useState<number>(window.innerWidth)

      useEffect(() => {
        function handleResize() {
          setWidth(window.innerWidth)
        }
        window.addEventListener('resize', handleResize)
      }, [])

      return (
        <StiledStepsContainer ref={ref}>
          <StiledSteps
            progressDot={progressDot}
            current={Number(container_variables?.current)}
            direction={width >= 800 ? 'horizontal' : 'vertical'}
          >
            {steps.map((step) => (
              <Step key={step.title} title={step.title} />
            ))}
          </StiledSteps>
        </StiledStepsContainer>
      )
    }
  )
)
