import { memo, ReactNode } from 'react'
import { StyledBoxWrapper, BoxWrapperProps } from './styled'

type Props = {
  children: ReactNode
  className?: string
  flexDirection?: string
  justify?: string
  borderTop?: string
  borderBottom?: string
  display?: boolean
  /**
   * @deprecated This prop will deprecate soon
   */
  columnCount?: number
  padding?: boolean
  enableMargin?: boolean
  sticky?: 'top' | 'bottom'
  sm?: number
  md?: number
  lg?: number
} & BoxWrapperProps

export const BoxWrapper = memo(
  ({
    children,
    flexDirection,
    justify = 'flex-start',
    borderTop,
    borderBottom,
    columnCount,
    enablePadding,
    enableMargin,
    display,
    sticky,
    sm = 2,
    md = 3,
    lg = 4
  }: Props) => {
    const size = {
      sm: sm,
      md: sm >= md ? sm : md,
      lg: sm >= lg ? (sm >= md ? sm : md) : lg
    }

    return (
      <StyledBoxWrapper
        display={display}
        enableMargin={enableMargin}
        flexDirection={flexDirection}
        justifyContent={justify}
        columnCount={columnCount}
        size={size}
        enablePadding={enablePadding}
        borderBottom={borderBottom ? '1px solid #d6d6d6' : 'none'}
        borderTop={borderTop ? '1px solid #d6d6d6' : 'none'}
      >
        {children}
      </StyledBoxWrapper>
    )
  }
)
