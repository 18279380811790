import styled from 'styled-components'
import { IDetail } from './types'

export const ProductCardContainer = styled.div`
  height: 200px;
  width: 100%;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;
`

export const ImageWrapper = styled.div`
  width: 35%;
  div {
    width: 100%;
  }
  img {
    height: 170px;
    width: 100%;
    object-fit: cover;
    margin: 0px;
  }
`

export const DetailsContainer = styled.div`
  padding: 12px;
  padding-left: 25px;
  width: 100%;
  height: 100%;
`

export const Detail = styled.div<IDetail>`
  width: ${({ width }) => width || '30%'};
  display: flex;
  flex-direction: column;
`

export const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
export const CardContainer = styled.div`
  padding: 8px;
`
