import { Form, InputNumber as InputNumberAnt } from 'antd'
import { useState, KeyboardEvent } from 'react'
import { preventNonNumericalInput } from '../../../utils/inputNumberHelpers'
import Button from '../../NewButton'
import { Text } from '../../Text'
import { StyledInputNumber } from './styled'

type Props = {
  onChange: (e: number) => void
  minNumber: number
  maxNumber?: number
  defaultValue?: number
  value?: number
  required?: boolean
  placeholder?: string
  disabled?: boolean
  helperText?: string
}
export const InputNumber = ({
  onChange,
  defaultValue = 0,
  minNumber,
  maxNumber = 0,
  value = 0,
  placeholder = '',
  required = false,
  disabled,
  helperText
}: Props) => {
  const [error, setError] = useState(false)
  const onChangeValue: (n: number) => void = (value = 0) => {
    onChange(value)
  }

  const handleMaxValue = (e: KeyboardEvent<HTMLInputElement>) => {
    setError(false)
    const value = Number(e.currentTarget.value)
    if (maxNumber && value > maxNumber) {
      setError(true)
    }
  }
  return (
    <Form.Item required={required} label={placeholder}>
      {error && (
        <Text text={`The maximum available quantity is ${maxNumber}`} color="red" />
      )}
      <StyledInputNumber>
        <Button
          variant="ghost"
          iconPosition="left"
          iconName="LeftOutlined"
          size="large"
          onClick={() => onChangeValue(--value)}
          disabled={disabled || value <= minNumber}
        />

        <InputNumberAnt
          onKeyUp={handleMaxValue}
          min={minNumber}
          max={maxNumber}
          defaultValue={defaultValue}
          onChange={onChangeValue}
          value={value}
          controls={false}
          size="large"
          className="input"
          disabled={disabled}
          onKeyDown={preventNonNumericalInput}
          placeholder={placeholder}
        />

        <Button
          variant="ghost"
          iconPosition="left"
          iconName="RightOutlined"
          size="large"
          onClick={() => onChangeValue(++value)}
          disabled={disabled || value >= maxNumber}
        />
      </StyledInputNumber>
      {helperText && <Text alert text={helperText} />}
    </Form.Item>
  )
}
