import { IError } from '../../containers/Error/types'

export interface ISuccessInfo {
  title: string
  message: string
}

export enum OrderStatus {
  FORWARDED = 'FORWARDED',
  ACCEPTED = 'ACCEPTED',
  PROCESSED = 'PROCESSED',
  REFUSED = 'REFUSED'
}

export enum OrderAddressedTo {
  CLIENT = 'CLIENT',
  EVENT = 'EVENT',
  OTHER = 'OTHER'
}

export interface IOrderItem {
  id: string
  orderId?: string
  orderableItemId?: string
  productCode?: string | null
  quantity?: number
}
export interface IOrderData {
  id?: string
  createdDate?: string
  fullfillmentDate?: string
  updatedDate?: string
  addressedTo?: OrderAddressedTo
  ownerEmail?: string
  ownerName?: string
  ownerSurname?: string
  status?: OrderStatus
  refuseReason?: string | null
  refuseNote?: string | null
  additionalNote?: string
  notes?: string
  required?: boolean
  shipTo?: string
  regionCountry?: string
  orderItems?: IOrderItem[]
  acceptNote?: string
  processNote?: string
}

export interface IPagination {
  totalPages?: number
  page?: number
  pageSize?: number
  total?: number
}

export interface IOrderListFilters {
  [k: string]: any
}

export interface IOrderListState {
  selectedTab: string
  isTableLoading: boolean
  filters: IOrderListFilters
  tempFilters: IOrderListFilters
  dropDown: Record<string, any>
  pagination: IPagination
  items: IOrderData[]
  orderSelected: IOrderData
  latestForwarded: Record<string, any>
  latestRefused: Record<string, any>
  latestProcessed: Record<string, any>
  tempOrder: IOrderData
  successInfo?: ISuccessInfo
  isLoading: boolean
  error: IError
}

export interface IPagination {
  totalPages?: number
  page?: number
  pageSize?: number
  total?: number
}

export interface IOrdersResultSearch {
  results: IOrderData[]
  page: number
  pageSize: number
  totalPages: number
  total: number
}

export interface IReasonApiData {
  refuseReason: string
  refuseNote: string
}
