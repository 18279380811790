export const masterSpecActionType = {
  MASTER_SPEC_FETCH_ALL: 'masterSpec/MASTER_SPEC_FETCH_ALL',
  PROJECT_SPEC_FETCH_ALL: 'masterSpec/PROJECT_SPEC_FETCH_ALL',
  CHARACTERISTICS_FETCH_ALL: 'masterSpec/CHARACTERISTICS_FETCH_ALL',
  CREATE_MASTER_SPEC: 'masterSpec/CREATE_MASTER_SPEC',
  CREATE_PROJECT_SPEC: 'masterSpec/CREATE_PROJECT_SPEC',
  UPDATE_MASTER_SPEC: 'masterSpec/UPDATE_MASTER_SPEC',
  UPLOAD_MSPEC_THUMBNAIL: 'masterSpec/UPLOAD_MSPEC_THUMBNAIL',
  UPLOAD_UPDATE_CHAR: 'masterSpec/UPLOAD_UPDATE_CHAR',
  UPLOAD_UPDATE_PROJ_CHAR: 'masterSpec/UPLOAD_UPDATE_PROJ_CHAR',
  UPLOAD_MSPEC_CHAR_IMAGE: 'masterSpec/UPLOAD_MSPEC_CHAR_IMAGE',
  UPLOAD_PSPEC_CHAR_IMAGE: 'masterSpec/UPLOAD_PSPEC_CHAR_IMAGE',
  UPDATE_PROJECT_SPEC: 'masterSpec/UPDATE_PROJECT_SPEC',
  UPDATE_PROJECT_SPEC_AND_SEND: 'masterSpec/UPDATE_PROJECT_SPEC_AND_SEND',
  CREATE_PROJECT_SPEC_AND_SEND: 'masterSpec/CREATE_PROJECT_SPEC_AND_SEND',
  CHECK_SAP_CODES: 'masterSpec/CHECK_SAP_CODES',
  DELETE_MASTER_SPEC: 'masterSpec/DELETE_MASTER_SPEC',
  DELETE_PROJECT_SPEC: 'masterSpec/DELETE_PROJECT_SPEC',
  GET_DETAIL: 'masterSpec/GET_DETAIL',
  GET_PROJECT_DETAIL: 'masterSpec/GET_PROJECT_DETAIL',
  GET_SPECIFICATION_SECTIONS: 'masterSpec/GET_SPECIFICATION_SECTIONS',
  APPLY_SAP_CODE_TO_ALL_CHARACTER: 'masterSpec/APPLY_SAP_CODE_TO_ALL_CHARACTER',
  GET_CHARACTERISTICS_AND_APPLY_DEFAULT:
    'masterSpec/GET_CHARACTERISTICS_AND_APPLY_DEFAULT'
}
