import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { get, unset } from 'lodash'
import { IError } from '../../containers/Error/types'
import { getCleanError } from '../../utils'
import {
  APPLY_FILTERS_AND_SEARCH,
  CHANGE_PAGE_AND_SEARCH,
  CHANGE_TAB_AND_SEARCH,
  CHECK_ITEM_AVAILABILITY_FOR_PROPOSE,
  CONFIRM_ACCEPT_ORDER,
  CONFIRM_PROCESS_ORDER,
  CONFIRM_PROPOSE_ORDER,
  CONFIRM_REJECT_ORDER,
  FETCH_LATEST_ORDER,
  FETCH_ORDER_DETAILS,
  SEARCH_ORDER_LIST
} from './constants'
import {
  IOrderData,
  IOrderListFilters,
  IOrderListState,
  IPagination,
  IReasonApiData,
  ISuccessInfo,
  OrderStatus
} from './models'

const refactorDropdown = (key: string, values: Record<string, any>[]) => {
  switch (key) {
    case 'countries':
      return values.map(({ name, days }) => ({ name, value: name, days }))
    case 'owners':
      return values
    case 'refuseReason':
      return values.map(({ text, value }) => ({ name: text, value: value }))
    default:
      return values.map((value) => ({ name: value, value: value }))
  }
}

const initialState: IOrderListState = {
  selectedTab: OrderStatus.FORWARDED,
  isTableLoading: false,
  orderSelected: {},
  items: [],
  filters: {},
  tempFilters: {},
  tempOrder: {},
  dropDown: {},
  pagination: {
    totalPages: 0,
    page: 1,
    pageSize: 10,
    total: 0
  },
  latestForwarded: {},
  latestRefused: {},
  latestProcessed: {},
  error: {},
  successInfo: undefined,
  isLoading: false
}

const orderListSlice = createSlice({
  name: 'orderList',
  initialState,
  reducers: {
    // tabs
    setSelectedTab: (state: IOrderListState, { payload }: PayloadAction<string>) => {
      state.selectedTab = payload
    },
    resetSelectedTab: (state: IOrderListState) => {
      state.selectedTab = initialState.selectedTab
    },
    // loader
    setIsTableLoading: (state: IOrderListState, { payload }: PayloadAction<boolean>) => {
      state.isTableLoading = payload
    },
    // filters
    resetFilters: (state: IOrderListState) => {
      state.filters = initialState.filters
    },
    setFilters: (state: IOrderListState, { payload }: PayloadAction<{}>) => {
      const key: keyof IOrderListFilters = get(payload, 'key', '')
      const value: any = get(payload, 'value', '')
      state.filters[key] = value
    },
    removeFilter: (state: IOrderListState, { payload }: any) => {
      unset(state.filters, payload)
    },
    resetTempFilters: (state: IOrderListState) => {
      state.tempFilters = initialState.tempFilters
    },
    filtersToTemp: (state: IOrderListState) => {
      state.tempFilters = state.filters
    },
    setTempFilters: (state: IOrderListState, { payload }: PayloadAction<{}>) => {
      const key: keyof IOrderListFilters = get(payload, 'key', '')
      const value: any = get(payload, 'value', '')
      state.tempFilters[key] = value
    },
    applyTempFilter: (state: IOrderListState) => {
      state.filters = state.tempFilters
    },
    // dropDown
    setDropDown: (state, { payload }: PayloadAction<Record<string, any>>) => {
      const refactoredPayload = Object.entries(payload).reduce(
        (acc, [k, v]) => ({
          ...acc,
          [k]: refactorDropdown(k, v)
        }),
        {}
      )
      state.dropDown = refactoredPayload
    },
    // pagination
    setPagination: (state, { payload }: PayloadAction<IPagination>) => {
      state.pagination = { ...state.pagination, ...payload }
    },
    resetPagination: (state: IOrderListState) => {
      state.pagination = initialState.pagination
    },
    // orders
    resetData: (state: IOrderListState) => {
      state.items = initialState.items
    },
    setData: (
      state: IOrderListState,
      { payload }: PayloadAction<{ data: IOrderData[] }>
    ) => {
      const { data } = payload
      state.items = data
    },
    setOrder: (
      state: IOrderListState,
      { payload }: PayloadAction<{ data: IOrderData }>
    ) => {
      const { data } = payload
      state.orderSelected = data
    },
    setTempOrder: (
      state: IOrderListState,
      { payload }: PayloadAction<{ data: IOrderData }>
    ) => {
      const { data } = payload
      state.tempOrder = data
    },
    updateTempOrder: (
      state: IOrderListState,
      { payload }: PayloadAction<{ key: keyof IOrderData; value: any }>
    ) => {
      const { key, value } = payload
      state.tempOrder[key] = value
    },
    setLatestForwarded: (
      state: IOrderListState,
      { payload }: PayloadAction<{ data: IOrderData }>
    ) => {
      const { data } = payload
      state.latestForwarded = data
    },
    setLatestRefused: (
      state: IOrderListState,
      { payload }: PayloadAction<{ data: IOrderData }>
    ) => {
      const { data } = payload
      state.latestRefused = data
    },
    setLatestProcessed: (
      state: IOrderListState,
      { payload }: PayloadAction<{ data: IOrderData }>
    ) => {
      const { data } = payload
      state.latestProcessed = data
    },
    setError: (state: IOrderListState, { payload }: PayloadAction<IError>) => {
      state.error = getCleanError(payload)
    },
    clearError: (state: IOrderListState) => {
      state.error = initialState.error
    },
    setSuccess: (state: IOrderListState, { payload }: PayloadAction<ISuccessInfo>) => {
      state.successInfo = payload
    },
    clearSuccess: (state: IOrderListState) => {
      state.successInfo = initialState.successInfo
    },
    setLoader: (state: IOrderListState, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload
    }
  }
})

export const orderListActions = {
  ...orderListSlice.actions,
  searchOrderList: createAction(SEARCH_ORDER_LIST),
  applyFilterAndSearch: createAction(APPLY_FILTERS_AND_SEARCH),
  changeTab: createAction<{}>(CHANGE_TAB_AND_SEARCH),
  changePage: createAction<{ page: number }>(CHANGE_PAGE_AND_SEARCH),
  getOrderDetails: createAction<{ id: string }>(FETCH_ORDER_DETAILS),
  getLatestOrder: createAction(FETCH_LATEST_ORDER),
  confirmReject: createAction<{ id: string; reasonData: IReasonApiData }>(
    CONFIRM_REJECT_ORDER
  ),
  confirmAccept: createAction<{ id: string; acceptNote?: string }>(CONFIRM_ACCEPT_ORDER),
  confirmProcess: createAction<{ id: string; processNote?: string }>(
    CONFIRM_PROCESS_ORDER
  ),
  confirmPropose: createAction<{ orderData: IOrderData }>(CONFIRM_PROPOSE_ORDER),
  checkAvailabilityInPropose: createAction(CHECK_ITEM_AVAILABILITY_FOR_PROPOSE)
}

export const orderListReducer = orderListSlice.reducer
