import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { StyledImageContainer, StyledImage } from './styled'

export type Props = {
  className?: string
  logoUri?: string
}

export const Logo = ({ className, logoUri }: Props) => {
  const navigate = useNavigate()

  const nav = useCallback(
    (path: string) => {
      navigate(path)
    },
    [navigate]
  )

  return (
    <StyledImageContainer onClick={() => nav('/')}>
      <StyledImage className={className} src={logoUri} alt="Logo" />
    </StyledImageContainer>
  )
}
