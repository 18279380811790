import { DatePicker, Form, Input, InputNumber, Select } from 'antd'
import moment from 'moment'
import { FC, useEffect, useState } from 'react'
import { InputNumberWrapper, InputWrapper } from '../../../style/styledInput'
import { Label, Value } from '../../../style/styledLabels'
import { BoxWrapper } from '../../BoxWrapper'
import { TEST_RESULT_DROPDOWN, WEIGHT_LOSS_DROPDOWN } from './utils'

const OtherTestedPackagingForm: FC<any> = ({ data, formInstance, onFinish }) => {
  const [pkCodesDropdown, setPKCodeDropdown] = useState([])
  const [packageCategory, setPackageCategory] = useState(data?.category || '-')

  const mapPkCodesDropdown = (fetchResult: any) => {
    return fetchResult?.map((elem: any) => {
      return {
        label: elem.packagingCode,
        value: elem.id,
        packageCategory: elem.packageCategory
      }
    })
  }

  const handleSearch = async (value: string) => {
    try {
      if (value) {
        if (value.length > 2) {
          const result = await fetch(
            `${process.env.REACT_APP_BASE_URL}/package/search/package-code/${value}`
          )
          const fetchResult = await result.json()
          const mappedValues = mapPkCodesDropdown(fetchResult)
          setPKCodeDropdown(mappedValues)
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    if (formInstance !== null) {
      formInstance.resetFields()
      formInstance.setFieldsValue({
        testNumber: data?.testNumer,
        testedCode: data?.testedCode,
        testStartDate: data.testStartDate && moment(data.testStartDate),
        testResult: data?.testResult,
        weightLossTest: data?.weightLossTest,
        minPercentVolatilesInSpecific: data?.minPercentVolatilesInSpecific,
        maxPercentVolatilesInSpecific: data?.maxPercentVolatilesInSpecific,
        materials: data?.materials,
        packagingCode: data?.packagingCode,
        sideCollectorId: data?.sideCollectorId
      })
    }
  }, [data, formInstance])

  return (
    <Form form={formInstance} layout="vertical" name="other_tested" onFinish={onFinish}>
      <BoxWrapper columnCount={4} enablePadding>
        <InputWrapper>
          <Form.Item label="Test Number" name="testNumber">
            <Input placeholder="Max 250 char" />
          </Form.Item>
        </InputWrapper>
        <InputWrapper>
          <Form.Item label="Tested Code" name="testedCode">
            <Input placeholder="Max 250 char" />
          </Form.Item>
        </InputWrapper>
        <InputWrapper>
          <Form.Item
            label="Test Start Date"
            name="testStartDate"
            rules={[{ required: true }]}
          >
            <DatePicker
              status={
                formInstance.getFieldsValue(['testStartDate'])?.testStartDate
                  ? undefined
                  : 'error'
              }
            />
          </Form.Item>
        </InputWrapper>
        <InputWrapper>
          <Form.Item label="Test Result" name="testResult" rules={[{ required: true }]}>
            <Select
              options={TEST_RESULT_DROPDOWN}
              status={
                formInstance.getFieldsValue(['testResult'])?.testResult
                  ? undefined
                  : 'error'
              }
            />
          </Form.Item>
        </InputWrapper>
        <InputWrapper>
          <Form.Item
            label="Weight Loss Test"
            name="weightLossTest"
            validateTrigger="onBlur"
            rules={[{ required: true }]}
          >
            <Select
              options={WEIGHT_LOSS_DROPDOWN}
              status={
                formInstance.getFieldsValue(['weightLossTest'])?.weightLossTest
                  ? undefined
                  : 'error'
              }
            />
          </Form.Item>
        </InputWrapper>
        <InputNumberWrapper>
          <Form.Item
            label="Min % Volatiles In Specific"
            name="minPercentVolatilesInSpecific"
          >
            <InputNumber min={0} max={9999.99} step={0.01} placeholder="Insert Value" />
          </Form.Item>
        </InputNumberWrapper>
        <InputNumberWrapper>
          <Form.Item
            label="Max % Volatiles In Specific"
            name="maxPercentVolatilesInSpecific"
          >
            <InputNumber min={0} max={9999.99} step={0.01} placeholder="Insert Value" />
          </Form.Item>
        </InputNumberWrapper>
        <InputWrapper>
          <Form.Item label="Materials" name="materials">
            <Input placeholder="Max 250 char" />
          </Form.Item>
        </InputWrapper>
        <InputWrapper>
          <Form.Item label="PK Intercos Code" name="sideCollectorId">
            <Select
              placeholder="Start typing to see results"
              options={
                pkCodesDropdown?.length
                  ? pkCodesDropdown
                  : [
                      {
                        label: data.packagingCode,
                        value: data.sideCollectorId
                      }
                    ]
              }
              onSearch={handleSearch}
              filterOption={false}
              showSearch={true}
              onSelect={(value: any, option: any) => {
                setPackageCategory(option.packageCategory)
              }}
            />
          </Form.Item>
        </InputWrapper>
        <InputWrapper>
          <Label>Packaging Category</Label>
          <Value>{packageCategory}</Value>
        </InputWrapper>
        <Form.Item
          hidden={true}
          name="sideCollectorId"
          shouldUpdate={(prev, curr) => {
            return prev.packagingCode !== curr.packagingCode
          }}
        />
      </BoxWrapper>
    </Form>
  )
}

export default OtherTestedPackagingForm
