import { DividerProps } from 'antd'
import { StyledDivider } from './styled'

type Props = {
  orientation?: DividerProps
  orientationMargin?: DividerProps
  disableBorder?: boolean
}

export const Divider = ({ disableBorder = false }: Props) => (
  <>
    <StyledDivider $disableborder={disableBorder} />
  </>
)
