import { Form } from 'antd'
import { get } from 'lodash'
import { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import { InfoCell, SectionTitle, Select } from '../../components'
import { BoxWrapper } from '../../components/BoxWrapper'
import { ColorPreview } from '../../components/ColorPreview'
import { ColorType } from '../../components/ColorPreview/types'
import { Divider } from '../../components/Divider'
import {
  applicationAreaDrpDwn,
  collectionDropDown,
  colorDropDown,
  storeProdTypeToForm
} from '../../features/storeProducts/constants'
import { IImageData, IStoreProd } from '../../features/storeProducts/model'
import { getStoreProdDropdownByKey } from '../../features/storeProducts/selectors'
import { selectPermissions } from '../../features/users/selectors'
import { FlexColumnWrapper, FlexRowWrapper } from '../../style/styledCommonWrappers'
import { Label, Value } from '../../style/styledLabels'
import {
  checkDisableFieldInUpdate,
  entityFieldVisibileByType
} from '../../utils/entityHelper'
import { EntitiesStyledBoxWrapper, FlexColorWrapper } from './styled'
import { IProductDetailProps } from './types'

const ProductDetailContent: FC<IProductDetailProps> = ({
  data,
  isEditing = false,
  onUpdate = () => {}
}) => {
  const permissions = useSelector(selectPermissions)
  const typeValue = data.type

  const prodFormDropdown = useSelector(getStoreProdDropdownByKey('productForm'))
  const firstProductionOriginDropdown = useSelector(getStoreProdDropdownByKey('FPO'))
  const [isImageTouched, setImageTouched] = useState<boolean>(false)

  const filteredProdFormD = prodFormDropdown.filter(
    ({ value }: any) => !Object.values(storeProdTypeToForm).includes(value)
  )
  const canSeeField = permissions.storeProduct?.canSeeField
  const checkEntityFieldVisibility = (key: keyof IStoreProd) => {
    const visibilityObj = canSeeField?.[key]
    // default to true to map only custom cases
    const fieldVisibility = visibilityObj ? visibilityObj?.onRead : true
    const isVisibleForOperation = isEditing ? visibilityObj?.onEdit : fieldVisibility

    return isVisibleForOperation && entityFieldVisibileByType({ type: typeValue!, key })
  }

  const checkDisableField = (key: keyof IStoreProd) => {
    return checkDisableFieldInUpdate({ data, key, type: typeValue! })
  }

  const updateHandler = (key: keyof IStoreProd, value: any) => {
    onUpdate(key, value)
  }

  const updateImageHandler = (data: IImageData) => {
    if (!isImageTouched) setImageTouched(true)
    updateHandler('image', data)
  }
  // TODO: stop using view component togheter with edit components -> create separate files and fix BoxWrapper wrong css > div
  return (
    <Form layout="vertical">
      <BoxWrapper enablePadding>
        <InfoCell
          value={data.image?.value || ''}
          label={'store-prod-edit'}
          dataType="image"
          inputType="upload"
          isEditing={isEditing}
          inputConfig={{
            fileKey: 'store-prod-edit',
            onChange: (imageData: Record<string, any>) => updateImageHandler(imageData),
            isUrl: !isImageTouched || !isEditing
          }}
        />
      </BoxWrapper>
      <Divider disableBorder />

      <SectionTitle value="General Data" />
      <Divider />
      <BoxWrapper enablePadding>
        {checkEntityFieldVisibility('productCode') && (
          // temp workaround to not break word
          <div style={{ width: '100%', padding: '0.3%' }}>
            <InfoCell label="Product Code" value={get(data, 'productCode', '')} />
          </div>
        )}
        {(checkEntityFieldVisibility('labCode') ||
          checkEntityFieldVisibility('industrialCode')) && (
          // override wrong use of > div in styled components
          <EntitiesStyledBoxWrapper style={{ width: '100%', padding: 0 }}>
            {checkEntityFieldVisibility('industrialCode') && (
              <>
                {isEditing ? (
                  <InfoCell
                    label="Industrial Code"
                    value={data.industrialCode || ''}
                    isEditing={isEditing}
                    inputConfig={{
                      onChange: (value: string) => updateHandler('industrialCode', value),
                      disabled: checkDisableField('industrialCode')
                    }}
                  />
                ) : (
                  <FlexColumnWrapper className="ant-form-item">
                    <Label>Industrial Code</Label>
                    <Value>{data.industrialCode || '-'}</Value>
                  </FlexColumnWrapper>
                )}
              </>
            )}
            {checkEntityFieldVisibility('labCode') && (
              <>
                {isEditing ? (
                  <InfoCell
                    label="Lab Code"
                    value={data.labCode || ''}
                    isEditing={isEditing}
                    inputConfig={{
                      onChange: (value: string) => updateHandler('labCode', value),
                      disabled: checkDisableField('labCode')
                    }}
                  />
                ) : (
                  <FlexColumnWrapper className="ant-form-item">
                    <Label>Lab Code</Label>
                    <Value>{data.labCode || '-'}</Value>
                  </FlexColumnWrapper>
                )}
              </>
            )}
          </EntitiesStyledBoxWrapper>
        )}
        {checkEntityFieldVisibility('productForm') && (
          <InfoCell
            value={data.productForm || ''}
            label="Product Form"
            inputType="select"
            isEditing={isEditing}
            inputConfig={{
              items: filteredProdFormD,
              onChange: ({ value }: Record<string, any>) =>
                updateHandler('productForm', value),
              disabled: checkDisableField('productForm')
            }}
          />
        )}
        {checkEntityFieldVisibility('groupCode') && (
          <InfoCell
            label="Multipack Code"
            value={data.groupCode || ''}
            isEditing={isEditing}
            inputConfig={{
              onChange: (value: string) => updateHandler('groupCode', value),
              disabled: checkDisableField('groupCode')
            }}
          />
        )}
        {checkEntityFieldVisibility('color') && (
          <>
            {isEditing ? (
              <FlexColorWrapper>
                <Select
                  label="Color"
                  value={data.color || ''}
                  onChange={({ value }: { value: string }) =>
                    updateHandler('color', value)
                  }
                  disabled={checkDisableField('color')}
                  items={colorDropDown}
                />
                {data?.color && (
                  <ColorPreview color={(data?.color as ColorType) || null} />
                )}
              </FlexColorWrapper>
            ) : (
              <FlexRowWrapper>
                <FlexColumnWrapper>
                  <Label>Color</Label>
                  <Value>{data.color || ''}</Value>
                </FlexColumnWrapper>
                {data?.color && (
                  <ColorPreview color={(data?.color as ColorType) || null} />
                )}
              </FlexRowWrapper>
            )}
          </>
        )}
        {checkEntityFieldVisibility('finish') && (
          <InfoCell
            label="Finish"
            value={data.finish || ''}
            isEditing={isEditing}
            inputConfig={{
              onChange: (value: string) => updateHandler('finish', value),
              disabled: checkDisableField('finish')
            }}
          />
        )}
        {checkEntityFieldVisibility('FPO') && (
          <>
            {isEditing ? (
              <>
                <InfoCell
                  label="First Production Origin"
                  value={data.FPO || ''}
                  inputType="select"
                  isEditing={isEditing}
                  inputConfig={{
                    onChange: ({ value }: { value: string }) =>
                      updateHandler('FPO', value),
                    disabled: checkDisableField('FPO'),
                    items: firstProductionOriginDropdown
                  }}
                />
              </>
            ) : (
              <FlexRowWrapper>
                <FlexColumnWrapper>
                  <Label>First Production Origin</Label>
                  <Value>{data.FPO || '-'}</Value>
                </FlexColumnWrapper>
              </FlexRowWrapper>
            )}
          </>
        )}
      </BoxWrapper>
      <SectionTitle value="Detailed Information" />
      <Divider />
      <BoxWrapper enablePadding>
        {checkEntityFieldVisibility('applicationArea') && (
          <InfoCell
            label="Application Area"
            value={data.applicationArea || ''}
            isEditing={isEditing}
            inputType="select"
            inputConfig={{
              onChange: ({ value }: { value: string }) =>
                updateHandler('applicationArea', value),
              disabled: checkDisableField('applicationArea'),
              items: applicationAreaDrpDwn
            }}
          />
        )}
        {checkEntityFieldVisibility('productCategory') && (
          <InfoCell
            label="Product Category"
            value={data.productCategory || ''}
            isEditing={isEditing}
            inputConfig={{
              onChange: (value: string) => updateHandler('productCategory', value),
              disabled: checkDisableField('productCategory')
            }}
          />
        )}
        {checkEntityFieldVisibility('finalName') && (
          <InfoCell
            label="Final Name"
            value={data.finalName || ''}
            isEditing={isEditing}
            inputConfig={{
              onChange: (value: string) => updateHandler('finalName', value),
              disabled: checkDisableField('finalName')
            }}
          />
        )}
        {checkEntityFieldVisibility('panMold') && (
          <InfoCell
            label="PAN/MOLD"
            value={data.panMold || ''}
            isEditing={isEditing}
            inputConfig={{
              onChange: (value: string) => updateHandler('panMold', value),
              disabled: checkDisableField('panMold')
            }}
          />
        )}
        {checkEntityFieldVisibility('packagingRef') && (
          <InfoCell
            label="PK INTERCOS CODE"
            value={data.packagingRef || ''}
            isEditing={isEditing}
            inputConfig={{
              onChange: (value: string) => updateHandler('packagingRef', value),
              disabled: checkDisableField('packagingRef')
            }}
          />
        )}
        {checkEntityFieldVisibility('pmBU') && (
          <InfoCell
            label="PM BU"
            value={data.pmBU || ''}
            isEditing={isEditing}
            inputConfig={{
              onChange: (value: string) => updateHandler('pmBU', value),
              disabled: checkDisableField('pmBU')
            }}
          />
        )}
        {checkEntityFieldVisibility('noteBU') && (
          <InfoCell
            label="Note BU"
            value={data.noteBU || ''}
            isEditing={isEditing}
            inputConfig={{
              onChange: (value: string) => updateHandler('noteBU', value),
              disabled: checkDisableField('noteBU')
            }}
          />
        )}
      </BoxWrapper>
      <Divider disableBorder />
      <SectionTitle value="Formula Schema Data" />
      <Divider />
      <BoxWrapper enablePadding>
        {checkEntityFieldVisibility('formulaSchemaCode') && (
          <InfoCell
            label="Formula Scheme"
            value={data.formulaSchemaCode || ''}
            isEditing={isEditing}
            inputConfig={{
              onChange: (value: string) => updateHandler('formulaSchemaCode', value),
              disabled: checkDisableField('formulaSchemaCode')
            }}
          />
        )}
        {checkEntityFieldVisibility('launchYear') && (
          <InfoCell
            label="Launch Year"
            value={data.launchYear || ''}
            isEditing={isEditing}
            inputConfig={{
              onChange: (value: string) => updateHandler('launchYear', value),
              disabled: checkDisableField('launchYear')
            }}
          />
        )}
        {checkEntityFieldVisibility('collection') && (
          <InfoCell
            label="Collection"
            inputType="select"
            value={data.collection || ''}
            isEditing={isEditing}
            inputConfig={{
              onChange: ({ value }: { value: string }) =>
                updateHandler('collection', value),
              disabled: checkDisableField('collection'),
              items: collectionDropDown
            }}
          />
        )}
        {checkEntityFieldVisibility('family') && (
          <InfoCell
            label="Family "
            value={data.family || ''}
            isEditing={isEditing}
            inputConfig={{
              onChange: (value: string) => updateHandler('family', value),
              disabled: checkDisableField('family')
            }}
          />
        )}
      </BoxWrapper>
      <Divider disableBorder />
      <SectionTitle value="Logistic Data" />
      <Divider />
      <BoxWrapper enablePadding>
        {checkEntityFieldVisibility('colorNameOnLabel') && (
          <InfoCell
            label="Color in Label"
            value={data.colorNameOnLabel || ''}
            isEditing={isEditing}
            inputConfig={{
              onChange: (value: string) => updateHandler('colorNameOnLabel', value),
              disabled: checkDisableField('colorNameOnLabel')
            }}
          />
        )}
        {checkEntityFieldVisibility('labCodeInLabel') && (
          <InfoCell
            label="Lab Code in Label"
            value={data.labCodeInLabel || ''}
            isEditing={isEditing}
            inputConfig={{
              onChange: (value: string) => updateHandler('labCodeInLabel', value),
              disabled: checkDisableField('labCodeInLabel')
            }}
          />
        )}
        {checkEntityFieldVisibility('shakeWell') && (
          <InfoCell
            label="Shake Well"
            value={data.shakeWell || ''}
            isEditing={isEditing}
            inputConfig={{
              onChange: (value: string) => updateHandler('shakeWell', value),
              disabled: checkDisableField('shakeWell')
            }}
          />
        )}
        {checkEntityFieldVisibility('SPF') && (
          <InfoCell
            label="SPF"
            value={data.SPF || ''}
            isEditing={isEditing}
            inputConfig={{
              onChange: (value: string) => updateHandler('SPF', value),
              disabled: checkDisableField('SPF')
            }}
          />
        )}
        {checkEntityFieldVisibility('availableQuantity') && (
          <InfoCell label="Available quantity" value={data.availableQuantity || 0} />
        )}
        {checkEntityFieldVisibility('noteSR') && (
          <InfoCell
            label="Note SR"
            value={data.noteSR || ''}
            isEditing={isEditing}
            inputConfig={{
              onChange: (value: string) => updateHandler('noteSR', value),
              disabled: checkDisableField('noteSR')
            }}
          />
        )}
      </BoxWrapper>
    </Form>
  )
}

export default ProductDetailContent
