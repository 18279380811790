import { get } from 'lodash'
import { FC } from 'react'
import { getContentofConditionalProductCodeField } from '../../../features/cart/utils'
import ProductCard from '../../ProductCard'
import { ProductCardWrapper } from './styled'
import { IProductList } from './types'

const ProductList: FC<IProductList> = ({
  productList,
  isEditing = false,
  onQuantityChanged,
  onDeleteProduct
}) => {
  return (
    <ProductCardWrapper>
      {productList.map(({ id, item, details, quantity, productCode }) => {
        const conditionalProductCodeField = getContentofConditionalProductCodeField(item)
        return (
          <ProductCard
            title={`Product ${conditionalProductCodeField.value}`}
            key={id}
            id={id}
            photoSrc={get(item, 'thumbnail.url', '')}
            packagingReference={get(item, 'packagingRef', '')}
            finalName={get(item, 'finalName', '')}
            code={conditionalProductCodeField}
            details={details}
            quantity={quantity}
            isEditing={isEditing}
            onQuantityChanged={onQuantityChanged}
            availableQuantity={get(item, 'availableQuantity', 0)}
            isDeletable={isEditing}
            onDeleteProduct={onDeleteProduct}
          />
        )
      })}
    </ProductCardWrapper>
  )
}

export default ProductList
