import styled from 'styled-components'
import { footerHeight } from '../../assets/mixins'

export const footerActionHeight = 45
export const FooterActions = styled.div`
  position: fixed;
  display: flex;
  height: ${footerActionHeight}px;
  width: 100%;
  background-color: white;
  left: 0;
  bottom: ${footerHeight}px;
  justify-content: flex-end;
  border-top: 1px solid rgb(238, 238, 238);
  padding: 5px 20px;
  align-items: center;
  > button {
    margin: 0px 18px;
  }
`

export const StepContent = styled.div<{ heightToRemove: number }>`
  height: ${({ heightToRemove }) => `calc(100% - ${heightToRemove}px)`};
  overflow: auto;
`

const titleHeight = 50
export const TitleWrapper = styled.div`
  height: ${titleHeight}px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
