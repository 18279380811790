import { AnyAction } from '@reduxjs/toolkit'
import { get } from 'lodash'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import { refactoredDataOnCreate } from '../../utils/entityHelper'
import { getPackageId } from '../packaging/api'
import { CATEGORY, PackagingDto } from '../packaging/model'
import { PACKAGING_ID_TOOLING } from '../product/constants'
import { createEntityApi } from './api'
import { ENTITY_ADD_TOOLING, ENTITY_CREATE } from './constants'
import { IEntity } from './model'
import { entityActions } from './reducer'
import { selectNewEntity } from './selectors'

function* addToolingSaga({ payload }: AnyAction) {
  yield put(entityActions.setIsLoading(true))
  const { selected } = payload
  try {
    const packagingTbd: PackagingDto = yield call(getPackageId, PACKAGING_ID_TOOLING)
    yield put(entityActions.updateNewEntity({ key: 'selectedTooling', value: selected }))
    yield put(entityActions.setNewEntityPackaging(packagingTbd))

    const packageFamilies = get(packagingTbd, 'packageFamilies', [])
    for (let i = 0; i < packageFamilies.length; i++) {
      const isVials =
        get(packageFamilies, `[${i}].category`, '').toLowerCase() === CATEGORY.VIALS
      const variantData = get(packageFamilies, `[${i}].packageVariants`, []).map(
        ({ vialDetails, id }: any) => ({
          ...vialDetails,
          idPackaging: id
        })
      )
      if (!isVials) {
        const [selectedVariant] = variantData
        yield put(
          entityActions.setNewEnitityVariant({
            sideIdx: i,
            selected: selectedVariant
          })
        )
      }
    }
  } catch (err: any) {
    yield put(entityActions.setError(err))
  } finally {
    yield put(entityActions.setIsLoading(false))
  }
}

function* createEntitySaga() {
  yield put(entityActions.setIsLoading(true))
  try {
    const newEntityData: IEntity = yield select(selectNewEntity)
    const refactoredData = refactoredDataOnCreate(newEntityData)
    const form_data = new FormData()
    if (refactoredData.image) {
      form_data.append('image', refactoredData.image)
    }
    form_data.append('document', JSON.stringify(refactoredData.document))

    yield call(createEntityApi, { form_data })
  } catch (err: any) {
    yield put(entityActions.setError(err))
  } finally {
    yield put(entityActions.setIsLoading(false))
  }
}

export default function* entitySaga() {
  yield takeLatest(ENTITY_ADD_TOOLING, addToolingSaga)
  yield takeLatest(ENTITY_CREATE, createEntitySaga)
}
