import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { footerHeight, headerHeight } from '../../assets/mixins'
import { Button, SectionTitle } from '../../components'
import { Breadcrumb } from '../../components/Breadcrumb'
import { Divider } from '../../components/Divider'
import { Loader } from '../../components/Loader'
import { PageContent } from '../../containers'
import AttachmentForm from '../../containers/Administration/AttachmentForm'
import { ITempAttchFields } from '../../containers/Administration/AttachmentForm/types'
import { administrationActions } from '../../features/administration'
import {
  selectAdminError,
  selectAttachmentDetail
} from '../../features/administration/selectors'
import { selectIsLoading } from '../../features/app/selectors'
import { GenericLayout } from '../../layouts'
import { AppRoutes } from '../constants'
import { footerActionHeight } from '../CreationPackage/styled'
import { ButtonWrapper } from './styled'

const AttachmentDetail: FC = () => {
  const dispatch = useDispatch()
  const { id } = useParams()
  const navigate = useNavigate()
  const adminError = useSelector(selectAdminError)
  const isLoading = useSelector(selectIsLoading)
  const attachmentData = useSelector(selectAttachmentDetail)

  useEffect(() => {
    if (id) {
      dispatch(administrationActions.getAttachmentDetail({ id }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const cancelHandler = () => {
    navigate(-1)
  }

  const saveHandler = () => {
    dispatch(
      administrationActions.updateAttachment({
        attachData: attachmentData as Record<string, any>,
        successCb: () => navigate(-1)
      })
    )
  }

  const updateHandler = (key: keyof ITempAttchFields, value: any) => {
    const newData = { ...attachmentData, [key]: value }
    dispatch(administrationActions.setAttachmentDetail(newData))
  }

  return (
    <GenericLayout error={adminError}>
      <PageContent
        headerHeight={headerHeight}
        footerHeight={footerHeight + footerActionHeight}
      >
        <Breadcrumb
          items={[
            {
              name: 'Administration',
              href: AppRoutes.VAULT_ADMINISTRATION
            },
            {
              name: 'edit attachment'
            }
          ]}
        />
        {isLoading && <Loader />}
        {!isLoading && (
          <>
            <Divider disableBorder />
            <SectionTitle
              value="Edit Attachment"
              addon={
                <ButtonWrapper>
                  <Button label="Cancel" variant="link" onClick={cancelHandler} />
                  <Button label="Save" onClick={saveHandler} />
                </ButtonWrapper>
              }
            />
            <AttachmentForm data={attachmentData || {}} onUpdate={updateHandler} />
          </>
        )}
      </PageContent>
    </GenericLayout>
  )
}

export default AttachmentDetail
