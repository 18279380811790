import styled from 'styled-components'

export const titleHeight = 50
export const TitleWrapper = styled.div`
  height: ${titleHeight}px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const EmptyWrapper = styled.div`
  min-height: 250px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
