import { get } from 'lodash'
import { FC } from 'react'
import { useSelector } from 'react-redux'
import {
  AZ_WIKIPACK_IE,
  AZ_WIKIPACK_PKACCEVO,
  AZ_WIKIPACK_RDF,
  AZ_WIKIPACK_TCS,
  AZ_WIKIPACK_MRKT
} from '../../../auth-msal'
import { selectUserRole } from '../../../features/users/selectors'
import { columnsGeneratorHelper } from '../../../utils'
import {
  rowsGeneratorHelperNew,
  visibilityByRolesHelper
} from '../../../utils/tableHelpers'
import Table from '../../NewTable'
import { IExcludeRules } from '../../NewTable/types'
import { IOthersPackagingTableProps } from './types'

const OthersPackagingTable: FC<IOthersPackagingTableProps> = ({
  tableConfig,
  actionConfig,
  dataList,
  enableRowSelect,
  onSelectRow,
  isSingleSelect,
  emptyBody,
  expandablePath = '',
  enablePagination = false,
  onChangePage = () => {},
  selectedRows,
  isLoading,
  pageData,
  isChildOpen
}) => {
  const userRoles = useSelector(selectUserRole)

  const colHideRules: IExcludeRules = {
    supplierRef: {
      byRoles: [
        AZ_WIKIPACK_PKACCEVO,
        AZ_WIKIPACK_IE,
        AZ_WIKIPACK_RDF,
        AZ_WIKIPACK_MRKT,
        AZ_WIKIPACK_TCS
      ]
    },
    testedSupplier: {
      byRoles: [
        AZ_WIKIPACK_PKACCEVO,
        AZ_WIKIPACK_IE,
        AZ_WIKIPACK_RDF,
        AZ_WIKIPACK_MRKT,
        AZ_WIKIPACK_TCS
      ]
    }
  }

  const columnGenerator = () => {
    let columnList = columnsGeneratorHelper(tableConfig)

    // TODO - Possible Optimization -
    // This filters functions should stay in Table component

    columnList = columnList.filter(({ key }) => {
      const rulesByRole = colHideRules?.[key]?.byRoles || []
      return visibilityByRolesHelper(userRoles, rulesByRole)
    })

    // ============================

    return columnList
  }

  const rowsGenerator = () => {
    const newList = rowsGeneratorHelperNew(dataList, tableConfig)
    return newList.map((data) => {
      const expandContent: Record<string, any>[] = get(data, expandablePath, [])
      if (expandContent.length) {
        data['children'] = expandContent
      }
      return data
    })
  }

  const columns = columnGenerator()
  const dataRow = dataList.length ? rowsGenerator() : []

  return (
    <Table
      pagination={{
        current: get(pageData, 'page', 0),
        pageSize: get(pageData, 'pageSize', 10),
        total: get(pageData, 'total', 10)
      }}
      onChangePage={onChangePage}
      enablePagination={enablePagination}
      rowKey="id"
      columns={columns}
      items={dataRow}
      actionColumnConfig={actionConfig}
      enableRowSelect={enableRowSelect}
      onSelectRow={onSelectRow}
      isSingleSelect={isSingleSelect}
      emptyBody={emptyBody}
      selectedRows={selectedRows}
      isLoading={isLoading}
      expandableConfig={isChildOpen ? { isDefaultOpen: true } : undefined}
    />
  )
}

export default OthersPackagingTable
