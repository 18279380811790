import { get } from 'lodash'
import { FC } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  canReadProjectSpecificationDetail,
  canUserAddProjectSpecification
} from '../../../auth-msal'
import { SectionTitle, Table } from '../../../components'
import { Divider } from '../../../components/Divider'
import Button from '../../../components/NewButton'
import { TOP_RIGHT } from '../../../components/NewTable/constants'
import { Text } from '../../../components/Text'
import { Tooltip } from '../../../components/Tooltip'
import { PACKAGING_STATUS } from '../../../features/packaging/constants'
import { getPackageDetail } from '../../../features/packaging/selectors'
import { selectUserRole } from '../../../features/users/selectors'
import { columnsGeneratorHelper } from '../../../utils'
import { AddProjectSpecificationModal } from '../AddProjectSpecificationModal'
import { EmptyWrapper } from './styled'
import { IProjectSpecificationListProps } from './types'

export const ProjectSpecificationList: FC<IProjectSpecificationListProps> = ({
  dataList = [],
  tableConfig,
  packageId,
  masterSpecList
}) => {
  const navigate = useNavigate()
  const userRoles = useSelector(selectUserRole)
  const columns = columnsGeneratorHelper(tableConfig)
  const packageDetails = useSelector(getPackageDetail) as Record<string, any>
  const packageAvailable =
    get(packageDetails, 'status', '') === PACKAGING_STATUS.AVAILABLE

  const actionConfig = {
    columnTitle: 'Actions',
    actionRenderer: ({ data }: any) => (
      <>
        {canReadProjectSpecificationDetail(userRoles) && (
          <Tooltip title="Detail">
            <Button
              onClick={() => {
                navigate(
                  `/wikipack/packaging/${packageId}/project-specifications/${data.id}`
                )
              }}
              iconName="InfoCircleOutlined"
              variant="link"
            />
          </Tooltip>
        )}
      </>
    )
  }

  return (
    <>
      <SectionTitle
        value="Project Specifications"
        addon={
          !!dataList?.length &&
          packageAvailable && (
            <AddProjectSpecificationModal
              masterSpecificationList={masterSpecList || []}
              packageId={packageId || ''}
            />
          )
        }
        isBold
      />
      <Divider />
      <Table
        enablePagination
        paginationPosition={TOP_RIGHT}
        columns={columns}
        items={dataList}
        rowKey="id"
        actionColumnConfig={actionConfig}
        emptyBody={
          <EmptyWrapper>
            <Text text="There are no Project Specifications" variant="h4" />
            {canUserAddProjectSpecification(userRoles) && packageAvailable && (
              <AddProjectSpecificationModal
                masterSpecificationList={masterSpecList || []}
                packageId={packageId || ''}
              />
            )}
          </EmptyWrapper>
        }
        forceHeight
      />
    </>
  )
}
