import { PageHeader } from 'antd'
import styled from 'styled-components'
import { Text } from '../Text'

export const pageTitleHeight = 45
export const StyledPageHeader = styled(PageHeader)`
  width: 100%;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  height: ${pageTitleHeight}px;
  padding: 10px 0px 0px;
  .ant-page-header-heading {
    height: 40px;
  }
`

export const pageSubtitleHeight = 40
export const StyledText = styled(Text)`
  height: ${pageSubtitleHeight}px;
  margin: 0px;
  padding: 10px 0px;
`
