import styled from 'styled-components'

export const EmptyWrapper = styled.div`
  min-height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const AlternativeWrapper = styled.div`
  .ant-table-cell {
    background-color: white;
  }
`
