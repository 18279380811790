import { get } from 'lodash'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { footerHeight, headerHeight } from '../../assets/mixins'
import { Button, SectionTitle } from '../../components'
import { Divider } from '../../components/Divider'
import { Loader } from '../../components/Loader'
import { PageContent } from '../../containers'
import MasterSpecificationCharacteristicContent from '../../containers/MasterSpecificationCharacteristicContent'
import { masterSpecActions } from '../../features/masterSpec/reducer'
import {
  selectNewCharacteristic,
  selectNewMasterSpec
} from '../../features/masterSpec/selectors'
import { getIsLoading, selectPackagingError } from '../../features/packaging/selectors'
import { GenericLayout } from '../../layouts'
import { ButtonsWrapper } from '../MasterSpecificationDetail/styled'
import { cleanMasterSpec } from '../MasterSpecificationDetail/utils'
import { replaceObjectById } from './utils'

const MasterSpecificationDetail: any = () => {
  // Selector
  const newMasterSpec = useSelector(selectNewMasterSpec)
  const packagingError = useSelector(selectPackagingError)
  const isLoading = useSelector(getIsLoading)
  const newCharacteristic = useSelector(selectNewCharacteristic)

  const [isEditing] = useState(false)
  const { specId } = useParams()
  const navigate = useNavigate()

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(masterSpecActions.getDetail({ specId: specId || '' }))
    dispatch(masterSpecActions.getCharacteristics())
    dispatch(masterSpecActions.clearError())
    dispatch(masterSpecActions.clearSuccess())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    dispatch(masterSpecActions.getDetail({ specId: specId || '' }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing])

  const cancelHandler = () => {
    navigate(-1)
  }

  const saveHandler = () => {
    let resultMasterSpec = { ...newMasterSpec }
    let characteristics = get(newMasterSpec, 'masterSpecificationCharacteristics', [])
    const disc = get(newCharacteristic, 'specificationCharacteristicImage')

    if (disc) {
      // immagine modificata
      dispatch(
        masterSpecActions.uploadAndUpdateCharacteristic({
          ...newCharacteristic
        })
      )
    } else {
      // immagine non modificata
      const charId = get(newCharacteristic, 'id', '')
      const resultCharacteristics = replaceObjectById(
        characteristics,
        charId,
        newCharacteristic
      )
      resultMasterSpec.masterSpecificationCharacteristics = resultCharacteristics
      const payload = cleanMasterSpec(resultMasterSpec)
      if (payload) dispatch(masterSpecActions.updateMasterSpecification(payload))
    }
    navigate(-1)
  }

  return (
    <GenericLayout error={packagingError}>
      <PageContent
        footerHeight={footerHeight}
        headerHeight={headerHeight}
        justifyContent="flex-start"
      >
        <Divider disableBorder />
        <SectionTitle
          value={''}
          isBold
          addon={
            <ButtonsWrapper>
              <>
                <Button label="Cancel" variant="text" onClick={cancelHandler} />
                <Button label="Save" onClick={saveHandler} />
              </>
            </ButtonsWrapper>
          }
        />
        {isLoading ? (
          <Loader />
        ) : (
          <MasterSpecificationCharacteristicContent
            newCharacteristic={newCharacteristic}
          />
        )}
      </PageContent>
    </GenericLayout>
  )
}

export default MasterSpecificationDetail
