import { FC } from 'react'
import { useDispatch } from 'react-redux'
import { Button } from '../../../components'
import { FlexSection } from '../../../components/FlexSection'
import { Modal } from '../../../components/Modal'
import { Text } from '../../../components/Text'
import { ISaveButtonProps } from './types'

const SaveButton: FC<ISaveButtonProps> = ({ onConfirm = () => {} }) => {
  const dispatch = useDispatch()

  const closeModalHandler = () => {
    dispatch({
      type: 'app/closeModal',
      payload: { modalKey: 'save-variant-modal' }
    })
  }

  const saveHandler = () => {
    closeModalHandler()
    onConfirm()
  }

  return (
    <Modal
      title={''}
      btnName="Save"
      modalKey="save-variant-modal"
      isSmall
      btnIcon=""
      size={'small'}
      centered
      footer={[
        <Button
          key="cancel"
          label="Cancel"
          variant="ghost"
          onClick={closeModalHandler}
        />,
        <Button key="save" label="Save" onClick={saveHandler} />
      ]}
    >
      <FlexSection flexDirection="column" justifyContent="center" alignItems="center">
        <Text text="This variant will be updated" variant="h4" />
        <Text text="Are you  sure?" variant="h5" />
      </FlexSection>
    </Modal>
  )
}

export default SaveButton
