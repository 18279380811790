import { PayloadAction } from '@reduxjs/toolkit'
import { get } from 'lodash'
import { SagaIterator } from 'redux-saga'
import { SagaReturnType, call, put, select, takeLatest } from 'redux-saga/effects'
import { replaceObjectById } from '../../pages/MasterSpecCharacteristicDetail/utils'
import { masterSpecStatus } from '../../pages/MasterSpecificationDetail/constants'
import {
  cleanMasterSpec,
  convertImageDataToImage
} from '../../pages/MasterSpecificationDetail/utils'
import { cleanProjectSpec } from '../../pages/ProjectSpecificationDetail/utils'
import { refactorCharacteristicForMasterCreation } from '../../utils/masterSpecification/characteristics'
import {
  checkSapCodes,
  createMasterSpec,
  createProjectSpec,
  deleteMasterSpec,
  deleteProjectSpec,
  getMasterSpecDetail,
  getProjectSpecDetail,
  getSpecificationSections,
  searchCharacteristics,
  searchMasterSpec,
  searchProjectSpec,
  sendToSap,
  updateMasterSpec,
  updateProjectSpec,
  uploadCharImage,
  uploadMasterSpecThumbnail
} from './api'
import { masterSpecActionType } from './constants'
import {
  IFetchMasterSpecListPayload,
  IGetDetailPayload,
  IMasterSpec,
  IMasterSpecDetail,
  BaseSpecificationCharacteristic,
  IDeleteMasterSpecPayload,
  IProjectSpecDetail,
  MasterSpecification,
  IApplyAllSapCodeActionPayload,
  SpecificationSection
} from './model'
import { masterSpecActions } from './reducer'
import {
  selectAlertMessage,
  selectCharacteristicList,
  selectNewMasterSpec,
  selectNewProjectSpec,
  selectProjectSpecDetail,
  selectSpecificationSections
} from './selectors'

type IFetchListResp = SagaReturnType<typeof fetchMasterSpecList>
function* fetchMasterSpecList({ filters = {} }: IFetchMasterSpecListPayload) {
  try {
    const masterSpecList: IMasterSpec[] = yield call(searchMasterSpec, filters)
    return masterSpecList
  } catch (err: any) {
    yield put(masterSpecActions.setError(err))
    throw err
  }
}

function* fetchProjectSpecList({ filters = {} }: IFetchMasterSpecListPayload) {
  try {
    const masterSpecList: IMasterSpec[] = yield call(searchProjectSpec, filters)
    return masterSpecList
  } catch (err: any) {
    yield put(masterSpecActions.setError(err))
    throw err
  }
}

type IFetchCharListResp = SagaReturnType<typeof fetchCharList>
function* fetchCharList() {
  try {
    const charList: BaseSpecificationCharacteristic[] = yield call(searchCharacteristics)
    return charList
  } catch (err: any) {
    yield put(masterSpecActions.setError(err))
    throw err
  }
}

function* fetchMasterSpecsSaga({ payload }: PayloadAction<IFetchMasterSpecListPayload>) {
  const { filters, isAddNew } = payload
  if (!isAddNew) yield put(masterSpecActions.setLoader(true))
  try {
    const list: IFetchListResp = yield call(fetchMasterSpecList, { filters })
    if (isAddNew) yield put(masterSpecActions.setAddNewList(list))
    else yield put(masterSpecActions.setList(list))
  } catch (err: any) {
    yield put(masterSpecActions.setError(err))
  } finally {
    yield put(masterSpecActions.setLoader(false))
  }
}

function* fetchProjectSpecsSaga({ payload }: PayloadAction<IFetchMasterSpecListPayload>) {
  const { filters } = payload
  yield put(masterSpecActions.setLoader(true))
  try {
    const list: IFetchListResp = yield call(fetchProjectSpecList, { filters })
    yield put(masterSpecActions.setProjectSpecificationList(list))
  } catch (err: any) {
    yield put(masterSpecActions.setError(err))
  } finally {
    yield put(masterSpecActions.setLoader(false))
  }
}

function* fetchCharSaga(): SagaIterator {
  try {
    const characteristicList: IFetchCharListResp = yield call(fetchCharList)
    yield put(masterSpecActions.setCharacteristics(characteristicList))
  } catch (err: any) {
    throw err
  }
}

function* getAllCharSaga({ payload }: PayloadAction<{ hasLoader: boolean }>) {
  const { hasLoader } = payload || {}
  if (hasLoader) {
    yield put(masterSpecActions.setLoader(true))
  }
  try {
    yield call(fetchCharSaga)
  } catch (err: any) {
    yield put(masterSpecActions.setError(err))
  } finally {
    if (hasLoader) {
      yield put(masterSpecActions.setLoader(false))
    }
  }
}

function* getCharAndApplyDefaultSaga() {
  yield put(masterSpecActions.setLoader(true))

  try {
    yield call(getSpecificationSectionsSaga)
    yield call(fetchCharSaga)
    const specificationSections: SpecificationSection[] = yield select(
      selectSpecificationSections
    )

    const characteristicList: BaseSpecificationCharacteristic[] = yield select(
      selectCharacteristicList
    )

    const aestethicAndDecorationsId =
      specificationSections?.find((section: any) => section.code === 'A')?.id || ''

    const aesteticListForCreation = refactorCharacteristicForMasterCreation(
      characteristicList,
      aestethicAndDecorationsId
    )
    yield put(
      masterSpecActions.updateNewMasterSpecification({
        key: 'masterSpecificationCharacteristics',
        value: aesteticListForCreation
      })
    )
  } catch (err: any) {
    yield put(masterSpecActions.setError(err))
  } finally {
    yield put(masterSpecActions.setLoader(false))
  }
}

function* createMasterSpecSaga({ payload }: PayloadAction<IFetchMasterSpecListPayload>) {
  yield put(masterSpecActions.setLoader(true))
  try {
    yield call(createMasterSpec, payload)
    yield call(fetchMasterSpecList, {
      filters: { sideCollectorId: payload.filters?.sideCollectorId }
    })
  } catch (err: any) {
    yield put(masterSpecActions.setError(err))
  } finally {
    yield put(masterSpecActions.setLoader(false))
    if (!get(payload, 'packageVariantId'))
      yield put({
        type: masterSpecActionType.MASTER_SPEC_FETCH_ALL,
        payload: {
          filters: {
            sideCollectorId: get(payload, 'sideCollectorId'),
            category: get(payload, 'category')
          }
        }
      })
  }
}

function* createProjectSpecSaga({ payload }: PayloadAction<any>) {
  const { data, callback } = payload
  yield put(masterSpecActions.setLoader(true))
  try {
    yield call(createProjectSpec, data)
  } catch (err: any) {
    yield put(masterSpecActions.setError(err))
    yield put(masterSpecActions.setAlert(err))
  } finally {
    const alert: Record<string, any> = yield select(selectAlertMessage)
    yield put({
      type: masterSpecActionType.PROJECT_SPEC_FETCH_ALL,
      payload: {
        filters: {
          sideCollectorId: get(data, 'sideCollectorId', ''),
          category: get(data, 'category')
        }
      }
    })
    yield put(masterSpecActions.setLoader(false))
    if (!alert) callback()
  }
}

function* updateMasterSpecSaga({ payload }: PayloadAction<Partial<IMasterSpecDetail>>) {
  yield put(masterSpecActions.setLoader(true))
  try {
    if (!payload.thumbnailOverride || payload.thumbnailOverride.createdAt) {
      yield call(updateMasterSpec, payload)
    } else {
      const image = convertImageDataToImage(get(payload, 'thumbnailOverride', {}))
      yield call(uploadMSpecThumbnailSaga, { data: image })
      const updatedPayload: Partial<IMasterSpecDetail> = yield select(selectNewMasterSpec)
      const cleanPayload = cleanMasterSpec(updatedPayload) as Record<string, any>
      yield call(updateMasterSpec, cleanPayload)
    }
  } catch (err: any) {
    yield put(masterSpecActions.setError(err))
  } finally {
    yield put(masterSpecActions.setLoader(false))
    yield put({
      type: masterSpecActionType.GET_DETAIL,
      payload: { specId: get(payload, 'id') }
    })
  }
}

function* uploadMSpecThumbnailSaga({ data }: any) {
  yield put(masterSpecActions.setLoader(true))
  try {
    const thumbnailId: string = yield call(uploadMasterSpecThumbnail, data)
    yield put(
      masterSpecActions.updateNewMasterSpecification({
        key: 'thumbnailOverrideId',
        value: get(thumbnailId, 'thumbnailImageId', '')
      })
    )
  } catch (err: any) {
    yield put(masterSpecActions.setError(err))
  } finally {
    yield put(masterSpecActions.setLoader(false))
  }
}
function* uploadPSpecThumbnailSaga({ data }: any) {
  yield put(masterSpecActions.setLoader(true))
  try {
    const thumbnailId: string = yield call(uploadMasterSpecThumbnail, data)
    yield put(
      masterSpecActions.updateNewProjectSpecification({
        key: 'thumbnailOverrideId',
        value: get(thumbnailId, 'thumbnailImageId', '')
      })
    )
  } catch (err: any) {
    yield put(masterSpecActions.setError(err))
  } finally {
    yield put(masterSpecActions.setLoader(false))
  }
}

function* uploadMSpecCharImageSaga({ payload }: any) {
  yield put(masterSpecActions.setLoader(true))
  try {
    const image = convertImageDataToImage(payload)
    const imageId: string = yield call(uploadCharImage, image)
    yield put(
      masterSpecActions.updateNewCharacteristic({
        key: 'specificationCharacteristicImageId',
        value: get(imageId, 'thumbnailImageId', '')
      })
    )
  } catch (err: any) {
    yield put(masterSpecActions.setError(err))
  } finally {
    yield put(masterSpecActions.setLoader(false))
  }
}

function* uploadAndUpdateCharSaga({ payload }: any) {
  let resultMasterSpec: Partial<MasterSpecification> = yield select(selectNewMasterSpec)
  let characteristics = get(resultMasterSpec, 'masterSpecificationCharacteristics', [])

  const imageData = payload.specificationCharacteristicImage
  let newCharacteristic = payload

  yield put(masterSpecActions.setLoader(true))
  try {
    const image = convertImageDataToImage(imageData)
    const imageId: Record<string, any> = yield call(uploadCharImage, image)
    newCharacteristic.specificationCharacteristicImageId = imageId.characteristicImageId

    const charId = get(newCharacteristic, 'id', '')
    const resultCharacteristics = replaceObjectById(
      characteristics,
      charId,
      newCharacteristic
    )
    const payloadToClean = {
      ...resultMasterSpec,
      masterSpecificationCharacteristics: resultCharacteristics
    }

    const payload = cleanMasterSpec(payloadToClean)
    if (payload)
      yield put({
        type: masterSpecActionType.UPDATE_MASTER_SPEC,
        payload: payload
      })
  } catch (err: any) {
    yield put(masterSpecActions.setError(err))
  } finally {
    yield put(masterSpecActions.setLoader(false))
  }
}

function* uploadAndUpdateProjCharSaga({ payload }: any) {
  let resultProjectSpec: Partial<IProjectSpecDetail> = yield select(selectNewProjectSpec)
  let characteristics = get(resultProjectSpec, 'projectSpecificationCharacteristics', [])

  const imageData = payload.specificationCharacteristicImage
  let newCharacteristic = payload

  yield put(masterSpecActions.setLoader(true))
  try {
    const image = convertImageDataToImage(imageData)
    const imageId: Record<string, any> = yield call(uploadCharImage, image)
    newCharacteristic.specificationCharacteristicImageId = imageId.characteristicImageId

    const charId = get(newCharacteristic, 'id', '')
    const resultCharacteristics = replaceObjectById(
      characteristics,
      charId,
      newCharacteristic
    )
    const payloadToClean = {
      ...resultProjectSpec,
      projectSpecificationCharacteristics: resultCharacteristics
    }

    const payload = cleanProjectSpec(payloadToClean)
    if (payload)
      yield put({
        type: masterSpecActionType.UPDATE_PROJECT_SPEC,
        payload: payload
      })
  } catch (err: any) {
    yield put(masterSpecActions.setError(err))
  } finally {
    yield put(masterSpecActions.setLoader(false))
  }
}

function* updateProjectSpecSaga({ payload }: any) {
  yield put(masterSpecActions.setLoader(true))
  try {
    if (!payload.thumbnailOverride || payload.thumbnailOverride.createdAt) {
      yield call(updateProjectSpec, payload)
    } else {
      const image = convertImageDataToImage(get(payload, 'thumbnailOverride', {}))
      yield call(uploadPSpecThumbnailSaga, { data: image })
      const updatedPayload: Partial<IMasterSpecDetail> = yield select(
        selectNewProjectSpec
      )
      const cleanPayload = cleanProjectSpec(updatedPayload) as Record<string, any>
      yield call(updateProjectSpec, cleanPayload)
    }
  } catch (err: any) {
    yield put(masterSpecActions.setAlert(err))
  } finally {
    yield put({
      type: masterSpecActionType.GET_PROJECT_DETAIL,
      payload: { specId: payload.id }
    })
  }
}

type IcheckSapCodesSaga = SagaReturnType<any>
function* checkSapCodesSaga({ payload }: any) {
  const { data, openDuplicateModal, openSendToSapModal } = payload
  yield put(masterSpecActions.setLoader(true))
  const cleanPayload = cleanProjectSpec(data)
  const sapCodes = {
    sapCodes: data.concernedSapCodes.map((c: any) => {
      return c.code
    })
  }
  try {
    const result: IcheckSapCodesSaga = yield call(checkSapCodes, data.id, sapCodes)
    const hasDuplicates = get(result, 'length', false)
    if (hasDuplicates) {
      openDuplicateModal(result, cleanPayload)
    } else {
      openSendToSapModal(cleanPayload, false)
    }
  } catch (err: any) {
    yield put(masterSpecActions.setError(err))
  } finally {
    yield put(masterSpecActions.setLoader(false))
  }
}

function* sendtoSapSaga({ payload }: any) {
  const { data, associateDuplicatedSapCodes } = payload
  yield put(masterSpecActions.setLoader(true))
  try {
    yield call(updateProjectSpecSaga, { payload: data })
    if (data.status === masterSpecStatus.COMPLETED)
      yield call(sendToSap, data.id, associateDuplicatedSapCodes)
  } catch (err: any) {
    yield put(masterSpecActions.setError(err))
  } finally {
    yield put({
      type: masterSpecActionType.GET_PROJECT_DETAIL,
      payload: { specId: get(payload, 'data.id') }
    })
    yield put(masterSpecActions.setLoader(false))
  }
}

function* sendNewtoSapSaga({ payload }: any) {
  const { data, associateDuplicatedSapCodes } = payload
  yield put(masterSpecActions.setLoader(true))
  try {
    yield call(createProjectSpec, payload)
    yield call(sendToSap, data.id, associateDuplicatedSapCodes)
  } catch (err: any) {
    yield put(masterSpecActions.setError(err))
  } finally {
    yield put(masterSpecActions.setLoader(false))
  }
}

function* deleteMasterSpecSaga({ payload }: PayloadAction<IDeleteMasterSpecPayload>) {
  yield put(masterSpecActions.setLoader(true))
  try {
    yield call(deleteMasterSpec, payload)
  } catch (err: any) {
    yield put(masterSpecActions.setError(err))
  } finally {
    yield put(masterSpecActions.setLoader(false))
  }
}

function* deleteProjectSpecSaga({ payload }: PayloadAction<IDeleteMasterSpecPayload>) {
  yield put(masterSpecActions.setLoader(true))
  try {
    yield call(deleteProjectSpec, payload)
  } catch (err: any) {
    yield put(masterSpecActions.setError(err))
  } finally {
    yield put(masterSpecActions.setLoader(false))
  }
}

function* getDetailSaga({ payload }: PayloadAction<IGetDetailPayload>) {
  const { specId } = payload
  yield put(masterSpecActions.setLoader(true))
  try {
    const masterSpecDetail: IMasterSpecDetail = yield call(getMasterSpecDetail, specId)
    yield put(masterSpecActions.setDetail(masterSpecDetail))
  } catch (err: any) {
    yield put(masterSpecActions.setError(err))
  } finally {
    yield put(masterSpecActions.setLoader(false))
  }
}

function* getProjectDetailSaga({ payload }: PayloadAction<IGetDetailPayload>) {
  const { specId, hasLoader = true } = payload
  if (hasLoader) {
    yield put(masterSpecActions.setLoader(true))
  }
  try {
    const projectSpecDetail: IMasterSpecDetail = yield call(getProjectSpecDetail, specId)
    yield put(masterSpecActions.setProjectDetail(projectSpecDetail))
  } catch (err: any) {
    yield put(masterSpecActions.setError(err))
  } finally {
    if (hasLoader) {
      yield put(masterSpecActions.setLoader(false))
    }
  }
}

function* getSpecificationSectionsSaga(): SagaIterator {
  yield put(masterSpecActions.setLoader(true))
  try {
    const specificationSections: IMasterSpecDetail = yield call(getSpecificationSections)
    yield put(masterSpecActions.setSpecificationSections(specificationSections))
  } catch (err: any) {
    yield put(masterSpecActions.setError(err))
  } finally {
    yield put(masterSpecActions.setLoader(false))
  }
}

function* applyAllSapCodesToCharactersSaga({
  payload
}: PayloadAction<IApplyAllSapCodeActionPayload>) {
  yield put(masterSpecActions.setLoader(true))

  try {
    const projejctSpecDetails: IProjectSpecDetail = yield select(selectProjectSpecDetail)
    const newProjectSpec: IProjectSpecDetail = {
      ...projejctSpecDetails,
      projectSpecificationCharacteristics:
        projejctSpecDetails?.projectSpecificationCharacteristics.map((data) => ({
          ...data,
          sapCodes: [...payload.sapCodes]
        })) || []
    }
    const cleanPayload = cleanProjectSpec(newProjectSpec) as Partial<IProjectSpecDetail>
    yield call(updateProjectSpec, cleanPayload)
    yield put(
      masterSpecActions.getProjectDetail({ specId: newProjectSpec.id, hasLoader: false })
    )
  } catch (err: any) {
    yield put(masterSpecActions.setError(err))
  } finally {
    yield put(masterSpecActions.setLoader(false))
  }
}

export default function* masterSpecSaga() {
  yield takeLatest(masterSpecActionType.MASTER_SPEC_FETCH_ALL, fetchMasterSpecsSaga)
  yield takeLatest(masterSpecActionType.PROJECT_SPEC_FETCH_ALL, fetchProjectSpecsSaga)
  yield takeLatest(masterSpecActionType.CHARACTERISTICS_FETCH_ALL, getAllCharSaga)
  yield takeLatest(masterSpecActionType.CREATE_MASTER_SPEC, createMasterSpecSaga)
  yield takeLatest(masterSpecActionType.CREATE_PROJECT_SPEC, createProjectSpecSaga)
  yield takeLatest(masterSpecActionType.UPDATE_MASTER_SPEC, updateMasterSpecSaga)
  yield takeLatest(masterSpecActionType.UPLOAD_MSPEC_THUMBNAIL, uploadMSpecThumbnailSaga)
  yield takeLatest(masterSpecActionType.UPLOAD_MSPEC_CHAR_IMAGE, uploadMSpecCharImageSaga)
  yield takeLatest(masterSpecActionType.UPDATE_PROJECT_SPEC, updateProjectSpecSaga)
  yield takeLatest(masterSpecActionType.CHECK_SAP_CODES, checkSapCodesSaga)
  yield takeLatest(masterSpecActionType.UPDATE_PROJECT_SPEC_AND_SEND, sendtoSapSaga)
  yield takeLatest(masterSpecActionType.CREATE_PROJECT_SPEC_AND_SEND, sendNewtoSapSaga)
  yield takeLatest(masterSpecActionType.DELETE_MASTER_SPEC, deleteMasterSpecSaga)
  yield takeLatest(masterSpecActionType.DELETE_PROJECT_SPEC, deleteProjectSpecSaga)
  yield takeLatest(masterSpecActionType.GET_DETAIL, getDetailSaga)
  yield takeLatest(masterSpecActionType.GET_PROJECT_DETAIL, getProjectDetailSaga)
  yield takeLatest(masterSpecActionType.UPLOAD_UPDATE_CHAR, uploadAndUpdateCharSaga)
  yield takeLatest(
    masterSpecActionType.UPLOAD_UPDATE_PROJ_CHAR,
    uploadAndUpdateProjCharSaga
  )
  yield takeLatest(
    masterSpecActionType.GET_SPECIFICATION_SECTIONS,
    getSpecificationSectionsSaga
  )
  yield takeLatest(
    masterSpecActionType.APPLY_SAP_CODE_TO_ALL_CHARACTER,
    applyAllSapCodesToCharactersSaga
  )
  yield takeLatest(
    masterSpecActionType.GET_CHARACTERISTICS_AND_APPLY_DEFAULT,
    getCharAndApplyDefaultSaga
  )
}
