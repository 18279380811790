import { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from '../../../components'
import { InputTextArea } from '../../../components/Form/TextArea'
import { Modal } from '../../../components/Modal'
import { Text } from '../../../components/Text'
import { actions as appActions } from '../../../features/app'
import { cartActions } from '../../../features/cart'
import { getCartItems } from '../../../features/cart/selectors'
import { IConfirmationModalProps } from './types'

export const ConfirmationModal: FC<IConfirmationModalProps> = ({ onBack }) => {
  const dispatch = useDispatch()

  const products = useSelector(getCartItems)

  const modalKey = 'order-confirmation'

  const backHandler = () => {
    closeModalHandler()
    onBack()
  }

  const openAvailabilityWarning = () => {
    dispatch(
      appActions.openPopUp({
        key: 'availability-warning',
        title: 'Warning',
        message: `The order cannot be processed because the products are no more available.
        You can send a request to the Sample Room by using the Store Contact Support link available in the User Menu`,
        footer: [<Button label="Ok" onClick={backHandler} />]
      })
    )
  }

  const closeModalHandler = () => {
    dispatch({ type: 'app/closeModal', payload: { modalKey: modalKey } })
  }

  const checkAndUpdateAvailabilty: (arg?: Record<string, any>[]) => void = (
    updatedProds = []
  ) => {
    if (updatedProds.length) {
      updatedProds.forEach(({ productCode, availableQuantity }: any) => {
        const prodData = products.find(
          ({ productCode: itemCode }) => productCode === itemCode
        )
        if (prodData) {
          const newprod = {
            ...prodData,
            item: {
              ...prodData.item,
              availableQuantity
            }
          }
          dispatch(cartActions.updateCartItemById({ id: prodData.id, itemData: newprod }))
        }
      })
      openAvailabilityWarning()
      dispatch(cartActions.setCartLoader(false))
    } else {
      dispatch(cartActions.confirmOrder())
    }
  }

  const checkAvailabilityAndConfirm = () => {
    closeModalHandler()
    dispatch(cartActions.setCartLoader(true))
    dispatch(
      cartActions.checkAvailability({
        items: products,
        warningCallback: checkAndUpdateAvailabilty,
        hasLoader: false
      })
    )
  }

  return (
    <Modal
      btnName="Submit"
      modalKey={modalKey}
      size="small"
      title=""
      isSmall
      footer={[
        <Button key="cancel" label="Cancel" variant="link" onClick={closeModalHandler} />,
        <Button key="continue" label="Continue" onClick={checkAvailabilityAndConfirm} />
      ]}
      centered
    >
      <Text variant="h3" text="Other Information" bold={true} />
      <Text variant="h4" text="Insert here any further information needed" />
      <InputTextArea
        titleConfig={{
          title: 'NOTE',
          variant: 'p'
        }}
        placeholder="Insert here any further information needed"
        onChange={(value) => dispatch(cartActions.updateNote(value))}
      />
    </Modal>
  )
}
