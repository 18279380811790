import { randomizeCharacteristicId } from '../../components/AddMasterSpecCharacteristicModal/utils'
import { DefaultCharacteristicInCreation } from '../../containers/MasterSpecificationDetailsContent/constants'
import {
  BaseSpecificationCharacteristic,
  MasterSpecCharacteristic
} from '../../features/masterSpec/model'
import { convertBaseCharToMasterChar } from '../../pages/MasterSpecificationDetail/utils'

export const refactorCharacteristicForMasterCreation: (
  list: BaseSpecificationCharacteristic[],
  sectionId: string
) => Partial<MasterSpecCharacteristic>[] = (list, sectionId) => {
  if (!list) return []

  const filteredCharacteris =
    list?.filter(
      ({ characteristicDescription }) =>
        characteristicDescription?.includes(
          DefaultCharacteristicInCreation.TOTAL_CRITICAL_DEFECTS
        ) ||
        characteristicDescription?.includes(
          DefaultCharacteristicInCreation.TOTAL_MAJOR_DEFECTS
        ) ||
        characteristicDescription?.includes(
          DefaultCharacteristicInCreation.TOTAL_MINOR_DEFECTS
        )
    ) || []

  const refactoredList = filteredCharacteris.map((data) => {
    const newData = convertBaseCharToMasterChar(randomizeCharacteristicId(data))
    return {
      ...newData,
      qualityInspection: true,
      specificationSectionId: sectionId
    }
  })
  return refactoredList
}
