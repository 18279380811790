import { ReactNode, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { StyledButtonCard } from './styled'

type Props = {
  className?: string
  title: string
  path: string
  children?: ReactNode
}

export const ButtonCard = ({ className, children, path }: Props) => {
  const navigate = useNavigate()

  const nav = useCallback(
    (path: string) => {
      navigate(path)
    },
    [navigate]
  )

  return (
    <div style={{ width: 300 }}>
      <StyledButtonCard className="custom-child" onClick={() => nav(`${path}`)}>
        {children}
      </StyledButtonCard>
    </div>
  )
}
