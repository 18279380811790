import { OrderStatus } from '../../../features/orderList/models'
import { AppRoutes } from '../../../pages/constants'
import { TABLE_DATA_TYPE } from '../../NewTable/constants'

export const ORDERLIST_TABLE_CONFIG = {
  id: {
    label: 'Order ID',
    dataType: TABLE_DATA_TYPE.LINK,
    pathname: AppRoutes.STORE_ORDER_DETAILS,
    newTab: true
  },
  createdDate: { label: 'Date of request', dataType: TABLE_DATA_TYPE.DATE },
  fullfillmentDate: {
    label: 'Request date of fullfilment',
    dataType: TABLE_DATA_TYPE.DATE
  },
  addressedTo: { label: 'Client/Event/Other', dataType: TABLE_DATA_TYPE.TEXT },
  additionalNote: { label: 'Destination Notes', dataType: TABLE_DATA_TYPE.TEXT },
  ownerFullName: { label: 'User of the request', dataType: TABLE_DATA_TYPE.TEXT }
}

export const ORDERLIST_STATUS_TABLE_CONFIG = {
  [OrderStatus.FORWARDED]: ORDERLIST_TABLE_CONFIG,
  [OrderStatus.ACCEPTED]: ORDERLIST_TABLE_CONFIG,
  [OrderStatus.PROCESSED]: ORDERLIST_TABLE_CONFIG,
  [OrderStatus.REFUSED]: ORDERLIST_TABLE_CONFIG
}
