import { isEmpty } from 'lodash'
import { FC } from 'react'
import { useSelector } from 'react-redux'

import { PopUp } from '../../components'
import { Footer } from '../../components/Footer'
import Header from '../../components/Header'
import HeaderMenu from '../../components/Header/HeaderMenu'
import { Error } from '../../containers'
import { selectPermissions } from '../../features/users/selectors'
import { HOME_LINKS } from '../../pages/constants'
import { routeVisibilityHelper } from '../../utils'
import { GenericLayoutContainer } from './styled'
import { IGenericLayoutProps } from './types'

const GenericLayout: FC<IGenericLayoutProps> = ({ children, error }) => {
  const userPermissions = useSelector(selectPermissions)

  const filteredRoutes = HOME_LINKS.filter(({ path }) => {
    const isVisible = routeVisibilityHelper(path, userPermissions)
    return isVisible
  })
  const menuItems = filteredRoutes.map(({ path, label }) => ({
    danger: false,
    disabled: false,
    key: path,
    label: label
  }))

  const hasError = !isEmpty(error)

  return (
    <GenericLayoutContainer>
      <PopUp />
      <Header>
        <HeaderMenu menus={menuItems} />
      </Header>
      {hasError ? <Error error={error} /> : <>{children}</>}
      <Footer />
    </GenericLayoutContainer>
  )
}

export default GenericLayout
