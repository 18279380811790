import { Tooltip as TooltipAntd } from 'antd'
import { TooltipPlacement } from 'antd/lib/tooltip'
import { ReactNode } from 'react'

type Props = {
  title: string | ReactNode
  children?: ReactNode
  placement?: TooltipPlacement
}
export const Tooltip = ({ title, children, placement }: Props) => (
  <TooltipAntd title={title} placement={placement}>
    <span>{children}</span>
  </TooltipAntd>
)
