import styled from 'styled-components'

export const StyledInputNumber = styled.div`
  display: flex;
  .input {
    border-radius: 0;
    width: 45px;
  }
  button {
    width: 40px;
    height: 40px;
    padding: 4px 0;
    font-size: 18px;
    border-radius: 0px !important;
  }
`
