import { Form } from 'antd'
import { get } from 'lodash'
import { FC, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Button, InfoCell } from '../../../components'
import { BoxWrapper } from '../../../components/BoxWrapper'
import { Divider } from '../../../components/Divider'
import { Modal } from '../../../components/Modal'
import { AttachmentFilter } from '../../../features/packaging/model'
import { IAttachFilterModalProps } from './types'

const AttachFilterModal: FC<IAttachFilterModalProps> = ({
  filtersData,
  onApplyFilter,
  modalKey
}) => {
  const dispatch = useDispatch()
  const [tempFilter, setTempFilter] = useState<AttachmentFilter>({})

  const initTempFilter = () => {
    setTempFilter(filtersData)
  }

  const updateTempFilterHandlder = (key: keyof AttachmentFilter, value: any) => {
    setTempFilter({
      ...tempFilter,
      [key]: value
    })
  }

  const closeModalHandler = () => {
    dispatch({
      type: 'app/closeModal',
      payload: { modalKey }
    })
  }

  const applyFilterHandler = () => {
    closeModalHandler()
    onApplyFilter(tempFilter)
  }

  return (
    <Modal
      btnName={'Filter'}
      modalKey={modalKey}
      title={'Filter Attachments'}
      size={'medium'}
      isSmall
      btnClicked={initTempFilter}
      btnType={'ghost'}
      centered
      footer={[<Button key="search" label={'Search'} onClick={applyFilterHandler} />]}
    >
      <Form layout={'vertical'}>
        <Divider disableBorder />
        <BoxWrapper md={2} lg={3} enablePadding>
          <InfoCell
            label="Name"
            value={get(tempFilter, 'name', '')}
            inputConfig={{
              onChange: (value: string) => updateTempFilterHandlder('name', value)
            }}
            isEditing
          />
          <InfoCell
            label="Description"
            value={get(tempFilter, 'description', '')}
            inputConfig={{
              onChange: (value: string) => updateTempFilterHandlder('description', value)
            }}
            isEditing
          />
          <InfoCell
            label="SAP Codes"
            value={get(tempFilter, 'SAPCodes', '')}
            inputConfig={{
              onChange: (value: string) => updateTempFilterHandlder('SAPCodes', value)
            }}
            isEditing
          />
        </BoxWrapper>
      </Form>
    </Modal>
  )
}

export default AttachFilterModal
