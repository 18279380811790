import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Button } from '../../../components'
import { InputTextArea } from '../../../components/Form/TextArea'
import { Modal } from '../../../components/Modal'
import { Text } from '../../../components/Text'
import { orderListActions } from '../../../features/orderList/reducer'

const AcceptModal = () => {
  const dispatch = useDispatch()
  const { id } = useParams()
  const [acceptNote, setAcceptNote] = useState('')

  const closeModalHandler = () => {
    dispatch({ type: 'app/closeModal', payload: { modalKey: 'order-accept' } })
  }

  const confirmAcceptHandler = () => {
    dispatch(orderListActions.confirmAccept({ id: id!, acceptNote }))
    dispatch({ type: 'app/closeModal', payload: { modalKey: 'order-accept' } })
  }

  const footer = [
    <Button key="cancel" label="Cancel" onClick={closeModalHandler} variant="ghost" />,
    <Button key="confirm" label="Accept" onClick={confirmAcceptHandler} />
  ]

  return (
    <Modal
      btnName="Accept"
      size="small"
      isSmall
      modalKey="order-accept"
      title="Insert note for acceptance, if necessary"
      btnType="primary"
      footer={footer}
      centered
    >
      <Text variant="h4" text="If needed you can insert a note:" />
      <InputTextArea
        titleConfig={{
          variant: 'p'
        }}
        placeholder="Insert here any further information needed"
        onChange={(value) => setAcceptNote(value)}
      />
    </Modal>
  )
}

export default AcceptModal
