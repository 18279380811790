export const TEST_EXTENSION_OPTIONS = [
  { value: 'TEST', label: 'TEST' },
  { value: 'EXTENSION', label: 'EXTENSION' }
]

export const dropdownify = (arrayOfOptions: any[]) => {
  return arrayOfOptions.map((option) => ({ value: option, label: option }))
}

export const WEIGHT_LOSS_DROPDOWN = [
  { value: 'FAILED', label: 'FAILED' },
  { value: 'NO_VOLATILES', label: 'NO VOLATILES' },
  { value: 'NOT_CONDUCTED', label: 'NOT CONDUCTED' },
  { value: 'PASSED', label: 'PASSED' },
  { value: 'PK_NOT_APPROPRIATE', label: 'PK NOT APPROPRIATE' },
  { value: 'PK_HOMOLOGATED', label: 'PK HOMOLOGATED' }
]
