import { get, isNumber } from 'lodash'
import { IError } from '../containers/Error/types'

export const getStatusCode: (
  s: string
) => 'success' | 'warning' | '403' | '404' | '500' = (status) => {
  if (status === 'success' || status === 'warning') return status
  if (!isNumber(status)) return '500'
  const statusCode = +status
  if (statusCode >= 400 && statusCode < 404) return '403'
  if (statusCode >= 404 && statusCode < 500) return '404'
  return '500'
}

export const getCleanError: (err: IError) => IError = (err) => {
  const statusCode = get(err, 'status', '500')
  const message = get(err, 'message', 'Something went wrong')
  const subTitle = get(err, 'subTitle', '')
  const title = get(err, 'title', 'Error')
  const status = getStatusCode(statusCode)
  return { status, message, subTitle, title }
}
