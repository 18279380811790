import { FC } from 'react'
import { useDispatch } from 'react-redux'
import { Button } from '../../../components'
import { Modal } from '../../../components/Modal'
import { Text } from '../../../components/Text'
import { ISyncModalProps } from './types'

const SyncModal: FC<ISyncModalProps> = ({ data, onSync = () => {} }) => {
  const dispatch = useDispatch()

  const isDisabled = data.status === 'PUBLISHED'

  const modalKey = `add-sync-${data.id}`

  const closeModalHandler = () => {
    dispatch({ type: 'app/closeModal', payload: { modalKey } })
  }

  const syncHandler = () => {
    closeModalHandler()
    onSync({ ...data, status: 'PUBLISHED' })
  }

  return (
    <Modal
      modalKey={`add-sync-${data.id}`}
      btnIcon="SyncOutlined"
      btnType="link"
      title=""
      size="small"
      isSmall={true}
      disabled={isDisabled}
      centered
      footer={[
        <Button key="no" label="No" variant="ghost" onClick={closeModalHandler} />,
        <Button key="yes" label="Yes, change" onClick={syncHandler} />
      ]}
    >
      <Text
        variant="h4"
        text="You are changing the status of the attachments from draft to published"
      />
      <Text variant="h5" text="Are you sure you want to procede with this operation?" />
    </Modal>
  )
}

export default SyncModal
