import { ReactNode } from 'react'
import { StyledText } from './styled'

export type TextVariant = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p'

export type Props = {
  text: string | number | boolean | ReactNode
  variant?: TextVariant
  bold?: boolean
  className?: string
  size?: string
  value?: string | number | boolean
  children?: ReactNode
  alert?: boolean
  italic?: boolean
  color?: string
}

export const Text = ({
  value,
  text,
  variant,
  bold,
  className,
  size,
  alert,
  color,
  italic
}: Props) => {
  return (
    <StyledText
      alert={alert}
      className={className}
      as={variant}
      fontWeight={bold ? 'bold' : 'normal'}
      fontSize={size}
      color={color}
      italic={italic}
    >
      {value || text}
    </StyledText>
  )
}
