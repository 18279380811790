import * as Icons from '@ant-design/icons'
import { FC, useEffect } from 'react'
import { useKeyPress } from '../../utils/useKeyPress'
import { Tooltip } from '../Tooltip'
import { IconContainer, StyledButton } from './styled'
import { IButtonProps } from './types'

const Button: FC<IButtonProps> = ({
  label,
  iconName,
  variant = 'primary',
  shape = 'round',
  onClick = () => {},
  disabled = false,
  className,
  iconPosition = 'right',
  enableTooltip = false,
  tooltipLabel,
  enableKeyPress
}) => {
  const IconModule: [string, any] | undefined = Object.entries(Icons).find(
    ([antdIconName]) => antdIconName === iconName
  )

  const Icon = IconModule ? IconModule[1] : null

  const getBorderRadius = () => {
    switch (shape) {
      case 'round':
        return '10px'
      case 'square':
        return '0px'
      case 'circle':
        return '25px'
      default:
        return '10px'
    }
  }

  const borderRadius = getBorderRadius()

  const buttonStyles = {
    borderRadius: borderRadius
  }

  const searchOnPress = useKeyPress('Enter')
  useEffect(() => {
    if (!disabled && searchOnPress && enableKeyPress) {
      onClick({} as React.MouseEvent<HTMLElement, MouseEvent>) // default button type needs this type
    }
  }, [onClick, searchOnPress, disabled, enableKeyPress])

  return (
    <StyledButton
      icon={iconPosition === 'left' && Icon && <Icon />}
      type={variant}
      onClick={onClick}
      style={buttonStyles}
      label={label}
      className={className}
      disabled={disabled}
    >
      {label}
      {iconPosition === 'right' && Icon && (
        <IconContainer label={label}>
          {enableTooltip ? (
            <Tooltip title={tooltipLabel || ''}>
              <Icon />
            </Tooltip>
          ) : (
            <Icon />
          )}
        </IconContainer>
      )}
    </StyledButton>
  )
}

export default Button
