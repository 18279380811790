import styled from 'styled-components'
import { footerHeight } from '../../assets/mixins'
import { Text } from '../../components/Text'

export const footerActionHeight = 45
export const FooterActions = styled.div`
  position: fixed;
  display: flex;
  height: ${footerActionHeight}px;
  width: 100%;
  background-color: white;
  left: 0;
  bottom: ${footerHeight}px;
  justify-content: flex-end;
  border-top: 1px solid rgb(238, 238, 238);
  padding: 5px 20px;
  align-items: center;
  > button {
    margin: 0px 18px;
  }
`

export const StyledText = styled(Text)`
  margin-top: 2%;
  text-align: center;
`

export const StepContent = styled.div<{ heightToRemove: number }>`
  height: ${({ heightToRemove }) => `calc(100% - ${heightToRemove}px)`};
  overflow: auto;
`
