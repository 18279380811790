import {
  FORMULA_SCHEMA_STATUS_NOT_VISIBLE,
  FORMULA_STATUS_NOT_VISIBLE
} from '../../features/formulaSchema/constants'
import { Formula } from '../../features/formulaSchema/model'

export const checkIsFormulaSchemaStatusValid: (s: string) => boolean = (status: string) =>
  !FORMULA_SCHEMA_STATUS_NOT_VISIBLE.includes(status)

export const checkIsFormulaStatusValid: (s: string) => boolean = (status: string) =>
  !FORMULA_STATUS_NOT_VISIBLE.includes(status)

export const filterFormulaByStatus: (a: Formula[]) => Formula[] = (formulaList) =>
  formulaList ? formulaList.filter(({ status }) => checkIsFormulaStatusValid(status)) : []
