import { OrderStatus } from './models'

export const SEARCH_ORDER_LIST = 'storeOrders/SEARCH_ORDER_LIST'
export const CHANGE_TAB_AND_SEARCH = 'storeOrders/CHANGE_TAB_AND_SEARCH'
export const APPLY_FILTERS_AND_SEARCH = 'storeOrders/APPLY_FILTERS_AND_SEARCH'
export const REMOVE_FILTER_AND_SEARCH = 'storeOrders/removeFilterSaga'
export const CHANGE_PAGE_AND_SEARCH = 'storeOrders/CHANGE_PAGE_AND_SEARCH'
export const FETCH_ORDER_DETAILS = 'storeOrders/FETCH_ORDER_DETAILS'
export const FETCH_LATEST_ORDER = 'storeOrders/FETCH_LATEST_ORDER'
export const CONFIRM_REJECT_ORDER = 'storeOrders/CONFIRM_REJECT_ORDER'
export const CONFIRM_ACCEPT_ORDER = 'storeOrders/CONFIRM_ACCEPT_ORDER'
export const CONFIRM_PROPOSE_ORDER = 'storeOrders/CONFIRM_PROPOSE_ORDER'
export const CONFIRM_PROCESS_ORDER = 'storeOrders/CONFIRM_PROCESS_ORDER'
export const CHECK_ITEM_AVAILABILITY_FOR_PROPOSE =
  'storeOrders/CHECK_ITEM_AVAILABILITY_FOR_PROPOSE'

export const ORDER_LIST_TABS = [
  {
    label: 'Forwarded',
    value: OrderStatus.FORWARDED
  },
  {
    label: 'Accepted',
    value: OrderStatus.ACCEPTED
  },
  {
    label: 'Processed',
    value: OrderStatus.PROCESSED
  },
  {
    label: 'Refused',
    value: OrderStatus.REFUSED
  }
]

export const LATEST_ORDER_COOKIE_NAME = 'intercos-latest-order'
// NOTE ===== LATEST_ORDER_COOKIE_NAME STRUCTURE ====
// {
//     FORWARDED: {
//        updatedDate: ''
// }
//     REFUSED: {
//        updatedDate: ''
// }
// }
//

export const ORDER_STATUS_COLOR = {
  [OrderStatus.FORWARDED]: 'grey',
  [OrderStatus.ACCEPTED]: 'green',
  [OrderStatus.PROCESSED]: 'green',
  [OrderStatus.REFUSED]: 'red'
}

export const REFUSED_REASONS = {
  UNDELIVERABLE_AT_ADDRESS: 'UNDELIVERABLE_AT_ADDRESS'
}
