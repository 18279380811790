import { Form, InputNumber } from 'antd'
import { get } from 'lodash'
import { FC } from 'react'
import { useDispatch } from 'react-redux'
import { inputMinWidth } from '../../../assets/mixins'
import { toolingActions } from '../../../features/tooling'
import { ToolingDto } from '../../../features/tooling/model'
import { BoxInfo } from '../../BoxInfo'
import { BoxWrapper } from '../../BoxWrapper'
import InfoCell from '../../InfoCell'
import { replaceDotsWithCommas } from '../../ToolingTable/constants'
import { IToolingInfo } from './types'
import { toolingStatus, yesNoNull } from './utils'

const ToolingInfo: FC<IToolingInfo> = ({
  data,
  isEditing = false,
  isCreating = false
}) => {
  const dispatch = useDispatch()

  const onChange = (input: { key: keyof ToolingDto; value: any }) => {
    dispatch(toolingActions.updateEditingData(input))
  }
  const placeholder = 'Insert Value'
  return (
    <Form layout="vertical">
      <br />
      <BoxWrapper columnCount={4} enablePadding>
        <InfoCell
          label={'Status'}
          value={
            !isEditing
              ? replaceDotsWithCommas(get(data, 'status', ''))
              : get(data, 'status', '')
          }
          inputType="select"
          isEditing={isEditing}
          inputConfig={{
            items: toolingStatus,
            onChange: ({ value }: Record<string, string>) => {
              onChange({ key: 'status', value })
            }
          }}
        />
        <InfoCell
          label={'Tooling Code'}
          value={get(data, 'code', '')}
          isEditing={isCreating}
          inputConfig={{
            placeholder: 'Insert Value',
            required: true,
            error: !get(data, 'code', ''),
            onChange: (value: string) => {
              onChange({ key: 'code', value })
            }
          }}
        />
        <InfoCell
          label={'Tooling Type'}
          value={get(data, 'type', '')}
          isEditing={isEditing}
          inputConfig={{
            placeholder: 'Insert Value',
            required: true,
            error: !get(data, 'type', ''),
            onChange: (value: string) => {
              onChange({ key: 'type', value })
            }
          }}
        />
        <InfoCell
          label={'Tooling Shape'}
          value={get(data, 'toolingShape', '')}
          isEditing={isEditing}
          inputConfig={{
            placeholder: 'Insert Value',
            onChange: (value: string) => {
              onChange({ key: 'toolingShape', value })
            }
          }}
        />
        <InfoCell
          label={'Tooling Material'}
          value={get(data, 'material', '')}
          isEditing={isEditing}
          inputConfig={{
            placeholder: 'Insert Value',
            onChange: (value: string) => {
              onChange({ key: 'material', value })
            }
          }}
        />
        <InfoCell
          label={'Back Injection'}
          value={get(data, 'backInjection', '')}
          isEditing={isEditing}
          inputType="options-button"
          inputConfig={{
            options: yesNoNull,
            onChange: (value: boolean) => {
              onChange({ key: 'backInjection', value })
            }
          }}
        />
        {!isEditing ? (
          <BoxInfo
            label="Tooling Diameter"
            value={replaceDotsWithCommas(data?.diameter)}
          />
        ) : (
          <Form.Item label="Tooling Diameter">
            <InputNumber
              style={{ minWidth: inputMinWidth, width: '100%' }}
              type="number"
              step="0.01"
              max="9999.99"
              placeholder={placeholder}
              stringMode
              value={data?.diameter}
              min="0"
              onChange={(value: string) => {
                onChange({ key: 'diameter', value })
              }}
            />
          </Form.Item>
        )}
        {!isEditing ? (
          <BoxInfo label="Tooling Length" value={replaceDotsWithCommas(data?.length)} />
        ) : (
          <Form.Item label="Tooling Length">
            <InputNumber
              style={{ minWidth: inputMinWidth, width: '100%' }}
              type="number"
              step="0.01"
              max="9999.99"
              placeholder={placeholder}
              stringMode
              value={data?.length}
              min="0"
              onChange={(value: string) => {
                onChange({ key: 'length', value })
              }}
            />
          </Form.Item>
        )}
        {!isEditing ? (
          <BoxInfo label="Tooling Width" value={replaceDotsWithCommas(data?.width)} />
        ) : (
          <Form.Item label="Tooling Width">
            <InputNumber
              style={{ minWidth: inputMinWidth, width: '100%' }}
              type="number"
              step="0.01"
              max="9999.99"
              placeholder={placeholder}
              stringMode
              value={data?.width}
              min="0"
              onChange={(value: string) => {
                onChange({ key: 'width', value })
              }}
            />
          </Form.Item>
        )}
        {!isEditing ? (
          <BoxInfo label="Tooling Height" value={replaceDotsWithCommas(data?.height)} />
        ) : (
          <Form.Item label="Tooling Height">
            <InputNumber
              style={{ minWidth: inputMinWidth, width: '100%' }}
              type="number"
              step="0.01"
              max="9999.99"
              placeholder={placeholder}
              stringMode
              value={data?.height}
              min="0"
              onChange={(value: string) => {
                onChange({ key: 'height', value })
              }}
            />
          </Form.Item>
        )}
        <InfoCell
          label={'Industrial or Flat Mold Code'}
          value={get(data, 'industrialCodeMold', '')}
          isEditing={isEditing}
          inputConfig={{
            placeholder: 'Insert Value',
            onChange: (value: string) => {
              onChange({ key: 'industrialCodeMold', value })
            }
          }}
        />
        {!isEditing ? (
          <BoxInfo label="Tooling Volume" value={replaceDotsWithCommas(data?.volume)} />
        ) : (
          <Form.Item label="Tooling Volume">
            <InputNumber
              style={{ minWidth: inputMinWidth, width: '100%' }}
              type="number"
              step="0.01"
              max="9999.99"
              placeholder={placeholder}
              stringMode
              value={data?.volume}
              min="0"
              onChange={(value: string) => {
                onChange({ key: 'volume', value })
              }}
            />
          </Form.Item>
        )}
        {!isEditing ? (
          <BoxInfo
            label="Tooling Internal Volume"
            value={replaceDotsWithCommas(data?.toolingInternalVolume)}
          />
        ) : (
          <Form.Item label="Tooling Internal Volume">
            <InputNumber
              style={{ minWidth: inputMinWidth, width: '100%' }}
              type="number"
              step="0.01"
              max="9999.99"
              placeholder={placeholder}
              stringMode
              value={data?.toolingInternalVolume}
              min="0"
              onChange={(value: string) => {
                onChange({ key: 'toolingInternalVolume', value })
              }}
            />
          </Form.Item>
        )}
        {!isEditing ? (
          <BoxInfo
            label="Powder Fillable Volume"
            value={replaceDotsWithCommas(data?.powderFillableVolume)}
          />
        ) : (
          <Form.Item label="Powder Fillable Volume">
            <InputNumber
              style={{ minWidth: inputMinWidth, width: '100%' }}
              type="number"
              step="0.01"
              max="9999.99"
              placeholder={placeholder}
              stringMode
              value={data?.powderFillableVolume}
              min="0"
              onChange={(value: string) => {
                onChange({ key: 'powderFillableVolume', value })
              }}
            />
          </Form.Item>
        )}
        <InfoCell
          label={'Mold Size'}
          dataType="number"
          value={
            !isEditing
              ? replaceDotsWithCommas(get(data, 'moldSize', ''))
              : get(data, 'moldSize', '')
          }
          isEditing={isEditing}
          inputConfig={{
            placeholder: 'Insert Value',
            onChange: (value: string) => {
              onChange({ key: 'moldSize', value })
            }
          }}
        />
        <InfoCell
          label={'Cavities'}
          value={get(data, 'cavities', '')}
          dataType="number"
          isEditing={isEditing}
          inputConfig={{
            placeholder: 'Insert Value',
            onChange: (value: string) => {
              onChange({ key: 'cavities', value })
            }
          }}
        />
        <InfoCell
          label={'Pilot Mold Code'}
          value={get(data, 'pilotMoldCode', '')}
          isEditing={isEditing}
          inputConfig={{
            placeholder: 'Insert Value',
            onChange: (value: string) => {
              onChange({ key: 'pilotMoldCode', value })
            }
          }}
        />
      </BoxWrapper>
    </Form>
  )
}

export default ToolingInfo
