import styled from 'styled-components'
import { typography, variant, ColorProps, TypographyProps } from 'styled-system'

type StyledTextProps = {
  upperCase?: boolean
  alert?: boolean
  italic?: boolean
} & ColorProps &
  TypographyProps

export const StyledText = styled.span<StyledTextProps>`
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: ${({ italic }) => (italic ? 'italic' : 'normal')};
  text-transform: ${({ upperCase }) => (upperCase ? 'uppercase' : 'none')};
  ${({ alert }) => alert && { color: 'red' }}
  ${({ color, alert }) => !alert && { color: color || 'black' }}
  ${typography}
  ${variant({
    prop: 'as',
    variants: {
      h1: {
        fontSize: 'extraLarge',
        fontWeight: 'bold',
        marginBottom: 1,
        marginTop: 1
      },
      h2: {
        fontSize: 'large',
        fontWeight: 'bold',
        marginBottom: 1,
        marginTop: 1
      },
      h3: {
        fontSize: 'medium',
        marginBottom: 1,
        marginTop: 1
      },
      h4: {
        fontSize: 'base',
        marginBottom: 1,
        marginTop: 1
      },
      h5: {
        fontSize: 'small',
        marginBottom: 1,
        marginTop: 1
      },
      h6: {
        fontSize: 'extraSmall',
        marginBottom: 1,
        marginTop: 1
      },
      p: {
        fontSize: 'base',
        marginBottom: 1,
        marginTop: 1
      }
    }
  })}
`
