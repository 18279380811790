import { InfoCircleOutlined } from '@ant-design/icons'
import { Form, Select as SelectAnt } from 'antd'
import { isArray, orderBy } from 'lodash'
import { FC } from 'react'
import { inputMinWidth } from '../../../assets/mixins'
import { filterOptionHandler } from '../../../utils/filterOptionHandler'
import { ISelectProps } from './types'

const { Option } = SelectAnt

const Select: FC<ISelectProps> = ({
  items,
  value,
  onChange = () => {},
  onClear = () => {},
  mode,
  defaultValue = '',
  required,
  label,
  disabled,
  allowClear = true,
  error = false,
  message,
  enableTooltip,
  onBlur,
  withLookup = false,
  orderItems,
  orderKey,
  style
}) => {
  const changeHandler = (value: any) => {
    if (typeof value !== 'undefined') {
      let newValue
      if (!isArray(value)) {
        newValue = items.find(({ value: itemValue }) => itemValue === value) || ''
      } else {
        newValue = items.reduce((acc, item) => {
          if (value.includes(item.value)) {
            acc.push(item)
          }
          return acc
        }, [])
      }
      onChange(newValue)
    }
  }

  const orderedItems = (orderItems ? orderBy(items, [orderKey], ['asc']) : items) || []
  return (
    <Form.Item
      required={required}
      label={label}
      tooltip={enableTooltip && { title: message, icon: <InfoCircleOutlined /> }}
      validateStatus={error ? 'error' : undefined}
    >
      <SelectAnt
        style={{ minWidth: inputMinWidth, ...style }}
        dropdownClassName="select-with-disabled"
        mode={mode}
        value={value}
        disabled={disabled}
        onChange={changeHandler}
        onClear={onClear}
        onBlur={onBlur}
        defaultValue={defaultValue}
        allowClear={allowClear}
        showSearch={withLookup}
        filterOption={withLookup && filterOptionHandler}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.stopPropagation()
          }
        }}
        data-cy={`select-${label}`}
      >
        {orderedItems &&
          orderedItems.map((item, i) => (
            <Option
              value={item.value}
              key={`${item.value}${i}`}
              data-cy={`select-${label}-option-${item.name}`}
            >
              {item.name}
            </Option>
          ))}
      </SelectAnt>
    </Form.Item>
  )
}

export default Select
