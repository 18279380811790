import { Form } from 'antd'
import { get } from 'lodash'
import { FC } from 'react'
import { useSelector } from 'react-redux'
import { PACKAGING_STATUS } from '../../../features/packaging/constants'
import {
  getAttachmentContentTypesList,
  getDropdownAttachmentStatus,
  getDropdownFilteredContentTypes
} from '../../../features/packaging/selectors'
import { BoxWrapper } from '../../BoxWrapper'
import InfoCell from '../../InfoCell'
import Button from '../../NewButton'
import { Text } from '../../Text'
import { AttachmentCardContainer, TitleWrapper } from './styled'
import { AttachmentCardProps } from './types'

const AttachmentCard: FC<AttachmentCardProps> = ({
  data,
  isEditing = false,
  updateData = () => {},
  onDelete = () => {},
  title = ''
}) => {
  const dropdownAttachmentTypes = useSelector(getDropdownFilteredContentTypes)
  const attachmentContentTypesList = useSelector(getAttachmentContentTypesList)

  const dropdownAttachmentStatus = useSelector(getDropdownAttachmentStatus)

  const selectedContTypeId = get(data, 'contentTypeId', '')
  const selectedContData = attachmentContentTypesList.find(
    ({ id }: any) => id === selectedContTypeId
  )

  return (
    <AttachmentCardContainer>
      <TitleWrapper>
        <Text text={title} variant="h3" />
        {isEditing && (
          <Button
            label="Delete"
            variant="text"
            iconName="DeleteOutlined"
            iconPosition="right"
            onClick={onDelete}
          />
        )}
      </TitleWrapper>
      <Form layout="vertical">
        <BoxWrapper sm={2} md={2} lg={2} enablePadding>
          <InfoCell
            value={get(data, 'file.value', '')}
            label={'variant-attach'}
            isEditing={isEditing}
            dataType="image"
            inputType="upload"
            inputConfig={{
              fileKey: `variant-attach-${Date.now()}`,
              topMessage: 'Drop or browse the attachment',
              fileType: 'any',
              bottomMessage: '',
              onChange: (value: any) => {
                updateData('file', {
                  ...data.file,
                  ...value,
                  name: get(data, 'file.name', '') || get(value, 'name', '')
                })
              },
              enableCharRule: true
            }}
          />
        </BoxWrapper>
        <BoxWrapper sm={2} md={2} lg={2} enablePadding>
          <InfoCell
            label="Content Type"
            value={get(data, 'contentType', '')}
            inputType="select"
            isEditing={isEditing}
            inputConfig={{
              items: dropdownAttachmentTypes,
              onChange: ({ value }: Record<string, any>) => {
                const selectedContType = attachmentContentTypesList.find(
                  ({ id }: { id: string }) => value === id
                )
                const contentTypeLabel = get(selectedContType, 'value', '')
                updateData('contentType', contentTypeLabel)
                updateData('SAPCodes', [])
                updateData('contentTypeId', value)
              }
            }}
          />
          <InfoCell
            label="Name"
            value={get(data, 'file.name', '')}
            isEditing={isEditing}
            inputConfig={{
              onChange: (value: string) =>
                updateData('file', { ...data.file, name: value })
            }}
          />
        </BoxWrapper>
        <BoxWrapper sm={2} md={2} lg={2} enablePadding>
          <InfoCell
            label="Status"
            value={get(data, 'status', PACKAGING_STATUS.DRAFT)}
            inputType="select"
            isEditing={isEditing}
            inputConfig={{
              items: dropdownAttachmentStatus,
              onChange: ({ value }: Record<string, any>) => updateData('status', value)
            }}
          />
          <InfoCell
            label="Description"
            value={get(data, 'description', '')}
            isEditing={isEditing}
            inputConfig={{
              onChange: (value: string) => updateData('description', value)
            }}
          />
        </BoxWrapper>
        <BoxWrapper sm={2} md={2} lg={2} enablePadding>
          <InfoCell
            dataType="tags"
            inputType="input-tag"
            value={get(data, 'SAPCodes', [])}
            label="Sap Codes"
            isEditing={isEditing}
            inputConfig={{
              disabled: !get(selectedContData, 'sapCodeEnabled', false),
              onChange: (value: any) => updateData('SAPCodes', value)
            }}
          />
        </BoxWrapper>
      </Form>
    </AttachmentCardContainer>
  )
}

export default AttachmentCard
