import { Form, Input } from 'antd'
import React, { FC, useState } from 'react'
import { inputMinWidth } from '../../../assets/mixins'
import { Tag } from '../../Tag'
import { InputTagContainer, TagsContainer } from './styled'
import { IInputTagsProps } from './types'

const InputTags: FC<IInputTagsProps> = ({
  label,
  disabled,
  value,
  placeholder,
  onChange = () => {}
}) => {
  const [tempValue, setTempValue] = useState('')

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTempValue(e.currentTarget.value)
  }

  const onEnterTagHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.code === 'Enter') {
      const valueList: string[] = tempValue.trim().split(/[,\s]+/) || []
      const newValue = value.concat(valueList)
      setTempValue('')
      onChange(newValue)
    }
  }

  const onRemoveTagHandler = (idx: number) => {
    const newList = value
    newList.splice(idx, 1)
    onChange(newList)
  }

  return (
    <InputTagContainer>
      <Form.Item label={label}>
        <Input
          style={{ minWidth: inputMinWidth }}
          disabled={disabled}
          value={tempValue}
          onChange={changeHandler}
          onKeyDown={(e) => onEnterTagHandler(e)}
          placeholder={placeholder}
        />
      </Form.Item>
      {!!value.length && (
        <TagsContainer disabled={disabled}>
          {value.map((val, idx: number) => (
            <Tag
              key={`${val}-${idx}`}
              value={val.toString()}
              path={idx.toString()}
              onRemove={(index) => onRemoveTagHandler(+index)}
              closable={!disabled}
            />
          ))}
        </TagsContainer>
      )}
    </InputTagContainer>
  )
}

export default InputTags
