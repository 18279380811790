import { FC } from 'react'
import { StatusTooltipProps } from './types'

const StatusTooltip: FC<StatusTooltipProps> = ({ statusList }) => {
  return (
    <>
      {statusList.map(({ name, value, infoMessage }) => (
        <p key={value}>
          {name}: {infoMessage}
        </p>
      ))}
    </>
  )
}

export default StatusTooltip
