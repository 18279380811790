import styled from 'styled-components'

export const InputTagContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const TagsContainer = styled.div<{ disabled: boolean }>`
  border: 1px solid #d9d9d9;
  max-height: 180px;
  overflow: auto;
  padding: 10px;
  border-radius: 8px;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  > div {
    margin-bottom: 5px;
  }
  .ant-tag {
    ${({ disabled }) => disabled && { borderColor: 'grey' }}
  }
`
