import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Button } from '../../../components'
import { InputTextArea } from '../../../components/Form/TextArea'
import { Modal } from '../../../components/Modal'
import { Text } from '../../../components/Text'
import { orderListActions } from '../../../features/orderList/reducer'

const ProcessModal = () => {
  const dispatch = useDispatch()
  const { id } = useParams()
  const [processNote, setProcessNote] = useState('')

  const closeModalHandler = () => {
    dispatch({ type: 'app/closeModal', payload: { modalKey: 'order-process' } })
  }

  const confirmProcessHandler = () => {
    dispatch(orderListActions.confirmProcess({ id: id!, processNote }))
    dispatch({ type: 'app/closeModal', payload: { modalKey: 'order-process' } })
  }

  const footer = [
    <Button key="back" label="Back" onClick={closeModalHandler} variant="ghost" />,
    <Button key="Continue" label="Continue" onClick={confirmProcessHandler} />
  ]

  return (
    <Modal
      btnName="Process"
      size="small"
      isSmall
      modalKey="order-process"
      title="Insert note for process the order, if necessary"
      btnType="primary"
      footer={footer}
      centered
    >
      <Text variant="h4" text="If needed you can insert a note:" />
      <InputTextArea
        titleConfig={{
          variant: 'p'
        }}
        placeholder="Insert here any further information needed"
        onChange={(value) => setProcessNote(value)}
      />
    </Modal>
  )
}

export default ProcessModal
