import { FC, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const NotAllowedPage: FC = () => {
  const navigate = useNavigate()
  useEffect(() => navigate('/'), [navigate])
  return null
}

export default NotAllowedPage
