import { IOption } from '../../Form/RadioInput/types'

export const yesNoNull: IOption[] = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
  { label: 'Null', value: null }
]

export const toolingStatus = [
  { label: 'AVAILABLE', value: 'AVAILABLE' },
  { label: 'DISCONTINUED', value: 'DISCONTINUED' }
]
