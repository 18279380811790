import { FC } from 'react'
import { Text } from '../Text'
import { SectionTitleContainer } from './styled'
import { ISectionTitleProps } from './types'

const SectionTitle: FC<ISectionTitleProps> = ({
  value,
  isBold = false,
  addon,
  color
}) => {
  return (
    <SectionTitleContainer>
      <Text text={value} variant="h4" bold={isBold} color={color} />
      {!!addon && addon}
    </SectionTitleContainer>
  )
}

export default SectionTitle
