import { DatePicker, Form } from 'antd'
import { get } from 'lodash'
import moment from 'moment'
import { FC } from 'react'
import { threeOptions } from '../../../features/constants'
import {
  INNOVATION_LEVEL_DROPDOWN,
  LEVEL_DROPDOWN,
  applicationAreaDropdown,
  fillingMethodCycleDropdown,
  firstProductOriginDropdown,
  formulaTransferToDropdown,
  intercosInsidePositioningDropdown,
  internalLastingPositionDropdown,
  mascaraPositioningDropdown,
  productCategoryDropdown,
  productFormDropdown
} from '../../../features/product/constants'
import { DatePickerWrapper } from '../../../style/styledDatePicker'
import { BoxWrapper } from '../../BoxWrapper'
import { Divider } from '../../Divider'
import InfoCell from '../../InfoCell'

import { IAddDataSideFormProps } from './types'

const AdditionalDataSideForm: FC<IAddDataSideFormProps> = ({
  data,
  onUpdate,
  isMascara,
  isReady = false
}) => {
  const updateHandler = (key: string, value: any) => {
    onUpdate(key, value)
  }

  const url = `${window.location.href}`
  const wikipack = url.includes('wikipack')

  return (
    <Form layout={'vertical'}>
      <BoxWrapper columnCount={4} enablePadding>
        <InfoCell
          label="Market Bench"
          value={get(data, 'marketBench', '')}
          isEditing
          inputConfig={{
            placeholder: 'Market Bench',
            onChange: (value: any) => updateHandler('marketBench', value)
          }}
        />
        <InfoCell
          label="Note"
          value={get(data, 'note', '')}
          isEditing
          inputConfig={{
            placeholder: 'Note',
            onChange: (value: any) => updateHandler('note', value)
          }}
        />
      </BoxWrapper>
      <Divider />
      <BoxWrapper columnCount={4} enablePadding>
        <InfoCell
          label="Intercos Inside"
          value={get(data, 'intercosInside', null)}
          inputType="options-button"
          isEditing
          inputConfig={{
            options: threeOptions,
            required: true,
            onChange: (value: any) => updateHandler('intercosInside', value)
          }}
        />
        <InfoCell
          label={'Opaque Packaging'}
          value={get(data, 'opaquePackaging', null)}
          inputType="options-button"
          isEditing
          inputConfig={{
            options: threeOptions,
            required: true,
            onChange: (value: any) => updateHandler('opaquePackaging', value)
          }}
        />
        <InfoCell
          label="First Production Origin"
          value={get(data, 'firstProductionOrigin', '')}
          isEditing
          inputType="select"
          inputConfig={{
            items: firstProductOriginDropdown,
            onChange: ({ value }: any) => updateHandler('firstProductionOrigin', value)
          }}
        />
      </BoxWrapper>
      <Divider />
      <BoxWrapper columnCount={4} enablePadding>
        <InfoCell
          label="Application Area"
          value={get(data, 'applicationArea', '')}
          isEditing
          inputType="select"
          inputConfig={{
            required: true,
            error: !get(data, 'applicationArea', ''),
            items: applicationAreaDropdown,
            onChange: ({ value }: any) => updateHandler('applicationArea', value)
          }}
        />
        <InfoCell
          label="Product Category"
          value={get(data, 'productCategory', '')}
          isEditing
          inputType="select"
          inputConfig={{
            required: true,
            error: !get(data, 'productCategory', ''),
            items: productCategoryDropdown,
            onChange: ({ value }: any) => updateHandler('productCategory', value)
          }}
        />
        <InfoCell
          label="Product Form"
          value={get(data, 'productForm', '')}
          isEditing
          inputType="select"
          inputConfig={{
            required: true,
            error: !get(data, 'productForm', ''),
            items: productFormDropdown,
            onChange: ({ value }: any) => updateHandler('productForm', value)
          }}
        />
        <InfoCell
          label="Filling Method Cycle"
          value={get(data, 'fillingMethodCycle', '')}
          isEditing
          inputType="select"
          inputConfig={{
            items: fillingMethodCycleDropdown,
            onChange: ({ value }: any) => updateHandler('fillingMethodCycle', value)
          }}
        />
        <InfoCell
          label="Internal Lasting Position"
          value={get(data, 'internalLastingPosition', '')}
          isEditing
          inputType="select"
          inputConfig={{
            items: internalLastingPositionDropdown,
            onChange: ({ value }: any) => updateHandler('internalLastingPosition', value)
          }}
        />
        <InfoCell
          label="Formula Transfer To"
          value={get(data, 'formulaTransferTo', '')}
          isEditing
          inputType="select"
          inputConfig={{
            required: true,
            error: !get(data, 'formulaTransferTo', ''),
            items: formulaTransferToDropdown,
            onChange: ({ value }: any) => updateHandler('formulaTransferTo', value)
          }}
        />
        <InfoCell
          label="Intercos Inside Positioning"
          value={get(data, 'intercosInsidePositioning', '')}
          isEditing
          inputType="select"
          inputConfig={{
            items: intercosInsidePositioningDropdown,
            onChange: ({ value }: any) =>
              updateHandler('intercosInsidePositioning', value)
          }}
        />
        <InfoCell
          label="Mascara Positioning"
          value={get(data, 'mascaraPositioning', '')}
          isEditing
          inputType="select"
          inputConfig={{
            items: mascaraPositioningDropdown,
            onChange: ({ value }: any) => updateHandler('mascaraPositioning', value),
            disabled: !isMascara
          }}
        />
      </BoxWrapper>

      <Divider />

      <BoxWrapper columnCount={4} enablePadding>
        {!isReady && !wikipack && (
          <>
            <DatePickerWrapper>
              <span>Stability Start Date</span>
              <DatePicker
                value={data.stabilityStartDate && moment(data.stabilityStartDate)}
                format="DD/MM/YYYY"
                onChange={(value) => {
                  if (!value) updateHandler('stabilityStartDate', null)
                  else updateHandler('stabilityStartDate', value.utc().format())
                }}
              />
            </DatePickerWrapper>
            <InfoCell
              label="Innovation Level"
              value={get(data, 'innovationLevel')}
              isEditing
              inputType="select"
              inputConfig={{
                items: INNOVATION_LEVEL_DROPDOWN,
                onChange: ({ value }: any) => {
                  if (!value) updateHandler('innovationLevel', null)
                  else updateHandler('innovationLevel', value)
                }
              }}
            />
            <InfoCell
              label="Bulk Level"
              value={get(data, 'bulkLevel')}
              isEditing
              inputType="select"
              inputConfig={{
                items: LEVEL_DROPDOWN,
                onChange: ({ value }: any) => updateHandler('bulkLevel', parseInt(value))
              }}
            />
            <InfoCell
              label="Compatibility Level"
              value={get(data, 'compatibility')}
              isEditing
              inputType="select"
              inputConfig={{
                items: LEVEL_DROPDOWN,
                onChange: ({ value }: any) =>
                  updateHandler('compatibility', parseInt(value))
              }}
            />
            <InfoCell
              label="Industrial Level"
              value={get(data, 'industrialLevel')}
              isEditing
              inputType="select"
              inputConfig={{
                items: LEVEL_DROPDOWN,
                onChange: ({ value }: any) =>
                  updateHandler('industrialLevel', parseInt(value))
              }}
            />
            <InfoCell
              label="Packaging Level"
              value={get(data, 'packagingLevel')}
              isEditing
              inputType="select"
              inputConfig={{
                items: LEVEL_DROPDOWN,
                onChange: ({ value }: any) =>
                  updateHandler('packagingLevel', parseInt(value))
              }}
            />
          </>
        )}
      </BoxWrapper>
      <br />
    </Form>
  )
}

export default AdditionalDataSideForm
