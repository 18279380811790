import styled from 'styled-components'
import { JustifyContentProps } from 'styled-system'
import { footerControlHeight, footerHeight } from '../../assets/mixins'

export const FooterCtrlContainer = styled.div<JustifyContentProps>`
  display: flex;
  position: fixed;
  width: 100%;
  padding: 5px 20px;
  background-color: white;
  border-top: 1px solid #eee;
  flex-direction: row;
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: center;
  height: ${footerControlHeight}px;
  left: 0;
  bottom: ${footerHeight}px;
  > * {
    margin: 0px 18px;
  }
`
