import { get } from 'lodash'
import { createSelector } from 'reselect'
import type { RootState } from '../store'
import {
  selectUserDisplayName,
  selectUserEmail,
  selectUserRole
} from '../users/selectors'

const selectStore = (state: RootState) => state.cart

export const getCartItems = createSelector(selectStore, (cart) => cart.items || [])
export const getCartItemsByIds = createSelector(selectStore, (cart) =>
  get(cart, 'itemsByIds')
)

export const getCartItemsCount = createSelector(getCartItems, (items) => {
  const count = items?.reduce((acc, item) => acc + item.quantity, 0)
  return count
})

export const getCartId = createSelector(selectStore, (cart) => get(cart, 'cartId'))

export const selectDetails = createSelector(selectStore, (cart) => get(cart, 'details'))
export const selectCustomer = createSelector(selectStore, (cart) => get(cart, 'customer'))
export const selectSetRequired = createSelector(selectCustomer, (customer) =>
  get(customer, 'setRequired')
)
export const selectAdditionalNote = createSelector(
  selectCustomer,
  (customer) => customer.additionalNote || ''
)
export const selectType = createSelector(selectCustomer, (customer) =>
  get(customer, 'type')
)

export const selectCountry = createSelector(selectDetails, (details) =>
  get(details, 'country')
)
export const selectAddress = createSelector(selectDetails, (details) =>
  get(details, 'address')
)
export const selectDate = createSelector(selectDetails, (details) => get(details, 'date'))
export const selectStep1Requirements = createSelector(
  getCartItems,
  (items) => !items?.length
)

export const selectStep2Requirements = createSelector(
  selectAdditionalNote,
  (note) => !note
)
export const selectStep3Requirements = createSelector(selectDetails, (details) => {
  const data = Object.entries(details).find(([k, v]) => !v)
  return data ? true : false
})
export const selectStep4Requirements = createSelector(getCartItems, (cartItems) =>
  cartItems?.reduce((acc, item) => item.quantity <= item.item.availableQuantity, false)
)
export const selectCartLoader = createSelector(selectStore, (cart) =>
  get(cart, 'cartLoader', false)
)

export const selectOrderNote = createSelector(selectStore, (store) => store.notes || '')

export const selectCartError = createSelector(selectStore, (cart) => cart.error)

export const selectCartSuccess = createSelector(selectStore, (cart) => cart.successInfo)

export const selectOrderData = createSelector(
  selectUserEmail,
  selectUserDisplayName,
  selectDate,
  selectType,
  selectCountry,
  selectAddress,
  selectAdditionalNote,
  selectSetRequired,
  selectOrderNote,
  selectUserRole,
  (
    ownerEmail,
    ownerName,
    fullfillmentDate,
    addressedTo,
    regionCountry,
    shipTo,
    additionalNote,
    required,
    notes,
    ownerRoles
  ) => {
    return {
      ownerEmail,
      ownerName,
      ownerSurname: '',
      addressedTo,
      additionalNote,
      required,
      shipTo,
      regionCountry,
      fullfillmentDate,
      notes,
      ownerRoles
    }
  }
)
