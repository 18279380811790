import { FC } from 'react'
import { ContentWrapper, PageContentContainer } from './styled'
import { IPageContentProps } from './types'

const PageContent: FC<IPageContentProps> = ({
  children,
  backgroundImage,
  footerHeight = 0,
  headerHeight = 0,
  justifyContent = 'center',
  contentCentered = false
}) => {
  return (
    <PageContentContainer
      backgroundImage={backgroundImage}
      footerHeight={footerHeight}
      headerHeight={headerHeight}
      justifyContent={justifyContent}
    >
      <ContentWrapper centered={contentCentered}>{children}</ContentWrapper>
    </PageContentContainer>
  )
}

export default PageContent
