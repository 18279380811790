import { get } from 'lodash'
import { ProjectSpecCharacteristic } from '../../features/masterSpec/model'

export const filterCharacteristics = (
  charArray: ProjectSpecCharacteristic[],
  specificationSectionId: string
) => {
  return charArray
    ?.filter(
      (characteristic: any) =>
        characteristic.specificationSectionId === specificationSectionId
    )
    .map((characteristic: any) => {
      return {
        ...characteristic,
        sapCodesToShow: get(characteristic, 'sapCodes', []).map(
          (sapCode: any) => sapCode.code
        )
      }
    })
}
