import { OTHER_PACKAGES } from '../../../features/packaging/model'
import { TABLE_DATA_TYPE } from '../../NewTable/constants'
import { ITableConfig } from '../../NewTable/types'

export const RELATION_TABLE_CONFIG: ITableConfig = {
  id: {
    label: 'ID',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'id',
    hide: true
  },
  packagingCode: {
    label: 'PK Intercos Code',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'packagingCode'
  },
  supplierRef: {
    label: 'Supplier Pkg Code',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'supplierRef'
  },
  testedSupplier: {
    label: 'Packaging Supplier',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'testedSupplier',
    dataIndex: 'testedSupplier.name'
  }
}

export const RELATION_ALTERNATIVE_TABLE_CONFIG: ITableConfig = {
  id: {
    label: 'ID',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'id',
    hide: true
  },
  packagingCode: {
    label: 'PK Intercos Code',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'packagingCode'
  },
  supplierRef: {
    label: 'Supplier Pkg Code',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'supplierRef'
  },
  testedSupplier: {
    label: 'Packaging Supplier',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'testedSupplier',
    dataIndex: 'testedSupplier.name'
  },
  relatedPackages: {
    dataPath: 'relatedPackages',
    hide: true
  }
}

export const RELATIONSHIPS_TABLE_CONFIG = {
  [OTHER_PACKAGES.FURTHER]: RELATION_TABLE_CONFIG,
  [OTHER_PACKAGES.RELATED]: RELATION_TABLE_CONFIG,
  [OTHER_PACKAGES.ALTERNATIVE]: RELATION_ALTERNATIVE_TABLE_CONFIG
}
