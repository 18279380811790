import { FC } from 'react'
import { StatusTagContainer } from './styled'
import { IStatusTagProps } from './types'

const StatusTag: FC<IStatusTagProps> = ({
  tagColor = 'green',
  preLabel = 'Status:',
  label
}) => {
  return (
    <StatusTagContainer tagColor={tagColor}>
      {preLabel} {label}
    </StatusTagContainer>
  )
}

export default StatusTag
