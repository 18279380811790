import { uniqueId } from 'lodash'
import { SapCodeConcerned } from '../../features/packaging/model'

export const randomizeSapCodeConcerned = (scc: SapCodeConcerned) => {
  const result = {
    ...scc,
    id: uniqueId()
  }
  return result
}

export const customToFixed = (number: string, allowedDecimalsLimit: number) => {
  const regex = new RegExp(`\\.\\d{${allowedDecimalsLimit + 1},}`)
  if (regex.test(number)) return parseFloat(number).toFixed(allowedDecimalsLimit)
  else return number
}
