import { get } from 'lodash'
import { createSelector } from 'reselect'
import { Formula } from '../formulaSchema/model'
import type { RootState } from '../store'
import { selectPermissions } from '../users/selectors'
import { IProductDropdown } from './model'

const selectProduct = (state: RootState) => state.product

export const getProduct = createSelector(selectProduct, (product) => product)

export const selectProdIsLoading = createSelector(
  selectProduct,
  (product) => product.isLoading
)
export const selectProdError = createSelector(selectProduct, (product) => product.error)

export const getProductCreate = createSelector(selectProduct, (product) => product.item)

export const hasPackage = createSelector(selectProduct, (product) => {
  return !!product.packaging
})

export const getIsPackageVariantSelected = createSelector(selectProduct, (product) => {
  return !product.item.sides.some(({ packageVariantId }) => !packageVariantId)
})

export const getIsSidesFormulaSelected = createSelector(selectProduct, (product) => {
  return !product.item.sides.some(({ fSchema = {} }) => !fSchema.formula?.length)
})

export const selectSelectedPackage = createSelector(selectProduct, (product) => {
  return product.item.packaging as Record<string, any>
})

export const hasTooling = createSelector(selectProduct, (product) => {
  return !!product.tooling
})

export const selectSelectedTooling = createSelector(selectProduct, (product) => {
  return product.tooling
})

export const hasSchema = createSelector(selectProduct, (product) => {
  return !!product.schema
})

export const selectProdFSchema = createSelector(selectProduct, (product) => {
  return product.schema
})

export const selectProdFormulaCodes = createSelector(selectProduct, (product) => {
  return product.formulaCodesDEVEX
})

export const selectCreationForRequest = createSelector(getProduct, (product) => {
  return product.temp
})

export const selectPackagingAttachments = createSelector(getProduct, (product) =>
  product.packaging
    // eslint-disable-next-line array-callback-return
    ?.map((pk) => {
      if (pk.attachments) {
        return pk?.attachments.filter((att) =>
          ['Technical Drawing', 'AreaDeco'].includes(att.attachmentContentType.name)
        )
      }
    })
    .flat()
)
export const selectProductCategories = createSelector(getProduct, (product) =>
  get(product, 'dropDown.productCategory')
)
export const selectApplicationArea = createSelector(getProduct, (product) =>
  get(product, 'dropDown.applicationArea')
)

export const getCreationIndustrialCodeInformationsTable = createSelector(
  getProduct,
  (product) => {
    const formulas: Formula[] = get(product, 'schema.0.formula', [])
    const formulasSelected = get(product, 'item.formulaCodesDEVEX', [])

    return formulas.filter(({ formulaCodeDEVEX }) =>
      formulasSelected.includes(formulaCodeDEVEX)
    )
  }
)

export const selectSidesData = createSelector(
  selectProduct,
  (product) => product.item.sides
)

export const isAdditionalDataFormValid = createSelector(
  getProductCreate,
  (productItem) => {
    const isSidesValid = !productItem.sides.some((sideData) => {
      return (
        !get(sideData, 'applicationArea', '') ||
        !get(sideData, 'productCategory', '') ||
        !get(sideData, 'productForm', '') ||
        !get(sideData, 'formulaTransferTo', '')
      )
    })
    return (
      !!get(productItem, 'name', '') &&
      !!get(productItem, 'fillingAvailableIn', '') &&
      !!get(productItem, 'businessSegment', '') &&
      // !!get(productItem, 'status', '') &&
      !!get(productItem, 'businessUnit', '') &&
      isSidesValid
    )
  }
)

export const getDropDownsByKey = (key: keyof IProductDropdown) => {
  return createSelector(getProduct, (product) =>
    product.dropDown[key] ? product.dropDown[key] : []
  )
}

export const selectSuccessInfo = createSelector(
  selectProduct,
  (product) => product.successInfo
)

export const getProdFilteredStatusByCRUD = (type: 'onCreate' | 'onEdit' | 'onRead') =>
  createSelector(
    getDropDownsByKey('status'),
    selectPermissions,
    (prodStatus = [], permissions) => {
      const statusPermissions = permissions.vaultProduct?.canSeeValue.productStatus || []
      const allowedStatus = statusPermissions
        .filter((value) => value[type])
        .map(({ value }) => value)
      const filteredStatus = prodStatus.filter(({ value }) =>
        allowedStatus.includes(value)
      )
      return filteredStatus
    }
  )
