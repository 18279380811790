import styled from 'styled-components'

export const TagsContainer = styled.div<{ isEditing: boolean }>`
  width: 50%;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  > div {
    margin-bottom: 10px;
  }
  .ant-tag {
    ${({ isEditing }) => !isEditing && { borderColor: 'grey' }}
  }
`
