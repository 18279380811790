import { TablePaginationConfig } from 'antd'
import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { footerHeight, headerHeight } from '../../assets/mixins'
import { Button, SectionTitle } from '../../components'
import { Divider } from '../../components/Divider'
import { ITActionColumnConfig } from '../../components/NewTable/types'
import { SUPPLIER_MANAGE_TABLE_CONFIG } from '../../components/Packaging/AttachmentsTable/constants'
import { PageContent } from '../../containers'
import ManagementTable from '../../containers/Administration/ManagementTable'
import { administrationActions } from '../../features/administration'
import {
  selectAdminError,
  selectSupplierList,
  selectSupplierPaginationsNew
} from '../../features/administration/selectors'
import { GenericLayout } from '../../layouts'
import { AppRoutes } from '../constants'
import { FooterActions, footerActionHeight } from '../CreationPackage/styled'

const SupplierResult: FC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const adminError = useSelector(selectAdminError)
  const supplierList = useSelector(selectSupplierList)
  const pagination = useSelector(selectSupplierPaginationsNew)

  const changePageHandler = (pageData: TablePaginationConfig) => {
    const { current } = pageData
    dispatch(
      administrationActions.updateSupplierPagination({
        key: 'page',
        value: current || 1
      })
    )
    dispatch(administrationActions.searchSuppliers())
  }

  const backOnFiltersForm = () => {
    dispatch(administrationActions.clearSupplierPagination())
    navigate(`${AppRoutes.VAULT_ADMINISTRATION}/supplier`)
  }

  const actionConfig: ITActionColumnConfig = {
    columnTitle: 'Actions',
    actionRenderer: ({ data }) => {
      return (
        <Button
          label="Edit"
          variant="link"
          onClick={() =>
            navigate(`${AppRoutes.VAULT_ADMINISTRATION}/supplier/${data.id}`)
          }
        />
      )
    }
  }

  useEffect(() => {
    dispatch(administrationActions.searchSuppliers())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <GenericLayout error={adminError}>
      <PageContent
        headerHeight={headerHeight}
        footerHeight={footerHeight + footerActionHeight}
      >
        <SectionTitle value="Administration" isBold />
        <Divider disableBorder />
        <SectionTitle value="Supplier Management" isBold />
        <ManagementTable
          dataList={supplierList || []}
          enablePagination
          actionConfig={actionConfig}
          pageData={pagination}
          tableConfig={SUPPLIER_MANAGE_TABLE_CONFIG}
          onChangePage={changePageHandler}
        />
        <FooterActions>
          <Button label="Back" onClick={backOnFiltersForm} />
        </FooterActions>
      </PageContent>
    </GenericLayout>
  )
}

export default SupplierResult
