import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Table } from '../../../components'
import { Divider } from '../../../components/Divider'
import { TOP_RIGHT } from '../../../components/NewTable/constants'
import { Title } from '../../../components/Title'
import { Tooltip } from '../../../components/Tooltip'
import { administrationActions } from '../../../features/administration'
import { selectCurrencyRates } from '../../../features/administration/selectors'
import { columnsGeneratorHelper } from '../../../utils'
import { getPricingTableColumns } from './utils'

const PricingTable: FC = () => {
  const dispatch = useDispatch()

  // useSelector variables
  const currencyRates = useSelector(selectCurrencyRates)

  const mapTableData = (currencyRates: Record<string, any>[]) => {
    if (currencyRates)
      return currencyRates.reduce((acc: any, curr: any) => {
        const id = curr.id
        acc[id] = curr.USDExchangeRate
        return acc
      }, {})
  }

  // useState variables
  const [editingId, setEditingId] = useState('')
  const [mappedExchangeValues, setMappedExchangeValues] = useState(
    mapTableData(currencyRates || [])
  )

  const columns = columnsGeneratorHelper(
    getPricingTableColumns(mappedExchangeValues, setMappedExchangeValues, editingId)
  )

  useEffect(() => {
    dispatch(administrationActions.getCurrencyRates())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const actionConfig = {
    columnTitle: '',
    actionRenderer: ({ data }: any) => (
      <>
        {editingId !== data.id && (
          <Tooltip title={`Edit Exchange Rate for ${data.currency}`}>
            <Button
              onClick={() => {
                editHandler(data.id)
              }}
              iconName="FormOutlined"
              variant="link"
              disabled={!!editingId || data.currency === 'USD'}
            />
          </Tooltip>
        )}
        {editingId === data.id && (
          <>
            <Button
              onClick={cancelHandler}
              iconName="CloseCircleOutlined"
              variant="link"
            />
            <Button onClick={saveHandler} iconName="SaveOutlined" variant="link" />
          </>
        )}
      </>
    )
  }

  const editHandler = (id: string) => {
    setEditingId(id)
  }
  const cancelHandler = () => {
    setEditingId('')
    setMappedExchangeValues(mapTableData(currencyRates || []))
  }
  const saveHandler = () => {
    setEditingId('')
    dispatch(
      administrationActions.setCurrencyRate({
        id: editingId,
        data: mappedExchangeValues[editingId]
      })
    )
  }

  return (
    <>
      <Title title={'Pricing'} variantTitle={'h3'} enableBold />
      <Divider disableBorder />
      <Table
        enablePagination={false}
        paginationPosition={TOP_RIGHT}
        rowKey="id"
        columns={columns}
        items={currencyRates || []}
        actionColumnConfig={actionConfig}
      />
    </>
  )
}

export default PricingTable
