import styled from 'styled-components'

export const StatusTagContainer = styled.div<{ tagColor: string }>`
  width: 280px;
  height: 50px;
  display: flex;
  align-items: center;
  border: ${({ tagColor }) => `1px solid ${tagColor}`};
  border-radius: 4px;
  border-left-width: 5px;
  padding: 18px;
  margin: 16px 0px;
`
