import { apiClient } from '../../api/client'
import {
  ICheckProductPayload,
  IExtractPayload,
  ProductDto,
  ProductResultSearch
} from './model'

export const getProducts = () => {
  return apiClient.get<ProductDto[]>('product')
}

export const searchProducts = (filters: Record<string, any>) => {
  return apiClient.post<ProductResultSearch>('v2/product/search', filters)
}

export const getProductById = (id: string) => {
  return apiClient.get<ProductDto>(`product/${id}`)
}

export const patchProductById = (id: string, data: any) => {
  return apiClient.patch<ProductDto>(`v2/product/${id}`, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export const deleteProduct = (id: string) => {
  return apiClient.delete<ProductDto>(`product/${id}`)
}

export const getProductsExcel = (filters: Record<string, any>) => {
  return apiClient.post<any>('product/search/excel', filters, { responseType: 'blob' })
}

export const extractProductExcel = (data: IExtractPayload) => {
  return apiClient.post<any>('v2/product/search/excel', data, { responseType: 'blob' })
}

export const checkProductValidation = (productData: ICheckProductPayload) => {
  return apiClient.post<any>('product/exists', productData)
}
