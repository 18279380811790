import { InfoCircleOutlined } from '@ant-design/icons'
import { Form, Input as InputAnt, InputRef } from 'antd'
import React, { ReactNode, forwardRef, memo, useEffect } from 'react'
import { inputMinWidth } from '../../../assets/mixins'

type Props = {
  required?: boolean
  disabled?: boolean
  value?: string
  label: string
  error?: boolean
  placeholder?: string
  minLength?: number
  maxLength?: number
  onBlur?: () => void
  onChange?: (value: string, title: string | undefined) => void
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
  onAction?: (value?: string) => void
  enableTooltip?: boolean
  message?: string | ReactNode
  reduxKey?: string
  typeOfSelect?: string
  destroyByChange?: string
  enableValidation?: boolean
}

export const Input = memo(
  forwardRef<InputRef, Props>(
    (
      {
        onChange,
        value,
        label,
        placeholder,
        minLength = 0,
        maxLength = 250,
        required = false,
        error = false,
        disabled,
        onBlur,
        enableTooltip = false,
        message = '',
        reduxKey,
        onAction,
        typeOfSelect = 'text',
        onKeyDown,
        destroyByChange = ''
      }: Props,
      ref
    ) => {
      useEffect(() => {
        onAction?.(value)
      }, [value, onAction])

      const formattedValue =
        typeOfSelect === 'date' && value ? value.split('T')[0] : value
      return (
        <Form.Item
          label={label}
          required={required}
          validateStatus={error ? 'error' : undefined}
          tooltip={enableTooltip && { title: message, icon: <InfoCircleOutlined /> }}
        >
          <InputAnt
            ref={ref}
            style={{ minWidth: inputMinWidth }}
            disabled={disabled}
            value={formattedValue}
            onBlur={onBlur}
            onChange={(e) => onChange && onChange(e.target.value, reduxKey)}
            onKeyDown={(e) => onKeyDown && onKeyDown(e)}
            placeholder={placeholder}
            maxLength={Number(maxLength)}
            minLength={Number(minLength)}
            type={typeOfSelect}
            data-cy={'input-' + label}
          />
        </Form.Item>
      )
    }
  )
)
