import styled from 'styled-components'

export const ModalBody = styled.div`
  padding: 20px;
`

export const TitleWrapper = styled.div`
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const AttachmentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 15px;
`

export const MessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const AttachmentCardWrapper = styled.div`
  width: 100%;
  margin: 15px 0px;
`
