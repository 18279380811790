import { Form, Switch as SwitchAnt } from 'antd'

type Props = {
  onChange?: (value: boolean, reduxKey: string | undefined) => void
  checkedChildren?: string
  unCheckedChildren?: string
  value: boolean
  defaultValue?: boolean
  required?: boolean
  label?: string
  error?: boolean
  disabled?: boolean
  reduxKey?: string
}
export const Switch = ({
  onChange,
  checkedChildren,
  unCheckedChildren,
  value,
  required,
  disabled,
  label,
  error,
  reduxKey = ''
}: Props) => {
  const onChangeHandler = (e: boolean, reduxKey: string) => {
    if (onChange) {
      onChange(e, reduxKey)
    }
  }

  return (
    <Form.Item
      required={required}
      label={label}
      validateStatus={error ? 'error' : undefined}
    >
      <SwitchAnt
        disabled={disabled}
        checked={!!value}
        checkedChildren={checkedChildren}
        unCheckedChildren={unCheckedChildren}
        onChange={(e) => onChangeHandler(e, reduxKey)}
      />
    </Form.Item>
  )
}
