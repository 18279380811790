import { CSSProperties } from 'react'
import styled from 'styled-components'

type Props = {
  justifyContent?: CSSProperties['justifyContent']
  alignItems?: CSSProperties['alignItems']
}
export const StyledModalContent = styled.div<Props>`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  justify-content: ${({ justifyContent }) => (justifyContent ? justifyContent : 'start')};
  align-items: ${({ alignItems }) => (alignItems ? alignItems : 'start')};
`

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  > button {
    margin: 0 8px;
  }
`
