import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { footerHeight, headerHeight } from '../../assets/mixins'
import { Button, SectionTitle } from '../../components'
import { Breadcrumb } from '../../components/Breadcrumb'
import { Divider } from '../../components/Divider'
import { Loader } from '../../components/Loader'
import { PageContent } from '../../containers'
import ProductDetailContent from '../../containers/ProductDetailContent'
import ChildrenTable from '../../containers/StoreProduct/ChildrenTable'
import { cartActions } from '../../features/cart'
import { storeActions } from '../../features/storeProducts'
import { IStoreProd, IStoreProdChild } from '../../features/storeProducts/model'
import {
  getDetail,
  selectLoader,
  selectNewStoreProd,
  selectStoreProdError
} from '../../features/storeProducts/selectors'
import { selectPermissions } from '../../features/users/selectors'
import { GenericLayout } from '../../layouts'
import { entityFieldVisibileByType } from '../../utils/entityHelper'
import { AppRoutes } from '../constants'
import AddCartModal from './AddCartModal'
import { ButtonsWrapper } from './styled'

const StoreProductsDetail: FC = () => {
  const dispatch = useDispatch()
  const isStoreLoading = useSelector(selectLoader)
  const storeProdError = useSelector(selectStoreProdError)
  const product = useSelector(getDetail)
  const newProd = useSelector(selectNewStoreProd)

  const [isOnEdit, setIsOnEdit] = useState(false)

  const { id } = useParams()
  const permissions = useSelector(selectPermissions)

  const canOrder = permissions.storeOrders?.canUpdate
  const canEdit = permissions.storeProduct?.canUpdate

  useEffect(() => {
    if (id) {
      dispatch(storeActions.getDetail({ id }))
    }
    dispatch(cartActions.getCart())
    return () => {
      dispatch(cartActions.clearError())
      dispatch(storeActions.clearError())
      dispatch(cartActions.clearSuccess())
      dispatch(storeActions.resetNew())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const openAddCartHadnler = () => {
    dispatch(storeActions.checkProductAvailabilty())
  }

  const editModeHandler = () => {
    setIsOnEdit(true)
    dispatch(storeActions.setNewTemp())
  }

  const cancelHandler = () => {
    setIsOnEdit(false)
    dispatch(storeActions.resetNew())
  }

  const data = isOnEdit ? newProd : product
  const finalName = data?.finalName || ''

  const addChildHandler = (newChild: IStoreProdChild) => {
    if (id) {
      dispatch(storeActions.addStoreProdChild({ parentId: id, data: newChild }))
    }
  }

  const deleteChildHandler = (childId: string) => {
    dispatch(storeActions.deleteStoreProdChild({ id: childId }))
  }

  const deleteProdHandler = () => {
    if (id) {
      dispatch(storeActions.deleteStoreProd({ id }))
    }
  }

  const updateProdHandler = () => {
    setIsOnEdit(false)
    dispatch(storeActions.updateStoreProd())
  }

  const updateProdByKey = (key: keyof IStoreProd, value: any) => {
    dispatch(storeActions.updateNewEntity({ key, value }))
  }

  const children =
    data && data.children ? data.children.map((data) => ({ ...data, parentId: id })) : []

  return (
    <GenericLayout error={storeProdError}>
      <PageContent
        footerHeight={footerHeight}
        headerHeight={headerHeight}
        justifyContent="flex-start"
      >
        {isStoreLoading ? (
          <Loader />
        ) : (
          <>
            <Breadcrumb
              items={[
                { name: 'Products', href: AppRoutes.STORE_PRODUCTS },
                { name: `Product Detail ${finalName || '-'}` }
              ]}
            />

            <Divider disableBorder />
            <SectionTitle
              value={finalName}
              isBold
              addon={
                <ButtonsWrapper>
                  {!isOnEdit ? (
                    <>
                      {canEdit && (
                        <Button label="Edit" variant="ghost" onClick={editModeHandler} />
                      )}
                      {canOrder && (
                        <AddCartModal
                          onOpen={openAddCartHadnler}
                          productCode={product?.productCode}
                        />
                      )}
                    </>
                  ) : (
                    <>
                      <Button label="Cancel" variant="text" onClick={cancelHandler} />
                      <Button
                        label="Delete"
                        variant="ghost"
                        onClick={deleteProdHandler}
                      />
                      <Button label="Save" onClick={updateProdHandler} />
                    </>
                  )}
                </ButtonsWrapper>
              }
            />
            <Divider disableBorder />

            <ProductDetailContent
              data={data || {}}
              isEditing={isOnEdit}
              onUpdate={updateProdByKey}
            />
            {entityFieldVisibileByType({ type: data?.type, key: 'children' }) && data && (
              <>
                <Divider disableBorder />
                <ChildrenTable
                  list={children}
                  onAdd={addChildHandler}
                  parentType={data.type}
                  onDelete={deleteChildHandler}
                  hasDelete={!isOnEdit && canEdit && (data?.children || []).length > 1}
                  hasAdd={!isOnEdit && canEdit}
                />
              </>
            )}
          </>
        )}
      </PageContent>
    </GenericLayout>
  )
}

export default StoreProductsDetail
