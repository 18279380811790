import { get } from 'lodash'
import { IMappedDropdowns, MAPPED_DROPDOWN } from '../features/product/constants'
import { IDropdown } from '../features/types'

export interface ITaxonomies {
  [key: string]: string[]
}

export interface IDropDowns {
  [key: string]: IDropdown[]
}

export const refactorTaxonomies: (t: ITaxonomies) => IDropDowns = (taxonomies) => {
  return Object.entries(taxonomies).reduce((acc, [k, v]) => {
    return {
      ...acc,
      [k]: MAPPED_DROPDOWN[k as keyof IMappedDropdowns]
        ? MAPPED_DROPDOWN[k as keyof IMappedDropdowns].filter(({ value }) =>
            v.some((val) => val.toLowerCase() === value.toLowerCase())
          )
        : v.map((el: string) => ({ name: el.toLocaleUpperCase(), value: el }))
    }
  }, {})
}

export const getDynamicPackDropdownByKey: (arg: {
  key: string
  category: string
  subcategory: string
  taxonomies: Record<string, any>[]
}) => IDropdown[] = ({ key, category, subcategory, taxonomies }) => {
  const filteredCategory = taxonomies.find(
    ({ value: catValue }: any) => catValue.toLowerCase() === category.toLowerCase()
  )

  const filteredSubcategory = get(filteredCategory, 'children.subcategory', []).find(
    ({ value: subValue }: any) => subValue.toLowerCase() === subcategory.toLowerCase()
  )

  const catOrSubcat = filteredSubcategory || filteredCategory
  const dropDownList = get(catOrSubcat, `children.${key}`, [])

  if (!dropDownList.length) return []
  return dropDownList.map(({ value }: { value: string }) => ({
    name: value.toUpperCase(),
    value
  }))
}

export const transformNullStringToNull = (updateObj: any) => {
  const newObj: any = {}
  for (let key in updateObj) {
    newObj[key] = updateObj[key] === 'Null' ? null : updateObj[key]
  }
  return newObj
}

export const viewNullAsNullString = (data: any, field: string) => {
  return data?.[field] ? data?.[field] : 'Null'
}
