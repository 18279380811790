import { AnyAction, PayloadAction } from '@reduxjs/toolkit'
import { get } from 'lodash'
import moment from 'moment'
import { call, put, select, takeLatest, all } from 'redux-saga/effects'
import { DATE_FORMAT } from '../../components/Form/RangeDatePicker/contants'
import { orderDataExtractor } from '../../utils/orderHelpers'
import { actions as appActions } from '../app/reducer'
import { getStoreProductById } from '../storeProducts/api'
import {
  getOrder,
  getLatestOrder,
  searchOrders,
  rejectOrder,
  acceptOrder,
  proposeOrder,
  processOrder
} from './api'
import {
  APPLY_FILTERS_AND_SEARCH,
  CHANGE_PAGE_AND_SEARCH,
  CHANGE_TAB_AND_SEARCH,
  CHECK_ITEM_AVAILABILITY_FOR_PROPOSE,
  CONFIRM_ACCEPT_ORDER,
  CONFIRM_PROCESS_ORDER,
  CONFIRM_PROPOSE_ORDER,
  CONFIRM_REJECT_ORDER,
  FETCH_LATEST_ORDER,
  FETCH_ORDER_DETAILS,
  ORDER_LIST_TABS,
  REMOVE_FILTER_AND_SEARCH,
  SEARCH_ORDER_LIST
} from './constants'
import {
  IOrderData,
  IOrderItem,
  IOrdersResultSearch,
  IPagination,
  OrderStatus
} from './models'
import { orderListActions } from './reducer'
import {
  selectOrderListFilters,
  selectOrderListPagination,
  selectOrderSelected,
  selectSelectedTab
} from './selectors'

function* searchOrderList(payload?: AnyAction) {
  const loader = get(payload, 'loader', true)
  try {
    if (loader) {
      yield put(orderListActions.setIsTableLoading(true))
    }
    const selectedTab: string = yield select(selectSelectedTab)
    const filters: Record<string, any> = yield select(selectOrderListFilters)
    const { page: selectedPage, pageSize: selectedPageSize }: IPagination = yield select(
      selectOrderListPagination
    )

    const createdDateFrom = moment(
      get(filters, 'createdDate.createdDateFrom', ''),
      DATE_FORMAT
    )

    const createdDateTo = moment(
      get(filters, 'createdDate.createdDateTo', ''),
      DATE_FORMAT
    ).add(1, 'day')

    const fullfillmentDateFrom = moment(
      get(filters, 'fullfillmentDate.fullfillmentDateFrom', ''),
      DATE_FORMAT
    )
    const fullfillmentDateTo = moment(
      get(filters, 'fullfillmentDate.fullfillmentDateTo', ''),
      DATE_FORMAT
    ).add(1, 'day')

    const searchResult: IOrdersResultSearch = yield call(searchOrders, {
      status: selectedTab,
      notes: get(filters, 'notes', ''),
      ownerEmail: get(filters, 'owner', ''),
      addressedTo: get(filters, 'addressedTo', ''),
      createdDateFrom: createdDateFrom,
      createdDateTo: createdDateTo,
      fullfillmentDateFrom: fullfillmentDateFrom,
      fullfillmentDateTo: fullfillmentDateTo,
      pageSize: selectedPageSize,
      page: selectedPage
    })

    const { results, totalPages, page, pageSize, total } = searchResult

    yield put(orderListActions.setPagination({ totalPages, page, pageSize, total }))

    yield put(orderListActions.setData({ data: results }))
  } catch (err: any) {
    yield put(appActions.setError(err))
  } finally {
    if (loader) {
      yield put(orderListActions.setIsTableLoading(false))
    }
  }
}

function* changeTabAndSearch({ payload }: AnyAction) {
  try {
    const { activeStatus } = payload
    yield put(orderListActions.resetData())
    yield put(orderListActions.resetFilters())
    yield put(orderListActions.resetPagination())
    const selectedTab = ORDER_LIST_TABS[+activeStatus]
    yield put(orderListActions.setSelectedTab(selectedTab.value))
    yield searchOrderList()
  } catch (err: any) {
    yield put(appActions.setError(err))
  }
}
function* applyFilterAndSearch() {
  try {
    yield put(orderListActions.applyTempFilter())
    yield put(orderListActions.resetTempFilters())
    yield searchOrderList()
  } catch (err: any) {
    yield put(appActions.setError(err))
  }
}

function* removeFilterAndSearch({ payload }: AnyAction) {
  try {
    if (payload.path.includes('createdDate')) {
      payload.path = 'createdDate'
    }
    if (payload.path.includes('fullfillmentDate')) {
      payload.path = 'fullfillmentDate'
    }
    yield put(orderListActions.removeFilter(payload.path))
    yield searchOrderList()
  } catch (err: any) {
    yield put(appActions.setError(err))
  }
}

function* changePageAndSearch({ payload }: AnyAction) {
  try {
    const { page }: IPagination = payload
    yield put(orderListActions.setPagination({ page }))
    yield searchOrderList()
  } catch (err: any) {
    yield put(appActions.setError(err))
  }
}

function* fetchOrderDetails({ payload }: AnyAction) {
  yield put(orderListActions.setLoader(true))
  try {
    const { id } = payload
    const order: IOrderData = yield call(getOrder, id)
    yield put(orderListActions.setOrder({ data: order }))
  } catch (err: any) {
    yield put(appActions.setError(err))
  } finally {
    yield put(orderListActions.setLoader(false))
  }
}

function* fetchLatestOrder() {
  try {
    const lastForwarded: IOrderData = yield call(getLatestOrder, OrderStatus.FORWARDED)
    const lastRefused: IOrderData = yield call(getLatestOrder, OrderStatus.REFUSED)
    const lastProcessed: IOrderData = yield call(getLatestOrder, OrderStatus.PROCESSED)

    yield put(orderListActions.setLatestForwarded({ data: lastForwarded }))
    yield put(orderListActions.setLatestRefused({ data: lastRefused }))
    yield put(orderListActions.setLatestProcessed({ data: lastProcessed }))
  } catch (err: any) {
    yield put(appActions.setError(err))
  }
}

function* rejectOrderSaga({ payload }: AnyAction) {
  yield put(orderListActions.setLoader(true))
  try {
    const { id, reasonData } = payload
    yield call(rejectOrder, id, reasonData)
    yield put(
      orderListActions.setSuccess({
        title: 'You have refused the order',
        message:
          'Refusal order has been sent.\nYou can continue to view the order in the list of refused orders'
      })
    )
  } catch (err: any) {
    yield put(orderListActions.setError(err))
  } finally {
    yield put(orderListActions.setLoader(false))
  }
}

function* acceptOrderSaga({
  payload
}: AnyAction | PayloadAction<{ id: string; acceptNote?: string }>) {
  yield put(orderListActions.setLoader(true))
  try {
    const { id, acceptNote } = payload
    const resp: Record<string, any> = yield call(acceptOrder, id, acceptNote)
    const { status } = resp
    if (status === OrderStatus.ACCEPTED.toString()) {
      yield put(
        orderListActions.setSuccess({
          title: 'You have accepted the order',
          message:
            'You can continue to view the order in the list of accepted orders from which you can transform the order into a fulfilled one'
        })
      )
    } else {
      throw Error('Something went wrong')
    }
  } catch (err: any) {
    yield put(orderListActions.setError(err))
  } finally {
    yield put(orderListActions.setLoader(false))
  }
}

function* proposeOrderSaga({ payload }: AnyAction) {
  yield put(orderListActions.setLoader(true))
  try {
    const { orderData } = payload
    const { id } = orderData
    yield call(proposeOrder, id, orderData)
    yield put(
      orderListActions.setSuccess({
        title: 'Your order has been forwarded',
        message: 'You can continue to view the order in the list of forwarded orders'
      })
    )
  } catch (err: any) {
    yield put(appActions.setError(err))
  } finally {
    yield put(orderListActions.setLoader(false))
  }
}

function* processOrderSaga({
  payload
}: AnyAction | PayloadAction<{ id: string; processNote?: string }>) {
  yield put(orderListActions.setLoader(true))
  try {
    const { id, processNote } = payload
    const resp: Record<string, any> = yield call(processOrder, id, processNote)
    const { status } = resp
    if (status === OrderStatus.PROCESSED.toString()) {
      yield put(
        orderListActions.setSuccess({
          title: 'You have processed the order',
          message:
            'You can continue to view the order in the list of processed orders from which you can transform the order into a fulfilled one'
        })
      )
    } else {
      throw Error('Something went wrong')
    }
  } catch (err: any) {
    yield put(orderListActions.setError(err))
  } finally {
    yield put(orderListActions.setLoader(false))
  }
}

function* checkAvailabilitySaga() {
  yield put(appActions.setLoading(true))
  try {
    const orderData: IOrderData = yield select(selectOrderSelected)
    const { orderItems } = orderDataExtractor(orderData)
    const products: IOrderItem[] = yield all(
      orderItems.map(({ orderableItemId }: any) =>
        call(getStoreProductById, orderableItemId)
      )
    )
    const newOrderitems = orderItems.map((data) => ({
      ...data,
      item: products.find((prod) => prod.id === (data.item?.id || ''))
    }))
    yield put(
      orderListActions.setTempOrder({ data: { ...orderData, orderItems: newOrderitems } })
    )
  } catch (err: any) {
    yield put(appActions.setError(err))
  } finally {
    yield put(appActions.setLoading(false))
  }
}

export default function* orderListSaga() {
  yield takeLatest(SEARCH_ORDER_LIST, searchOrderList)
  yield takeLatest(APPLY_FILTERS_AND_SEARCH, applyFilterAndSearch)
  yield takeLatest(REMOVE_FILTER_AND_SEARCH, removeFilterAndSearch)
  yield takeLatest(CHANGE_TAB_AND_SEARCH, changeTabAndSearch)
  yield takeLatest(CHANGE_PAGE_AND_SEARCH, changePageAndSearch)
  yield takeLatest(FETCH_ORDER_DETAILS, fetchOrderDetails)
  yield takeLatest(FETCH_LATEST_ORDER, fetchLatestOrder)
  yield takeLatest(CONFIRM_REJECT_ORDER, rejectOrderSaga)
  yield takeLatest(CONFIRM_ACCEPT_ORDER, acceptOrderSaga)
  yield takeLatest(CONFIRM_PROPOSE_ORDER, proposeOrderSaga)
  yield takeLatest(CONFIRM_PROCESS_ORDER, processOrderSaga)
  yield takeLatest(CHECK_ITEM_AVAILABILITY_FOR_PROPOSE, checkAvailabilitySaga)
}
