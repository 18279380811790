import styled from 'styled-components'

export const TagsWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: auto;
`

const buttonWrapperHeight = 44
export const ButtonWrapper = styled.div`
  height: ${buttonWrapperHeight}px;
  width: 100%;
  padding: 5px 20px;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`
export const FlexGap = styled.div`
  display: flex;
  gap: 10px;
  overflow: auto;
`

export const FilterBtnWrapper = styled.div`
  width: 65px;
`
