import { get } from 'lodash'
import { FC } from 'react'
import { footerHeight, headerHeight } from '../../assets/mixins'
import { Button } from '../../components'
import { Advice } from '../../components/Advice'
import PageContent from '../PageContent'
import { ActionsContainer } from './styled'
import { IErrorProps } from './types'

const Error: FC<IErrorProps> = ({ customBack, error }) => {
  const refreshHandler = () => {
    window.location.reload()
  }

  const backAction = customBack ? customBack : refreshHandler
  const status = get(error, 'status', '500')
  const message = get(error, 'message', 'Something went wrong')
  const subTitle = get(error, 'subTitle', '')
  const title = get(error, 'title', 'Error')

  return (
    <PageContent footerHeight={footerHeight} headerHeight={headerHeight}>
      <Advice status={status} subTitle={subTitle} title={title}>
        {message}
      </Advice>
      <ActionsContainer>
        <Button label="Back" onClick={backAction} />
      </ActionsContainer>
    </PageContent>
  )
}

export default Error
