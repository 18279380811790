import { ButtonType } from 'antd/lib/button'
import React, { ReactNode, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectModalKey } from '../../features/app/selectors'
import { useModalListener } from '../../utils/useModalListener'
import Button from '../NewButton'
import { Shape } from '../NewButton/types'
import { StyledBtnWrapper, StyledModal } from './styled'

const sizeMap = {
  small: 520,
  medium: 700,
  large: '70vw'
}

type Size = 'small' | 'medium' | 'large'

type Props = {
  modalKey: string
  title: string
  children?: ReactNode
  hideActions?: boolean
  size: Size
  btnName?: string
  btnType?: ButtonType
  btnShape?: Shape
  btnIcon?: string
  btnClicked?: () => void
  paddingBody?: string
  isSmall?: boolean
  okText?: string
  footer?: ReactNode[]
  centered?: boolean
  disabled?: boolean
  onAction?: (value?: string) => void
  onClose?: () => void
  iconPosition?: 'right' | 'left'
  onShowSaga?: string
  btnMargin?: string
}

export const Modal = ({
  modalKey,
  title,
  children,
  hideActions,
  size,
  btnName,
  btnType = 'primary',
  btnShape = 'round',
  btnClicked,
  onClose = () => {},
  paddingBody = '20px',
  isSmall = false,
  okText = 'Ok',
  btnIcon,
  footer,
  centered = false,
  disabled = false,
  iconPosition = 'left',
  onAction,
  onShowSaga,
  btnMargin = ''
}: Props) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const dispatch = useDispatch()
  const currentModalKey = useSelector(selectModalKey)
  useEffect(() => {
    if (!currentModalKey) setIsModalVisible(false)
    if (currentModalKey === modalKey) setIsModalVisible(true)
  }, [currentModalKey, modalKey])
  const showModal = () => {
    if (btnClicked) {
      btnClicked()
    }
    if (onShowSaga) {
      dispatch({ type: onShowSaga })
    }
    onAction?.()
    dispatch({ type: 'app/setModalKey', payload: modalKey })
    setIsModalVisible(true)
    dispatch({ type: 'app/setModalKey', payload: modalKey })
  }

  const handleOk = () => {
    setIsModalVisible(true)
  }

  const handleCancel = () => {
    onClose()
    setIsModalVisible(false)
    dispatch({ type: 'app/setModalKey', payload: undefined })
  }

  useModalListener(modalKey, handleCancel)
  return (
    <>
      <StyledBtnWrapper margin={btnMargin}>
        <Button
          label={btnName}
          onClick={showModal}
          variant={btnType}
          shape={btnShape}
          iconName={btnIcon}
          disabled={disabled}
          iconPosition={iconPosition}
        />
      </StyledBtnWrapper>

      <StyledModal
        width={sizeMap[size]}
        title={title}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose
        footer={hideActions ? null : !!footer?.length ? footer : null}
        isSmall={isSmall}
        okText={okText}
        centered={centered}
      >
        {React.Children.map(children, (item: any) =>
          React.cloneElement(item, { modalKey })
        )}
      </StyledModal>
    </>
  )
}
