import { isEmpty } from 'lodash'
import { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PackagingSearchForm } from '../../containers'
import ResultSection from '../../containers/Packaging/ResultSection'
import { packagingActions } from '../../features/packaging'
import {
  getPackagingTempFilters,
  selectPackagingError
} from '../../features/packaging/selectors'
import { checkSearchFormValidation } from '../../utils'
import { Advice } from '../Advice'
import { Modal } from '../Modal'
import Button from '../NewButton'
import { ModalBodyContainer } from './styled'
import { IAddPackagingModalProps } from './types'

const AddPackagingModal: FC<IAddPackagingModalProps> = ({
  onAdd = () => {},
  btnLabel
}) => {
  const dispatch = useDispatch()
  const tempFilter = useSelector(getPackagingTempFilters)

  const [isResult, setIsResult] = useState(false)
  const [selectedPackages, setSelectedPackages] = useState<any[]>([])

  const packagingError = useSelector(selectPackagingError)

  const addPackagesHandler = () => {
    dispatch({ type: 'app/closeModal', payload: { modalKey: 'product-add-packaging' } })
    onAdd(selectedPackages)
  }

  const resetModalHandler = () => {
    setIsResult(false)
    dispatch(packagingActions.clearFilters())
    dispatch(packagingActions.resetPagination())
    setSelectedPackages([])
    if (!isEmpty(packagingError)) {
      dispatch(packagingActions.clearError())
    }
  }

  const searchHandler = () => {
    setIsResult(!isResult)
    dispatch(packagingActions.applyFilters())
    dispatch(packagingActions.getPackages())
  }

  const checkIfDisabled = () => checkSearchFormValidation(tempFilter)

  const selectHandler = (rows: any[]) => {
    setSelectedPackages(rows)
  }

  const backHandler = () => {
    setIsResult(false)
    setSelectedPackages([])
    dispatch(packagingActions.resetPagination())
  }

  const footer = !isResult
    ? [
        <Button
          key="search"
          label="Search"
          onClick={searchHandler}
          disabled={checkIfDisabled()}
          enableKeyPress
        />
      ]
    : [
        <Button key="back" label="Back" onClick={backHandler} />,
        <Button
          key="add"
          label="Add"
          disabled={!selectedPackages.length}
          onClick={addPackagesHandler}
        />
      ]

  return (
    <Modal
      btnName={btnLabel}
      modalKey="product-add-packaging"
      title="Filter package"
      footer={footer}
      btnClicked={resetModalHandler}
      centered
      size="large"
    >
      <ModalBodyContainer>
        {!isEmpty(packagingError) ? (
          <Advice
            status={packagingError?.status || '500'}
            subTitle={packagingError?.subTitle}
            title={packagingError?.title}
          >
            {packagingError?.message}
          </Advice>
        ) : (
          <>
            {!isResult && <PackagingSearchForm />}
            {isResult && (
              <ResultSection
                enableSelect
                selectedRows={selectedPackages}
                onSelect={selectHandler}
                isSingleSelect
              />
            )}
          </>
        )}
      </ModalBodyContainer>
    </Modal>
  )
}

export default AddPackagingModal
