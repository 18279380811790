import { Form } from 'antd'
import { get } from 'lodash'
import { FC } from 'react'
import { useSelector } from 'react-redux'
import { threeOptions } from '../../../features/constants'
import { selectFormulaSchemaDropdown } from '../../../features/formulaSchema/selectors'
import { StyledBox } from '../../../style/styledBox'
import { InputWrapper } from '../../../style/styledInput'
import { Label, Value } from '../../../style/styledLabels'
import { concatenateIfArray } from '../../../utils/productHelper'
import { BoxWrapper } from '../../BoxWrapper'
import { InputTextArea } from '../../Form/TextArea'
import InfoCell from '../../InfoCell'
import { Text } from '../../Text'
import { IRegulatoryCheckProps } from './types'

const RegulatoryCheckInfo: FC<IRegulatoryCheckProps> = ({
  data,
  isMulti,
  isEditing,
  onUpdate = () => {},
  hasDisclaimer = false
}) => {
  const dropdownData = useSelector(selectFormulaSchemaDropdown)

  // Add NULL on selects
  let lorealBLComplianceDropdown = get(dropdownData, 'lorealBLCompliance', []).slice()
  lorealBLComplianceDropdown.push({ name: 'Null', value: null })
  let lvmhBLComplianceDropdown = get(dropdownData, 'lvmhBLCompliance', []).slice()
  lvmhBLComplianceDropdown.push({ name: 'Null', value: null })
  let chanelBLComplianceDropdown = get(dropdownData, 'chanelBLCompliance', []).slice()
  chanelBLComplianceDropdown.push({ name: 'Null', value: null })
  let cotyBLComplianceDropdown = get(dropdownData, 'cotyBLCompliance', []).slice()
  cotyBLComplianceDropdown.push({ name: 'Null', value: null })
  let kikoBLComplianceDropdown = get(dropdownData, 'kikoBLCompliance', []).slice()
  kikoBLComplianceDropdown.push({ name: 'Null', value: null })

  return (
    <Form layout="vertical">
      {hasDisclaimer && (
        <Text
          text="*For clients selling in Europe, packaging conformity must be verified case by case opening a ticket or brief to Packaging or Regulatory Teams"
          italic
        />
      )}
      <BoxWrapper columnCount={4} enablePadding>
        <InfoCell
          label={'EU Compliance'}
          value={get(data, 'EUCompliance', null)}
          dataType="options-value"
          inputType="options-button"
          inputConfig={{
            options: threeOptions,
            onChange: (value: Record<string, any>) => onUpdate('EUCompliance', value)
          }}
          tooltipConfig={{
            enable: true,
            message: 'EC Regulation 1223/2009 on cosmetics'
          }}
          isEditing={isEditing}
        />
        <InfoCell
          label={'US Compliance'}
          value={get(data, 'USCompliance', null)}
          dataType="options-value"
          inputType="options-button"
          tooltipConfig={{
            enable: true,
            message: 'FDA Regulation'
          }}
          inputConfig={{
            options: threeOptions,
            onChange: (value: Record<string, any>) => onUpdate('USCompliance', value)
          }}
          isEditing={isEditing}
        />
        <InfoCell
          label={'CHINA Compliance'}
          value={get(data, 'chinaCompliance', null)}
          dataType="options-value"
          inputType="options-button"
          tooltipConfig={{
            enable: true,
            message:
              'All ingredients are included IECIC (Inventory of Existing Cosmetic Ingredients in China)'
          }}
          inputConfig={{
            options: threeOptions,
            onChange: (value: Record<string, any>) => onUpdate('chinaCompliance', value)
          }}
          isEditing={isEditing}
        />
        <InfoCell
          label={'JP Compliance'}
          value={get(data, 'JPCompliance', null)}
          dataType="options-value"
          inputType="options-button"
          tooltipConfig={{
            enable: true,
            message: 'Inventory as approved ingredients'
          }}
          inputConfig={{
            options: threeOptions,
            onChange: (value: Record<string, any>) => onUpdate('JPCompliance', value)
          }}
          isEditing={isEditing}
        />
        <InfoCell
          label={'KOREA Compliance'}
          value={get(data, 'koreaCompliance', null)}
          dataType="options-value"
          inputType="options-button"
          tooltipConfig={{
            enable: true,
            message: 'Inventory as approved ingredients'
          }}
          inputConfig={{
            options: threeOptions,
            onChange: (value: Record<string, any>) => onUpdate('koreaCompliance', value)
          }}
          isEditing={isEditing}
        />

        <InfoCell
          label={'Clean At SEPHORA'}
          value={get(data, 'cleanAtSephora', null)}
          dataType="options-value"
          inputType="options-button"
          inputConfig={{
            options: threeOptions,
            onChange: (value: Record<string, any>) => onUpdate('cleanAtSephora', value)
          }}
          isEditing={isEditing}
        />
        <InfoCell
          label={'Clean At Ulta'}
          value={get(data, 'cleanAtUlta', null)}
          dataType="options-value"
          inputType="options-button"
          inputConfig={{
            options: threeOptions,
            onChange: (value: Record<string, any>) => onUpdate('cleanAtUlta', value)
          }}
          isEditing={isEditing}
        />
        <InfoCell
          value={get(data, 'intercosCleanBL', null)}
          dataType="options-value"
          inputType="options-button"
          inputConfig={{
            options: threeOptions,
            onChange: (value: Record<string, any>) => onUpdate('intercosCleanBL', value)
          }}
          label={'Intercos Clean BL'}
          tooltipConfig={{
            enable: true,
            message: 'ex Pure Clean'
          }}
          isEditing={isEditing}
        />
        <InfoCell
          value={get(data, 'lorealBLCompliance', null) || 'Null'}
          inputType="select"
          label={"L'Oréal BL Compliance"}
          isEditing={isEditing}
          inputConfig={{
            items: lorealBLComplianceDropdown,
            onChange: ({ value }: Record<string, any>) => {
              onUpdate('lorealBLCompliance', value)
            },
            onClear: () => {
              onUpdate('lorealBLCompliance', 'Null')
            }
          }}
        />
        <InfoCell
          value={get(data, 'lvmhBLCompliance', null) || 'Null'}
          inputType="select"
          label={'LVMH BL Compliance'}
          isEditing={isEditing}
          inputConfig={{
            items: lvmhBLComplianceDropdown,
            onChange: ({ value }: Record<string, any>) => {
              onUpdate('lvmhBLCompliance', value)
            },
            onClear: () => {
              onUpdate('lvmhBLCompliance', 'Null')
            }
          }}
        />
        <InfoCell
          value={get(data, 'chanelBLCompliance', null) || 'Null'}
          inputType="select"
          label={'Chanel BL Compliance'}
          isEditing={isEditing}
          inputConfig={{
            items: chanelBLComplianceDropdown,
            onChange: ({ value }: Record<string, any>) => {
              onUpdate('chanelBLCompliance', value)
            },
            onClear: () => {
              onUpdate('chanelBLCompliance', 'Null')
            }
          }}
        />
        <InfoCell
          value={get(data, 'cotyBLCompliance', null) || 'Null'}
          inputType="select"
          label={'Coty BL Compliance'}
          isEditing={isEditing}
          inputConfig={{
            items: cotyBLComplianceDropdown,
            onChange: ({ value }: Record<string, any>) => {
              onUpdate('cotyBLCompliance', value)
            },
            onClear: () => {
              onUpdate('cotyBLCompliance', 'Null')
            }
          }}
        />
        <InfoCell
          value={get(data, 'kikoBLCompliance', null) || 'Null'}
          inputType="select"
          label={'KIKO BL Compliance'}
          isEditing={isEditing}
          inputConfig={{
            items: kikoBLComplianceDropdown,
            onChange: ({ value }: Record<string, any>) => {
              onUpdate('kikoBLCompliance', value)
            },
            onClear: () => {
              onUpdate('kikoBLCompliance', 'Null')
            }
          }}
        />
        <InfoCell
          label={'Natural % (ISO16128)'}
          value={get(data, 'naturalPercentage', '')}
          inputConfig={{
            onChange: (value: Record<string, any>) => onUpdate('naturalPercentage', value)
          }}
          isEditing={isEditing}
        />
        <InfoCell
          label="SPF Details"
          value={get(data, 'SPFDetails', '')}
          inputConfig={{
            onChange: (value: Record<string, any>) => onUpdate('SPFDetails', value)
          }}
          isEditing={isEditing}
        />
      </BoxWrapper>
      <BoxWrapper columnCount={1} enablePadding sm={1} md={1} lg={1}>
        {isEditing ? (
          <StyledBox>
            <InputTextArea
              titleConfig={{
                title: 'Regulatory Notes',
                variant: 'h5'
              }}
              placeholder="Insert text here"
              value={data?.regulatoryNotes}
              onChange={(value: string) => onUpdate('regulatoryNotes', value)}
            />
          </StyledBox>
        ) : (
          <InputWrapper>
            <Label>Regulatory Notes</Label>
            <Value>
              {data?.regulatoryNotes ? concatenateIfArray(data.regulatoryNotes) : '-'}
            </Value>
          </InputWrapper>
        )}
      </BoxWrapper>
      <BoxWrapper columnCount={4} enablePadding>
        {isMulti && (
          <InfoCell
            label="CSAR Registered"
            value={get(data, 'csarRegistered', null)}
            inputConfig={{
              onChange: (value: Record<string, any>) => onUpdate('csarRegistered', value)
            }}
            isEditing={isEditing}
          />
        )}
        {isMulti && (
          <InfoCell
            label="Vegan"
            value={get(data, 'vegan', '')}
            inputConfig={{
              onChange: (value: Record<string, any>) => onUpdate('vegan', value)
            }}
            isEditing={isEditing}
          />
        )}
        {isMulti && (
          <InfoCell
            label="Vegan Notes"
            value={get(data, 'veganNotes', '')}
            inputConfig={{
              onChange: (value: Record<string, any>) => onUpdate('veganNotes', value)
            }}
            isEditing={isEditing}
          />
        )}
        {isMulti && (
          <InfoCell
            label="RSPO"
            value={get(data, 'RSPO', '')}
            inputConfig={{
              onChange: (value: Record<string, any>) => onUpdate('RSPO', value)
            }}
            isEditing={isEditing}
          />
        )}
        {isMulti && (
          <InfoCell
            label="Clean Of Microplastic"
            value={get(data, 'microplasticFree', '')}
            inputConfig={{
              onChange: (value: Record<string, any>) =>
                onUpdate('microplasticFree', value)
            }}
            isEditing={isEditing}
          />
        )}
        {isMulti && (
          <InfoCell
            label="Clean Of Microplastic Notes"
            value={get(data, 'microplasticFreeNotes', '')}
            inputConfig={{
              onChange: (value: Record<string, any>) =>
                onUpdate('microplasticFreeNotes', value)
            }}
            isEditing={isEditing}
          />
        )}

        {isMulti && (
          <InfoCell
            label="Clean Of Silicones"
            value={get(data, 'siliconesFree', '')}
            inputConfig={{
              onChange: (value: Record<string, any>) => onUpdate('siliconesFree', value)
            }}
            isEditing={isEditing}
          />
        )}
        {isMulti && (
          <InfoCell
            label="Clean Of Talc"
            value={get(data, 'talcFree', '')}
            inputConfig={{
              onChange: (value: Record<string, any>) => onUpdate('talcFree', value)
            }}
            isEditing={isEditing}
          />
        )}
        {isMulti && (
          <InfoCell
            label="Clean Of PEG"
            value={get(data, 'pegFree', '')}
            inputConfig={{
              onChange: (value: Record<string, any>) => onUpdate('pegFree', value)
            }}
            isEditing={isEditing}
          />
        )}
        {isMulti && (
          <InfoCell
            label="LVMH BL Compliance"
            value={get(data, 'lvmhBLCompliance', '')}
            inputConfig={{
              onChange: (value: Record<string, any>) =>
                onUpdate('lvmhBLCompliance', value)
            }}
            isEditing={isEditing}
          />
        )}
        {isMulti && (
          <InfoCell
            label="Clean Of Silica"
            value={get(data, 'silicaFree', '')}
            inputConfig={{
              onChange: (value: Record<string, any>) => onUpdate('silicaFree', value)
            }}
            isEditing={isEditing}
          />
        )}
        {isMulti && (
          <InfoCell
            label="Clean Of GMO"
            value={get(data, 'GMO', '')}
            inputConfig={{
              onChange: (value: Record<string, any>) => onUpdate('GMO', value)
            }}
            isEditing={isEditing}
          />
        )}
        {isMulti && (
          <InfoCell
            label="Clean of Preservatives"
            value={get(data, 'cleanOfPreservatives', '')}
            inputConfig={{
              onChange: (value: Record<string, any>) =>
                onUpdate('cleanOfPreservatives', value)
            }}
            isEditing={isEditing}
          />
        )}
        {isMulti && (
          <InfoCell
            label="Clean of Mineral Oils"
            value={get(data, 'cleanOfMineralOils', '')}
            inputConfig={{
              onChange: (value: Record<string, any>) =>
                onUpdate('cleanOfMineralOils', value)
            }}
            isEditing={isEditing}
          />
        )}
      </BoxWrapper>
    </Form>
  )
}

export default RegulatoryCheckInfo
