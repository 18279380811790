import { FC } from 'react'
import { DeleteMessage } from './styled'

const DeleteToolingMessage: FC = () => {
  return (
    <DeleteMessage>
      Please proceed with permanent deletion of the tooling only in case it has been
      created by mistake. In the following cases instead please only change the tooling
      status:
      <ul>
        <li>Available - tooling can be used </li>
        <li>Discontinued - tooling has been dismissed</li>
      </ul>
    </DeleteMessage>
  )
}

export default DeleteToolingMessage
