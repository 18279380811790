export const TOP_RIGHT_AND_BOTTOM_RIGHT = 'TOP_RIGHT_AND_BOTTOM_RIGHT'
export const TOP_RIGHT = 'TOP_RIGHT'
export const BOTTOM_RIGHT = 'BOTTOM_RIGHT'

export const TABLE_DATA_TYPE = {
  TEXT: 'text',
  ARRAY: 'array',
  DATE: 'date',
  BOOL: 'bool',
  NODE: 'node',
  MODAL_LIST: 'modalList',
  IMAGE: 'image',
  LINK: 'link',
  THREESTATE: 'threeState'
} as const
