import { StyledFooter } from './styled'

type Props = {
  className?: string
}
export const Footer = ({ className }: Props) => {
  return (
    <StyledFooter className={className}>
      © INTERCOS S.P.A. 2018 / ALL RIGHTS RESERVED
    </StyledFooter>
  )
}
