import { storeProductFormsWithAggregateEntities } from '../storeProducts/constants'
import { CheckUtilsType } from './model'

export const checkQuantity = ({
  cartItems = [],
  quantity,
  productCode
}: CheckUtilsType) => {
  const itemInCart = cartItems.find(({ item }) => item.productCode === productCode)
  return itemInCart ? quantity + itemInCart.quantity : quantity
}

export const getContentofConditionalProductCodeField = (item: any) => {
  const isAggregateProductForm = storeProductFormsWithAggregateEntities.includes(
    item.productForm
  )
  if (isAggregateProductForm) {
    return {
      label: 'Product Code',
      value: item.productCode
    }
  } else {
    return {
      label: item.industrialCode ? 'Industrial code' : 'Lab Code',
      value: item.industrialCode ? item.industrialCode : item.labCode
    }
  }
}
