import { Divider } from 'antd'
import styled from 'styled-components'

export type StyledDividerProps = {
  $disableborder?: boolean
}

export const StyledDivider = styled(Divider)<StyledDividerProps>`
  margin: 6px 0px;
  ${({ $disableborder }) => $disableborder && { borderTop: '0' }}
`
