import styled from 'styled-components'

export const DatePickerWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  .ant-picker {
    width: 100%;
  }
`
