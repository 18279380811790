import { get } from 'lodash'
import { createSelector } from 'reselect'
import { type RootState } from '../store'
import { IStoreProdDropdowns } from './model'

const selectStore = (state: RootState) => state.store
export const getFilters = createSelector(selectStore, (store) => get(store, 'filters'))
export const getDetail = createSelector(selectStore, (store) => store.detail)

export const selectChildDetail = createSelector(selectStore, (store) => store.childDetail)
export const selectNewChild = createSelector(selectStore, (store) => store.newChild)

export const getProductsDetailName = createSelector(getDetail, (detail) =>
  get(detail, 'finalName')
)

export const getDetailImage = createSelector(selectStore, (store) =>
  get(store, 'thumbnail.url')
)
export const getTempFilters = createSelector(selectStore, (store) => store.tempFilters)

export const getProductCode = createSelector(getDetail, (detail) =>
  get(detail, 'productCode')
)
export const selectProductPagination = createSelector(
  selectStore,
  (state) => state.pagination
)
export const selectLoader = createSelector(selectStore, (state) => state.isLoading)

export const selectProducts = createSelector(selectStore, (state) => state.items)

export const selectSearchRequirements = createSelector(getTempFilters, (filters) => {
  const isActive = Object.entries(filters).find(([k, v]) =>
    k === 'specialEffect' ? v !== null : v
  )
  return isActive ? true : false
})
export const getMaxQuantity = createSelector(getDetail, (detail) =>
  get(detail, 'availableQuantity', 0)
)

export const selectStoreProdError = createSelector(selectStore, (store) => store.error)

export const selectStoreProdLoader = createSelector(
  selectStore,
  (store) => store.isLoading
)

export const selectNewStoreProd = createSelector(selectStore, (store) => store.new)

export const selectStoreProdSuccess = createSelector(
  selectStore,
  (store) => store.successInfo
)

export const getStoreProdDropdownByKey = (key: keyof IStoreProdDropdowns) =>
  createSelector(selectStore, (store) => store.dropDown[key] || [])
