import { FC } from 'react'
import { FooterCtrlContainer } from './styled'
import { IFooterControlProps } from './types'

const FooterControl: FC<IFooterControlProps> = ({
  children,
  justifyContent = 'flex-end'
}) => {
  return (
    <FooterCtrlContainer justifyContent={justifyContent}>{children}</FooterCtrlContainer>
  )
}

export default FooterControl
