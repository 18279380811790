import { WarningOutlined } from '@ant-design/icons'
import React, { FC } from 'react'
import { Tooltip } from '../../Tooltip'
import { IconWrapper } from './styles'
import { ITableWarnProps } from './types'

const TableWarning: FC<ITableWarnProps> = ({ message }) => {
  return (
    <IconWrapper>
      <Tooltip title={message}>
        <WarningOutlined
          style={{
            fontSize: '20px',
            color: 'orange'
          }}
        />
      </Tooltip>
    </IconWrapper>
  )
}

export default TableWarning
