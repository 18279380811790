import { get } from 'lodash'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Button, Select } from '../../../components'
import { InputTextArea } from '../../../components/Form/TextArea'
import { Modal } from '../../../components/Modal'
import { actions as appActions } from '../../../features/app'
import { orderListActions } from '../../../features/orderList/reducer'
import { selectOrderListDropdown } from '../../../features/orderList/selectors'

const RejectModal = () => {
  const dispatch = useDispatch()
  const { id } = useParams()
  const [refuseReason, setRefuseReason] = useState('')
  const [refuseNote, setRefuseNote] = useState('')
  const dropdownTaxonomies = useSelector(selectOrderListDropdown)

  const openRejectionWarning = () => {
    dispatch(
      appActions.openPopUp({
        title: 'Are you sure you want to reject the order?',
        key: 'rejection-warning',
        footer: [
          <Button
            key="confirm"
            label="Confirm"
            onClick={() => {
              dispatch(appActions.closePopUp())
              confirmRejectHandler()
            }}
          />
        ]
      })
    )
  }

  const closeModalHandler = () => {
    setRefuseReason('')
    dispatch({ type: 'app/closeModal', payload: { modalKey: 'order-reject' } })
  }

  const confirmRejectHandler = () => {
    dispatch(
      orderListActions.confirmReject({
        id: id!,
        reasonData: {
          refuseReason: refuseReason,
          refuseNote: refuseNote
        }
      })
    )
    dispatch({ type: 'app/closeModal', payload: { modalKey: 'order-reject' } })
  }

  const footer = [
    <Button key="cancel" label="Cancel" onClick={closeModalHandler} variant="ghost" />,
    <Button
      key="reject"
      label="Reject"
      onClick={openRejectionWarning}
      disabled={!refuseReason || !refuseNote}
    />
  ]

  return (
    <Modal
      btnName="Reject"
      size="small"
      isSmall
      modalKey="order-reject"
      title="Insert the notes and motivations for refusal"
      btnType="ghost"
      footer={footer}
      centered
    >
      <Select
        value={refuseReason}
        items={get(dropdownTaxonomies, 'refuseReason', [])}
        label={'Refusal Feedback'}
        onChange={({ value }) => setRefuseReason(value)}
      />
      <InputTextArea
        placeholder={'Refusal Motivation'}
        value={refuseNote}
        onChange={(value) => setRefuseNote(value)}
      />
    </Modal>
  )
}

export default RejectModal
