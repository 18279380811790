import { Steps } from 'antd'
import styled from 'styled-components'
import { mediumSizeWidth } from '../../assets/mixins'

export const StiledStepsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 30px;
  padding: 5px;
  .ant-steps-item-title {
    @media (min-width: ${mediumSizeWidth}px) {
      font-size: 12px;
    }
  }
`
export const StiledSteps = styled(Steps)`
  padding: 4px;
`
