import styled from 'styled-components'

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  button {
    margin-right: 10px;
  }
`

export const DeleteMessage = styled.div`
  text-align: left;
`
