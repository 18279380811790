import { FC } from 'react'
import { BoxWrapper } from '../BoxWrapper'
import { Divider } from '../Divider'
import { InputNumber } from '../Form/InputNumber'
import InfoCell from '../InfoCell'
import Button from '../NewButton'
import { Text } from '../Text'
import {
  CardContainer,
  Detail,
  DetailsContainer,
  ImageWrapper,
  ProductCardContainer,
  TitleWrapper
} from './styled'
import { IProductCard } from './types'

const ProductCard: FC<IProductCard> = ({
  id,
  photoSrc = '',
  quantity = '',
  finalName = '',
  details = '',
  code = { label: '', value: '' },
  packagingReference = '',
  isEditing = false,
  onQuantityChanged = () => {},
  availableQuantity = 0,
  title,
  isDeletable = false,
  onDeleteProduct = () => {}
}) => {
  const quantityChangeHandler = (value: number) => {
    if (!isEditing) return
    onQuantityChanged(id, value)
  }

  const deleteProductHandler = () => {
    if (!isDeletable) return
    onDeleteProduct(id)
  }

  const piecesWord = availableQuantity > 1 ? 'pieces' : 'piece'
  const isValidQuantity = +quantity <= availableQuantity
  const isQuantityDisable = !availableQuantity
  const quantityHelper = isQuantityDisable
    ? 'no more available'
    : !isValidQuantity
    ? `only ${availableQuantity} ${piecesWord} available`
    : ''

  return (
    <>
      {title && (
        <>
          <TitleWrapper>
            <Text text={title} variant="h4" />
            {isDeletable && (
              <Button
                label="Delete"
                variant="text"
                iconName="DeleteOutlined"
                onClick={deleteProductHandler}
              />
            )}
          </TitleWrapper>
          <Divider disableBorder={false} />
        </>
      )}
      <CardContainer>
        <ProductCardContainer>
          <ImageWrapper>
            <InfoCell
              value={photoSrc}
              label={`product-${code.value}`}
              dataType="image"
              inputConfig={{
                isUrl: true
              }}
            />
          </ImageWrapper>
          <DetailsContainer>
            <BoxWrapper sm={2} md={2} lg={3} enablePadding>
              <Detail>
                <Text text="Quantity" bold />
                {isEditing ? (
                  <InputNumber
                    minNumber={1}
                    maxNumber={availableQuantity}
                    value={+quantity}
                    onChange={quantityChangeHandler}
                    disabled={isQuantityDisable}
                    helperText={quantityHelper}
                  />
                ) : (
                  <Text text={quantity} color="grey" />
                )}
              </Detail>
              <Detail>
                <Text text="Final Name" bold />
                <Text text={finalName} color="grey" />
              </Detail>

              <Detail>
                <Text text={`${code.label}`} bold />
                <Text text={code.value} color="grey" />
              </Detail>
              <Detail>
                <Text text="PK Intercos Code" bold />
                <Text text={packagingReference} color="grey" />
              </Detail>
            </BoxWrapper>
          </DetailsContainer>
        </ProductCardContainer>
      </CardContainer>
    </>
  )
}

export default ProductCard
