export const ADMINISTRATION_IMPORT_SUPPLIER =
  'administration/ADMINISTRATION_IMPORT_SUPPLIER'
export const ADMINISTRATION_IMPORT_ANAGRAPHIC =
  'administration/ADMINISTRATION_IMPORT_ANAGRAPHIC'
export const ADMINISTRATION_IMPORT_PRICE = 'administration/ADMINISTRATION_IMPORT_PRICE'
export const ADMINISTRATION_IMPORT_RELATIONS =
  'administration/ADMINISTRATION_IMPORT_RELATIONS'
export const ADMINISTRATION_GET_ATTACHMENTS =
  'administration/ADMINISTRATION_GET_ATTACHMENTS'
export const ADMINISTRATION_GET_ATTACHMENTS_DETAIL =
  'administration/ADMINISTRATION_GET_ATTACHMENTS_DETAIL'
export const ADMINISTRATION_UPDATE_ATTACHMENT =
  'administration/ADMINISTRATION_UPDATE_ATTACHMENT'
export const ADMINISTRATION_CREATE_ATTACHMENTS =
  'administration/ADMINISTRATION_CREATE_ATTACHMENTS'
export const ADMINISTRATION_CREATE_SUPPLIER =
  'administration/ADMINISTRATION_CREATE_SUPPLIER'
export const ADMINISTRATION_SEARCH_SUPPLIER =
  'administration/ADMINISTRATION_SEARCH_SUPPLIER'
export const ADMINISTRATION_GET_SUPPLIER_DETAIL =
  'administration/ADMINISTRATION_GET_SUPPLIER_DETAIL'
export const ADMINISTRATION_UPDATE_SUPPLIER =
  'administration/ADMINISTRATION_UPDATE_SUPPLIER'
export const ADMINISTRATION_CHECK_SUPPLIER = 'administration/findSapCode'
export const ADMINISTRATION_GET_CURRENCY_RATES = 'administration/getCurrencyRates'
export const ADMINISTRATION_SET_CURRENCY_RATE = 'administration/setCurrencyRate'
export const ADMINISTRATION_GET_GROUP_NAMES =
  'administration/ADMINISTRATION_GET_GROUP_NAMES'
