import { Collapse } from 'antd'
import moment from 'moment'
import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { TextVariant } from '../Text'
import { Title } from '../Title'

const CustomCollapse = styled(Collapse)`
  .ant-collapse-header-text > * {
    margin: 0;
  }
  .ant-collapse-header-text {
    flex: 1;
  }
  > div > div.ant-collapse-header {
    align-items: center !important;
  }
`

type Props = {
  children: ReactNode
  title: string
  subtitleValue: string | null
  subtitleLabel: string
  variant: TextVariant | 'Title'
  open: boolean
  isDate: boolean
}

const { Panel } = Collapse

const getTitleVariant = (variant = 'h1', title = '', subtitle: string) => {
  switch (variant) {
    case 'h1':
      return <h1>{title}</h1>
    case 'h2':
      return <h2>{title}</h2>
    case 'h3':
      return <h3>{title}</h3>
    case 'h4':
      return <h4>{title}</h4>
    case 'h5':
      return <h5>{title}</h5>
    case 'h6':
      return <h6>{title}</h6>
    case 'p':
      return <p>{title}</p>
    case 'Title':
      return (
        <div>
          <Title
            className="boxinfo-title"
            title={title}
            subTitle={subtitle}
            flexDirection="row"
            spaceBetweenTitles
            variantTitle="h3"
            borderBottom
          />
        </div>
      )
  }
}

export const Accordion = ({
  children,
  title,
  subtitleLabel,
  subtitleValue,
  variant,
  open = false,
  isDate = false
}: Props) => {
  if (isDate)
    subtitleValue = subtitleValue ? moment(subtitleValue).format('DD/MM/YYYY') : '-'

  const subtitle = subtitleValue ? `${subtitleLabel || ''} ${subtitleValue}` : ''

  return (
    <CustomCollapse ghost defaultActiveKey={open ? ['defaultOpen'] : []}>
      <Panel
        key={open ? 'defaultOpen' : ''}
        header={getTitleVariant(variant, title, subtitle)}
      >
        {children}
      </Panel>
    </CustomCollapse>
  )
}
