import { get } from 'lodash'
import { createSelector } from 'reselect'
import { RootState } from '../store'

const selectApp = (state: RootState) => state.app

export const selectAppError = createSelector(selectApp, (state) => state.error)

export const selectAppPopUp = createSelector(selectApp, (state) => state.popUp)

export const selectModalKey = createSelector(selectApp, (app) => get(app, 'modalKey'))

export const selectIsLoading = createSelector(selectApp, (state) => state.loading)
