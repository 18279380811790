import { DownOutlined } from '@ant-design/icons'
import { Menu } from 'antd'
import { CheckboxValueType } from 'antd/lib/checkbox/Group'
import { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { userGroupId } from '../../auth-msal'
import { actions as appActions } from '../../features/app'
import { packagingActions } from '../../features/packaging'
import { usersActions } from '../../features/users'
import {
  selectPermissions,
  selectUserDisplayName,
  selectUserRole
} from '../../features/users/selectors'
import { AppRoutes } from '../../pages/constants'
import { CheckBoxList } from '../Form/CheckBoxList'
import { Modal } from '../Modal'
import Button from '../NewButton'
import { StyledDropdown } from './styled'
import { Avatar } from '.'
import './styled'

export const AvatarDropdown = () => {
  const currentRoles = useSelector(selectUserRole)
  const permissions = useSelector(selectPermissions)
  const { pathname } = useLocation()
  const [roles, setRoles] = useState<string[]>(currentRoles || [])
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const devMode = process.env.REACT_APP_DEV_MODE === 'true'
  const displayName = useSelector(selectUserDisplayName)

  const handleRoles = useCallback((value: CheckboxValueType[]) => {
    setRoles(value as string[])
  }, [])

  const nav = useCallback(
    (path: string) => {
      navigate(path)
    },
    [navigate]
  )
  const onClick = useCallback(() => {
    dispatch(usersActions.setUserRoles(roles))
    dispatch(usersActions.setPermissions({ roles }))
    dispatch(appActions.setModalKey(undefined))
    dispatch(packagingActions.setFilteredStatus({ roles: roles || [] }))
  }, [dispatch, roles])

  const items = Object.values(userGroupId).map((v) => {
    return {
      name: v,
      value: v
    }
  })

  const filterItems = (section: string) =>
    pathname.includes(section) || pathname === AppRoutes.HOME

  const filterSupportByPermission = (type: 'vault' | 'store') =>
    permissions?.support ? permissions.support[type] : false

  const menu = (
    <Menu
      items={[
        {
          label: <div onClick={(e) => nav('/logout')}>Logout</div>,
          key: 'logout'
        },
        {
          label: (
            <a href="mailto:info.digitalbeautyvault@intercos.com">
              Vault Contact Support
            </a>
          ),
          key: 'vault-support',
          disabled: !(filterSupportByPermission('vault') && filterItems('vault'))
        },
        {
          label: (
            <a href="mailto:info.digitalbeautystore@intercos.com">
              Store Contact Support
            </a>
          ),
          key: 'store-support',
          disabled: !(filterSupportByPermission('store') && filterItems('store'))
        }
      ]}
    />
  )
  return (
    <>
      <StyledDropdown overlay={menu} className="dropdown">
        <Link to="" onClick={(e) => e.preventDefault()}>
          <Avatar name={displayName} />
          <DownOutlined />
        </Link>
      </StyledDropdown>
      {devMode && (
        <Modal
          btnName="Select Group"
          title="Select a group"
          size="small"
          modalKey="modal-groups"
          btnType="link"
          isSmall={true}
        >
          <>
            <div data-cy="select-group-checkbox-list">
              <CheckBoxList
                items={items}
                onChange={handleRoles}
                placeholder="Select a role"
                required={false}
                value={roles}
              />
            </div>
          </>

          <Button label="Apply" onClick={onClick} variant="primary" />
        </Modal>
      )}
    </>
  )
}
