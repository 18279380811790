import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { footerHeight, headerHeight } from '../../assets/mixins'
import { SectionTitle } from '../../components'
import { Text } from '../../components/Text'
import { PageContent } from '../../containers'
import { CartContent } from '../../containers/CartContent'
import { cartActions } from '../../features/cart'
import { selectCartError } from '../../features/cart/selectors'
import { GenericLayout } from '../../layouts'
import { footerActionHeight } from '../CreationPackage/styled'

const CartPage: FC = () => {
  const dispatch = useDispatch()
  const cartError = useSelector(selectCartError)

  useEffect(() => {
    dispatch(cartActions.getCart())
    return () => {
      dispatch(cartActions.clearSuccess())
      dispatch(cartActions.clearError())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <GenericLayout error={cartError}>
      <PageContent
        footerHeight={footerHeight + footerActionHeight}
        headerHeight={headerHeight}
        justifyContent="flex-start"
      >
        <SectionTitle value="Cart" isBold />
        <Text text="Follow the steps to submit a new order" variant="h5" />
        <CartContent />
      </PageContent>
    </GenericLayout>
  )
}

export default CartPage
