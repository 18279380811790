import { createSelector } from 'reselect'
import type { RootState } from '../store'
import { ToolingTaxonomyDropDown } from './model'

const selectTooling = (state: RootState) => state.tooling

export const getToolingFilters = createSelector(
  selectTooling,
  (tooling) => tooling.filters
)
export const getToolingItems = createSelector(selectTooling, (tooling) => tooling.items)

export const selectToolingPagination = createSelector(
  selectTooling,
  (state) => state.pagination
)

export const selectToolingDropdown = createSelector(
  selectTooling,
  (state) => state.dropDown
)
export const selectToolingLoader = createSelector(
  selectTooling,
  (state) => state.isLoading
)

export const getToolingDropdownByKey = (key: string) =>
  createSelector(
    selectToolingDropdown,
    (dropDown) => dropDown[key as keyof ToolingTaxonomyDropDown] || []
  )

export const selectToolingError = createSelector(selectTooling, (state) => state.error)

export const selectToolingSection = createSelector(
  selectTooling,
  (state) => state.isToolingSection
)

export const getTempToolingFilters = createSelector(
  selectTooling,
  (state) => state.tempFilters
)

export const getToolingDetail = createSelector(
  selectTooling,
  (state) => state.toolingDetail
)

export const getToolingEditingData = createSelector(
  selectTooling,
  (state) => state.toolingEditingData
)

export const selectToolingSuccess = createSelector(
  selectTooling,
  (state) => state.successInfo
)
