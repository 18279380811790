import { AppRoutes } from '../../pages/constants'
import { TABLE_DATA_TYPE } from '../NewTable/constants'
import { ITableConfig } from '../NewTable/types'

export const replaceDotsWithCommas = (input: string | number) => {
  if (input && typeof input === 'string') return input.replace(/\./g, ',')
  if (input && typeof input === 'number') return input.toString().replace(/\./g, ',')
  else return ''
}

export const TOOLING_TABLE_CONFIG: ITableConfig = {
  id: {
    dataPath: 'id',
    hide: true
  },
  code: {
    label: 'Tooling Code',
    dataType: TABLE_DATA_TYPE.LINK,
    pathname: AppRoutes.VAULT_TOOLING_DETAILS,
    newTab: true,
    dataPath: 'code'
  },
  type: {
    label: 'Tooling Type',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'type'
  },
  toolingShape: {
    label: 'Tooling Shape',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'toolingShape'
  },
  material: {
    label: 'Tooling Material',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'material'
  },
  backInjection: {
    label: 'Back Injection',
    dataType: TABLE_DATA_TYPE.THREESTATE,
    dataPath: 'backInjection'
  },
  diameter: {
    label: 'Tooling Diameter',
    dataType: TABLE_DATA_TYPE.NODE,
    dataPath: 'diameter',
    renderNode: (data: string) => {
      return replaceDotsWithCommas(data)
    }
  },
  length: {
    label: 'Tooling Length',
    dataType: TABLE_DATA_TYPE.NODE,
    dataPath: 'length',
    renderNode: (data: string) => {
      return replaceDotsWithCommas(data)
    }
  },
  width: {
    label: 'Tooling Width',
    dataType: TABLE_DATA_TYPE.NODE,
    dataPath: 'width',
    renderNode: (data: string) => {
      return replaceDotsWithCommas(data)
    }
  },
  height: {
    label: 'Tooling Height',
    dataType: TABLE_DATA_TYPE.NODE,
    dataPath: 'height',
    renderNode: (data: string) => {
      return replaceDotsWithCommas(data)
    }
  },
  industrialCodeMold: {
    label: 'Industrial or Flat Mold Code',
    dataType: TABLE_DATA_TYPE.BOOL,
    dataPath: 'industrialCodeMold'
  },
  status: {
    label: 'Status',
    dataType: TABLE_DATA_TYPE.MODAL_LIST,
    dataPath: 'status',
    hide: true
  },
  volume: {
    label: 'Tooling Volume',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'volume',
    hide: true
  },
  toolingInternalVolume: {
    label: 'Tooling Internal Volume',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'toolingInternalVolume',
    hide: true
  },
  powderFillableVolume: {
    label: 'Powder Fillable Volume',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'powderFillableVolume',
    hide: true
  },
  moldSize: {
    label: 'Mold Size',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'moldSize',
    hide: true
  },
  cavities: {
    label: 'Cavities',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'cavities',
    hide: true
  },
  pilotMoldCode: {
    label: 'Pilot Mold Code',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'pilotMoldCode',
    hide: true
  }
}
