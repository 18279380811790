import { PageHeader } from 'antd'
import styled from 'styled-components'

export const pageHeaderHeight = 56
export const StyledPageHeader = styled(PageHeader)`
  width: 100%;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  height: ${pageHeaderHeight}px;
  padding: 10px 0px 0px;
  .ant-page-header-heading {
    height: 46px;
  }
  .ant-page-header-heading-title {
    font-size: 38px;
  }
`

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const IconWrapper = styled.div`
  svg {
    width: 22px;
    height: 22px;
  }
`

export const ActionsWrapper = styled.div`
  display: flex;
  button {
    margin: 0px 8px;
  }
`

export const AlertWrapper = styled.div`
  position: fixed;
  top: 30%;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
`
