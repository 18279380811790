import { apiClient } from '../../api/client'

export const importAnagraphic = (data: any) => {
  return apiClient.post<any>('orderableItem/import', data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export const importPrice = (data: any) => {
  return apiClient.post<any>('package/import/pricing', data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export const importRelations = (data: any) => {
  return apiClient.post<any>('package/relationship/import', data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export const importSupplier = (data: any) => {
  return apiClient.post<any>('suppliers/import', data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export const importAttachments = () => {
  return apiClient.get<any>('package/attachmentContentTypes')
}

export const updateAttachment = (id: string, body: Record<string, any>) => {
  return apiClient.patch<any>(`package/attachmentContentType/${id}`, body)
}

export const getGroupNames = () => {
  return apiClient.get<any>('suppliers/group-names')
}

export const insertAttachment = (data: Record<string, any>) => {
  return apiClient.post<any>('package/attachmentContentType', data)
}
export const getSuppliersByFilters = (data: Record<string, any>) => {
  return apiClient.post<any>('suppliers/search', data)
}
export const createNewSupplier = (data: Record<string, any>) => {
  return apiClient.post<any>('suppliers', data)
}
export const searchOneSupplier = (id: string) => {
  return apiClient.get<any>(`suppliers/${id}`)
}
export const updateOneSupplier = ({
  data,
  id
}: {
  data: Record<string, any>
  id: string
}) => {
  return apiClient.patch<any>(`suppliers/${id}`, data)
}

export const getCurrencyRates = () => {
  return apiClient.get<any>('currencies/rates')
}

export const setCurrencyRate = ({
  data,
  id
}: {
  data: Record<string, any>
  id: string
}) => {
  return apiClient.patch<any>(`currencies/rate/${id}`, { USDExchangeRate: data })
}
