import { BrowserUtils } from '@azure/msal-browser'
import { useMsal } from '@azure/msal-react'
import { FC, useEffect } from 'react'
import { Loader } from '../../components/Loader'

export const Logout: FC = () => {
  const { instance } = useMsal()

  useEffect(() => {
    instance.logoutRedirect({
      account: instance.getActiveAccount(),
      onRedirectNavigate: () => !BrowserUtils.isInIframe()
    })
  }, [instance])

  return <Loader />
}

export default Logout
