import { FC } from 'react'
import { Button } from '../../components'
import { Advice } from '../../components/Advice'
import { Divider } from '../../components/Divider'
import { Text } from '../../components/Text'
import { ContentContainer, MessageContainer, SuccessContainer } from './styled'
import { ISuccessProps } from './types'

const Success: FC<ISuccessProps> = ({ data, children, buttons }) => {
  const { title, message, status = 'success' } = data

  return (
    <SuccessContainer>
      <Advice status={status} />
      {(title || message) && (
        <MessageContainer>
          {title && <Text variant="h2" text={title || ''} />}
          {message && <Text text={message || ''} variant="h4" />}
        </MessageContainer>
      )}
      <ContentContainer>
        <Divider disableBorder />
        {children}
        {buttons &&
          buttons.map(({ label, onClick, iconName, variant }, idx) => (
            <div key={`${label}-${idx}`}>
              <Divider disableBorder />
              <Button
                label={label}
                onClick={onClick}
                iconName={iconName || undefined}
                variant={variant || 'primary'}
              />
            </div>
          ))}
      </ContentContainer>
    </SuccessContainer>
  )
}

export default Success
