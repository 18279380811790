import { get } from 'lodash'

export const sortItemsRanges = (data: Record<string, any>[]) => {
  if (!checkRangeOptions(data)) return data
  return data.sort((a, b) => {
    if (a.value[0] === '<') {
      return -1
    }
    const [firstValueA] = a.value.split(' ')
    const [firstValueB] = b.value.split(' ')
    const re = new RegExp('[0-9]{1,4}')
    const numberA = Number(re.exec(firstValueA.substring(1))?.[0])
    const numberB = Number(re.exec(firstValueB.substring(1))?.[0])
    return numberA - numberB
  })
}

export const checkRangeOptions = (data: Record<string, any>[]) => {
  const firstItem = get(data, '0.name.0', '')
  const regex = new RegExp('[a-z,A-Z]{*')
  return !regex.test(firstItem)
}
