import { get } from 'lodash'
import { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Button, InfoCell } from '../../../components'
import { FlexSection } from '../../../components/FlexSection'
import { Modal } from '../../../components/Modal'
import { Text } from '../../../components/Text'
import { packagingActions } from '../../../features/packaging'
import { PackagingDto } from '../../../features/packaging/model'
import {
  getPackageDetail,
  selectRelatedOnDelete
} from '../../../features/packaging/selectors'
import { IDeleteButtonProps } from './types'

const DeleteButton: FC<IDeleteButtonProps> = ({ id }) => {
  const dispatch = useDispatch()
  const packagingDetail = useSelector(getPackageDetail)
  const relatedProducts = useSelector(selectRelatedOnDelete)
  const navigate = useNavigate()

  const checkStatusHandler = () => {
    dispatch(
      packagingActions.checkStatusOnDelete({ value: packagingDetail as PackagingDto })
    )
  }

  const cancelHandler = () => {
    dispatch({
      type: 'app/closeModal',
      payload: { modalKey: 'delete-status-with-products' }
    })
    dispatch(packagingActions.resetRelatedProducts())
  }

  const deleteHandler = () => {
    dispatch({
      type: 'app/closeModal',
      payload: { modalKey: 'delete-status-with-products' }
    })
    dispatch(
      packagingActions.deleteVariantFromVariant({
        value: { id },
        successCallback: () => {
          navigate(`/wikipack/packaging/${get(packagingDetail, 'id', '')}`)
        }
      })
    )
  }

  return (
    <Modal
      isSmall
      modalKey="delete-status-with-products"
      size="medium"
      btnName="Delete"
      btnIcon="DeleteOutlined"
      btnType="link"
      btnClicked={checkStatusHandler}
      onClose={() => dispatch(packagingActions.resetRelatedProducts())}
      title={''}
      centered
      footer={[
        <Button key="cancel" label="Cancel" onClick={cancelHandler} type="ghost" />,
        <Button key="delete" label="Delete" onClick={deleteHandler} />
      ]}
    >
      <FlexSection
        gap={0}
        flexDirection={'column'}
        justifyContent={'center'}
        alignItems={'center'}
      >
        {!!relatedProducts.length && (
          <Text
            text={
              'Changing the packaging status the products below won’t be RTG anymore.'
            }
            variant={'h4'}
          />
        )}
        <Text text={'Are you sure do you want to delete this variant?'} variant={'h4'} />

        {!!relatedProducts.length && (
          <InfoCell value={relatedProducts} label={'List of products'} />
        )}
      </FlexSection>
    </Modal>
  )
}

export default DeleteButton
