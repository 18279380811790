import moment, { Moment } from 'moment'
import { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, Select } from '../..'
import { REFUSED_REASONS } from '../../../features/orderList/constants'
import { IOrderData } from '../../../features/orderList/models'
import { getOrderListDropdownByKey } from '../../../features/orderList/selectors'
import { IDropdown } from '../../../features/types'
import { selectUserRole } from '../../../features/users/selectors'
import { orderDataExtractor } from '../../../utils/orderHelpers'
import { BoxInfo } from '../../BoxInfo'
import { BoxWrapper } from '../../BoxWrapper'
import { Divider } from '../../Divider'
import { DatePicker } from '../../Form/DatePicker'
import { Input } from '../../Form/Input'
import { DATE_FORMAT } from '../../Form/RangeDatePicker/contants'
import { Text } from '../../Text'
import { GeneralInfoContainer } from './styled'
import { IGeneralInfo } from './types'
import { canViewCreatedBy } from './utils'

const GeneralInfo: FC<IGeneralInfo> = ({
  data,
  isEditable = false,
  onEditFullfilmentDate = () => {},
  onEditInputValues = () => {}
}) => {
  const countriesDropdown: IDropdown[] = useSelector(
    getOrderListDropdownByKey('countries')
  )

  const [isEditingShipTo, setIsEditingShipTo] = useState(false)
  const [isEditingCountry, setIsEditingCountry] = useState(false)
  const [isFullfilmentDateEditing, setIsFullfilmentDateEditing] = useState(false)

  const userRoles = useSelector(selectUserRole)

  const {
    ownerName,
    ownerSurname,
    addressedTo,
    additionalNote,
    notes,
    required,
    shipTo,
    regionCountry,
    fullfillmentDate,
    refuseReason
  } = orderDataExtractor(data)

  const [shipToInputValue, setShipToInputValue] = useState(shipTo)
  const [regionInputValue, setRegionInputValue] = useState(regionCountry)

  const countriesData = countriesDropdown.find(({ value }) => value === regionCountry)
  const days = countriesData?.days || 0

  const fullfilmentDateInfoClicked = () => {
    if (!isEditable) return
    setIsFullfilmentDateEditing((prevState) => !prevState)
  }

  const shipToClicked = () => {
    if (!isEditable) return
    setIsEditingShipTo(true)
  }

  const regionClicked = () => {
    if (!isEditable) return
    setIsEditingCountry(true)
  }

  const changeDateHandler = (value: Moment) => {
    setIsFullfilmentDateEditing(false)
    onEditFullfilmentDate(value)
  }

  const containerClicked = () => {
    if (!isEditable) return
  }
  const handleUpdateInput = (key: keyof IOrderData, value: any) => {
    onEditInputValues(key, value)
  }

  const handleUpdateShipTo = () => {
    handleUpdateInput('shipTo', shipToInputValue)
    setIsEditingShipTo(false)
  }

  const handleUpdateRegion = () => {
    handleUpdateInput('regionCountry', regionInputValue)
    setIsEditingCountry(false)
  }

  useEffect(() => {
    setShipToInputValue(shipTo)
    setRegionInputValue(regionCountry)
  }, [shipTo, regionCountry])

  const createdBy = ownerName || ownerSurname ? `${ownerName} ${ownerSurname}` : ''
  const isUndeliverable = refuseReason === REFUSED_REASONS.UNDELIVERABLE_AT_ADDRESS

  return (
    <GeneralInfoContainer onClick={containerClicked}>
      <Text text="General Info" variant="h4" />
      <Divider />
      <BoxWrapper>
        {canViewCreatedBy(userRoles) && <BoxInfo label="Created By" value={createdBy} />}
        <BoxInfo
          label="Request date of fullfilment"
          value={fullfillmentDate && moment(fullfillmentDate).format(DATE_FORMAT)}
          isEditable={isEditable}
          onClick={fullfilmentDateInfoClicked}
        />
        {isFullfilmentDateEditing && (
          <DatePicker
            className="date-picker"
            onChange={changeDateHandler}
            value={fullfillmentDate ? moment(fullfillmentDate) : undefined}
            minDays={days}
            excludeDays={['sunday', 'saturday']}
            isOpen={isFullfilmentDateEditing}
          />
        )}
        <BoxInfo label="Destination Order" value={addressedTo} />
        <BoxInfo label="Specific Notes" value={additionalNote} />
        <BoxInfo label="General Notes" value={notes} />
        <BoxInfo label="Set required" value={required} />
        {!isEditingShipTo ? (
          <BoxInfo
            label="Ship to"
            value={shipTo}
            onClick={shipToClicked}
            isEditable={isUndeliverable && isEditable}
          />
        ) : (
          <BoxInfo
            label="Ship to"
            value={shipToInputValue}
            isEditable={isUndeliverable && isEditable}
          >
            <Input
              label=""
              placeholder="Insert address"
              value={shipToInputValue}
              onChange={setShipToInputValue}
            />
            <Button
              label=""
              iconName="SaveOutlined"
              onClick={() => handleUpdateShipTo()}
              variant="text"
            />
          </BoxInfo>
        )}
        {!isEditingCountry ? (
          <BoxInfo
            label="Region/Country"
            value={regionCountry}
            onClick={regionClicked}
            isEditable={isEditable}
          />
        ) : (
          <BoxInfo label="Region/Country" value={regionCountry} isEditable={isEditable}>
            <Select
              label=""
              items={countriesDropdown}
              onChange={(value: Record<string, any>) => setRegionInputValue(value.value)}
              value={regionInputValue}
            />
            <Button
              label=""
              iconName="SaveOutlined"
              onClick={() => handleUpdateRegion()}
              variant="text"
            />
          </BoxInfo>
        )}
      </BoxWrapper>
    </GeneralInfoContainer>
  )
}

export default GeneralInfo
