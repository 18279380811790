import { Form } from 'antd'
import { FC, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { fSchemaActions } from '../../../features/formulaSchema'
import { ModalBodyContainer } from '../../AddMasterSpecCharacteristicModal/styled'
import { Modal } from '../../Modal'
import Button from '../../NewButton'
import OtherTestedPackagingForm from '../../Product/OtherTestedPackagesForm'

const OtherTestedPackagingModal: FC<any> = ({
  btnLabel,
  btnIcon = 'PlusCircleOutlined',
  onBtnClick,
  modalKey,
  onSave,
  disabled = false,
  onUpdate = () => {},
  data = {}
}) => {
  const dispatch = useDispatch()
  const [submittable, setSubmittable] = useState(false)

  const closeModalHandler = () => {
    dispatch({ type: 'app/closeModal', payload: { modalKey: modalKey } })
    dispatch(fSchemaActions.resetOtherTestedPackaging())
  }
  const [form] = Form.useForm()
  const values = Form.useWatch([], form)
  const handleFinish = (values: any) => {
    onSave({ ...values, formulaSchemaId: data.formulaSchemaId, id: data.id })
    dispatch({ type: 'app/closeModal', payload: { modalKey: modalKey } })
  }
  useEffect(() => {
    form.validateFields(['weightLossTest', 'testResult', 'testStartDate']).then(
      () => {
        setSubmittable(true)
      },
      () => {
        setSubmittable(false)
      }
    )
  }, [values, form])

  return (
    <Modal
      isSmall={true}
      btnName={btnLabel}
      disabled={disabled}
      btnType="link"
      btnIcon={btnIcon}
      modalKey={modalKey}
      title=""
      footer={[
        <Button key="cancel" label="Cancel" onClick={closeModalHandler} />,
        <Button
          key="save"
          label="Save"
          disabled={!submittable}
          onClick={() => form.submit()}
        />
      ]}
      btnClicked={onBtnClick}
      centered
      size="large"
    >
      <ModalBodyContainer>
        <OtherTestedPackagingForm
          onFinish={handleFinish}
          data={data}
          isEditing={true}
          onUpdate={onUpdate}
          formInstance={form}
        />
      </ModalBodyContainer>
    </Modal>
  )
}

export default OtherTestedPackagingModal
