import { Image } from 'antd'
import styled from 'styled-components'

export const StyledImgContainer = styled.div`
  display: flex;
  flex: 1;
`
export const StyledImage = styled(Image)`
  width: 250px;
  height: 250px;
  border: 1px solid #ccc;
  margin: 10px;
  object-fit: contain;
`
