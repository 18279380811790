import styled from 'styled-components'

export const GeneralInfoContainer = styled.div`
  .date-picker {
    visibility: hidden;
    height: 0;
    padding: 0;
    width: 0;
    position: absolute;
  }
`
