import { get } from 'lodash'
import { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectGroupNamesDropdown } from '../../../features/administration/selectors'
import { threeOptions } from '../../../features/constants'
import { packagingActions } from '../../../features/packaging'
import { PACKAGING_STATUS } from '../../../features/packaging/constants'
import { CATEGORY, PACKAGE_TYPE } from '../../../features/packaging/model'
import {
  getAllStatus,
  getDropdownTestedSupplier,
  selectAllTaxonomies
} from '../../../features/packaging/selectors'
import { selectUserRole } from '../../../features/users/selectors'
import {
  checkFieldVisibilityByCategory,
  getDynamicPackDropdownByKey
} from '../../../utils'
import {
  checkFieldDisability,
  checkFieldVisibilityByRoles,
  checkFieldVisibilityByType
} from '../../../utils/packagingFormHelper'
import { checkRangeOptions, sortItemsRanges } from '../../../utils/sortItemsRanges'
import { BoxWrapper } from '../../BoxWrapper'
import { Divider } from '../../Divider'
import InfoCell from '../../InfoCell'
import { canViewCreatedBy } from '../../Order/GeneralInfo/utils'
import { Text } from '../../Text'
import { IGeneralInfoProps } from './types'

const GeneralInfo: FC<IGeneralInfoProps> = ({
  data = {},
  isEditing = false,
  updateData = () => {},
  categoryPreselected = false,
  isCreating = false,
  isMulti = false
}) => {
  const statusDropdowns = useSelector(getAllStatus)
  const testedSupplierDropdowns = useSelector(getDropdownTestedSupplier)
  const groupNameDropDownItems = useSelector(selectGroupNamesDropdown)
  const allTaxonomies = useSelector(selectAllTaxonomies)
  const userRoles = useSelector(selectUserRole)

  const categoryValue: string = get(data, 'category', '')
  const subCategoryValue: string = data.subcategory || ''

  const catInLowercase = categoryValue.toLowerCase()
  const subCatInLowercase = subCategoryValue.toLowerCase()
  const isVials = catInLowercase === CATEGORY.VIALS

  const dispatch = useDispatch()

  const isFieldVisible = (key: string) => {
    let visibility = true

    visibility =
      visibility &&
      checkFieldVisibilityByType({
        key,
        type: isMulti ? PACKAGE_TYPE.MULTI : PACKAGE_TYPE.MONO
      })

    visibility =
      visibility &&
      checkFieldVisibilityByCategory({
        key,
        category: catInLowercase,
        subcategory: subCatInLowercase
      })

    visibility =
      visibility &&
      checkFieldVisibilityByRoles({
        key,
        roles: userRoles,
        isOnCreate: isCreating && isEditing,
        isOnEdit: !isCreating && isEditing,
        isOnRead: !isCreating && !isEditing
      })
    return visibility
  }

  const isFieldDisable = (key: string) => {
    return checkFieldDisability(key, catInLowercase, subCatInLowercase)
  }

  const getCategoryDropDown = () => {
    const filteredCategory = allTaxonomies.filter(({ value: catValue }: any) =>
      isVials
        ? catValue.toLowerCase() === CATEGORY.VIALS
        : catValue.toLowerCase() !== CATEGORY.VIALS
    )
    return filteredCategory.map(({ value }: any) => ({
      name: value,
      value
    }))
  }

  const getFilteredCategory = () => {
    return allTaxonomies.find(
      ({ value: catValue }: any) => catValue.toLowerCase() === catInLowercase
    )
  }

  const getSubCategoryDropDown = () => {
    const filteredCategory = getFilteredCategory()
    const filteredSubcategory = get(filteredCategory, 'children.subcategory', [])
    return filteredSubcategory.map(({ value }: any) => ({
      name: value,
      value
    }))
  }

  const getDropDown = (key: string) => {
    return getDynamicPackDropdownByKey({
      key,
      category: categoryValue,
      subcategory: subCategoryValue,
      taxonomies: allTaxonomies
    })
  }

  const packageFamily = get(data, 'packageFamilies[0]', {})
  const updatePackageFamilyMono = (key: string, value: any) => {
    const newValue = {
      ...packageFamily,
      [key]: value
    }

    if (key === 'clean') {
      newValue['cleanTypes'] = []
    }

    updateData('packageFamilies', [newValue])
  }

  const subCategoryDropDown = getSubCategoryDropDown()

  const isSubCategoryDisable =
    !categoryValue || (!!categoryValue && isFieldDisable('subcategory'))
  const isBottomVisible =
    !isCreating ||
    (isEditing &&
      categoryValue &&
      ((subCategoryDropDown.length && subCategoryValue) ||
        !subCategoryDropDown.length)) ||
    !isEditing ||
    isMulti

  const isCatOthers = catInLowercase === CATEGORY.OTHERS
  const isSizeInput = catInLowercase === CATEGORY.AEROSOL || isCatOthers
  const materialValue = get(packageFamily, 'material', [])
  return (
    <>
      <Text text="General Information" variant="h3" />
      <Divider />
      <BoxWrapper columnCount={4} enablePadding>
        {isCreating && (
          <InfoCell
            label="Status"
            value={get(data, 'status', PACKAGING_STATUS.DRAFT)}
            inputType="select"
            isEditing={isEditing}
            inputConfig={{
              items: statusDropdowns,
              onChange: ({ value }: Record<string, any>) => updateData('status', value),
              orderItems: true,
              orderKey: 'name'
            }}
          />
        )}
        {!isCreating && (
          <InfoCell
            label="PK Intercos Code"
            value={get(data, 'packagingCode', '')}
            inputConfig={{
              onChange: (value: string) => updateData('packagingCode', value)
            }}
          />
        )}
        {!isCreating && isFieldVisible('supplierRef') && (
          <InfoCell
            label="Supplier Pkg Code"
            value={get(data, 'supplierRef', '')}
            isEditing={isEditing}
            inputConfig={{
              onChange: (value: string) => updateData('supplierRef', value)
            }}
          />
        )}
        {!isCreating && isFieldVisible('testedSupplier') && (
          <InfoCell
            label="Packaging Supplier"
            value={get(data, 'testedSupplier.name', '')}
            isEditing={isEditing}
            inputType="select"
            inputConfig={{
              required: true,
              withLookup: true,
              items: testedSupplierDropdowns,
              onChange: ({ value, name, groupName }: Record<string, any>) => {
                updateData('testedSupplier', {
                  ...data.testedSupplier,
                  id: value,
                  name: name,
                  groupName: groupName
                })
              },
              orderItems: true,
              orderKey: 'name'
            }}
          />
        )}
        {!isCreating && (
          <InfoCell
            label="Neutro"
            value={isMulti ? 'MULTIPACK' : get(data, 'packageFamilies[0].neutroName', '')}
            isEditing={false}
          />
        )}
        {!isCreating && isFieldVisible('testedSupplier') && (
          <InfoCell
            label="Group Supplier Name"
            value={get(data, 'testedSupplier.groupName', '')}
            isEditing={isEditing}
            inputType="select"
            inputConfig={{
              items: groupNameDropDownItems,
              onChange: ({ value }: Record<string, any>) => {
                updateData('testedSupplier', {
                  ...data.testedSupplier,
                  groupName: value
                })
              },
              orderItems: true,
              orderKey: 'name'
            }}
          />
        )}
      </BoxWrapper>
      <Divider />
      {!isMulti && (
        <>
          <BoxWrapper columnCount={4} enablePadding>
            {isFieldVisible('category') && (
              <InfoCell
                label="Category"
                value={categoryValue}
                inputType="select"
                isEditing={categoryPreselected ? isEditing && !isVials : isEditing}
                inputConfig={{
                  items: getCategoryDropDown(),
                  onChange: ({ value }: Record<string, any>) =>
                    updateData('category', value),
                  orderItems: true,
                  orderKey: 'name',
                  disabled: !isCreating || (isEditing && !isCreating)
                }}
              />
            )}
            {isFieldVisible('subcategory') && (
              <InfoCell
                label="Subcategory"
                value={subCategoryValue}
                inputType={isCatOthers ? 'input' : 'select'}
                isEditing={isEditing}
                inputConfig={{
                  items: subCategoryDropDown,
                  onChange: (inputValue: Record<string, any>) =>
                    updateData(
                      'subcategory',
                      isCatOthers ? inputValue : inputValue.value
                    ),
                  disabled: isSubCategoryDisable,
                  orderItems: true,
                  orderKey: 'name'
                }}
              />
            )}
          </BoxWrapper>
          <Divider />
        </>
      )}
      {isBottomVisible && (
        <>
          {isCreating && (
            <>
              <BoxWrapper columnCount={4} enablePadding>
                {isFieldVisible('supplierRef') && (
                  <InfoCell
                    label="Supplier Pkg Code"
                    value={get(data, 'supplierRef', '')}
                    isEditing={isEditing}
                    inputConfig={{
                      onChange: (value: string) => updateData('supplierRef', value)
                    }}
                  />
                )}
                {isFieldVisible('testedSupplier') && (
                  <InfoCell
                    label="Packaging Supplier"
                    value={get(data, 'testedSupplier.name', '')}
                    isEditing={isEditing}
                    inputType="select"
                    inputConfig={{
                      required: true,
                      error: !data?.testedSupplier?.name,
                      withLookup: true,
                      items: testedSupplierDropdowns,
                      onChange: ({ value, name, groupName }: Record<string, any>) => {
                        updateData('testedSupplier', {
                          ...data.testedSupplier,
                          id: value,
                          name: name,
                          groupName: groupName
                        })
                      },
                      orderItems: true,
                      orderKey: 'name'
                    }}
                  />
                )}
                {isFieldVisible('testedSupplier') && (
                  <InfoCell
                    label="Group Supplier Name"
                    value={get(data, 'testedSupplier.groupName', '')}
                    isEditing={isEditing}
                    inputType="select"
                    inputConfig={{
                      withLookup: true,
                      items: groupNameDropDownItems,
                      onChange: ({ value }: Record<string, any>) => {
                        dispatch(packagingActions.searchSuppliers(value))
                        return updateData('testedSupplier', {
                          ...data.testedSupplier,
                          groupName: value
                        })
                      },
                      orderItems: true,
                      orderKey: 'name'
                    }}
                  />
                )}
              </BoxWrapper>
              <Divider />
            </>
          )}
          {!isMulti && (
            <>
              <BoxWrapper columnCount={4} enablePadding>
                {isFieldVisible('size') && (
                  <InfoCell
                    label="Size"
                    value={get(packageFamily, 'size', '')}
                    inputType={isSizeInput ? 'input' : 'select'}
                    isEditing={isEditing}
                    inputConfig={{
                      items: getDropDown('size'),
                      onChange: (inputValue: Record<string, any>) =>
                        updatePackageFamilyMono(
                          'size',
                          isSizeInput ? inputValue : inputValue.value
                        ),
                      orderItems: true,
                      orderKey: 'name'
                    }}
                  />
                )}
                {isFieldVisible('material') && (
                  <InfoCell
                    label="Bulk-Contact Material"
                    value={materialValue}
                    inputType="select"
                    isEditing={isEditing}
                    inputConfig={{
                      items: getDropDown('material'),
                      required: isVials,
                      error: isVials && !materialValue.length,
                      onChange: (value: Record<string, any>) =>
                        updatePackageFamilyMono(
                          'material',
                          value.map(({ value }: { value: string }) => value)
                        ),
                      orderItems: true,
                      orderKey: 'name',
                      mode: 'multiple'
                    }}
                  />
                )}
                {isFieldVisible('panNumber') && (
                  <InfoCell
                    label="Pan Number"
                    value={get(packageFamily, 'panNumber', '')}
                    isEditing={isEditing}
                    inputConfig={{
                      onChange: (value: string) =>
                        updatePackageFamilyMono('panNumber', value)
                    }}
                  />
                )}
                {isFieldVisible('tipShape') && (
                  <InfoCell
                    label="Tip Shape"
                    value={get(packageFamily, 'tipShape', '')}
                    inputType="select"
                    isEditing={isEditing}
                    inputConfig={{
                      items: getDropDown('tipShape'),
                      onChange: ({ value }: Record<string, any>) =>
                        updatePackageFamilyMono('tipShape', value),
                      orderItems: true,
                      orderKey: 'name'
                    }}
                  />
                )}

                {isFieldVisible('panShape') && (
                  <InfoCell
                    label="Pan Shape"
                    value={get(packageFamily, 'panShape', isCatOthers ? '' : [])}
                    inputType={isCatOthers ? 'input' : 'select'}
                    isEditing={isEditing}
                    inputConfig={{
                      items: getDropDown('panShape'),
                      onChange: (inputValue: Record<string, any>) =>
                        updatePackageFamilyMono(
                          'panShape',
                          isCatOthers
                            ? inputValue
                            : inputValue.map(({ value }: any) => value)
                        ),
                      mode: 'multiple',
                      orderItems: true,
                      orderKey: 'name'
                    }}
                  />
                )}

                {isFieldVisible('shape') && (
                  <InfoCell
                    label="Ext. Shape"
                    value={get(packageFamily, 'shape', isCatOthers ? '' : [])}
                    inputType={isCatOthers ? 'input' : 'select'}
                    isEditing={isEditing}
                    inputConfig={{
                      items: getDropDown('shape'),
                      onChange: (inputValue: Record<string, any>) =>
                        updatePackageFamilyMono(
                          'shape',
                          isCatOthers
                            ? inputValue
                            : inputValue.map(({ value }: any) => value)
                        ),
                      mode: 'multiple',
                      orderItems: true,
                      orderKey: 'name',
                      disabled: !categoryValue
                    }}
                  />
                )}

                {isFieldVisible('technology') && (
                  <InfoCell
                    label="Technology"
                    value={get(packageFamily, 'technology', isCatOthers ? '' : [])}
                    inputType={isCatOthers ? 'input' : 'select'}
                    isEditing={isEditing}
                    inputConfig={{
                      items: getDropDown('technology'),
                      onChange: (inputValue: Record<string, any>) =>
                        updatePackageFamilyMono(
                          'technology',
                          isCatOthers ? inputValue : inputValue.value
                        ),
                      orderItems: true,
                      orderKey: 'name'
                    }}
                  />
                )}
                {isFieldVisible('capacityRange') && (
                  <InfoCell
                    label="Capacity Range"
                    value={get(packageFamily, 'capacityRange', '')}
                    inputType={isCatOthers ? 'input' : 'select'}
                    isEditing={isEditing}
                    inputConfig={{
                      items: sortItemsRanges(getDropDown('capacityRange')),
                      onChange: (inputValue: Record<string, any>) =>
                        updatePackageFamilyMono(
                          'capacityRange',
                          isCatOthers ? inputValue : inputValue.value
                        ),
                      orderItems: !checkRangeOptions(getDropDown('capacityRange')),
                      orderKey: 'name'
                    }}
                  />
                )}

                {isFieldVisible('panSize') && (
                  <InfoCell
                    label="Pan Size"
                    value={get(packageFamily, 'panSize', isCatOthers ? '' : [])}
                    inputType={isCatOthers ? 'input' : 'select'}
                    isEditing={isEditing}
                    inputConfig={{
                      items: getDropDown('panSize'),
                      onChange: (inputValue: Record<string, any>) =>
                        updatePackageFamilyMono(
                          'panSize',
                          isCatOthers
                            ? inputValue
                            : inputValue.map(({ value }: any) => value)
                        ),
                      mode: 'multiple',
                      orderItems: true,
                      orderKey: 'name'
                    }}
                  />
                )}
              </BoxWrapper>
              <BoxWrapper columnCount={4} enablePadding>
                {isFieldVisible('applicatorAndAccessories') && (
                  <InfoCell
                    label={isVials ? 'Accessories' : 'Applicator and Accessories'}
                    value={get(
                      packageFamily,
                      'applicatorAndAccessories',
                      isCatOthers ? '' : []
                    )}
                    inputType={isCatOthers ? 'input' : 'select'}
                    isEditing={isEditing}
                    inputConfig={{
                      items: getDropDown('applicatorAndAccessories'),
                      onChange: (inputValue: Record<string, any>) =>
                        updatePackageFamilyMono(
                          'applicatorAndAccessories',
                          isCatOthers
                            ? inputValue
                            : inputValue.map(({ value }: any) => value)
                        ),
                      mode: 'multiple',
                      orderItems: true,
                      orderKey: 'name'
                    }}
                  />
                )}

                {isFieldVisible('closure') && (
                  <InfoCell
                    label="Closure"
                    value={get(packageFamily, 'closure', '')}
                    inputType={isCatOthers ? 'input' : 'select'}
                    isEditing={isEditing}
                    inputConfig={{
                      items: getDropDown('closure'),
                      onChange: (inputValue: Record<string, any>) =>
                        updatePackageFamilyMono(
                          'closure',
                          isCatOthers ? inputValue : inputValue.value
                        ),
                      orderItems: true,
                      orderKey: 'name'
                    }}
                  />
                )}

                <InfoCell
                  label="Level"
                  value={get(packageFamily, 'level', '')}
                  inputType="select"
                  isEditing={isEditing}
                  inputConfig={{
                    items: getDropDown('level'),
                    onChange: ({ value }: Record<string, any>) =>
                      updatePackageFamilyMono('level', value),
                    orderItems: true,
                    orderKey: 'name'
                  }}
                />
              </BoxWrapper>
              <BoxWrapper columnCount={4} enablePadding>
                <InfoCell
                  label="Scouting"
                  dataType="options-value"
                  value={get(packageFamily, 'scouting', null)}
                  inputType="options-button"
                  isEditing={isEditing}
                  inputConfig={{
                    options: threeOptions,
                    onChange: (value: string) =>
                      updatePackageFamilyMono('scouting', value)
                  }}
                />
                {isFieldVisible('mirror') && (
                  <InfoCell
                    label="Mirror"
                    value={get(packageFamily, 'mirror', null)}
                    inputType="options-button"
                    dataType="options-value"
                    isEditing={isEditing}
                    inputConfig={{
                      options: threeOptions,
                      onChange: (value: any) => updatePackageFamilyMono('mirror', value)
                    }}
                  />
                )}
                <InfoCell
                  label="Airtight"
                  value={get(packageFamily, 'airtight', '')}
                  inputType="select"
                  isEditing={isEditing}
                  inputConfig={{
                    items: getDropDown('airtight'),
                    onChange: ({ value }: Record<string, any>) =>
                      updatePackageFamilyMono('airtight', value),
                    orderItems: true,
                    orderKey: 'name'
                  }}
                />
              </BoxWrapper>
              <Divider />
            </>
          )}
          <BoxWrapper columnCount={4} enablePadding>
            {isFieldVisible('clean') && (
              <InfoCell
                label="Clean"
                value={get(packageFamily, 'clean', null)}
                inputType="options-button"
                dataType="options-value"
                isEditing={isEditing}
                inputConfig={{
                  options: threeOptions,
                  onChange: (value: any) => updatePackageFamilyMono('clean', value)
                }}
              />
            )}
            {isFieldVisible('cleanTypes') && (
              <InfoCell
                label="Clean Types"
                value={get(packageFamily, 'cleanTypes', [])}
                inputType="select"
                isEditing={isEditing}
                inputConfig={{
                  items: getDropDown('cleanTypes'),
                  onChange: (value: Record<string, any>) =>
                    updatePackageFamilyMono(
                      'cleanTypes',
                      value.map(({ value }: { value: string }) => value)
                    ),
                  disabled: !get(packageFamily, 'clean', ''),
                  orderItems: true,
                  orderKey: 'name',
                  mode: 'multiple'
                }}
              />
            )}
            {isFieldVisible('foodgrade') && (
              <InfoCell
                label="Foodgrade"
                value={get(packageFamily, 'foodgrade', null)}
                inputType="options-button"
                dataType="options-value"
                isEditing={isEditing}
                inputConfig={{
                  options: threeOptions,
                  onChange: (value: any) => updatePackageFamilyMono('foodgrade', value)
                }}
              />
            )}

            {isFieldVisible('isValidNote') && (
              <InfoCell
                label="Note"
                value={get(data, 'isValidNote', '')}
                isEditing={isEditing}
                inputConfig={{
                  onChange: (value: string) => updateData('isValidNote', value)
                }}
              />
            )}
          </BoxWrapper>
          {canViewCreatedBy(userRoles) && (
            <InfoCell
              label="Created by"
              value={get(data, 'createdBy', '')}
              isEditing={false}
              inputConfig={{
                onChange: (value: string) => updateData('createdBy', value)
              }}
            />
          )}
        </>
      )}
    </>
  )
}

export default GeneralInfo
