import { Moment } from 'moment'
import { IError } from '../../containers/Error/types'

export enum PackagingCreationType {
  VIAL = 'vials',
  NO_VIAL = 'noVial',
  MULTI = 'multi'
}

export enum PACKAGE_TYPE {
  MONO = 'mono',
  MULTI = 'multi'
}

export type PackagingCreationFlowType = PACKAGE_TYPE.MONO | PACKAGE_TYPE.MULTI

export enum OTHER_PACKAGES {
  FURTHER = 'furtherVersions',
  RELATED = 'relatedPackages',
  ALTERNATIVE = 'alternativeStandards'
}

export interface IAttachment {
  id: string
  contentTypeId?: string
  contentTypeLabel?: string
  name?: string
  description?: string
  SAPCodes: string[]
  version: number
  date?: Moment | string
  status?: string
  author?: string
  attachmentContentType?: AttachmentContentType
  contentType?: string
  file?: Record<string, any>
  createdAt?: Moment | string
  dataType?: string
}

export type MasterSpecificationCharacteristic = {
  id: string
  itemName: string
  equipment: string
  testingMethod: string
  aql: string
  inspectionLevel: string
  standard: string
  supplierCoa: boolean
  masterHom: boolean
  customizedDev: boolean
  qualityInspection: boolean
  baseSpecificationCharacteristicId: string
  specificationSectionId: string
  masterSpecificationsId: string
  specificationSections: { id: string; name: string; code: string }
}

export type SapCodeConcerned = {
  code: string
  description: string
  sapDescription?: string
  projectSpecificationCharacteristicsId?: string
}

export type PackagingState = {
  items: PackagingDto[]
  itemsByIds: Record<string, any>
  filters: Record<string, any>
  tempFilters: Record<string, any>
  detail?: PackagingDto | {}
  tempDetail?: PackagingDto | {}
  detailVariant?: DetailVariant | {}
  dropDown: Record<string, any>
  taxonomies: Taxonomies | {}
  allTaxonomies: Record<string, any>[]
  pagination: Pagination
  newPackage: Record<string, any>
  newPackagingCode: string
  attachmentsFilters: AttachmentFilter
  tempAttachmentsFilters: AttachmentFilter
  newAttachment?: Record<string, any>
  newVersion:
    | {
        name: string
        description: string
        file: string
      }
    | {}
  relatedProducts?: Record<string, any>[]
  tempAttachment?: Attachments
  variantEdit?: DetailVariant | {}
  isLoading: boolean
  error: IError
  countriesDropdown: Record<string, any>[]
}

export type PackagingClone = {
  id: string
  author?: string
  relatedId?: string
}

export type Pagination = {
  totalPages: number
  page: number
  pageSize: number
  total: number
}

export type PackagingDto = {
  id: string
  type?: PACKAGE_TYPE.MONO | PACKAGE_TYPE.MULTI
  idDbPackage: number
  status?: string
  image?: Record<string, any>
  idSupplier: number
  supplierRef: string
  isValid: boolean
  isValidNote: string
  roomAllocation: null
  expiryDate: null
  name: string
  isDeleted: boolean
  createdAt: string
  createdBy: string
  updatedAt: string
  updatedBy: string
  deletedAt: null
  deletetBy: null
  thumbnailId: string
  packageFamilies: PackageFamilies[]
  thumbnail: Thumbnail
  category: string
  subcategory: string
  airtight: boolean
  attachments: Attachments[]
  furtherVersions: PackagingDto[]
  relatedPackages: PackagingDto[]
  variantCode?: string
  packagingCode?: string
  idPackaging?: string
}

export type VialsDto = {
  id: string
  packageFamilyId: string
  packageFamily: { id: string }
}

type PackageFamilies = {
  id: string
  createdAt: string
  updatedAt: string
  airtight: boolean
  applicatorAndAccessories: string
  capacityRange: string
  category: string
  subcategory: string
  clean: boolean
  closure: string
  filling: string
  USDTargetPrice: null
  foodgrade: boolean
  leadTimeDays: null
  legalWeight: string
  mainMaterial: string
  mirror: boolean
  MOQ: string
  omologato: boolean
  mP54: boolean
  panNumber: null
  panShape: null
  panSize: string
  level: string
  scouting: boolean
  shape: null
  size: string
  idJobExecutionFile: null
  technology: string
  cleanTypes: string
  sideCollectorId: string
  packageVariants: PackageVariants[]
  alternativeStandards: Record<string, any>[]
}

type PackageVariants = {
  id: string
  createdAt: string
  updatedAt: string
  packageFamilyId: string
  attachments: Attachments[]
}

export type AttachmentFilter = {
  name?: string
  description?: string
  SAPCodes?: string
}
export type AttachmentContentType = {
  id: string
  name: string
  type: string
  idDbPackage: number
  isImage: boolean
  sapCodeEnabled: boolean
}

export type Attachments = {
  id: string
  createdAt: string
  updatedAt: string
  packageVariantId: string
  idDbPackage: number
  attachmentContentTypeId: string
  url: string
  name: string
  description: string
  author: string
  version: number
  dataType: string
  attachmentContentType: AttachmentContentType
  otherVersions: Record<string, any>
  SAPCodes: string[]
}

type Thumbnail = {
  id?: string
  idDbPackage?: number
  url?: string
  name?: string
  description?: string
  author?: string
  version?: number
  dataType?: string
  createdAt?: string
  updatedAt?: string
}

export type DetailVariant = {
  id: string
  createdAt: string
  updatedAt: string
  packageFamilyId: string
  vialDetails: VialDetails
  attachments: Attachments[]
  packageFamily: PackageFamilies
  variantCode: string
}

type VialDetails = {
  id: string
  code: string
  dipstick: string
  wiper: string
  applicator: string
  applicatorNumber: string
  variantSupplierCode: string
  createdAt: string
  updatedAt: string
  packageVariantId: string
}

export type PackagingResultSearch = {
  results: PackagingDto[]
  page: number
  pageSize: number
  totalPages: number
  total: number
}

export type Taxonomies = {
  categoryAndSubcategory: CategoryAndSubcategory
  shape: string[]
  capacityRange: string[]
  closure: string[]
  filling: string[]
  panNumber: string[]
  panShape: string[]
  panSize: string[]
  level: string[]
  size: string[]
  technology: string[]
  cleanTypes: string[]
  dipstick: string[]
  wiper: string[]
  applicator: string[]
  applicatorNumber: string[]
}

type CategoryAndSubcategory = {
  Vials: string[]
  Pencils: string[]
  'Jars & Pot': string[]
  'Compact & Palette': string[]
  Stick: string[]
  Squeezable: []
  'Pumps & Droppers': string[]
}

export enum CATEGORY {
  ACCESSORIES = 'accessories',
  AEROSOL = 'aerosol',
  COMPACT_PALETTE = 'compact & palette',
  JARS_POT = 'jars & pot',
  MASK_FABRIC = 'mask fabric',
  MONODOSE = 'monodose',
  NAILS = 'nails',
  PENCILS = 'pencils & markers',
  PUMPS_DROPPERS = 'pumps & droppers',
  SACHET = 'sachet',
  SQUEEZABLE = 'squeezable',
  STICK = 'stick',
  VIALS = 'vials',
  OTHERS = 'others'
}

export enum SUBCATEGORY {
  APPLICATORS = 'applicators',
  PUFF = 'puff',
  SPONGES = 'sponges',
  OTHERS = 'others',
  BACK_INJECTION = 'back injection & side injection',
  CUSHION = 'cushion',
  PRISMA_BAKED = 'prisma & baked (old name prisma & cotto-rilievi)',
  STANDARD = 'standard',
  WET_DRY = 'wet&dry',
  LOOSE_POWDERS = 'loose powders',
  PEN = 'pen',
  CHOPSTICK = 'chopstick',
  DISPENSING_PENS = 'dispensing pens',
  MARKER = 'marker',
  MATIC = 'matic',
  MATITOLLE = 'matitolle',
  SHARPENABLE = 'sharpenable',
  TWIST_UP = 'twist-up',
  AIRLESS_PUMPS = 'airless pumps',
  DIPTUBE_CREAM_PUMP = 'diptube cream pump',
  DIPTUBE_SPRAY_PUMP = 'diptube spray pump',
  DIPTUBES_PUMP = 'diptubes pump',
  PIPETTE_DROPPERS = 'pipette droppers',
  ROLLON = 'rollon',
  SPECIAL_DROPPERS = 'special droppers',
  BOOTLES = 'bootles',
  TOTTLES = 'tottles',
  TUBES = 'tubes',
  FACE_STICK = 'face stick',
  LIPSTICK = 'lipstick',
  STYLO = 'stylo',
  EYELINER = 'eyeliner',
  LIPGLOSS_CONCEALER_EYESHADOW = 'lipgloss & concealer & eyeshadow',
  MASCARA_EYEBROW = 'mascara & eyebrow',
  ONLY_PROPEL = 'only propel'
}
