import { configureStore } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
import administrationReducer from './administration/reducer'
import appReducer from './app/reducer'
import cartReducer from './cart/reducer'
import entityReducer from './entity/reducer'
import formulaschemaReducer from './formulaSchema/reducer'
import masterSpecReducer from './masterSpec/reducer'
import { orderListReducer } from './orderList/reducer'
import packagingReducer from './packaging/reducer'
import productReducer from './product/reducer'
import productsReducer from './products/reducer'
import { rootSaga } from './saga'
import storeReducer from './storeProducts/reducer'
import toolingReducer from './tooling/reducer'
import userReducer from './users/reducer'

const rootReducer = {
  app: appReducer,
  user: userReducer,
  product: productReducer,
  packaging: packagingReducer,
  formulaschema: formulaschemaReducer,
  products: productsReducer,
  administration: administrationReducer,
  store: storeReducer,
  orderList: orderListReducer,
  cart: cartReducer,
  tooling: toolingReducer,
  entity: entityReducer,
  masterSpec: masterSpecReducer
}

const sagaMiddleware = createSagaMiddleware()

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(sagaMiddleware),
  devTools: process.env.NODE_ENV !== 'production'
})

sagaMiddleware.run(rootSaga)

export type RootState = {
  [K in keyof typeof rootReducer]: ReturnType<typeof rootReducer[K]>
}
