import { TablePaginationConfig } from 'antd'
import { IPageData, ISortData } from '../../NewTable/types'

export interface IProductsTable {
  dataList: Record<string, any>[]
  pageData: IPageData
  isLoading: boolean
  enablePagination: boolean
  onChangePage?: (pageData: TablePaginationConfig, sortData?: ISortData) => void
}

export interface WarningsJSON {
  type: STATUS_WARN
  formulaCodeDEVEX?: string
  newFormulaSchemaCode?: string
  changeNotes?: string
}

export enum STATUS_WARN {
  PACKAGE = 'PACKAGE',
  FSCHEMA = 'FORMULA_SCHEMA',
  FORMULA = 'FORMULA',
  FORMULA_FSCHEMA_LINK = 'FORMULA_FS_LINK',
  TOOLING = 'TOOLING'
}
