import { get } from 'lodash'
import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import { footerHeight, headerHeight } from '../../assets/mixins'
import { isSalaCampioni } from '../../auth-msal'
import { SectionTitle } from '../../components'
import { Breadcrumb } from '../../components/Breadcrumb'
import { Loader } from '../../components/Loader'
import { OrderDetailsContent, PageContent } from '../../containers'
import Success from '../../containers/Success'
import { OrderStatus } from '../../features/orderList/models'
import { orderListActions } from '../../features/orderList/reducer'
import {
  selectOrderError,
  selectOrderLoader,
  selectOrderSelected,
  selectOrderSuccess
} from '../../features/orderList/selectors'
import { selectPermissions, selectUserEmail } from '../../features/users/selectors'

import { GenericLayout } from '../../layouts'
import { AppRoutes } from '../constants'
import AcceptModal from './AcceptModal'
import ProcessModal from './ProcessModal'
import ProposeModal from './ProposeModal'
import RejectModal from './RejectModal'
import { ActionsWrapper, TitleWrapper } from './styled'

const OrderDetails: FC = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const successInfo = useSelector(selectOrderSuccess)
  const isLoading = useSelector(selectOrderLoader)
  const orderData = useSelector(selectOrderSelected)
  const userEmail = useSelector(selectUserEmail)
  const userPermissions = useSelector(selectPermissions)
  const orderError = useSelector(selectOrderError)

  const orderStatus = get(orderData, 'status', '')

  const ownerEmail = get(orderData, 'ownerEmail', '')
  const ownerRoles: string = get(orderData, 'ownerRoles', []).filter((x: string) => x)
  useEffect(() => {
    if (id) {
      dispatch(orderListActions.getOrderDetails({ id }))
    }
    return () => {
      dispatch(orderListActions.clearSuccess())
      dispatch(orderListActions.clearError())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isAdmin = userPermissions.is?.admin
  const isUserSalaCampioni = userPermissions.is?.salaCampioni
  const isSalesMarketBu = userPermissions.is?.salesMarketBu

  const isOwnerSalaCampioni = isSalaCampioni(ownerRoles)

  const canAccept = isUserSalaCampioni || isAdmin
  const canReject = isUserSalaCampioni || isAdmin
  const canProcess = isUserSalaCampioni || isAdmin
  const canPropose =
    (isSalesMarketBu && userEmail === ownerEmail) ||
    (isUserSalaCampioni && isOwnerSalaCampioni) ||
    (isAdmin && userEmail === ownerEmail)

  return (
    <GenericLayout error={orderError}>
      <PageContent
        footerHeight={footerHeight}
        headerHeight={headerHeight}
        justifyContent="flex-start"
      >
        {isLoading && <Loader />}
        {successInfo ? (
          <Success
            data={successInfo}
            buttons={[
              {
                label: 'Back to Order list',
                onClick: () => navigate(AppRoutes.STORE_ORDERS),
                variant: 'ghost'
              },
              {
                label: 'Back to home',
                onClick: () => navigate(AppRoutes.HOME),
                iconName: 'HomeOutlined'
              }
            ]}
          />
        ) : (
          <>
            <Breadcrumb
              items={[
                { name: 'ORDER LIST', href: '/store/orders', back: true },
                { name: `ORDER DETAILS ${get(orderData, 'id', '-')}` }
              ]}
            />
            <TitleWrapper>
              <SectionTitle value="Order List" isBold />
              <ActionsWrapper>
                {orderStatus === OrderStatus.REFUSED && canPropose && <ProposeModal />}
                {orderStatus === OrderStatus.FORWARDED && (
                  <>
                    {canReject && <RejectModal />}
                    {canAccept && <AcceptModal />}
                  </>
                )}
                {orderStatus === OrderStatus.ACCEPTED && canProcess && <ProcessModal />}
              </ActionsWrapper>
            </TitleWrapper>
            <OrderDetailsContent />
          </>
        )}
      </PageContent>
    </GenericLayout>
  )
}

export default OrderDetails
