import styled from 'styled-components'
import { footerHeight } from '../../assets/mixins'

export const ProductCardWrapper = styled.div`
  padding: 20px 0px;
`

export const OrderDetailsContainer = styled.div`
  padding-bottom: ${footerHeight}px;
`
