import { FC, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Button, SectionTitle } from '../../../components'
import { Divider } from '../../../components/Divider'
import FooterControl from '../../../components/FooterControl'
import { Upload } from '../../../components/Form/Upload'
import { Text } from '../../../components/Text'
import { Title } from '../../../components/Title'
import { administrationActions } from '../../../features/administration'
import { fileSaver } from '../../../utils/fileSaver'
import { AppRoutes } from '../../constants'

const PriceImport: FC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const baseUrl = process.env.REACT_APP_BASE_URL_IMG_THUMBNAIL
  const [importedPrice, setImportedPrice] = useState(null)

  const downloadHandler = () => {
    fileSaver(`${baseUrl}templates/template_price.xlsx`)
  }

  const updaloadHandler = (value: any) => {
    setImportedPrice(value)
  }

  const backHandler = () => {
    dispatch(administrationActions.clearType())
    navigate(AppRoutes.VAULT_ADMINISTRATION)
  }

  return (
    <>
      <Title title={'Target Price Import'} variantTitle={'h3'} enableBold />
      <Text
        text={'Remember that the package must exist in the system.'}
        variant={'h4'}
        alert
      />
      <Divider disableBorder />
      <SectionTitle
        value={'Use the attached template to import the Target Price'}
        addon={
          <Button
            label={'Download'}
            iconName={'DownloadOutlined'}
            variant={'ghost'}
            onClick={downloadHandler}
          />
        }
      />
      <Divider disableBorder />
      <Upload
        message={'No file selected'}
        label={''}
        required={false}
        description={
          'Drop here or browse the .xlsx file contains the Target Price to import'
        }
        name={'importedPrice'}
        value={importedPrice}
        onChange={updaloadHandler}
      />
      <FooterControl>
        <Button label="Back" onClick={backHandler} />
        <Button
          label="Start Import"
          onClick={() => dispatch(administrationActions.importPrice())}
          disabled={!importedPrice}
        />
      </FooterControl>
    </>
  )
}

export default PriceImport
