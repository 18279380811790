import { isEmpty } from 'lodash'
import { FC } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { InputNumber } from '../../../../components/Form/InputNumber'
import { Loader } from '../../../../components/Loader'
import { Text } from '../../../../components/Text'
import { Error } from '../../../../containers'
import Success from '../../../../containers/Success'
import {
  selectCartError,
  selectCartLoader,
  selectCartSuccess
} from '../../../../features/cart/selectors'
import { AppRoutes } from '../../../constants'
import { IAddCartModalContentProps } from '../types'

const AddCartModalContent: FC<IAddCartModalContentProps> = ({
  onResetError,
  quantity,
  onUpdateQuantity,
  availableQuantity,
  onCloseModal
}) => {
  const navigate = useNavigate()
  const cartLoader = useSelector(selectCartLoader)
  const cartError = useSelector(selectCartError)
  const cartSuccessInfo = useSelector(selectCartSuccess)

  if (cartLoader) return <Loader />

  if (!isEmpty(cartError)) return <Error customBack={onResetError} error={cartError} />

  const goToCart = () => {
    onCloseModal()
    navigate(AppRoutes.STORE_CART)
  }
  const backToResults = () => {
    onCloseModal()
    navigate(-1)
  }

  if (cartSuccessInfo)
    return (
      <Success
        data={cartSuccessInfo}
        buttons={[
          { label: 'Go to cart', onClick: goToCart },
          { label: 'Continue to navigate', onClick: backToResults, variant: 'ghost' }
        ]}
      />
    )

  const warningMessage = 'product no more available'

  return (
    <>
      <Text text="Add to cart" variant="h3" bold />
      <Text text="Set the quantity" />
      <InputNumber
        minNumber={1}
        value={quantity}
        onChange={onUpdateQuantity}
        maxNumber={availableQuantity}
        helperText={quantity > availableQuantity ? warningMessage : ''}
      />
    </>
  )
}

export default AddCartModalContent
