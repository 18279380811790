import { Form } from 'antd'
import { get } from 'lodash'
import { FC } from 'react'
import { useSelector } from 'react-redux'
import { InfoCell } from '../../../components'
import { BoxWrapper } from '../../../components/BoxWrapper'
import { selectAllTaxonomies } from '../../../features/packaging/selectors'
import { IMasterSpecSearchFormProps } from './types'

const MasterSpecSearchForm: FC<IMasterSpecSearchFormProps> = ({ data, onUpdate }) => {
  const allTaxonomies = useSelector(selectAllTaxonomies)

  const catInLowercase = data.category?.toLowerCase() || ''

  const getSubCategoryDropDown = () => {
    const categorySelected = allTaxonomies.find(
      ({ value: catValue }: any) => catValue.toLowerCase() === catInLowercase
    )
    const filteredSubcategory = get(categorySelected, 'children.subcategory', [])
    return filteredSubcategory.map(({ value }: any) => ({
      name: value,
      value
    }))
  }

  const getCategoryDropDown = () => {
    return allTaxonomies.map(({ value }: any) => ({
      name: value,
      value
    }))
  }

  const subCategoryDropdowns = getSubCategoryDropDown()

  return (
    <Form layout="vertical">
      <BoxWrapper sm={2} md={2} lg={3} enablePadding>
        <InfoCell
          label="Description"
          value={data.description || ''}
          inputType="input"
          isEditing
          inputConfig={{
            onChange: (value: any) => onUpdate('description', value)
          }}
        />
        <InfoCell
          label="Category"
          value={data.category || ''}
          inputType="select"
          isEditing
          inputConfig={{
            items: getCategoryDropDown(),
            onChange: ({ value }: { value: string }) => onUpdate('category', value)
          }}
        />
        <InfoCell
          label="Subcategory"
          value={data.subcategory || ''}
          inputType="select"
          isEditing
          inputConfig={{
            items: subCategoryDropdowns,
            onChange: ({ value }: { value: string }) => onUpdate('subcategory', value),
            disabled: !catInLowercase || !subCategoryDropdowns.length
          }}
        />
      </BoxWrapper>
    </Form>
  )
}

export default MasterSpecSearchForm
