import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { get, isArray, keyBy, set, unset } from 'lodash'
import { IError } from '../../containers/Error/types'
import { getCleanError } from '../../utils'
import { ITaxonomies } from '../../utils/taxonomiesHelper'
import { ProductDto } from '../products/model'
import { storeActionsType } from './constants'
import { storeProdFormDropdown, storeProdTypeDropdown } from './constants'
import {
  IStoreProd,
  IStoreProdChild,
  IStoreProdFilters,
  ISuccessInfo,
  OrderableItem,
  StoreProductsState
} from './model'

const initialState: StoreProductsState = {
  items: [],
  new: {},
  newChild: {},
  dropDown: {
    type: storeProdTypeDropdown,
    productForm: storeProdFormDropdown
  },
  itemsByIds: {},
  detail: undefined,
  temp: undefined,
  tempFilters: {},
  filters: {},
  pagination: {
    totalPages: 0,
    page: 1,
    pageSize: 10,
    total: 0
  },
  hasActionsAddon: true,
  isLoading: false,
  error: {}
}

const storeProductsSlice = createSlice({
  name: 'storeProducts',
  initialState,
  reducers: {
    updateNewEntity: (
      state: StoreProductsState,
      { payload }: PayloadAction<{ key: keyof IStoreProd; value: any }>
    ) => {
      const { key, value } = payload
      state.new[key] = value
    },
    updateNewChild: (
      state: StoreProductsState,
      { payload }: PayloadAction<{ key: keyof IStoreProdChild; value: any }>
    ) => {
      const { key, value } = payload
      state.newChild[key] = value
    },
    resetNew: (state: StoreProductsState) => {
      state.new = initialState.new
    },
    resetNewChild: (state: StoreProductsState) => {
      state.newChild = initialState.newChild
    },
    resetPagination: (state) => {
      state.pagination = initialState.pagination
    },
    resetTempFilters: (state) => {
      state.tempFilters = {}
    },
    setPagination: (
      state,
      {
        payload
      }: PayloadAction<{
        page: number
        pageSize?: number
        totalPages?: number
        total?: number
      }>
    ) => {
      state.pagination = { ...state.pagination, ...payload }
    },
    setNewTemp: (state: StoreProductsState) => {
      state.new = {
        ...(state.detail || {}),
        image: { value: state.detail?.thumbnail?.url || '' }
      }
    },
    setNewChildTemp: (state: StoreProductsState) => {
      state.newChild = state.childDetail || {}
    },
    removeActionsAddon: (state: StoreProductsState) => {
      state.hasActionsAddon = false
    },
    setProducts: (
      state: StoreProductsState,
      { payload }: PayloadAction<OrderableItem[]>
    ) => {
      state.items = payload
      state.itemsByIds = keyBy(state.items, 'id')
    },
    setTempFilter: (
      state: StoreProductsState,
      { payload }: PayloadAction<Record<string, any>>
    ) => {
      const { reduxKey, value } = payload
      state.tempFilters = { ...state.tempFilters, [reduxKey]: value }
    },
    updateTempFilter: (
      state: StoreProductsState,
      { payload }: PayloadAction<{ key: keyof IStoreProdFilters; value: any }>
    ) => {
      const { key, value } = payload
      state.tempFilters[key] = value
    },
    setDropdown: (state: StoreProductsState, { payload }: PayloadAction<ITaxonomies>) => {
      const dropdowns = Object.entries(payload).reduce(
        (acc, [key, values]) => ({
          ...acc,
          [key]: values.map((val) => ({ name: val, value: val }))
        }),
        {}
      )
      state.dropDown = {
        ...state.dropDown,
        ...dropdowns
      }
    },
    tempToFilter: (
      state: StoreProductsState,
      { payload }: PayloadAction<Record<string, any>>
    ) => {
      if (get(payload, 'resetPageData', false)) state.pagination = initialState.pagination
      state.filters = state.tempFilters
    },
    filterToTemp: (state: StoreProductsState) => {
      state.tempFilters = state.filters
    },
    setDetail: (state: StoreProductsState, { payload }: PayloadAction<IStoreProd>) => {
      state.detail = payload
    },
    setChildDetail: (
      state: StoreProductsState,
      { payload }: PayloadAction<IStoreProdChild>
    ) => {
      state.childDetail = payload
    },
    updateDetail: (
      state: StoreProductsState,
      { payload }: PayloadAction<{ key: keyof ProductDto; value: any }>
    ) => {
      const { key, value } = payload
      const detailCopy = { ...get(state, 'detail', {}) }
      state.detail = { ...detailCopy, [key]: value }
    },
    removeFilter: (state: StoreProductsState, { payload }: any) => {
      const { path } = payload
      const pathArray = path.split('.')
      const pathKey = pathArray.pop()
      const parentPath = pathArray.toString().replaceAll(',', '.') || ''
      const stateCopy = { ...state }
      const parentValue = get(stateCopy, parentPath)
      if (isArray(parentValue) && pathKey) {
        parentValue.splice(+pathKey, 1)
        set(state, parentPath, parentValue)
      } else {
        unset(state, path)
      }
    },
    changeLoader: (state: StoreProductsState, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload
    },
    setError: (state: StoreProductsState, { payload }: PayloadAction<IError | {}>) => {
      state.error = getCleanError(payload)
    },
    clearError: (state: StoreProductsState) => {
      state.error = initialState.error
    },
    setSuccess: (state: StoreProductsState, { payload }: PayloadAction<ISuccessInfo>) => {
      state.successInfo = payload
    },
    clearSuccess: (state: StoreProductsState) => {
      state.successInfo = initialState.successInfo
    }
  }
})

export const storeActions = {
  ...storeProductsSlice.actions,
  getDetail: createAction<{ id: string }>(storeActionsType.getDetail),
  checkProductAvailabilty: createAction(storeActionsType.checkItemAvailabilty),
  searchStoreProducts: createAction(storeActionsType.search),
  createEntity: createAction(storeActionsType.createEntity),
  updateStoreProd: createAction(storeActionsType.updateEntity),
  updateStoreProdChild: createAction(storeActionsType.updateEntityChild),
  deleteStoreProd: createAction<{ id: string }>(storeActionsType.deleteEntity),
  deleteStoreProdChild: createAction<{ id: string }>(storeActionsType.deleteEntityChild),
  addStoreProdChild: createAction<{ parentId: string; data: IStoreProdChild }>(
    storeActionsType.addEntityChild
  ),
  getChildDetail: createAction<{ id: string }>(storeActionsType.getChild)
}

export default storeProductsSlice.reducer
