import { createSelector } from 'reselect'
import type { RootState } from '../store'

const selectOrderList = (state: RootState) => state.orderList

export const selectSelectedTab = createSelector(
  selectOrderList,
  (state) => state.selectedTab
)

export const selectOrderListFilters = createSelector(
  selectOrderList,
  (state) => state.filters
)

export const selectOrderListItems = createSelector(
  selectOrderList,
  (state) => state.items
)

export const selectOrderListPagination = createSelector(
  selectOrderList,
  (state) => state.pagination
)

export const selectIsTableLoading = createSelector(
  selectOrderList,
  (state) => state.isTableLoading
)

export const selectOrderListDropdown = createSelector(
  selectOrderList,
  (state) => state.dropDown
)

export const getOrderListDropdownByKey = (key: string) =>
  createSelector(selectOrderListDropdown, (dropDown) => dropDown[key] || [])

export const selectOrdersTempFilters = createSelector(
  selectOrderList,
  (state) => state.tempFilters
)

export const selectOrdersFilters = createSelector(
  selectOrderList,
  (state) => state.filters
)

export const selectOrderSelected = createSelector(
  selectOrderList,
  (state) => state.orderSelected
)
export const selectTempOrder = createSelector(selectOrderList, (state) => state.tempOrder)

export const selectLatestForwarded = createSelector(
  selectOrderList,
  (state) => state.latestForwarded
)
export const selectLatestRefused = createSelector(
  selectOrderList,
  (state) => state.latestRefused
)
export const selectLatestProcessed = createSelector(
  selectOrderList,
  (state) => state.latestProcessed
)

export const selectOrderSuccess = createSelector(
  selectOrderList,
  (state) => state.successInfo
)

export const selectOrderLoader = createSelector(
  selectOrderList,
  (state) => state.isLoading
)

export const selectOrderError = createSelector(selectOrderList, (state) => state.error)
