export const TEST_RESULT_DROPDOWN = [
  { value: 'COMPATIBLE', label: 'COMPATIBLE' },
  { value: 'NOT_COMPATIBLE', label: 'NOT COMPATIBLE' },
  { value: 'NOT_EXECUTED', label: 'NOT EXECUTED' },
  { value: 'PK_NOT_APPROPRIATE', label: 'PK NOT APPROPRIATE' },
  { value: 'PK_NOT_COMPLIANT', label: 'PK NOT COMPLIANT' }
]

export const WEIGHT_LOSS_DROPDOWN = [
  { value: 'FAILED', label: 'FAILED' },
  { value: 'NO_VOLATILES', label: 'NO VOLATILES' },
  { value: 'NOT_CONDUCTED', label: 'NOT CONDUCTED' },
  { value: 'PASSED', label: 'PASSED' },
  { value: 'PK_NOT_APPROPRIATE', label: 'PK NOT APPROPRIATE' },
  { value: 'PK_HOMOLOGATED', label: 'PK HOMOLOGATED' }
]
