export const FORMULA_SCHEMA_GET_FORMULA = 'formulaschema/FORMULA_SCHEMA_GET_FORMULA'
export const FORMULA_SCHEMA_GET_DETAIL = 'formulaschema/FORMULA_SCHEMA_GET_DETAIL'
export const FORMULA_SCHEMA_UPDATE = 'formulaschema/FORMULA_SCHEMA_UPDATE'
export const FSCHEMA_CONFIRM_UPDATE_FORMULA =
  'formulaschema/FSCHEMA_CONFIRM_UPDATE_FORMULA'
export const ADD_OTHER_TESTED_PACKAGING = 'formulaschema/ADD_OTHER_TESTED_PACKAGING'
export const PATCH_OTHER_TESTED_PACKAGING = 'formulaschema/PATCH_OTHER_TESTED_PACKAGING'
export const DELETE_OTHER_TESTED_PACKAGING = 'formulaschema/DELETE_OTHER_TESTED_PACKAGING'
export const ADD_TESTED_CLAIM = 'formulaschema/ADD_TESTED_CLAIM'
export const PATCH_TESTED_CLAIM = 'formulaschema/PATCH_TESTED_CLAIM'
export const DELETE_TESTED_CLAIM = 'formulaschema/DELETE_TESTED_CLAIM'
export const GET_PK_DROPDOWN = 'formulaschema/GET_PK_DROPDOWN'
export const GET_TESTED_CLAIM_TAXONOMY = 'formulaschema/GET_TESTED_CLAIM_TAXONOMY'

export const FORMULA_SCHEMA_STATUS_NOT_VISIBLE = [
  'Cancelled',
  'Discontinued',
  'Replaced',
  'Partially Cancelled'
]

export const FORMULA_SCHEMA_STATUS_VISIBLE = [
  'FS Preliminary',
  'FS released',
  'On Creation',
  'FS Preliminary Submitted',
  'Released by R&D',
  'Released to TCS',
  'FS in progress',
  'FS Released Submitted'
]

export const FORMULA_STATUS_NOT_VISIBLE = [
  'Cancelled',
  'Do not produce',
  'Not compliant',
  'Partially Cancelled',
  'Discontinued'
]
