import { Menu } from 'antd'
import styled from 'styled-components'
import { menuAvatarWidth } from '../../../assets/mixins'

export const StyledMenu = styled(Menu)`
  .ant-menu-title-content {
    font-size: 17px;
  }
  .ant-menu {
    width: calc(100% - ${menuAvatarWidth}px);
  }
`
