import styled from 'styled-components'

export const buttonWrapperHeight = 44
export const ButtonWrapper = styled.div`
  height: ${buttonWrapperHeight}px;
  width: 100%;
  padding: 5px 20px;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

export const filterWidth = 65
export const FilterBtnWrapper = styled.div`
  width: ${filterWidth}px;
`

export const exportBtn = 130
export const ExportBtnWrapper = styled.div`
  width: ${exportBtn}px;
`

export const TagsWrapper = styled.div<{ width: string }>`
  width: ${({ width }) => width || 'auto'};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow-x: auto;
  overflow-y: hidden;
  > div {
    overflow-y: unset;
    width: auto;
  }
`
