import { get } from 'lodash'
import { ATTACHMENT_CONTENT_TYPE } from '../features/packaging/constants'
import { Attachments, IAttachment } from '../features/packaging/model'
import { attachmentByRole } from '../features/packaging/utils'
import { IPermissions } from '../features/users/model'

export const filterAttachmentsByRole = (attachments: Attachments[], roles: string[]) => {
  return attachments.filter((attachment: Attachments) => {
    const contentType: ATTACHMENT_CONTENT_TYPE = get(
      attachment,
      'attachmentContentType.name',
      ''
    ).toLowerCase()
    const allowedRoles = attachmentByRole?.[contentType]

    return (
      allowedRoles &&
      roles?.some((currentRole) => {
        return allowedRoles.includes(currentRole)
      })
    )
  })
}

export const filterAttachmentsByPermissions: (args: {
  attachments: IAttachment[]
  section: 'vaultProduct' | 'wikipackVariant'
  permissions: IPermissions
  crud: 'onEdit' | 'onRead' | 'onCreate'
}) => IAttachment[] = ({ attachments, permissions, crud, section }) => {
  return attachments.filter((attachment) => {
    const contentTypeName = attachment.attachmentContentType?.name.toLowerCase() || ''
    const attachmentRules = permissions[section]?.canSeeValue.attachments.find(
      ({ value }) => value === contentTypeName
    )
    if (!attachmentRules) {
      return false
    }

    return attachmentRules[crud]
  })
}

// NOTE - this is temporary solution
export const filterProductAttachments = (attachments: IAttachment[]) => {
  return attachments.filter((attachment) => {
    const attachmentName = get(attachment, 'attachmentContentType.name', '').toLowerCase()
    return attachmentName === 'technical drawing' || attachmentName === 'areadeco'
  })
}

export const orderAttachmentsByContentType: (a: IAttachment[]) => IAttachment[] = (
  attachments = []
) => {
  if (!attachments || !attachments.length) return []
  const orderedAttachments = [...attachments].sort((a, b) => {
    const aContentName = get(a, 'attachmentContentType.name', '')
    const bContentName = get(b, 'attachmentContentType.name', '')
    return aContentName.localeCompare(bContentName)
  })
  return orderedAttachments
}
