import { InfoCircleOutlined } from '@ant-design/icons'
import { Form, DatePicker as DatePickerAnt } from 'antd'
import moment, { Moment } from 'moment'
import { FC } from 'react'
import { inputMinWidth } from '../../../assets/mixins'
import { RDays, IDatePickerProps } from './types'

export const DatePicker: FC<IDatePickerProps> = ({
  onChange,
  size = 'middle',
  value,
  label,
  placeholder,
  required = false,
  error = false,
  disabled,
  onBlur,
  enableTooltip = false,
  message,
  minDays = 0,
  excludeDays = [],
  className,
  isOpen
}) => {
  const calculateExcludeDates = (calendarDate: Moment) => {
    let days = minDays
    let currentDate = moment()
    while (days > 0) {
      currentDate.add(1, 'days')
      const day = currentDate.day()
      const dayToExlude = RDays[day]
      const isToExclude = (excludeDays as string[]).includes(dayToExlude)
      if (!isToExclude) {
        days--
      }
    }
    const calendarDayNum = calendarDate.day()
    const calendarDayString = RDays[calendarDayNum]
    const isToExclude = (excludeDays as string[]).includes(calendarDayString)
    return currentDate > calendarDate || isToExclude
  }

  return (
    <Form.Item
      label={label}
      required={required}
      validateStatus={error ? 'error' : undefined}
      tooltip={enableTooltip && { title: message, icon: <InfoCircleOutlined /> }}
    >
      <DatePickerAnt
        className={className}
        style={{ minWidth: inputMinWidth, width: '100%' }}
        disabled={disabled}
        size={size}
        showToday={false}
        disabledDate={(calendarDate) => {
          if (excludeDays.length) {
            return calculateExcludeDates(calendarDate)
          }
          return moment().add(minDays, 'days') >= calendarDate
        }}
        format={'DD-MM-YYYY'}
        value={value}
        onBlur={onBlur}
        onChange={(date) => onChange(date)}
        placeholder={placeholder}
        open={isOpen}
      />
    </Form.Item>
  )
}
