import { Form } from 'antd'
import { FC } from 'react'
import { useSelector } from 'react-redux'
import { InfoCell, SectionTitle } from '../../../components'
import { BoxWrapper } from '../../../components/BoxWrapper'
import { Divider } from '../../../components/Divider'
import ChildrenTable from '../../../containers/StoreProduct/ChildrenTable'
import {
  applicationAreaDrpDwn,
  collectionDropDown,
  colorDropDown,
  entityProductCatDropdown,
  storeProdTypeToForm
} from '../../../features/storeProducts/constants'
import { IStoreProdChild, IStoreProd } from '../../../features/storeProducts/model'
import { getStoreProdDropdownByKey } from '../../../features/storeProducts/selectors'
import {
  checkFieldValidationByType,
  checkRequiredFieldByType,
  entityFieldDisaleByType,
  entityFieldVisibileByType
} from '../../../utils/entityHelper'
import { IEntityCreationFormProps } from './types'

const EntityCreationForm: FC<IEntityCreationFormProps> = ({ data, onUpdate }) => {
  const prodFormDropdown = useSelector(getStoreProdDropdownByKey('productForm'))
  const filteredProdFormD = prodFormDropdown.filter(
    ({ value }: any) => !Object.values(storeProdTypeToForm).includes(value)
  )
  const firstProductionOriginDropdown = useSelector(getStoreProdDropdownByKey('FPO'))

  const typeValue = data.type!

  const updateHandler = (key: keyof IStoreProd, value: any) => {
    onUpdate(key, value)
  }

  const checkEntityFieldVisibility = (key: keyof IStoreProd) => {
    let isVisible = true
    isVisible = isVisible && entityFieldVisibileByType({ type: typeValue, key })
    return isVisible
  }

  const checkFieldDisability = (key: keyof IStoreProd) => {
    return entityFieldDisaleByType({ type: typeValue, key })
  }

  const children = [...(data.children || [])]

  const addChildHandler = (newChild: IStoreProdChild) => {
    /* the id was added to delete filter from the table before saving the first time
    ids are associated to the children only from BE, so if it's not in edit mode the children are without ids.
    */
    const newChildren = children.concat({
      ...newChild,
      id: Date.now().toString()
    })
    onUpdate('children', newChildren)
  }

  const deleteChildHandler = (childId: string) => {
    const newChildren = children.filter(({ id }) => id !== childId)
    onUpdate('children', newChildren)
  }

  const checkValidationHandler = (key: keyof IStoreProd) => {
    return checkFieldValidationByType({ data, type: typeValue, key })
  }

  const checkRequiredFields = (key: keyof IStoreProd) => {
    return checkRequiredFieldByType({ data, key, type: typeValue })
  }

  return (
    <>
      <Form layout="vertical">
        <SectionTitle value="General Data" />
        <Divider />
        <BoxWrapper enablePadding>
          {checkEntityFieldVisibility('productForm') && (
            <InfoCell
              value={data.productForm || ''}
              label="Product Form"
              inputType="select"
              inputConfig={{
                items: filteredProdFormD,
                onChange: ({ value }: Record<string, any>) =>
                  updateHandler('productForm', value),
                required: checkRequiredFields('productForm'),
                error: checkValidationHandler('productForm'),
                disabled: checkFieldDisability('productForm')
              }}
              isEditing
            />
          )}
          {checkEntityFieldVisibility('groupCode') && (
            <InfoCell
              label="Multipack Code"
              value={data.groupCode || ''}
              inputConfig={{
                onChange: (value: string) => updateHandler('groupCode', value),
                required: checkRequiredFields('groupCode'),
                error: checkValidationHandler('groupCode')
              }}
              isEditing
            />
          )}
          {checkEntityFieldVisibility('color') && (
            <InfoCell
              label="Color"
              value={data.color || ''}
              inputType="select"
              inputConfig={{
                items: colorDropDown,
                onChange: ({ value }: { value: string }) => updateHandler('color', value),
                required: checkRequiredFields('color'),
                error: checkValidationHandler('color')
              }}
              isEditing
            />
          )}
          {checkEntityFieldVisibility('labCode') && (
            <InfoCell
              label="Lab Code"
              value={data.labCode || ''}
              inputConfig={{
                onChange: (value: string) => updateHandler('labCode', value),
                required: checkRequiredFields('labCode'),
                error: checkValidationHandler('labCode')
              }}
              isEditing
            />
          )}
          {checkEntityFieldVisibility('industrialCode') && (
            <InfoCell
              label="Industrial Code"
              value={data.industrialCode || ''}
              inputConfig={{
                onChange: (value: string) => updateHandler('industrialCode', value),
                required: checkRequiredFields('industrialCode'),
                error: checkValidationHandler('industrialCode')
              }}
              isEditing
            />
          )}
          {checkEntityFieldVisibility('finish') && (
            <InfoCell
              label="Finish"
              value={data.finish || ''}
              inputConfig={{
                onChange: (value: string) => updateHandler('finish', value),
                required: checkRequiredFields('finish'),
                error: checkValidationHandler('finish')
              }}
              isEditing
            />
          )}
          {checkEntityFieldVisibility('FPO') && (
            <InfoCell
              label="First Production Origin"
              value={data.FPO || ''}
              inputType="select"
              inputConfig={{
                items: firstProductionOriginDropdown,
                onChange: ({ value }: { value: string }) => updateHandler('FPO', value),
                required: checkRequiredFields('FPO'),
                error: checkValidationHandler('FPO')
              }}
              isEditing
            />
          )}
        </BoxWrapper>
        <Divider disableBorder />
        <SectionTitle value="Detailed Information" />
        <Divider />
        <BoxWrapper enablePadding>
          {checkEntityFieldVisibility('applicationArea') && (
            <InfoCell
              label="Application Area"
              value={data.applicationArea || ''}
              inputType="select"
              inputConfig={{
                items: applicationAreaDrpDwn,
                onChange: ({ value }: { value: string }) =>
                  updateHandler('applicationArea', value),
                required: checkRequiredFields('applicationArea'),
                error: checkValidationHandler('applicationArea')
              }}
              isEditing
            />
          )}
          {checkEntityFieldVisibility('productCategory') && (
            <InfoCell
              label="Product Category"
              value={data.productCategory || ''}
              inputType="select"
              inputConfig={{
                items: entityProductCatDropdown,
                onChange: ({ value }: { value: string }) =>
                  updateHandler('productCategory', value),
                required: checkRequiredFields('productCategory'),
                error: checkValidationHandler('productCategory')
              }}
              isEditing
            />
          )}
          {checkEntityFieldVisibility('finalName') && (
            <InfoCell
              label="Final Name"
              value={data.finalName || ''}
              inputConfig={{
                onChange: (value: string) => updateHandler('finalName', value),
                required: checkRequiredFields('finalName'),
                error: checkValidationHandler('finalName')
              }}
              isEditing
            />
          )}
          {checkEntityFieldVisibility('panMold') && (
            <InfoCell
              label="PAN/MOLD"
              value={data.panMold || ''}
              inputConfig={{
                onChange: (value: string) => updateHandler('panMold', value),
                required: checkRequiredFields('panMold'),
                error: checkValidationHandler('panMold')
              }}
              isEditing
            />
          )}
          {checkEntityFieldVisibility('packagingRef') && (
            <InfoCell
              label="PK INTERCOS CODE"
              value={data.packagingRef || ''}
              inputConfig={{
                onChange: (value: string) => updateHandler('packagingRef', value),
                required: checkRequiredFields('packagingRef'),
                error: checkValidationHandler('packagingRef')
              }}
              isEditing
            />
          )}
          {checkEntityFieldVisibility('pmBU') && (
            <InfoCell
              label="PM BU"
              value={data.pmBU || ''}
              inputConfig={{
                onChange: (value: string) => updateHandler('pmBU', value),
                required: checkRequiredFields('pmBU'),
                error: checkValidationHandler('pmBU')
              }}
              isEditing
            />
          )}
          {checkEntityFieldVisibility('noteBU') && (
            <InfoCell
              label="Note BU"
              value={data.noteBU || ''}
              inputConfig={{
                onChange: (value: string) => updateHandler('noteBU', value),
                required: checkRequiredFields('noteBU'),
                error: checkValidationHandler('noteBU')
              }}
              isEditing
            />
          )}
        </BoxWrapper>
        <Divider disableBorder />
        <SectionTitle value="Formula Schema Data" />
        <Divider />
        <BoxWrapper enablePadding>
          {checkEntityFieldVisibility('formulaSchemaCode') && (
            <InfoCell
              label="Formula Scheme"
              value={data.formulaSchemaCode || ''}
              inputConfig={{
                onChange: (value: string) => updateHandler('formulaSchemaCode', value),
                required: checkRequiredFields('formulaSchemaCode'),
                error: checkValidationHandler('formulaSchemaCode')
              }}
              isEditing
            />
          )}
          {checkEntityFieldVisibility('launchYear') && (
            <InfoCell
              label="Launch Year"
              value={data.launchYear || ''}
              inputConfig={{
                onChange: (value: string) => updateHandler('launchYear', value),
                required: checkRequiredFields('launchYear'),
                error: checkValidationHandler('launchYear')
              }}
              isEditing
            />
          )}
          {checkEntityFieldVisibility('collection') && (
            <InfoCell
              label="Collection"
              value={data.collection || ''}
              inputType="select"
              inputConfig={{
                items: collectionDropDown,
                onChange: ({ value }: { value: string }) =>
                  updateHandler('collection', value),
                required: checkRequiredFields('collection'),
                error: checkValidationHandler('collection')
              }}
              isEditing
            />
          )}
          {checkEntityFieldVisibility('family') && (
            <InfoCell
              label="Family "
              value={data.family || ''}
              inputConfig={{
                onChange: (value: string) => updateHandler('family', value),
                required: checkRequiredFields('family'),
                error: checkValidationHandler('family')
              }}
              isEditing
            />
          )}
        </BoxWrapper>
        <Divider disableBorder />
        <SectionTitle value="Logistic Data" />
        <Divider />
        <BoxWrapper enablePadding>
          {checkEntityFieldVisibility('colorNameOnLabel') && (
            <InfoCell
              label="Color in Label"
              value={data.colorNameOnLabel || ''}
              inputConfig={{
                onChange: (value: string) => updateHandler('colorNameOnLabel', value),
                required: checkRequiredFields('colorNameOnLabel'),
                error: checkValidationHandler('colorNameOnLabel')
              }}
              isEditing
            />
          )}
          {checkEntityFieldVisibility('labCodeInLabel') && (
            <InfoCell
              label="Lab Code in Label"
              value={data.labCodeInLabel || ''}
              inputConfig={{
                onChange: (value: string) => updateHandler('labCodeInLabel', value),
                required: checkRequiredFields('labCodeInLabel'),
                error: checkValidationHandler('labCodeInLabel')
              }}
              isEditing
            />
          )}
          {checkEntityFieldVisibility('shakeWell') && (
            <InfoCell
              label="Shake Well"
              value={data.shakeWell || ''}
              inputConfig={{
                onChange: (value: string) => updateHandler('shakeWell', value),
                required: checkRequiredFields('shakeWell'),
                error: checkValidationHandler('shakeWell')
              }}
              isEditing
            />
          )}
          {checkEntityFieldVisibility('SPF') && (
            <InfoCell
              label="SPF"
              value={data.SPF || ''}
              inputConfig={{
                onChange: (value: string) => updateHandler('SPF', value),
                required: checkRequiredFields('SPF'),
                error: checkValidationHandler('SPF')
              }}
              isEditing
            />
          )}
          {checkEntityFieldVisibility('noteSR') && (
            <InfoCell
              label="Note SR"
              value={data.noteSR || ''}
              isEditing
              inputConfig={{
                onChange: (value: string) => updateHandler('noteSR', value),
                required: checkRequiredFields('noteSR'),
                error: checkValidationHandler('noteSR')
              }}
            />
          )}
        </BoxWrapper>
      </Form>
      {checkEntityFieldVisibility('children') && (
        <>
          <Divider disableBorder />
          <ChildrenTable
            list={data.children || []}
            onAdd={addChildHandler}
            parentType={data.type}
            onDelete={deleteChildHandler}
            isCreating={true}
            hasDelete
            hasAdd
          />
        </>
      )}
    </>
  )
}

export default EntityCreationForm
