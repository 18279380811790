import { DatePicker, Form } from 'antd'
import { FC } from 'react'
import { DATE_FORMAT } from './contants'
import { IRangeDatePickerProps } from './types'

const { RangePicker } = DatePicker

const RangeDatePicker: FC<IRangeDatePickerProps> = ({
  label,
  required,
  onChange,
  dateFormat,
  value
}) => {
  return (
    <Form.Item required={required} label={label}>
      <RangePicker onChange={onChange} format={dateFormat || DATE_FORMAT} value={value} />
    </Form.Item>
  )
}

export default RangeDatePicker
