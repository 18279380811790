import { Form } from 'antd'
import { get } from 'lodash'
import { InfoCell } from '../../../components'
import { BoxWrapper } from '../../../components/BoxWrapper'
import { Divider } from '../../../components/Divider'
import { ICharSearchFilters, ICharSearchFormProps } from './types'

const CharacteristicSearchForm = ({
  charFilters,
  updateHandler,
  readonlyFilter
}: ICharSearchFormProps) => {
  const checkIsEditingField: (key: keyof ICharSearchFilters) => boolean = (key) => {
    return !readonlyFilter || !(key in readonlyFilter)
  }

  return (
    <Form layout="vertical">
      <Divider disableBorder />
      <BoxWrapper columnCount={3} enablePadding>
        <InfoCell
          label="Characteristic Code"
          value={get(charFilters, 'controlCharacteristic', '')}
          isEditing={checkIsEditingField('controlCharacteristic')}
          inputConfig={{
            placeholder: 'Max 250 char',
            onChange: (value: string) => updateHandler('controlCharacteristic', value)
          }}
        />

        <InfoCell
          label="Characteristic Description"
          value={get(charFilters, 'characteristicDescription', '')}
          isEditing={checkIsEditingField('characteristicDescription')}
          inputConfig={{
            onChange: (value: string) => updateHandler('characteristicDescription', value)
          }}
        />
        <InfoCell
          label="Method"
          value={get(charFilters, 'method', '')}
          isEditing={checkIsEditingField('method')}
          inputConfig={{
            onChange: (value: string) => updateHandler('method', value)
          }}
        />
        <InfoCell
          label="Sampling Procedure"
          value={get(charFilters, 'samplingProcedure', '')}
          isEditing={checkIsEditingField('samplingProcedure')}
          inputConfig={{
            placeholder: 'Max 250 char',
            onChange: (value: string) => updateHandler('samplingProcedure', value)
          }}
        />
        <InfoCell
          label="Catalog Code"
          value={get(charFilters, 'catalog', '')}
          isEditing={checkIsEditingField('catalog')}
          inputConfig={{
            onChange: (value: string) => updateHandler('catalog', value)
          }}
        />
        <InfoCell
          label="Catalog Description"
          value={get(charFilters, 'catalogDescription', '')}
          isEditing={checkIsEditingField('catalogDescription')}
          inputConfig={{
            onChange: (value: string) => updateHandler('catalogDescription', value)
          }}
        />
      </BoxWrapper>
    </Form>
  )
}

export default CharacteristicSearchForm
