import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit'
import { IError } from '../../containers/Error/types'
import { getCleanError } from '../../utils'
import { masterSpecActionType } from './constants'
import {
  IFetchMasterSpecListPayload,
  IMasterSpec,
  IMasterSpecDetail,
  IMasterSpecState,
  ISuccessInfo,
  BaseSpecificationCharacteristic,
  MasterSpecCharacteristic,
  IProjectSpecDetail,
  ProjectSpecCharacteristic,
  IApplyAllSapCodeActionPayload,
  IGetDetailPayload
} from './model'

const initialState: IMasterSpecState = {
  items: [],
  projectSpecificationList: [],
  addNewList: [],
  detail: {},
  projectSpecificationDetail: {},
  characteristicList: [],
  newMasterSpecification: {
    title: '',
    supplierName: '',
    supplierItemCode: '',
    masterSpecificationCharacteristics: []
  },
  newProjectSpecification: {},
  newCharacteristic: {},
  newProjectCharacteristic: {},
  pagination: {
    totalPages: 0,
    page: 1,
    pageSize: 10,
    total: 0
  },
  isLoading: false,
  error: {},
  specificationSections: [],
  alert: ''
}

const masterSpecSlice = createSlice({
  name: 'masterSpec',
  initialState: initialState,
  reducers: {
    setList: (state: IMasterSpecState, { payload }: PayloadAction<IMasterSpec[]>) => {
      state.items = payload
    },
    setAddNewList: (
      state: IMasterSpecState,
      { payload }: PayloadAction<IMasterSpec[]>
    ) => {
      state.addNewList = payload
    },
    setProjectSpecificationList: (
      state: IMasterSpecState,
      { payload }: PayloadAction<IMasterSpec[]>
    ) => {
      state.projectSpecificationList = payload
    },
    setDetail: (
      state: IMasterSpecState,
      { payload }: PayloadAction<IMasterSpecDetail>
    ) => {
      state.detail = payload
      state.newMasterSpecification = payload
    },
    setProjectDetail: (
      state: IMasterSpecState,
      { payload }: PayloadAction<IMasterSpecDetail>
    ) => {
      state.projectSpecificationDetail = payload
      state.newProjectSpecification = payload
    },
    setProjectSpecificationDetail: (
      state: IMasterSpecState,
      { payload }: PayloadAction<IMasterSpecDetail>
    ) => {
      state.projectSpecificationDetail = payload
      state.newProjectSpecification = payload
    },
    setCharacteristics: (
      state: IMasterSpecState,
      { payload }: PayloadAction<BaseSpecificationCharacteristic[]>
    ) => {
      state.characteristicList = payload
    },
    setNewCharacteristic: (
      state: IMasterSpecState,
      { payload }: PayloadAction<Partial<MasterSpecCharacteristic>>
    ) => {
      state.newCharacteristic = payload
    },
    setNewProjectCharacteristic: (
      state: IMasterSpecState,
      { payload }: PayloadAction<Partial<ProjectSpecCharacteristic>>
    ) => {
      state.newProjectCharacteristic = payload
    },
    updateNewMasterSpecification: (
      state: IMasterSpecState,
      { payload }: PayloadAction<{ key: keyof IMasterSpecDetail; value: any }>
    ) => {
      const { key, value } = payload
      state.newMasterSpecification[key] = value
    },
    updateNewProjectSpecification: (
      state: IMasterSpecState,
      { payload }: PayloadAction<{ key: keyof IProjectSpecDetail; value: any }>
    ) => {
      const { key, value } = payload
      state.newProjectSpecification[key] = value
    },
    updateNewCharacteristic: (
      state: IMasterSpecState,
      { payload }: PayloadAction<{ key: keyof MasterSpecCharacteristic; value: any }>
    ) => {
      const { key, value } = payload
      state.newCharacteristic[key] = value
    },
    updateNewProjectCharacteristic: (
      state: IMasterSpecState,
      { payload }: PayloadAction<{ key: keyof ProjectSpecCharacteristic; value: any }>
    ) => {
      const { key, value } = payload
      state.newProjectCharacteristic[key] = value
    },
    setError: (state: IMasterSpecState, { payload }: PayloadAction<IError>) => {
      state.error = getCleanError(payload)
    },
    setAlert: (state: IMasterSpecState, { payload }: any) => {
      state.alert = payload
    },
    clearError: (state: IMasterSpecState) => {
      state.error = initialState.error
    },
    setSuccess: (state: IMasterSpecState, { payload }: PayloadAction<ISuccessInfo>) => {
      state.successInfo = payload
    },
    clearSuccess: (state: IMasterSpecState) => {
      state.successInfo = initialState.successInfo
    },
    setLoader: (state: IMasterSpecState, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload
    },
    setSpecificationSections: (state: IMasterSpecState, { payload }: any) => {
      state.specificationSections = payload
    },
    resetNew: (state: IMasterSpecState) => {
      state.newMasterSpecification = initialState.newMasterSpecification
    }
  }
})

export const masterSpecActions = {
  ...masterSpecSlice.actions,
  getList: createAction<IFetchMasterSpecListPayload>(
    masterSpecActionType.MASTER_SPEC_FETCH_ALL
  ),
  getProjectSpecificationList: createAction<IFetchMasterSpecListPayload>(
    masterSpecActionType.PROJECT_SPEC_FETCH_ALL
  ),
  getCharacteristics: createAction(masterSpecActionType.CHARACTERISTICS_FETCH_ALL),
  createMasterSpecification: createAction<IMasterSpecDetail | {}>(
    masterSpecActionType.CREATE_MASTER_SPEC
  ),
  createProjectSpecification: createAction<IMasterSpecDetail | {}>(
    masterSpecActionType.CREATE_PROJECT_SPEC
  ),
  updateMasterSpecification: createAction<Partial<IMasterSpecDetail> | {}>(
    masterSpecActionType.UPDATE_MASTER_SPEC
  ),
  uploadMasterSpecThumbnail: createAction<any>(
    masterSpecActionType.UPLOAD_MSPEC_THUMBNAIL
  ),
  uploadAndUpdateCharacteristic: createAction<any>(
    masterSpecActionType.UPLOAD_UPDATE_CHAR
  ),
  uploadAndUpdateProjectChar: createAction<any>(
    masterSpecActionType.UPLOAD_UPDATE_PROJ_CHAR
  ),
  uploadMSpecCharImage: createAction<any>(masterSpecActionType.UPLOAD_MSPEC_CHAR_IMAGE),
  uploadPSpecCharImage: createAction<any>(masterSpecActionType.UPLOAD_PSPEC_CHAR_IMAGE),
  updateProjectSpecification: createAction<Partial<IProjectSpecDetail> | {}>(
    masterSpecActionType.UPDATE_PROJECT_SPEC
  ),
  updateProjectSpecificationAndSendToSap: createAction<Partial<IProjectSpecDetail> | {}>(
    masterSpecActionType.UPDATE_PROJECT_SPEC_AND_SEND
  ),
  createProjectSpecificationAndSendToSap: createAction<Partial<IProjectSpecDetail> | {}>(
    masterSpecActionType.CREATE_PROJECT_SPEC_AND_SEND
  ),
  checkSapCodes: createAction<{
    data: Partial<IProjectSpecDetail> | {}
    openDuplicateModal: any
    openSendToSapModal: any
    isCreateNew?: boolean
  }>(masterSpecActionType.CHECK_SAP_CODES),
  deleteMasterSpecification: createAction<{ specId: string }>(
    masterSpecActionType.DELETE_MASTER_SPEC
  ),
  deleteProjetSpecification: createAction<{ specId: string }>(
    masterSpecActionType.DELETE_PROJECT_SPEC
  ),
  getDetail: createAction<{ specId: string }>(masterSpecActionType.GET_DETAIL),
  getProjectDetail: createAction<IGetDetailPayload>(
    masterSpecActionType.GET_PROJECT_DETAIL
  ),
  getSpecificationSections: createAction(masterSpecActionType.GET_SPECIFICATION_SECTIONS),
  applyAllSapCodesToCharacters: createAction<IApplyAllSapCodeActionPayload>(
    masterSpecActionType.APPLY_SAP_CODE_TO_ALL_CHARACTER
  ),
  getCharacteriscticAndApplyDefault: createAction(
    masterSpecActionType.GET_CHARACTERISTICS_AND_APPLY_DEFAULT
  )
}

export default masterSpecSlice.reducer
