import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from '../../../../components'
import { Text } from '../../../../components/Text'
import { AppRoutes } from '../../../../pages/constants'
import { StyledEmptyCart } from './styled'

export const EmptyCart = () => {
  const navigate = useNavigate()
  const goToSearch = useCallback(
    () => navigate(AppRoutes.STORE_PRODUCTS_SEARCH),
    [navigate]
  )
  return (
    <StyledEmptyCart>
      <Text text="There are no products in your cart" variant="p" />
      <Button label="Add new one" onClick={goToSearch} variant="ghost" />
    </StyledEmptyCart>
  )
}
