import { get } from 'lodash'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { footerHeight, headerHeight } from '../../assets/mixins'
import { Button, SectionTitle } from '../../components'
import { Loader } from '../../components/Loader'
import { Title } from '../../components/Title'
import { PageContent } from '../../containers'
import { ButtonsWrapper } from '../../containers/Packaging/AddMasterSpecificationModal/styled'
import ProjectSpecificationDetailsContent from '../../containers/ProjectSpecificationDetailsContent'
import { actions } from '../../features/app'
import { masterSpecActions } from '../../features/masterSpec/reducer'
import {
  selectAlertMessage,
  selectCharacteristicList,
  selectNewProjectSpec
} from '../../features/masterSpec/selectors'
import {
  getIsLoading,
  getPackageDetail,
  selectPackagingError
} from '../../features/packaging/selectors'
import { GenericLayout } from '../../layouts'
import { masterSpecStatus } from '../MasterSpecificationDetail/constants'
import { FooterContent, ModalButtonsWrapper } from '../ProjectSpecificationDetail/styled'
import {
  cleanProjectSpec,
  createDuplicatesMessage,
  createSendToSapMessage
} from '../ProjectSpecificationDetail/utils'

export const ProjectSpecificationsEditor = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  // useSelector
  const characteristicList = useSelector(selectCharacteristicList)
  const packagingError = useSelector(selectPackagingError)
  const isLoading = useSelector(getIsLoading)
  const newProjectSpecDetails = useSelector(selectNewProjectSpec)
  const packagingDetails = useSelector(getPackageDetail) as Record<string, any>
  const alertMessage = useSelector(selectAlertMessage)

  // package details variables
  const imageValue = get(packagingDetails, 'image.value', '')
  const supplierName = get(packagingDetails, 'testedSupplier.name', '')
  const supplierItemCode = get(packagingDetails, 'testedSupplier.SAPCodes', '')
  const sideCollectorId = get(packagingDetails, 'id', '')

  // useState
  const [isEditing, setIsEditing] = useState(true)

  // useParams
  const { packageVariantId } = useParams()

  useEffect(() => {
    dispatch(masterSpecActions.getCharacteristics())
    dispatch(masterSpecActions.getSpecificationSections())
    dispatch(
      masterSpecActions.getList({
        filters: { sideCollectorId: sideCollectorId },
        isAddNew: false
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (alertMessage.message && alertMessage.message.length)
      dispatch(
        actions.openPopUp({
          isOpen: true,
          key: 'alertModal',
          title: 'Alert',
          message: get(alertMessage, 'response.data.error', ''),
          btnConfig: {
            btnLabel: 'Close'
          },
          isClosable: false,
          footer: [
            <ModalButtonsWrapper>
              <Button
                key="closeButton"
                label="close"
                onClick={() => {
                  dispatch(actions.closePopUp())
                  dispatch(masterSpecActions.setAlert(''))
                }}
              />
            </ModalButtonsWrapper>
          ]
        })
      )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alertMessage])

  const openDuplicateModal = (result: any, cleanPayload: any) => {
    const message = createDuplicatesMessage(result)
    dispatch(
      actions.openPopUp({
        title: 'Duplicates found',
        key: 'duplicates-found',
        message: message,
        footer: [
          <FooterContent>
            <Button
              key="ignore"
              label="No"
              onClick={() => {
                openSendToSapModal(cleanPayload, false)
              }}
              variant="ghost"
            />
            <Button
              key="overwrite"
              label="Yes"
              onClick={() => {
                openSendToSapModal(cleanPayload, true)
              }}
            />
          </FooterContent>
        ]
      })
    )
  }

  const backHandler = () => {
    navigate(-1)
  }

  const editModeHandler = () => {
    setIsEditing(true)
  }

  const saveAndSendHandler = () => {
    dispatch(
      masterSpecActions.checkSapCodes({
        data: newProjectSpecDetails,
        openDuplicateModal: openDuplicateModal,
        openSendToSapModal: openSendToSapModal
      })
    )
    setIsEditing(false)
  }

  const openSendToSapModal = (cleanPayload: any, overrideDuplicates: boolean) => {
    const sapCodes = get(cleanPayload, 'concernedSapCodes', [])
    dispatch(
      actions.openPopUp({
        isOpen: true,
        key: 'saveSendModal',
        title: 'Save & Send to Sap',
        message: createSendToSapMessage(sapCodes),
        footer: [
          <FooterContent>
            <Button
              key="cancel"
              label="Cancel"
              onClick={() => {
                dispatch(actions.closePopUp())
              }}
              variant="ghost"
            />
            <Button
              key="Confirm"
              label="Confirm"
              onClick={() => {
                dispatch(
                  masterSpecActions.createProjectSpecificationAndSendToSap({
                    data: cleanPayload,
                    associateDuplicatedSapCodes: overrideDuplicates
                  })
                )
                dispatch(actions.closePopUp())
              }}
            />
          </FooterContent>
        ],
        isClosable: true,
        onClose: () => {}
      })
    )
  }

  const saveHandler = () => {
    const tweaked = {
      ...newProjectSpecDetails,
      supplierName: supplierName,
      supplierItemCode: supplierItemCode,
      category: get(packagingDetails, 'category', ''),
      imageValue: imageValue,
      sideCollectorId: sideCollectorId,
      masterSpecificationId: newProjectSpecDetails.id,
      concernedSapCodes: newProjectSpecDetails.concernedSapCodes || []
    }
    const payload = cleanProjectSpec(tweaked, packageVariantId)
    dispatch(
      masterSpecActions.createProjectSpecification({
        data: payload || {},
        callback: backHandler
      })
    )
    // setIsEditing(false)
  }

  const cancelHandler = () => {
    navigate(-1)
  }

  const isSaveDisable =
    !newProjectSpecDetails.sapDescription ||
    newProjectSpecDetails.concernedSapCodes?.some(({ code }) => !code)

  return (
    <GenericLayout error={packagingError}>
      <PageContent
        footerHeight={footerHeight}
        headerHeight={headerHeight}
        justifyContent="flex-start"
      >
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <Title title="Project Specifications Editor" variantTitle="h2" />
            <>
              <SectionTitle
                value={''}
                isBold
                addon={
                  <ButtonsWrapper>
                    {!isEditing ? (
                      <>
                        <Button label="Back" variant="link" onClick={backHandler} />
                        <Button label="Edit" variant="ghost" onClick={editModeHandler} />
                      </>
                    ) : (
                      <>
                        <Button label="Cancel" variant="text" onClick={cancelHandler} />
                        {get(newProjectSpecDetails, 'status', masterSpecStatus.DRAFT) !==
                          masterSpecStatus.SENT_TO_SAP && (
                          <Button
                            label="Save"
                            onClick={saveHandler}
                            disabled={isSaveDisable}
                          />
                        )}
                        {false &&
                          newProjectSpecDetails.status === masterSpecStatus.COMPLETED && (
                            <Button
                              label="Save & Send to Sap"
                              onClick={saveAndSendHandler}
                            />
                          )}
                      </>
                    )}
                  </ButtonsWrapper>
                }
              />
              <ProjectSpecificationDetailsContent
                packageDetails={packagingDetails || {}}
                projectSpecDetails={newProjectSpecDetails}
                isEditing={true}
                characteristicList={characteristicList}
              />
            </>
          </>
        )}
      </PageContent>
    </GenericLayout>
  )
}
