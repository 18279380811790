import styled from 'styled-components'
import { headerHeight, logoWidth } from '../../assets/mixins'

export const StyledImageContainer = styled.div`
  width: ${logoWidth}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  .text {
    display: flex;
    justify-content: center;
    margin: 0;
  }
`
export const StyledImage = styled.img`
  height: ${headerHeight}px;
`
