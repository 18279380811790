import { FC } from 'react'
import { HEX_COLOR, IMAGE_COLOR } from './contants'
import { ColorPreviewContainer, StyledColorPreview, StyledImage } from './styled'
import { IColorPreviewProps, IHexColor, IMageColor } from './types'

export const ColorPreview: FC<IColorPreviewProps> = ({ color }) => {
  if (!color) return null
  const imageColor = IMAGE_COLOR[color as keyof IMageColor]
  const hexColor = HEX_COLOR[color as keyof IHexColor]

  return (
    <ColorPreviewContainer>
      <StyledColorPreview color={hexColor}>
        {imageColor && <StyledImage src={imageColor} alt="colorpreview" />}
      </StyledColorPreview>
    </ColorPreviewContainer>
  )
}
