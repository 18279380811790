import { get } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { OrdersTable } from '../../components'
import { ORDERLIST_STATUS_TABLE_CONFIG } from '../../components/Order/OrdersTable/constants'
import { TagsContainer } from '../../components/TagsContainer'
import { OrderStatus } from '../../features/orderList/models'
import { orderListActions } from '../../features/orderList/reducer'
import {
  selectIsTableLoading,
  selectOrderListDropdown,
  selectOrderListItems,
  selectOrdersFilters,
  selectSelectedTab
} from '../../features/orderList/selectors'
import EmptyBody from './EmptyBody'
import OrderFilterModal from './OrdersModalFilter'
import {
  ContentContainer,
  FilterActionsContainer,
  ModalWrapper,
  TagsContainerWrapper
} from './styled'

const OrderListContent = () => {
  const dispatch = useDispatch()
  const selectedTab = useSelector(selectSelectedTab)
  const orderList = useSelector(selectOrderListItems)
  const dropDownFilter = useSelector(selectOrderListDropdown)
  const isTableLoading = useSelector(selectIsTableLoading)
  const selectedFilters = useSelector(selectOrdersFilters)
  const ownerList = dropDownFilter?.owners?.[selectedTab]

  const changePageHandler = (paginationData: any) => {
    const { current } = paginationData
    dispatch(orderListActions.changePage({ page: current }))
  }

  const isForwarded = selectedTab === OrderStatus.FORWARDED
  const Empty = isForwarded && <EmptyBody />

  return (
    <ContentContainer isForwarded={isForwarded}>
      <FilterActionsContainer>
        <ModalWrapper>
          <OrderFilterModal ownerList={ownerList} />
        </ModalWrapper>
        <TagsContainerWrapper>
          <TagsContainer
            tags={selectedFilters}
            childId={''}
            inner={false}
            reducer={'storeOrders'}
            flowName={'storeOrders'}
            prefix={'storeOrders'}
            allowNoFilters
          />
        </TagsContainerWrapper>
      </FilterActionsContainer>
      <OrdersTable
        dataList={orderList}
        tableConfig={get(ORDERLIST_STATUS_TABLE_CONFIG, selectedTab, {})}
        actionConfig={{
          columnTitle: 'Details',
          detail: {
            btnLabel: 'Order Details'
          }
        }}
        emptyBody={Empty}
        isLoading={isTableLoading}
        onChangePage={changePageHandler}
      />
    </ContentContainer>
  )
}

export default OrderListContent
