import { createSelector } from 'reselect'
import type { RootState } from '../store'
import { IEntityDropdowns } from './model'

const selectEntity = (state: RootState) => state.entity

export const getEntityDropdownByKey = (key: keyof IEntityDropdowns) =>
  createSelector(selectEntity, (entity) => entity.dropDown[key] || [])

export const selectNewEntity = createSelector(selectEntity, (entity) => entity.new)

export const selectEntityLoader = createSelector(
  selectEntity,
  (entity) => entity.isLoading
)
