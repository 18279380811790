import { get } from 'lodash'
import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { footerHeight, headerHeight } from '../../assets/mixins'
import { Button, SectionTitle, TooltipBtn } from '../../components'
import { Divider } from '../../components/Divider'
import { Loader } from '../../components/Loader'
import { Title } from '../../components/Title'
import { PageContent } from '../../containers'
import { AlertModal } from '../../containers/Packaging/AlertModal'
import ProjectSpecificationDetailsContent from '../../containers/ProjectSpecificationDetailsContent'
import { actions } from '../../features/app'
import { masterSpecActions } from '../../features/masterSpec/reducer'
import {
  selectAlertMessage,
  selectCharacteristicList,
  selectIsLoading,
  selectMasterSpecError,
  selectNewProjectSpec,
  selectProjectSpecDetail
} from '../../features/masterSpec/selectors'
import { packagingActions } from '../../features/packaging'
import { PACKAGING_STATUS } from '../../features/packaging/constants'
import { getPackageDetail } from '../../features/packaging/selectors'
import { GenericLayout } from '../../layouts'
import { masterSpecStatus } from '../MasterSpecificationDetail/constants'
import {
  ButtonsWrapper,
  FooterContent,
  ModalButtonsWrapper,
  TextBox,
  WarningIcon,
  WarningWrapper
} from './styled'
import { IMasterSpecificationDetailPropps } from './types'
import {
  cleanProjectSpec,
  createDuplicatesMessage,
  createSendToSapMessage
} from './utils'

const ProjectSpecificationDetail: FC<IMasterSpecificationDetailPropps> = () => {
  // Selector
  const newProjectSpec = useSelector(selectNewProjectSpec)
  const projectSpecDetails = useSelector(selectProjectSpecDetail)
  const packageDetails = useSelector(getPackageDetail)
  const isLoading = useSelector(selectIsLoading)
  const characteristicList = useSelector(selectCharacteristicList)
  const alertMessage = useSelector(selectAlertMessage)

  const [isEditing, setIsEditing] = useState(false)
  const [changes, setChanges] = useState(false)
  const { specId, packageId } = useParams()

  const masterSpecError = useSelector(selectMasterSpecError)

  const packageAvailable =
    get(packageDetails, 'status', '') === PACKAGING_STATUS.AVAILABLE

  const isSentToSap = newProjectSpec.status === masterSpecStatus.SENT_TO_SAP

  const disabledSendToSap =
    isSentToSap &&
    (!changes || newProjectSpec.concernedSapCodes?.some(({ code }) => !code))

  const toggleChanges = (toggle: boolean) => {
    setChanges(toggle)
  }

  const navigate = useNavigate()

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(masterSpecActions.getSpecificationSections())
    dispatch(masterSpecActions.getProjectDetail({ specId: specId || '' }))
    dispatch(masterSpecActions.getCharacteristics())
    dispatch(masterSpecActions.clearError())
    dispatch(masterSpecActions.clearSuccess())
    packageId &&
      dispatch(packagingActions.getPackagingDetail({ id: packageId, hasLoader: false }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    dispatch(masterSpecActions.getProjectDetail({ specId: specId || '' }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing])

  useEffect(() => {
    if (alertMessage.message && alertMessage.message.length)
      dispatch(
        actions.openPopUp({
          isOpen: true,
          key: 'alertModal',
          title: 'Alert',
          message: get(alertMessage, 'response.data.error', ''),
          btnConfig: {
            btnLabel: 'Close'
          },
          isClosable: false,
          footer: [
            <ModalButtonsWrapper>
              <Button
                key="closeButton"
                label="close"
                onClick={() => {
                  dispatch(actions.closePopUp())
                  dispatch(masterSpecActions.setAlert(''))
                }}
              />
            </ModalButtonsWrapper>
          ]
        })
      )
    dispatch(masterSpecActions.setAlert(''))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alertMessage])

  const data = isEditing ? newProjectSpec : projectSpecDetails

  const editModeHandler = () => {
    setIsEditing(true)
  }
  const cancelHandler = () => {
    setChanges(false)
    setIsEditing(false)
  }
  const deleteHandler = () => {
    dispatch(masterSpecActions.deleteProjetSpecification({ specId: specId || '' }))
    navigate(-1)
  }

  const saveHandler = () => {
    const payload = cleanProjectSpec(data)
    dispatch(masterSpecActions.updateProjectSpecification(payload || {}))
    setIsEditing(false)
  }

  const openDuplicateModal = (result: any, cleanPayload: any) => {
    const message = createDuplicatesMessage(result)
    dispatch(
      actions.openPopUp({
        title: 'Duplicates found',
        key: 'duplicates-found',
        message: message,
        footer: [
          <FooterContent>
            <Button
              key="ignore"
              label="No"
              onClick={() => {
                openSendToSapModal(cleanPayload, false)
              }}
              variant="ghost"
            />
            <Button
              key="overwrite"
              label="Yes"
              onClick={() => {
                openSendToSapModal(cleanPayload, true)
              }}
            />
          </FooterContent>
        ]
      })
    )
  }

  const saveAndSendHandler = () => {
    dispatch(
      masterSpecActions.checkSapCodes({
        data: data,
        openDuplicateModal: openDuplicateModal,
        openSendToSapModal: openSendToSapModal
      })
    )
    setChanges(false)
    setIsEditing(false)
  }

  const openSendToSapModal = (cleanPayload: any, overrideDuplicates: boolean) => {
    const sapCodes = get(cleanPayload, 'concernedSapCodes', [])
    dispatch(
      actions.openPopUp({
        isOpen: true,
        key: 'saveSendModal',
        title: 'Save & Send to Sap',
        message: createSendToSapMessage(sapCodes),
        footer: [
          <FooterContent>
            <Button
              key="cancel"
              label="Cancel"
              onClick={() => {
                dispatch(actions.closePopUp())
              }}
              variant="ghost"
            />
            <Button
              key="Confirm"
              label="Confirm"
              onClick={() => {
                dispatch(
                  masterSpecActions.updateProjectSpecificationAndSendToSap({
                    data: cleanPayload,
                    associateDuplicatedSapCodes: overrideDuplicates
                  })
                )
                dispatch(actions.closePopUp())
              }}
            />
          </FooterContent>
        ],
        isClosable: true,
        onClose: () => {}
      })
    )
  }

  const isSaveDisable =
    !get(data, 'sapDescription') ||
    get(data, 'concernedSapCodes', []).some(({ code }: any) => !code)

  return (
    <GenericLayout error={masterSpecError}>
      <PageContent
        footerHeight={footerHeight}
        headerHeight={headerHeight}
        justifyContent="flex-start"
      >
        <AlertModal alertMessage={alertMessage} />
        <Title title="Project Specification Details" variantTitle="h3" />
        <Divider disableBorder />
        <SectionTitle
          value={''}
          isBold
          addon={
            packageAvailable && (
              <ButtonsWrapper>
                {!isSentToSap && (
                  <TooltipBtn
                    tooltipLabel="Delete"
                    btnLabel="Delete"
                    iconPosition="left"
                    hasIcon
                    iconName="DeleteOutlined"
                    message="Do you want to delete this Project Specification?"
                    onConfirm={() => {
                      deleteHandler()
                    }}
                    btnVariant="link"
                  />
                )}
                {!isEditing ? (
                  <Button label="Edit" variant="ghost" onClick={editModeHandler} />
                ) : (
                  <>
                    <Button label="Cancel" variant="text" onClick={cancelHandler} />
                    {get(data, 'status', masterSpecStatus.DRAFT) !==
                      masterSpecStatus.SENT_TO_SAP && (
                      <Button
                        label="Save"
                        onClick={saveHandler}
                        disabled={isSaveDisable}
                      />
                    )}
                    {get(data, 'status', masterSpecStatus.DRAFT) !==
                      masterSpecStatus.DRAFT && (
                      <Button
                        disabled={disabledSendToSap}
                        label="Save & Send to Sap"
                        onClick={() => {
                          saveAndSendHandler()
                        }}
                      />
                    )}
                  </>
                )}
              </ButtonsWrapper>
            )
          }
        />
        {newProjectSpec.status === masterSpecStatus.SENT_TO_SAP && !disabledSendToSap && (
          <TextBox>
            <WarningWrapper>
              <WarningIcon />
              Please be informed that by saving the changes on this Project Specification
              the
              <br />
              Control Plan will be automatically sent to SAP QM
              <br />
              <WarningIcon />
              Please remember to specify the limit value before sending to SAP
            </WarningWrapper>
          </TextBox>
        )}
        {isLoading ? (
          <Loader />
        ) : (
          <ProjectSpecificationDetailsContent
            packageDetails={packageDetails || {}}
            projectSpecDetails={data}
            isEditing={isEditing}
            characteristicList={characteristicList}
            toggleChanges={toggleChanges}
          />
        )}
      </PageContent>
    </GenericLayout>
  )
}

export default ProjectSpecificationDetail
