export const inputMinWidth = 170
export const logoWidth = 160
export const headerHeight = 75
export const footerHeight = 45
export const menuAvatarWidth = 40
export const sectionTitleHeight = 30
export const footerControlHeight = 44
export const footerActionHeight = 45

export const smallSizeWidth = 600
export const mediumSizeWidth = 900
export const largeSizeWidth = 1200
