import { InfoCircleOutlined } from '@ant-design/icons'
import { Tooltip } from '../Tooltip'
import { MarginSpan } from './styled'

type Props = {
  title: string
  hasMargin?: boolean
}

const InfoTooltip = (props: Props) => {
  const { title, hasMargin = true } = props
  return (
    <Tooltip title={title}>
      <MarginSpan hasMargin={hasMargin}>
        <InfoCircleOutlined />
      </MarginSpan>
    </Tooltip>
  )
}

export default InfoTooltip
