import { IProdStatus } from './model'

export const PRODUCT_DELETE_FSCHEMA = 'product/deleteSchemaSaga'
export const PRODUCT_ADD_TOOLING = 'product/addTooling'
export const PRODUCT_SELECT_FSCHEMA = 'product/PRODUCT_SELECT_FSCHEMA'
export const PRODUCT_CREATE = 'product/PRODUCT_CREATE'
export const PRODUCT_FILTER_TAXONOMIES = 'product/PRODUCT_FILTER_TAXONOMIES'

export const PACKAGING_ID_TOOLING = '6a0be6a4-98f8-4527-b068-a71c20e07308'

export const fillingAvailableInDropdown = [
  { name: 'ITALY', value: 'ITALY' },
  { name: 'CHINA', value: 'CHINA' },
  { name: 'KOREA', value: 'KOREA' },
  {
    name: 'ITALY | USA | CHINA',
    value: 'ITALY | USA | CHINA'
  },
  { name: 'ITALY | USA', value: 'ITALY | USA' },
  { name: 'ITALY | CHINA', value: 'ITALY | CHINA' },
  { name: 'ITALY | BRAZIL', value: 'ITALY | BRAZIL' },
  {
    name: 'ITALY | USA | BRAZIL | CHINA',
    value: 'ITALY | USA | BRAZIL | CHINA'
  },
  {
    name: 'ITALY | USA | BRAZIL',
    value: 'ITALY | USA | BRAZIL'
  },
  {
    name: 'ITALY | BRAZIL | CHINA',
    value: 'ITALY | BRAZIL | CHINA'
  },
  { name: 'ITALY | CHINA | KOREA', value: 'ITALY | CHINA | KOREA' },
  { name: 'ITALY | CHINA | USA', value: 'ITALY | CHINA | USA' },
  { name: 'ITALY | KOREA', value: 'ITALY | KOREA' },
  {
    name: 'ITALY | CHINA | USA | KOREA',
    value: 'ITALY | CHINA | USA | KOREA'
  },
  { name: 'USA', value: 'USA' },

  { name: 'BRAZIL', value: 'BRAZIL' },
  { name: 'BRAZIL | USA', value: 'BRAZIL | USA' },
  { name: 'BRAZIL | KOREA', value: 'BRAZIL | KOREA' },
  { name: 'BRAZIL | CHINA', value: 'BRAZIL | CHINA' },
  { name: 'USA | KOREA', value: 'USA | KOREA' },
  { name: 'USA | CHINA', value: 'USA | CHINA' },
  { name: 'KOREA | CHINA', value: 'KOREA | CHINA' },
  { name: 'ITALY | BRAZIL | KOREA', value: 'ITALY | BRAZIL | KOREA' },
  { name: 'ITALY | USA | KOREA', value: 'ITALY | USA | KOREA' },
  { name: 'BRAZIL | USA | KOREA', value: 'BRAZIL | USA | KOREA' },
  { name: 'BRAZIL | USA | CHINA', value: 'BRAZIL | USA | CHINA' },
  { name: 'BRAZIL | KOREA | CHINA', value: 'BRAZIL | KOREA | CHINA' },
  { name: 'USA | KOREA | CHINA', value: 'USA | KOREA | CHINA' },
  { name: 'ITALY | BRAZIL | USA | KOREA', value: 'ITALY | BRAZIL | USA | KOREA' },
  { name: 'ITALY | BRAZIL | KOREA | CHINA', value: 'ITALY | BRAZIL | KOREA | CHINA' },
  { name: 'BRAZIL | USA | KOREA | CHINA', value: 'BRAZIL | USA | KOREA | CHINA' },
  {
    name: 'ITALY | BRAZIL | USA | KOREA | CHINA',
    value: 'ITALY | BRAZIL | USA | KOREA | CHINA'
  }
]

export const businessSegmentDropdown = [
  { name: 'FOUNDATIONS', value: 'FOUNDATIONS' },
  { name: 'LIPSTICKS', value: 'LIPSTICKS' },
  { name: 'MASCARA & DELIVERYSYSTEMS', value: 'MASCARA & DELIVERYSYSTEMS' },
  { name: 'NAIL', value: 'NAIL' },
  { name: 'PENCILS & MARKERS', value: 'PENCILS & MARKERS' },
  { name: 'POWDERS', value: 'POWDERS' },
  { name: 'WET POWDERS', value: 'WET POWDERS' }
]

export const applicationAreaDropdown = [
  { name: 'EYES', value: 'EYES' },
  { name: 'FACE', value: 'FACE' },
  { name: 'HAIR', value: 'HAIR' },
  { name: 'LIPS', value: 'LIPS' },
  { name: 'NAIL', value: 'NAIL' },
  { name: 'KIT', value: 'KIT' }
]

export const productCategoryDropdown = [
  { name: 'ALLOVER', value: 'ALLOVER' },
  { name: 'BALM', value: 'BALM' },
  { name: 'BLUSH', value: 'BLUSH' },
  { name: 'BRONZER', value: 'BRONZER' },
  { name: 'CONCEALER', value: 'CONCEALER' },
  { name: 'EYEBROW', value: 'EYEBROW' },
  { name: 'EYELINER', value: 'EYELINER' },
  { name: 'EYESHADOW', value: 'EYESHADOW' },
  { name: 'FACE MIST', value: 'FACE MIST' },
  { name: 'FOUNDATION', value: 'FOUNDATION' },
  { name: 'GLOSS', value: 'GLOSS' },
  { name: 'HIGHLIGHTER', value: 'HIGHLIGHTER' },
  { name: 'KAJAL', value: 'KAJAL' },
  { name: 'LIPLINER', value: 'LIPLINER' },
  { name: 'LIPSTICK', value: 'LIPSTICK' },
  { name: 'MAKE-UP REMOVER', value: 'MAKE-UP REMOVER' },
  { name: 'MASCARA', value: 'MASCARA' },
  { name: 'NAIL', value: 'NAIL' },
  { name: 'NAIL CARE', value: 'NAIL CARE' },
  { name: 'NAIL LACQUER CLEARS', value: 'NAIL LACQUER CLEARS' },
  { name: 'NAIL LACQUER REMOVER', value: 'NAIL LACQUER REMOVER' },
  { name: 'POWDER FDT', value: 'POWDER FDT' },
  {
    name: 'POWDER FDT (WET AND DRY)',
    value: 'POWDER FDT (WET AND DRY)'
  },
  { name: 'PRESSED POWDER', value: 'PRESSED POWDER' },
  { name: 'PRIMER', value: 'PRIMER' },
  { name: 'SKIN TINT / BB', value: 'SKIN TINT / BB' }
]

export const productFormDropdown = [
  { name: 'AQUEOUS GEL', value: 'AQUEOUS GEL' },
  { name: 'BAKED', value: 'BAKED' },
  { name: 'BAKED EXTRUDED', value: 'BAKED EXTRUDED' },
  { name: 'COMPACT POWDER', value: 'COMPACT POWDER' },
  { name: 'EMULSION', value: 'EMULSION' },
  { name: 'EXTRUDED', value: 'EXTRUDED' },
  {
    name: 'FLUID ANHYDROUS',
    value: 'FLUID ANHYDROUS'
  },
  { name: 'INK', value: 'INK' },
  { name: 'LOOSE', value: 'LOOSE' },
  { name: 'MATRIX POWDER', value: 'MATRIX POWDER' },
  { name: 'NAIL LACQUER', value: 'NAIL LACQUER' },
  { name: 'OTHER FORMS', value: 'OTHER FORMS' },
  {
    name: 'POURED ANHYDROUS',
    value: 'POURED ANHYDROUS'
  },
  { name: 'PRISMA', value: 'PRISMA' },
  {
    name: 'SLURRY INJECTION',
    value: 'SLURRY INJECTION'
  },
  { name: 'SOLID EMULSION', value: 'SOLID EMULSION' },

  { name: 'SOLID GEL', value: 'SOLID GEL' }
]

export const fillingMethodCycleDropdown = [
  {
    name: 'AUTOMATIC FILLING',
    value: 'AUTOMATIC FILLINGg'
  },
  { name: 'MANUAL FILLING', value: 'MANUAL FILLING' },
  {
    name: 'MANUAL | AUTOMATIC FILLING',
    value: 'MANUAL | AUTOMATIC FILLING'
  },
  { name: 'SOFT MOLD', value: 'SOFT MOLD' },
  { name: 'METAL MOLD', value: 'METAL MOLD' },
  {
    name: 'SOFT MOLD | METAL MOLD',
    value: 'SOFT MOLD | METAL MOLD'
  },
  {
    name: 'VOLATILE SOFT MOLD',
    value: 'VOLATILE SOFT MOLD'
  }
]

export const internalLastingPositionDropdown = [
  { name: '<6h', value: '<6h' },
  { name: '6-8h', value: '6-8h' },
  { name: '8-12h', value: '8-12h' },
  { name: '12-16h', value: '12-16h' },
  { name: '16-24h', value: '16-24h' },
  { name: '24-36h', value: '24-36h' },
  { name: '>36h', value: '>36h' }
]

export const formulaTransferToDropdown = [
  { name: 'CHINA', value: 'CHINA' },
  { name: 'ITALY', value: 'ITALY' },
  {
    name: 'ITALY | BRAZIL | CHINA',
    value: 'ITALY | BRAZIL | CHINA'
  },
  { name: 'ITALY | BRAZIL', value: 'ITALY | BRAZIL' },
  { name: 'ITALY | CHINA', value: 'ITALY | CHINA' },
  { name: 'ITALY | CHINA | KOREA', value: 'ITALY | CHINA | KOREA' },
  { name: 'ITALY | CHINA | USA', value: 'ITALY | CHINA | USA' },
  {
    name: 'ITALY | CHINA | USA | KOREA',
    value: 'ITALY | CHINA | USA | KOREA'
  },
  { name: 'ITALY | KOREA', value: 'ITALY | KOREA' },
  { name: 'ITALY | USA', value: 'ITALY | USA' },
  {
    name: 'ITALY | USA | BRAZIL',
    value: 'ITALY | USA | BRAZIL'
  },
  {
    name: 'ITALY | USA | BRAZIL | CHINA',
    value: 'ITALY | USA | BRAZIL | CHINA'
  },
  {
    name: 'ITALY | USA | CHINA',
    value: 'ITALY | USA | CHINA'
  },
  { name: 'KOREA', value: 'KOREA' },
  { name: 'USA', value: 'USA' },
  { name: 'NON-TRASFERABLE', value: 'NON-TRASFERABLE' },
  { name: 'NOT TRANSFERRED YET', value: 'NOT TRANSFERRED YET' },
  { name: 'BRAZIL', value: 'BRAZIL' },
  { name: 'BRAZIL | USA', value: 'BRAZIL | USA' },
  { name: 'BRAZIL | KOREA', value: 'BRAZIL | KOREA' },
  { name: 'BRAZIL | CHINA', value: 'BRAZIL | CHINA' },
  { name: 'USA | KOREA', value: 'USA | KOREA' },
  { name: 'USA | CHINA', value: 'USA | CHINA' },
  { name: 'KOREA | CHINA', value: 'KOREA | CHINA' },
  { name: 'ITALY | BRAZIL | KOREA', value: 'ITALY | BRAZIL | KOREA' },
  { name: 'ITALY | USA | KOREA', value: 'ITALY | USA | KOREA' },
  { name: 'BRAZIL | USA | KOREA', value: 'BRAZIL | USA | KOREA' },
  { name: 'BRAZIL | USA | CHINA', value: 'BRAZIL | USA | CHINA' },
  { name: 'BRAZIL | KOREA | CHINA', value: 'BRAZIL | KOREA | CHINA' },
  { name: 'USA | KOREA | CHINA', value: 'USA | KOREA | CHINA' },
  { name: 'ITALY | BRAZIL | USA | KOREA', value: 'ITALY | BRAZIL | USA | KOREA' },
  { name: 'ITALY | BRAZIL | KOREA | CHINA', value: 'ITALY | BRAZIL | KOREA | CHINA' },
  { name: 'BRAZIL | USA | KOREA | CHINA', value: 'BRAZIL | USA | KOREA | CHINA' },
  {
    name: 'ITALY | BRAZIL | USA | KOREA | CHINA',
    value: 'ITALY | BRAZIL | USA | KOREA | CHINA'
  }
]

export const intercosInsidePositioningDropdown = [
  { name: 'PRESTIGE', value: 'prestige' },
  { name: 'MASS MARKET', value: 'Mass Market' },
  { name: 'MASSTIGE', value: 'masstige' },
  { name: 'ALL SEGMENTS', value: 'All Segmentss' }
]

export const mascaraPositioningDropdown = [
  { name: 'CURL', value: 'curl' },
  { name: 'LENGHT', value: 'lenght' },
  { name: 'VOLUME', value: 'volume' },
  { name: 'VOLUME++ ACC', value: 'volume++ acc' }
]

export const firstProductOriginDropdown = [
  { name: 'CHINA', value: 'CHINA' },
  { name: 'EUROPE', value: 'EUROPE' },
  { name: 'KOREA', value: 'KOREA' },
  { name: 'USA', value: 'USA' }
]

export const DYNAMIC_TAXONOMIES = [
  'status',
  'businessUnit',
  'businessSegment',
  'applicationArea',
  'productCategory',
  'productForm',
  'format',
  'packageType'
]

export const BUSINESS_UNITS = [
  { name: 'MAKEUP', value: 'MAKEUP' },
  { name: 'SKINCARE', value: 'SKINCARE' }
]

export const productStatusDropdown: IProdStatus[] = [
  {
    name: 'READY TO GO',
    value: 'READY_TO_GO',
    infoMessage: 'Sellable as-is by Sales and Marketing Teams'
  },
  {
    name: 'LEVEL 2 IN PROGRESS',
    value: 'LEVEL_2_IN_PROGRESS',
    infoMessage: 'Product not RTG as testing process not completed'
  },
  {
    name: 'ARCHIVE',
    value: 'ARCHIVE',
    infoMessage:
      'RTG products that must not be promoted by Sales and Marketing Teams as obsolete'
  },
  {
    name: 'CLIENT EXCLUSIVE',
    value: 'CLIENT_EXCLUSIVE',
    infoMessage: 'Products that have been granted in exclusive to a client'
  },
  {
    name: 'NOT STABLE',
    value: 'NOT_STABLE',
    infoMessage: 'Products that have not passed stability tests'
  },
  {
    name: 'NOT COMPATIBLE OR INDUSTRIALIZABLE',
    value: 'NOT_COMPATIBLE_OR_INDUSTRIALIZABLE',
    infoMessage:
      'Products that do not report stability issues but have not passed \n compatibility tests or industrialization phase'
  },
  {
    name: 'WIP INFORMATION',
    value: 'WIP_INFORMATION',
    infoMessage: 'Products sheet with missing information as still under BU creation'
  }
]

export const productStatusDropdownValues: Record<string, string> = {
  READY_TO_GO: 'READY_TO_GO',
  LEVEL_2_IN_PROGRESS: 'LEVEL_2_IN_PROGRESS',
  ARCHIVE: 'ARCHIVE',
  CLIENT_EXCLUSIVE: 'CLIENT_EXCLUSIVE',
  NOT_STABLE: 'NOT_STABLE',
  NOT_COMPATIBLE_OR_INDUSTRIALIZABLE: 'NOT_COMPATIBLE_OR_INDUSTRIALIZABLE',
  WIP_INFORMATION: 'WIP_INFORMATION'
}
export interface IMappedDropdown {
  name: string
  value: string
}
export interface IMappedDropdowns {
  packageType: IMappedDropdown[]
  status: IMappedDropdown[]
}

export const MAPPED_DROPDOWN: IMappedDropdowns = {
  packageType: [
    {
      name: 'Standard',
      value: 'mono'
    },
    {
      name: 'Multipackage',
      value: 'multi'
    }
  ],
  status: productStatusDropdown
}

export const INNOVATION_LEVEL_DROPDOWN: IMappedDropdown[] = [
  { name: 'A', value: 'A' },
  { name: 'AA', value: 'AA' },
  { name: 'AAA', value: 'AAA' }
]

export const LEVEL_DROPDOWN: IMappedDropdown[] = [
  { name: '1', value: '1' },
  { name: '2', value: '2' },
  { name: '3', value: '3' }
]
