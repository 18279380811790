import { get } from 'lodash'
import { FC } from 'react'
import { Divider } from '../../../components/Divider'
import { RelationshipsTable } from '../../../components/Packaging'
import AddRelationModal from '../../../components/Packaging/RelationshipsTable/AddRelationModal'
import { IRelationshipsProps } from '../../../components/Packaging/RelationshipsTable/types'
import { Text } from '../../../components/Text'
import { packagingActions } from '../../../features/packaging'
import { OTHER_PACKAGES } from '../../../features/packaging/model'
import { TitleWrapper } from '../../../pages/CreationPackage/styled'
import { EmptyWrapper } from './styled'

const Relationships: FC<IRelationshipsProps> = ({ isEditing = false, data }) => {
  const furtherVersionList: Record<string, any>[] = get(data, OTHER_PACKAGES.FURTHER, [])
  const relatedList: Record<string, any>[] = get(data, OTHER_PACKAGES.RELATED, [])

  const getType = (isFurther: boolean) =>
    isFurther ? 'other packaging versions' : 'equivalent packaging'

  const tableRender = (relationType: OTHER_PACKAGES.FURTHER | OTHER_PACKAGES.RELATED) => {
    const isFurther = relationType === OTHER_PACKAGES.FURTHER

    const list = isFurther ? furtherVersionList : relatedList

    const othersType = getType(isFurther)

    const deleteHandler = isFurther
      ? packagingActions.deleteFurtherNewPackage
      : packagingActions.deleteRelatedNewPackage
    const addHandler = isFurther
      ? packagingActions.addFurtherNewPackage
      : packagingActions.addRelatedNewPackage

    return (
      <>
        {(!!list.length && isEditing) || !isEditing ? (
          <RelationshipsTable
            data={data}
            onAdd={addHandler}
            onDelete={deleteHandler}
            isEditing={isEditing}
            relationType={relationType}
          />
        ) : (
          <EmptyWrapper>
            <Text text={`There are no ${othersType}`} variant="h4" />
            <AddRelationModal
              btnType="ghost"
              confirmAdd={addHandler}
              btnLabel="Add new one"
              relationType={relationType}
              modalKey={`package-relation-add-${relationType}`}
            />
          </EmptyWrapper>
        )}
      </>
    )
  }

  const modalBtnRenderer = (
    relationType: OTHER_PACKAGES.FURTHER | OTHER_PACKAGES.RELATED
  ) => {
    const isFurther = relationType === OTHER_PACKAGES.FURTHER

    const addHandler = isFurther
      ? packagingActions.addFurtherNewPackage
      : packagingActions.addRelatedNewPackage

    return (
      <AddRelationModal
        btnType="text"
        confirmAdd={addHandler}
        btnLabel="Add"
        hasIcon
        relationType={relationType}
        modalKey={`package-relation-add-${relationType}`}
      />
    )
  }

  return (
    <>
      <TitleWrapper>
        <Text text="Relationships" variant="h3" />
      </TitleWrapper>
      <Divider />
      <TitleWrapper>
        <Text text="Other Packaging Versions" variant="h4" />
        {isEditing &&
          !!furtherVersionList.length &&
          modalBtnRenderer(OTHER_PACKAGES.FURTHER)}
      </TitleWrapper>
      <Divider />
      {tableRender(OTHER_PACKAGES.FURTHER)}
      <Divider disableBorder />
      <Divider disableBorder />
      <TitleWrapper>
        <Text text="Equivalent Packaging" variant="h4" />
        {isEditing && !!relatedList.length && modalBtnRenderer(OTHER_PACKAGES.RELATED)}
      </TitleWrapper>
      <Divider />
      {tableRender(OTHER_PACKAGES.RELATED)}
    </>
  )
}

export default Relationships
