import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit'
import { get } from 'lodash'
import { IError } from '../../containers/Error/types'
import { getCleanError } from '../../utils'
import { ITaxonomies } from '../../utils/taxonomiesHelper'
import { PackagingDto } from '../packaging/model'
import { ToolingDto } from '../tooling/model'
import { ENTITY_ADD_TOOLING, ENTITY_CREATE, entityTypeDropdown } from './constants'
import { IEntity, IEntityState } from './model'

const initialState: IEntityState = {
  new: {},
  dropDown: {
    type: entityTypeDropdown
  },
  isLoading: false,
  error: {}
}

const entitySlice = createSlice({
  name: 'entity',
  initialState: initialState,
  reducers: {
    setNewEntityPackaging: (
      state: IEntityState,
      { payload }: PayloadAction<PackagingDto>
    ) => {
      state.new['packaging'] = {
        ...payload
        // thumbnail: { url: get(payload, 'image', '') }
      }
    },
    updateNewEntity: (
      state: IEntityState,
      { payload }: PayloadAction<{ key: keyof IEntity; value: any }>
    ) => {
      const { key, value } = payload
      state.new[key] = value
    },
    resetNewEntity: (state: IEntityState) => {
      state.new = initialState.new
    },
    removeNewEntityPack: (state: IEntityState) => {
      state.new.packaging = undefined
      state.new.sides = undefined
    },
    removeNewEntityTool: (state: IEntityState) => {
      state.new.packaging = undefined
      state.new.selectedTooling = undefined
      state.new.sides = undefined
    },
    setNewEntityTooling: (
      state: IEntityState,
      { payload }: PayloadAction<ToolingDto[]>
    ) => {
      const sides = [...(state.new?.sides || [])]
      sides[0].toolingId = payload[0].id
      state.new.sides = sides
      state.new.selectedTooling = payload
    },
    setNewEnitityVariant: (
      state: IEntityState,
      { payload }: PayloadAction<{ sideIdx: number; selected: Record<string, any> }>
    ) => {
      const { sideIdx, selected } = payload
      const sides = [...(state.new?.sides || [])]
      sides[sideIdx] = {
        vialsDetailId: get(selected, 'id', ''),
        packageVariantId: get(selected, 'idPackaging', ''),
        airtightPackagingNeeded: get(selected, 'airtight', false)
      }
      state.new.sides = sides
      state.new.status = 'READY_TO_GO'
    },
    setNewEnitityFSchema: (
      state: IEntityState,
      {
        payload
      }: PayloadAction<{ sideIdx: number; selectedFSchema: Record<string, any>[] }>
    ) => {
      const { sideIdx, selectedFSchema } = payload
      const availableFormulas = selectedFSchema
        .map(({ formula, formulaSchemaCode }) =>
          formula.map((data: any) => ({ ...data, formulaSchemaCode }))
        )
        .flat()
      const sides = [...(state.new?.sides || [])]
      sides[sideIdx] = {
        ...sides[sideIdx],
        fSchema: selectedFSchema.map((fSchemaData) => ({
          ...fSchemaData,
          formula: []
        })),
        availableFormulas
      }
      state.new.sides = sides
    },
    setNewEntityFormula: (
      state: IEntityState,
      {
        payload
      }: PayloadAction<{ sideIdx: number; selectedFormula: Record<string, any>[] }>
    ) => {
      const { sideIdx, selectedFormula } = payload
      const sides = [...(state.new?.sides || [])]
      const sideData = sides[sideIdx]
      if (sideData.fSchema) {
        sideData.fSchema?.forEach((_, idx) => {
          if (sideData.fSchema) {
            sideData.fSchema[idx].formula = []
          }
        })
      }
      selectedFormula.forEach(({ formulaSchemaCode, ...formulaData }) => {
        const fSchemaIdx = sideData.fSchema?.findIndex((fSchemaData) => {
          return fSchemaData.formulaSchemaCode === formulaSchemaCode
        })
        if (fSchemaIdx !== undefined && fSchemaIdx > -1 && sideData.fSchema) {
          sideData.fSchema[fSchemaIdx].formula.push({ formulaSchemaCode, ...formulaData })
        }
      })
      if (state.new.sides) {
        state.new.sides[sideIdx] = sideData
      }
      let childrenCopy: any = []
      state.new.sides?.forEach((side) => {
        side.fSchema?.forEach((schema) => {
          childrenCopy = childrenCopy.concat(schema.formula)
        })
      })

      selectedFormula.forEach((formula) => {
        const isInChild = !!childrenCopy.find(({ id }: any) => id === formula.id)
        if (!isInChild) {
          childrenCopy.push(formula)
        }
      })
      state.new.children = childrenCopy
    },
    removeNewEntityFSchemaById: (
      state: IEntityState,
      { payload }: PayloadAction<{ sideIdx: number; fSchemaId: string }>
    ) => {
      const { sideIdx, fSchemaId } = payload
      const sides = [...(state.new?.sides || [])]
      const sideData = sides[sideIdx] || {}
      const filteredFSchema = sideData.fSchema?.filter(({ id }) => id !== fSchemaId) || []
      const availableFormulas = filteredFSchema
        .map(({ formula, formulaSchemaCode }) =>
          formula.map((data: any) => ({ ...data, formulaSchemaCode }))
        )
        .flat()
      sides[sideIdx] = {
        fSchema: filteredFSchema,
        availableFormulas
      }
      state.new.sides = sides
      if (state.new.selectedFSchemas) {
        state.new.selectedFSchemas[sideIdx] = filteredFSchema
      }
    },
    removeNewEntityFSchema: (
      state: IEntityState,
      { payload }: PayloadAction<{ sideIdx: number }>
    ) => {
      const { sideIdx } = payload
      const sides = [...(state.new?.sides || [])]
      sides[sideIdx] = {
        fSchema: [],
        availableFormulas: []
      }
      state.new.sides = sides
    },
    setDropdown: (state: IEntityState, { payload }: PayloadAction<ITaxonomies>) => {
      const dropdowns = Object.entries(payload).reduce(
        (acc, [key, values]) => ({
          ...acc,
          [key]: values.map((val) => ({ name: val, value: val }))
        }),
        {}
      )
      state.dropDown = {
        ...state.dropDown,
        ...dropdowns
      }
    },
    setIsLoading: (state: IEntityState, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload
    },
    setError: (state: IEntityState, { payload }: PayloadAction<IError>) => {
      state.error = getCleanError(payload)
    },
    clearError: (state: IEntityState) => {
      state.error = initialState.error
    }
  }
})

export const entityActions = {
  ...entitySlice.actions,
  addTooling: createAction<{ selected: Record<string, any>[] }>(ENTITY_ADD_TOOLING),
  createEntity: createAction(ENTITY_CREATE)
}

export default entitySlice.reducer
