import styled from 'styled-components'
import { pageSubtitleHeight, pageTitleHeight } from '../../components/PageTitle/styled'
import { Text } from '../../components/Text'

export const Content = styled.div`
  padding: 50px 0px;
  height: calc(100% - ${pageTitleHeight}px - ${pageSubtitleHeight}px);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-evenly;
  width: 100%;
`

export const TypeCard = styled.div`
  display: flex;
  border: 1px solid grey;
  justify-content: space-between;
  padding: 16px;
  height: 80px;
  width: 30%;
  cursor: pointer;
`

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`

export const Title = styled(Text)`
  margin: 0px;
`

export const Subtitle = styled(Text)`
  margin: 0px;
  color: ${({ theme }) => theme.palette.primary.main};
`

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
