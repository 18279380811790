import { isEmpty } from 'lodash'
import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { footerHeight, headerHeight } from '../../assets/mixins'
import { PageContent } from '../../containers'
import ResultSection from '../../containers/Packaging/ResultSection'
import { packagingActions } from '../../features/packaging'
import {
  getPackagingFilters,
  selectPackagingError
} from '../../features/packaging/selectors'
import { GenericLayout } from '../../layouts'
import { AppRoutes } from '../constants'

const PackagingResult: FC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const packagingError = useSelector(selectPackagingError)
  const filters = useSelector(getPackagingFilters)

  useEffect(() => {
    if (isEmpty(filters)) {
      navigate(AppRoutes.WIKIPACK_PACKAGING)
    } else {
      dispatch(packagingActions.getPackages())
    }
    return () => {
      dispatch(packagingActions.clearError())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <GenericLayout error={packagingError}>
      <PageContent
        footerHeight={footerHeight}
        headerHeight={headerHeight}
        justifyContent="flex-start"
      >
        <ResultSection hasFilterBtn hasTitle hasAction hasExportBtn />
      </PageContent>
    </GenericLayout>
  )
}

export default PackagingResult
