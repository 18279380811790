import { faker } from '@faker-js/faker'
import { get } from 'lodash'
import { OrderableItem } from './model'

export const filterOrderableItems = (products: OrderableItem[]) =>
  products.filter((product) => get(product, 'availableQuantity') > 0)

export const generateComponets = (products: OrderableItem[]) => {
  const fakerItems = products.map((item) => {
    const components = []
    for (let i = 0; i < 10; i++) {
      const productName = faker.random.word()
      const productCategory = faker.random.word()
      const productCode = faker.random.word()
      const usage = faker.random.word()
      const launchYear = Math.floor(Math.random() * 6) + 2017
      const collection = faker.random.word()
      const colors = 'blue'
      const finish = 'finished'
      const packagingRef = faker.random.word()
      const id = Math.random().toString(36).slice(2)
      components.push({
        productName,
        productCategory,
        productCode,
        usage,
        launchYear,
        collection,
        colors,
        finish,
        packagingRef,
        id
      })
    }
    return { ...item, components }
  })
  return fakerItems
}
