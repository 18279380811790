import { Modal } from 'antd'
import styled from 'styled-components'

export const StyledModal = styled(Modal)`
  .ant-modal-content {
    height: 'max-content';
  }
`
export const Title = styled.div`
  width: 100%;
  font-size: 20px;
  font-weight: 900;
  text-align: center;
`

export const Message = styled.div`
  width: 100%;
  padding-top: 12px;
  padding-bottom: 20px;
  font-size: 16px;
  text-align: center;
  white-space: pre-line;
`

export const ButtonWrapper = styled.div`
  button {
    width: 100%;
  }
`
