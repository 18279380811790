export const valueVial = 'Vials'

export const colors = [
  {
    name: 'LIGHT BLUE',
    value: 'LIGHT BLUE'
  },
  {
    name: 'BLUE',
    value: 'BLUE'
  },
  {
    name: 'BROWN',
    value: 'BROWN'
  },
  {
    name: 'GREEN',
    value: 'GREEN'
  },
  {
    name: 'INCOLOR',
    value: 'INCOLOR'
  },
  {
    name: 'BEIGE',
    value: 'BEIGE'
  },
  {
    name: 'MAGICOLOR',
    value: 'MAGICOLOR'
  },
  {
    name: 'MULTICOLOR',
    value: 'MULTICOLOR'
  },
  {
    name: 'BLACK',
    value: 'BLACK'
  },
  {
    name: 'ORANGE',
    value: 'ORANGE'
  },
  {
    name: 'PINK',
    value: 'PINK'
  },
  {
    name: 'GRAY/SILVER',
    value: 'GRAY/SILVER'
  },
  {
    name: 'RED',
    value: 'RED'
  },
  {
    name: 'VIOLET',
    value: 'VIOLET'
  },
  {
    name: 'WHITE',
    value: 'WHITE'
  },
  {
    name: 'GENERIC PIGMENT/PEARLS',
    value: 'GENERIC PIGMENT/PEARLS'
  },
  {
    name: 'YELLOWW/GOLD',
    value: 'YELLOWW/GOLD'
  },
  {
    name: 'COMPOSITE',
    value: 'COMPOSITE'
  }
]
export const usage = [
  {
    name: 'A',
    value: 'A'
  },
  {
    name: 'B',
    value: 'B'
  },
  {
    name: 'C',
    value: 'C'
  },
  {
    name: 'D',
    value: 'D'
  },
  {
    name: 'E',
    value: 'E'
  },
  {
    name: 'F',
    value: 'F'
  },
  {
    name: 'G',
    value: 'G'
  },
  {
    name: 'H',
    value: 'H'
  },
  {
    name: 'I',
    value: 'I'
  },
  {
    name: 'J',
    value: 'J'
  },
  {
    name: 'K',
    value: 'K'
  },
  {
    name: 'L',
    value: 'L'
  },
  {
    name: 'M',
    value: 'M'
  },
  {
    name: 'N',
    value: 'N'
  },
  {
    name: 'O',
    value: 'O'
  },
  {
    name: 'P',
    value: 'P'
  },
  {
    name: 'Q',
    value: 'Q'
  },
  {
    name: 'R',
    value: 'R'
  },
  {
    name: 'S',
    value: 'S'
  },
  {
    name: 'U',
    value: 'U'
  },
  {
    name: 'V',
    value: 'V'
  }
]
export const dropDown = { colors, usage }
