import { Collapse } from 'antd'
import styled from 'styled-components'

export const SelectTypeWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const SelectTypeContainer = styled.div`
  display: flex;
  h4 {
    margin: 0px;
  }
  div {
    margin: 0px;
    margin-left: 10px;
  }
`
export const StyledAccordion = styled(Collapse)`
  & .ant-collapse-header,
  & .ant-collapse-content-box {
    padding: 0 !important;
  }
`
