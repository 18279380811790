import { useCallback } from 'react'
import { FlexDirectionProps } from 'styled-system'
import { Text, TextVariant } from '../Text'
import { StyledTextTitle, StyledTitle } from './styled'

export type PropsTitle = {
  className?: string
  variantTitle?: TextVariant
  variantSubTitle?: TextVariant
  title: string
  subTitle?: string | number | boolean
  enableLink?: boolean
  prefixLink?: string
  flexDirection?: string
  spaceBetweenTitles?: boolean
  borderBottom?: boolean
  enableBold?: boolean
} & FlexDirectionProps

export const Title = ({
  className,
  variantTitle,
  variantSubTitle,
  title,
  subTitle,
  enableLink,
  prefixLink,
  flexDirection,
  spaceBetweenTitles = false,
  borderBottom,
  enableBold
}: PropsTitle) => {
  const openInNewTab = useCallback(() => {
    if (!enableLink || (!prefixLink && !subTitle)) return
    window.open(`${prefixLink}${subTitle}`, '_blank', 'noopener,noreferrer')
  }, [enableLink, prefixLink, subTitle])

  return (
    <StyledTitle
      className={className}
      onClick={openInNewTab}
      spaceBetweenTitles={spaceBetweenTitles}
      flexDirection={flexDirection}
      borderBottom={borderBottom ? '1px solid #d6d6d6' : 'none'}
    >
      <StyledTextTitle
        className="title"
        text={title}
        variant={variantTitle}
        enableBold={enableBold}
      />
      {subTitle && <Text text={subTitle} variant={variantSubTitle} />}
    </StyledTitle>
  )
}
