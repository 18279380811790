export const masterSpecStatus = {
  DRAFT: 'DRAFT',
  COMPLETED: 'COMPLETED',
  SENT_TO_SAP: 'SENT_TO_SAP'
}

export const statusOptions = [
  { name: 'DRAFT', value: masterSpecStatus.DRAFT },
  { name: 'COMPLETED', value: masterSpecStatus.COMPLETED }
]
