import { Divider } from 'antd'
import { unset } from 'lodash'
import { FC } from 'react'
import { useSelector } from 'react-redux'
import { SectionTitle, TooltipBtn } from '../../components'
import { ActionsAttachs } from '../../components/NewTable/attachments'
import { ITActionColumnConfig } from '../../components/NewTable/types'
import { AttachmentsTable } from '../../components/Packaging'
import { TagsContainer } from '../../components/TagsContainer'
import { Text } from '../../components/Text'
import { Tooltip } from '../../components/Tooltip'
import { IAttachment } from '../../features/packaging/model'
import { selectPermissions } from '../../features/users/selectors'
import { filterAttachmentsByPermissions } from '../../utils/attachmentsHelper'
import AddModal from './AddModal'
import AttachFilterModal from './AttachFilterModal'
import { AddBtnWrapper, ButtonsWrapper, EmptyWrapper, TagsWrapper } from './styled'
import SyncModal from './SyncModal'
import { IVariantAttachSectionProps } from './types'
import UpdateAttachModal from './UpdateAttachModal'

const AttachSection: FC<IVariantAttachSectionProps> = ({
  dataList = [],
  tableConfig,
  isCreatePack,
  hasDelBtn,
  onDelete = () => {},
  hasDownBtn = false,
  hasEditBtn = false,
  onUpdate = () => {},
  hasSyncBtn = false,
  onSync = () => {},
  hasAddNewVerBtn = false,
  onAddNewVersion = () => {},
  hasFilterBtn = false,
  filters = {},
  onUpdateFilter = () => {},
  onAddAttachment = () => {},
  hasAdd = false,
  hasTitle = false,
  section = 'wikipackVariant'
}) => {
  const permissions = useSelector(selectPermissions)
  const canEdit = permissions.wikipackVariant?.canUpdate || false

  const canEditVariant = isCreatePack || (!isCreatePack && canEdit)

  const canCreatePermit = permissions.wikipackVariant?.canCreate || false

  const canCreate = (!isCreatePack && canCreatePermit) || isCreatePack

  const removeTagHandler = (path: string) => {
    const filtersCopy = { ...filters }
    const cleanPath = path.replace('filters.', '')
    unset(filtersCopy, cleanPath)
    onUpdateFilter(filtersCopy)
  }

  const hasAllActions =
    canEditVariant &&
    hasAddNewVerBtn &&
    hasEditBtn &&
    hasSyncBtn &&
    hasDownBtn &&
    hasDelBtn
  // this solution is wrong because it check always edit permission
  const hasAnyAction =
    canEditVariant ||
    hasAddNewVerBtn ||
    hasEditBtn ||
    hasSyncBtn ||
    hasDownBtn ||
    hasDelBtn

  const actionConfig: ITActionColumnConfig = {
    columnTitle: 'Actions',
    width: hasAllActions ? 250 : 200,
    actionRenderer: ({ data }) => {
      return (
        <>
          {canEditVariant && hasAddNewVerBtn && (
            <Tooltip title="Upload New Version">
              <AddModal
                data={data}
                modalKey={`add-new-version-${data.id}`}
                btnIcon="PlusOutlined"
                onConfirm={onAddNewVersion}
              />
            </Tooltip>
          )}
          {canEditVariant && hasEditBtn && (
            <Tooltip title="Edit">
              <UpdateAttachModal
                data={data}
                onSave={onUpdate}
                modalKey={`update-attachment-${data.id}`}
              />
            </Tooltip>
          )}
          {canEditVariant && hasSyncBtn && (
            <Tooltip title="Publish">
              <SyncModal data={data} onSync={onSync} />
            </Tooltip>
          )}
          {hasDownBtn && <ActionsAttachs url={data.url} />}
          {canEditVariant && hasDelBtn && (
            <TooltipBtn
              message="Do you want to delete this attachment?"
              btnVariant="text"
              hasIcon
              iconName="DeleteOutlined"
              onConfirm={() => onDelete(data)}
            />
          )}
        </>
      )
    }
  }

  let filteredList = [...dataList]
  const { name = '', description = '', SAPCodes = '' } = filters
  filteredList = filteredList.filter((attachment) => {
    const nameInLower = name.toLowerCase()
    const filterByName =
      !nameInLower || attachment.name?.toLowerCase().includes(nameInLower)
    const descInLower = description.toLowerCase()
    const filterByDesc =
      !descInLower || attachment.description?.toLowerCase().includes(descInLower)
    const sapCodesInLower = attachment.SAPCodes
      ? attachment.SAPCodes?.map((sapCode) => sapCode.toLowerCase())
      : []
    const filterBySap = !SAPCodes || sapCodesInLower.includes(SAPCodes.toLowerCase())
    return filterByName && filterByDesc && filterBySap
  })

  filteredList = filterAttachmentsByPermissions({
    attachments: filteredList,
    section,
    permissions: permissions,
    crud: 'onRead'
  })

  const hasAddTop = hasAdd && !!filteredList.length && canCreate
  const hasAddBottom = hasAdd && !filteredList.length && canCreate
  const hasActionCol = filteredList.length && hasAnyAction

  const EmptyBody = (
    <EmptyWrapper>
      <Text text="There are no attachments" variant="h4" />
      {hasAddBottom && (
        <AddModal
          modalKey={'add-new-attach-bottom'}
          subtitle='Please select the Information and add the new Attachment"'
          btnName={isCreatePack ? 'Add new one' : 'Add'}
          btnType="ghost"
          onConfirm={onAddAttachment}
          hasStatus={isCreatePack}
          confirmLabel={isCreatePack ? 'Ok' : 'Save'}
          uploadTopMess={
            isCreatePack
              ? 'Drop or browse the package attachment'
              : 'Drop or browse the attachment'
          }
          isContentTypeEnable
        />
      )}
    </EmptyWrapper>
  )

  return (
    <>
      {hasTitle && (
        <>
          <SectionTitle value="Attachments" />
          <Divider />
        </>
      )}
      <ButtonsWrapper>
        {hasFilterBtn && (
          <AttachFilterModal
            filtersData={filters}
            onApplyFilter={onUpdateFilter}
            modalKey="attachments-filters"
          />
        )}
        <TagsWrapper hasAdd={hasAddTop}>
          <TagsContainer
            tags={filters}
            childId={'filters'}
            inner={false}
            reducer={'filters'}
            flowName={'filters'}
            allowNoFilters
            basePath={'filters'}
            onRemoveTag={removeTagHandler}
          />
        </TagsWrapper>
        <AddBtnWrapper>
          {hasAddTop && (
            <AddModal
              modalKey={'add-new-attach-top'}
              subtitle="Please select the Information and add the new Attachment"
              btnIcon="PlusCircleOutlined"
              btnName="Add"
              onConfirm={onAddAttachment}
              hasStatus={isCreatePack}
              confirmLabel={isCreatePack ? 'Ok' : 'Save'}
              uploadTopMess={
                isCreatePack
                  ? 'Drop or browse the package attachment'
                  : 'Drop or browse the attachment'
              }
              isContentTypeEnable
            />
          )}
        </AddBtnWrapper>
      </ButtonsWrapper>
      {isCreatePack && !filteredList.length ? (
        EmptyBody
      ) : (
        <AttachmentsTable
          tableConfig={tableConfig}
          actionConfig={hasActionCol ? actionConfig : undefined}
          dataList={filteredList as IAttachment[]}
          emptyBody={EmptyBody}
        />
      )}
    </>
  )
}

export default AttachSection
