import styled from 'styled-components'
import { Text } from '../../Text'

export type StyledUploadProps = {}

export const FakeBtnFile = styled(Text)`
  position: absolute;
  top: -8px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border: 1px solid #d6d6d6;
  border-radius: 10px;
  min-height: 38px;
  ${({ error }: { error: boolean }) => error && 'border-color: var(--ant-error-color);'}
`
export const StyledUpload = styled.div<StyledUploadProps>`
  .btn-style {
    position: relative;
    cursor: pointer;
    z-index: 5;
    opacity: 0;
  }

  .btn-style::-webkit-file-upload-button {
    cursor: pointer;
  }

  .btn-style:focus {
    visibility: hidden;
  }

  .fake-btn {
    position: absolute;
    top: -8px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border: 1px solid #d6d6d6;
    border-radius: 10px;
    min-height: 38px;
  }

  .description {
    margin: 0;
    font-weight: 200;
    font-size: small;
  }
`
