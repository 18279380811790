import { Form } from 'antd'
import { find, get } from 'lodash'
import { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Button, InfoCell, SectionTitle, Table, TooltipBtn } from '../../components'
import AddMasterSpecCharacteristicModal from '../../components/AddMasterSpecCharacteristicModal'
import { BoxWrapper } from '../../components/BoxWrapper'
import { Divider } from '../../components/Divider'
import { TOP_RIGHT } from '../../components/NewTable/constants'
import { ITActionColumnConfig } from '../../components/NewTable/types'
import { IMasterSpecDetail } from '../../features/masterSpec/model'
import { masterSpecActions } from '../../features/masterSpec/reducer'
import {
  selectIsLoading,
  selectSpecificationSections
} from '../../features/masterSpec/selectors'
import { SapCodeConcerned } from '../../features/packaging/model'
import { IImageData } from '../../features/storeProducts/model'
import { statusOptions } from '../../pages/MasterSpecificationDetail/constants'
import { columnsGeneratorHelper } from '../../utils'
import { MASTER_SPEC_DETAIL_FORMULA } from './constants'
import { IMasterSpecificationDetailContentProps } from './types'

const MasterSpecificationDetailContent: FC<IMasterSpecificationDetailContentProps> = ({
  isEditing,
  packageDetails,
  masterSpecDetails,
  characteristicList
}) => {
  const isLoading = useSelector(selectIsLoading)
  const specificationSections = useSelector(selectSpecificationSections)

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const imageValue = get(packageDetails, 'image.value', '')
  const supplierName = get(packageDetails, 'testedSupplier.name', '')
  const supplierItemCode = get(packageDetails, 'testedSupplier.SAPCodes', '')
  const packageId = get(packageDetails, 'id', '')
  const specId = get(masterSpecDetails, 'id', '')

  const [isImageTouched] = useState<boolean>(false)

  const aestethicAndDecorationsId = find(
    specificationSections,
    (section) => section.code === 'A'
  )?.id

  const functionalAndDimensionalId = find(
    specificationSections,
    (section) => section.code === 'B'
  )?.id

  const aestethicAndDecorationList =
    masterSpecDetails.masterSpecificationCharacteristics?.filter(
      (characteristic) =>
        characteristic.specificationSectionId === aestethicAndDecorationsId
    ) || []
  const functionalAndDimensionalList =
    masterSpecDetails.masterSpecificationCharacteristics?.filter(
      (characteristic) =>
        characteristic.specificationSectionId === functionalAndDimensionalId
    ) || []

  const actionConfig: ITActionColumnConfig = {
    columnTitle: 'Actions',
    actionRenderer: ({ data }) => {
      return (
        <>
          <TooltipBtn
            tooltipLabel="Delete"
            hasIcon
            disabled={!isEditing}
            iconName="DeleteOutlined"
            message="Do you want to delete this row?"
            onConfirm={() => {
              deleteCharacteristicHandler(data.id)
            }}
            btnVariant="link"
          />
          <Button
            iconName="FormOutlined"
            variant="link"
            disabled={isEditing}
            onClick={() => {
              dispatch(masterSpecActions.setNewCharacteristic(data))
              navigate(
                `/wikipack/packaging/${packageId}/master-specifications/${specId}/characteristicEditor`
              )
            }}
          />
        </>
      )
    }
  }

  const columns = columnsGeneratorHelper(MASTER_SPEC_DETAIL_FORMULA)

  const onChange = (input: { key: keyof IMasterSpecDetail; value: any }) => {
    dispatch(masterSpecActions.updateNewMasterSpecification(input))
  }

  const addCharacteristicHandler = (characteristic: any, sectionId: string) => {
    const characteristicToAdd = { ...characteristic, specificationSectionId: sectionId }
    const payload: { key: keyof IMasterSpecDetail; value: any } = {
      key: 'masterSpecificationCharacteristics',
      value: [
        ...get(masterSpecDetails, 'masterSpecificationCharacteristics', []),
        characteristicToAdd
      ]
    }
    dispatch(masterSpecActions.updateNewMasterSpecification(payload))
  }

  const deleteCharacteristicHandler = (charId: string) => {
    const payload: { key: keyof IMasterSpecDetail; value: any } = {
      key: 'masterSpecificationCharacteristics',
      value: [
        ...get(masterSpecDetails, 'masterSpecificationCharacteristics', []).filter(
          (c) => c.id !== charId
        )
      ]
    }
    dispatch(masterSpecActions.updateNewMasterSpecification(payload))
  }

  const updateImageHandler = (data: IImageData) => {
    onChange({ key: 'thumbnailOverride', value: data })
  }

  return (
    <Form layout="vertical">
      <SectionTitle value={'Basic Info'} />
      <Divider disableBorder={false} />
      <>
        <InfoCell
          value={get(masterSpecDetails, 'thumbnailOverride.url') || imageValue}
          label=""
          isEditing={isEditing}
          dataType="image"
          inputType="upload"
          inputConfig={{
            fileKey: 'master-spec-edit',
            onChange: (imageData: any) => {
              updateImageHandler(imageData)
            },
            isUrl: !isImageTouched || !isEditing
          }}
        />
        <BoxWrapper columnCount={2} enablePadding>
          <InfoCell label="Supplier Name" value={supplierName} isEditing={false} />
          <InfoCell
            label="Supplier Item Code"
            value={supplierItemCode}
            isEditing={false}
          />
        </BoxWrapper>
        <BoxWrapper columnCount={3} enablePadding>
          <InfoCell
            label="Title"
            value={masterSpecDetails?.title || ''}
            isEditing={isEditing}
            inputConfig={{
              onChange: (value: string) => {
                onChange({ key: 'title', value })
              }
            }}
          />
          <InfoCell
            label="Description"
            value={masterSpecDetails?.description || ''}
            isEditing={isEditing}
            inputConfig={{
              onChange: (value: string) => onChange({ key: 'description', value })
            }}
          />
          <InfoCell
            value={masterSpecDetails?.status || ''}
            label="Status"
            inputType="select"
            isEditing={isEditing}
            inputConfig={{
              items: statusOptions,
              onChange: ({ value }: Record<string, any>) =>
                onChange({ key: 'status', value })
            }}
          />
          <InfoCell
            label="Project Master Code"
            value={masterSpecDetails?.masterCode || ''}
            isEditing={isEditing}
            inputConfig={{
              onChange: (value: string) => onChange({ key: 'masterCode', value })
            }}
          />
          <InfoCell
            label="Customer Project Name"
            value={masterSpecDetails?.customerProjectName || ''}
            isEditing={false}
            inputConfig={{
              onChange: (value: string) => onChange({ key: 'customerProjectName', value })
            }}
          />
          <InfoCell
            label="Nb Shade"
            value={masterSpecDetails?.nbShade || ''}
            isEditing={false}
            inputConfig={{
              onChange: (value: string) => onChange({ key: 'nbShade', value })
            }}
          />
          <InfoCell
            label="Remarks"
            value={masterSpecDetails?.remarks || ''}
            isEditing={isEditing}
            inputConfig={{
              onChange: (value: string) => onChange({ key: 'remarks', value })
            }}
          />
        </BoxWrapper>

        <Button
          iconPosition="left"
          disabled={true}
          iconName="PlusCircleOutlined"
          variant="link"
          label="Add Sap Code Concerned"
          onClick={() =>
            onChange({
              key: 'concernedSapCodes',
              value: [
                ...(masterSpecDetails?.concernedSapCodes || []),
                {}
              ] as SapCodeConcerned[]
            })
          }
        />
      </>

      <br />
      <br />
      <SectionTitle value="Aesthetics & Decorations" />
      <Divider disableBorder={false} />
      <Table
        enablePagination={false}
        paginationPosition={TOP_RIGHT}
        actionColumnConfig={actionConfig}
        rowKey="id"
        columns={columns}
        items={aestethicAndDecorationList}
        isLoading={isLoading}
      />
      <Divider disableBorder />
      <AddMasterSpecCharacteristicModal
        onAdd={(characteristic) =>
          addCharacteristicHandler(characteristic, aestethicAndDecorationsId || '')
        }
        isEditing={isEditing}
        btnLabel={'Add Characteristic'}
        characteristicList={characteristicList}
        sectionId={aestethicAndDecorationsId || ''}
        modalKey="master-spec-add-characteristic"
        readonlyFields={{ method: 'MP-036' }}
      />
      <br />
      <br />
      <br />
      <SectionTitle value="Functional & Dimensional" />
      <Divider disableBorder={false} />
      <Table
        enablePagination={false}
        paginationPosition={TOP_RIGHT}
        rowKey="id"
        columns={columns}
        items={functionalAndDimensionalList}
        actionColumnConfig={actionConfig}
        isLoading={isLoading}
      />
      <Divider disableBorder />
      <AddMasterSpecCharacteristicModal
        onAdd={(characteristic) =>
          addCharacteristicHandler(characteristic, functionalAndDimensionalId || '')
        }
        isEditing={isEditing}
        btnLabel={'Add Characteristic'}
        sectionId={functionalAndDimensionalId || ''}
        characteristicList={characteristicList}
        modalKey="master-spec-add-FD-characteristic"
      />
      <br />
      <br />
    </Form>
  )
}

export default MasterSpecificationDetailContent
