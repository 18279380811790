import { get } from 'lodash'
import { FC } from 'react'
import { useDispatch } from 'react-redux'
import { FlexSection } from '../../FlexSection'
import TooltipBtn from '../../TooltipBtn'
import DetailBtn from './DetailBtn'
import { IActionsColumnProp } from './types'

const ActionsColumn: FC<IActionsColumnProp> = ({ rec, actionConfig }) => {
  const dispatch = useDispatch()

  const detail = get(actionConfig, 'detail', null)
  const del = get(actionConfig, 'del', null)
  const actionRenderer = get(actionConfig, 'actionRenderer', null)

  const deleteHandler = () => {
    del && del.deleteCb && dispatch(del.deleteCb({ value: rec }))
  }

  const { isChild, hasDetail: childHasDetail, hasDelete: childHasDelete } = rec

  // todo -> quando ci serviranno download/copy/accordion gestiremo meglio le funzioni. Al momento serve solo il detail

  const hasDeleteBtn = (!isChild && del) || (isChild && childHasDelete)
  const hasDetailBtn = (!isChild && detail) || (isChild && childHasDetail)
  return (
    <FlexSection flexDirection="row" alignItems="center" justifyContent="center">
      {actionRenderer && actionRenderer({ data: rec })}
      {hasDetailBtn && (
        <DetailBtn
          enableTooltip={true}
          tooltipLabel="View Details"
          btnLabel={get(detail, 'btnLabel', undefined)}
          btnCallback={get(detail, 'btnCallback', undefined)}
          hasIcon={get(detail, 'hasIcon', false)}
          iconName={get(detail, 'iconName', 'InfoCircleOutlined')}
          newPage={get(detail, 'newPage', false)}
          btnVariant={get(detail, 'btnVariant', 'primary')}
          // TODO - possible clean up - basePath and pathKey will deprecate
          // thx to destinationPath
          basePath={get(detail, 'basePath', '')}
          pathKey={get(detail, 'pathKey', '')}
          destinationPath={get(detail, 'destinationPath', '')}
          rec={rec}
        />
      )}
      {hasDeleteBtn && (
        <TooltipBtn
          enableTooltip={get(del, 'enableTooltip', undefined)}
          tooltipLabel="Delete"
          btnLabel={get(del, 'btnLabel', undefined)}
          btnCallback={get(del, 'btnCallback', undefined)}
          hasIcon={get(del, 'hasIcon', false)}
          iconName={get(del, 'iconName', 'DeleteOutlined')}
          message={get(del, 'message', 'Do you want to delete this row?')}
          onConfirm={deleteHandler}
          btnVariant={get(del, 'btnVariant', 'primary')}
        />
      )}
    </FlexSection>
  )
}

export default ActionsColumn
