import { Breadcrumb } from 'antd'
import styled from 'styled-components'

export const StyledBreadCrumb = styled(Breadcrumb)`
  .ant-breadcrumb-link-active {
    cursor: pointer;
    color: #00000073;
    transition: color 0.3s;
    :hover {
      color: #000000d9;
    }
  }
`
