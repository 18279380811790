import { TablePaginationConfig } from 'antd'
import { get, isEmpty } from 'lodash'
import { FC, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  AZ_DBPRODOTTO_SALES,
  AZ_DBPRODOTTO_STRATEGICMARKETING,
  AZ_WIKIPACK_IE,
  AZ_WIKIPACK_MRKT,
  AZ_WIKIPACK_PKACCEVO,
  AZ_WIKIPACK_RDF,
  AZ_WIKIPACK_TCS,
  canUserExportPackagingSearchResult
} from '../../../auth-msal'
import { Button, PackagingTable, SectionTitle } from '../../../components'
import { FlexSection } from '../../../components/FlexSection'
import { Modal } from '../../../components/Modal'
import { IExcludeRules } from '../../../components/NewTable/types'
import { TagsContainer } from '../../../components/TagsContainer'
import { Text } from '../../../components/Text'
import { Tooltip } from '../../../components/Tooltip'
import { packagingActions } from '../../../features/packaging'
import { PACKAGE_TYPE } from '../../../features/packaging/model'
import {
  getIsLoading,
  getPackagingFilters,
  getPackagingItems,
  getPackagingTempFilters,
  selectPackagePagination
} from '../../../features/packaging/selectors'
import { selectPermissions, selectUserRole } from '../../../features/users/selectors'
import { checkSearchFormValidation } from '../../../utils'
import PackagingSearchForm from '../PackagingSearchForm'
import { ButtonWrapper, TagsWrapper } from './styled'
import { IResultSectionProps } from './types'

const ResultSection: FC<IResultSectionProps> = ({
  hasTitle = false,
  hasFilterBtn = false,
  enableSelect = false,
  selectedRows = [],
  onSelect = () => {},
  isSingleSelect = false,
  hasAction = false,
  hasExportBtn = false
}) => {
  const dispatch = useDispatch()
  const userRoles = useSelector(selectUserRole)
  const userPermissions = useSelector(selectPermissions)
  const tempFilter = useSelector(getPackagingTempFilters)
  const filters = useSelector(getPackagingFilters)
  const packageList = useSelector(getPackagingItems)
  const isLoading = useSelector(getIsLoading)
  const { page, pageSize, total } = useSelector(selectPackagePagination)

  const isMulti = get(filters, 'type', '').toLowerCase() === PACKAGE_TYPE.MULTI
  const canSeeExportButtons = canUserExportPackagingSearchResult(userRoles)
  const canUserExportTargetPrice =
    userPermissions.wikipackPackaging?.canExportTargetPrice || false

  const colHideRules: IExcludeRules = {
    supplierRef: {
      byRoles: [
        AZ_DBPRODOTTO_SALES,
        AZ_DBPRODOTTO_STRATEGICMARKETING,
        AZ_WIKIPACK_PKACCEVO,
        AZ_WIKIPACK_IE,
        AZ_WIKIPACK_RDF,
        AZ_WIKIPACK_MRKT,
        AZ_WIKIPACK_TCS
      ]
    },
    testedSupplier: {
      byRoles: [
        AZ_WIKIPACK_PKACCEVO,
        AZ_WIKIPACK_IE,
        AZ_WIKIPACK_RDF,
        AZ_WIKIPACK_MRKT,
        AZ_WIKIPACK_TCS
      ]
    },
    USDTargetPrice: {
      byRoles: [AZ_WIKIPACK_IE, AZ_WIKIPACK_RDF]
    },
    MOQ: {
      byRoles: [AZ_WIKIPACK_IE, AZ_WIKIPACK_RDF]
    }
  }

  const filtersCopy = { ...filters }
  const packageFamiliesFilter = get(filters, 'packageFamilies', [])
  if (filtersCopy.packageFamilies) {
    delete filtersCopy.packageFamilies
  }
  if (filtersCopy.type) {
    delete filtersCopy.type
  }

  const changePageHandler = (pageData: TablePaginationConfig) => {
    dispatch(
      packagingActions.setPagination({
        page: get(pageData, 'current', 1),
        pageSize: get(pageData, 'pageSize', 10),
        total: get(pageData, 'total', 10)
      })
    )
    dispatch(packagingActions.getPackages())
  }

  const removeTagHandler = (path: string) => {
    dispatch(packagingActions.cancelFilter({ path }))
    dispatch(packagingActions.getPackages())
  }

  const searchHandler = () => {
    dispatch({ type: 'app/closeModal', payload: { modalKey: 'package-filter' } })
    dispatch(packagingActions.resetPagination())
    dispatch(packagingActions.applyFilters())
    dispatch(packagingActions.getPackages())
  }

  const checkIfDisabled = () => checkSearchFormValidation(tempFilter)

  const modalOpenHandler = useCallback(() => {
    dispatch(packagingActions.filterToTemp())
  }, [dispatch])

  return (
    <div>
      {hasTitle && <SectionTitle value="Packaging" isBold />}
      <br />
      <FlexSection flexDirection="row" justifyContent="space-between">
        <FlexSection flexDirection="row">
          {hasFilterBtn && (
            <Modal
              btnName="Filter"
              title="Filter Package"
              modalKey="package-filter"
              size="large"
              btnClicked={modalOpenHandler}
              footer={[
                <ButtonWrapper>
                  <Button
                    label="Search"
                    onClick={searchHandler}
                    enableKeyPress
                    disabled={checkIfDisabled()}
                  />
                </ButtonWrapper>
              ]}
            >
              <PackagingSearchForm />
            </Modal>
          )}

          <TagsWrapper>
            {!isEmpty(filtersCopy) && (
              <TagsContainer
                tags={filtersCopy}
                blocked={'category'}
                childId={'packaging'}
                inner
                reducer={'packaging'}
                flowName={'packaging'}
                basePath={'filters'}
                onRemoveTag={removeTagHandler}
              />
            )}
            {packageFamiliesFilter.map((pack: any, index: number) => (
              <>
                {isMulti && !isEmpty(pack) && <Text text={`S${index + 1}:`} />}
                <TagsContainer
                  tags={pack}
                  blocked={'category'}
                  childId={'packaging'}
                  inner
                  reducer={'packaging'}
                  flowName={'packaging'}
                  basePath={`filters.packageFamilies.${index}`}
                  onRemoveTag={removeTagHandler}
                />
              </>
            ))}
          </TagsWrapper>
        </FlexSection>

        <FlexSection flexDirection="row" justifyContent="flex-end">
          {canSeeExportButtons && hasExportBtn && (
            <Button
              label="Export Macro"
              variant="ghost"
              onClick={() => {
                dispatch(packagingActions.exportMacro())
              }}
            />
          )}
          {canSeeExportButtons && hasExportBtn && (
            <Button
              label="Export Micro"
              variant="ghost"
              onClick={() => {
                dispatch(packagingActions.exportMicro())
              }}
            />
          )}
          {canUserExportTargetPrice && hasExportBtn && (
            <Tooltip title="For sourcing internal use only">
              <Button
                label="Export Target Price"
                variant="ghost"
                onClick={() => dispatch(packagingActions.exportTargetPrice())}
              />
            </Tooltip>
          )}
        </FlexSection>
      </FlexSection>
      <br />
      <PackagingTable
        enableRowSelect={enableSelect}
        enablePagination
        dataList={packageList}
        colHideRules={colHideRules}
        onChangePage={changePageHandler}
        isLoading={isLoading}
        pageData={{ page, pageSize, total }}
        selectedRows={selectedRows}
        onSelectRow={onSelect}
        isSingleSelect={isSingleSelect}
        hasAction={hasAction}
      />
    </div>
  )
}

export default ResultSection
