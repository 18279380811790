import { apiClient } from '../../api/client'
import { PackagingDto, PackagingResultSearch, PackagingClone } from './model'

export const getPackaging = () => {
  return apiClient.get<PackagingDto[]>('package')
}

export const searchPackaging = (filters: Record<string, any>) => {
  return apiClient.post<PackagingResultSearch>('v2/package/search', filters)
}

export const checkIfAlreadyRelated = (supplierRefData: Record<string, any>) => {
  return apiClient.post<Record<string, any>>('v2/package/search', supplierRefData, {
    params: { matchType: 'exactValue' }
  })
}

export const searchPackagingWithRelations = (
  data: Record<string, any>,
  params: { withFurther: boolean; withRelated: boolean }
) => {
  return apiClient.post<Record<string, any>>('v2/package/search', data, {
    params: {
      addFurtherVersions: params.withFurther,
      addRelatedPackages: params.withRelated
    }
  })
}

export const copyPackaging = ({ id, author }: PackagingClone) => {
  return apiClient.get<PackagingDto>(`package/${id}/clone/${author}`)
}

export const deletePackaging = ({ id, author }: PackagingClone) => {
  return apiClient.delete<PackagingDto>(`package/${id}/${author}`)
}
export const getPackageId = (id: string) => {
  return apiClient.get<PackagingDto>(`package/${id}`)
}

export const getVariantId = (id: string) => {
  return apiClient.get<PackagingDto>(`package/variant/${id}`)
}

export const getPackageTaxonomies = () => {
  return apiClient.get<Record<string, any>>('package/taxonomies')
}

export const getAllPackageTaxonomies = () => {
  return apiClient.get<Record<string, any>>('taxonomies/package')
}

export const createPackaging = (newPackage: Record<string, any>) => {
  return apiClient.post<any>('package', newPackage)
}

export const savePackaging = (dataToInsert: Record<string, any>, id: string) => {
  return apiClient.patch<any>(`package/${id}`, dataToInsert)
}

export const deleteFurtherPackaging = ({ id, relatedId }: PackagingClone) => {
  return apiClient.delete<PackagingDto>(`package/furtherVersions/${relatedId}`, {
    package: id
  })
}

export const addFurtherPackaging = (dataToInsert: Record<string, any>, id: string) => {
  return apiClient.post<any>(`package/furtherVersions/${id}`, dataToInsert)
}

export const deleteRelatedPackaging = ({ id, relatedId }: PackagingClone) => {
  return apiClient.delete<PackagingDto>(`package/related/${relatedId}`, {
    package: id
  })
}

export const addRelatedPackaging = (dataToInsert: Record<string, any>, id: string) => {
  return apiClient.post<any>(`package/related/${id}`, dataToInsert)
}

export const updateRelatedVariant = (dataToInsert: Record<string, any>) => {
  return apiClient.post<any>('package/variant', dataToInsert)
}

export const deleteRelatedVariant = ({ id, author }: Record<string, string>) => {
  return apiClient.delete<any>(`package/variant/${id}/${author}`)
}
export const deleteOneAttachment = ({ id, author }: Record<string, string>) => {
  return apiClient.delete<any>(`package/attachment/${id}/${author}`)
}
export const deleteRelatedAttachment = (id: string) => {
  return apiClient.delete<any>(`package/attachment/${id}`)
}

export const insertOneAttachment = (data: Record<string, any>) => {
  const { variantId, form_data } = data
  return apiClient.post<any>(`package/variant/${variantId}/attachment`, form_data)
}
export const insertAttachmentMulti = (data: Record<string, any>) => {
  const { packageId, form_data } = data
  return apiClient.post<any>(`package/${packageId}/attachment`, form_data)
}

export const createNewVersion = (data: Record<string, any>) => {
  const { form_data, author, id } = data
  return apiClient.post<any>(`package/attachment/${id}/${author}`, form_data)
}

export const updateOneAttachment = (data: Record<string, any>) => {
  const { requestBody, id } = data
  return apiClient.patch<any>(`package/attachment/${id}`, requestBody)
}

export const updateOnePackageVariant = ({ requestBody, id }: Record<string, any>) => {
  return apiClient.patch<any>(`package/variant/${id}`, requestBody)
}
export const findProductsWithPKG = (id: string) => {
  return apiClient.get<any>(`product/package/${id}`)
}

export const getTestedSupplierValues = () => {
  return apiClient.get<any>('suppliers')
}

export const exportMacro = (filters: Record<string, any>) => {
  return apiClient.get<any>('package/export/macro', {
    params: filters,
    responseType: 'blob'
  })
}

export const exportMicro = (filters: Record<string, any>) => {
  return apiClient.get<any>('package/export/micro', {
    params: filters,
    responseType: 'blob'
  })
}

export const exportTargetPrice = (filters: Record<string, any>) => {
  return apiClient.get<any>('package/export/targetPrice', {
    params: filters,
    responseType: 'blob'
  })
}

export const getCountriesOfOriginDropdown = () => {
  return apiClient.get<any>('suppliers/countries-of-origin')
}

export const searchSuppliers = (data: any) => {
  return apiClient.post<any>('suppliers/search', data)
}
