import { Footer as FooterAnt } from 'antd/lib/layout/layout'
import styled from 'styled-components'
import { footerHeight } from '../../assets/mixins'

export const StyledFooter = styled(FooterAnt)`
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  color: grey;
  padding: 10px;
  background: #ffffff;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  z-index: 3;
  height: ${footerHeight}px;
`
