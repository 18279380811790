import { get } from 'lodash'
import { FC } from 'react'

import { OTHER_PACKAGES } from '../../../features/packaging/model'
import { AppRoutes } from '../../../pages/constants'
import Button from '../../NewButton'
import { ITActionColumnConfig } from '../../NewTable/types'
import { Text } from '../../Text'

import OthersPackagingTable from '../OthersPackagingTable'
import { RELATIONSHIPS_TABLE_CONFIG } from '../OthersPackagingTable/constants'
import AddRelationModal from './AddRelationModal'
import { RELATION_MESSAGE } from './AddRelationModal/constants'
import { IRelationshipsProps } from './types'

const RelationshipsTable: FC<IRelationshipsProps> = ({
  showOnlyInfo,
  data,
  onAdd,
  onDelete,
  isEditing = false,
  relationType = OTHER_PACKAGES.FURTHER,
  hasEmptyState = false,
  hasDetailBtn = false,
  isChildOpen = false
}) => {
  const otherPackageList: Record<string, any>[] = get(data, relationType, [])

  const messagePortion = RELATION_MESSAGE[relationType]

  const actionConfig: ITActionColumnConfig = otherPackageList.length
    ? {
        columnTitle: 'Details',
        actionRenderer: ({ data }) => {
          return (
            <>
              {hasDetailBtn && (
                <Button
                  iconName="InfoCircleOutlined"
                  onClick={() =>
                    window.open(`${AppRoutes.WIKIPACK_PACKAGING}/${data.id}`, '_blank')
                  }
                  variant="link"
                />
              )}
            </>
          )
        },
        alignCenter: true,
        detail:
          !hasDetailBtn && isEditing
            ? {
                btnLabel: '',
                hasIcon: true,
                newPage: true,
                basePath: 'wikipack/packaging',
                pathKey: 'id',
                btnVariant: 'text'
              }
            : undefined,
        del: isEditing
          ? showOnlyInfo
            ? undefined
            : {
                hasIcon: true,
                message: `Are you sure you want to delete the ${messagePortion}?`,
                deleteCb: onDelete,
                btnVariant: 'text'
              }
          : undefined
      }
    : {}

  return (
    <OthersPackagingTable
      tableConfig={get(RELATIONSHIPS_TABLE_CONFIG, relationType, {})}
      actionConfig={actionConfig}
      dataList={otherPackageList}
      expandablePath={
        relationType === OTHER_PACKAGES.ALTERNATIVE ? 'relatedPackages' : relationType
      }
      emptyBody={
        isEditing &&
        !otherPackageList.length &&
        hasEmptyState && (
          <>
            <Text text={`There are no ${messagePortion}`} />
            <AddRelationModal
              btnType="primary"
              confirmAdd={onAdd}
              relationType={relationType}
              modalKey={`package-relation-add-${relationType}`}
            />
          </>
        )
      }
      isChildOpen={isChildOpen}
    />
  )
}

export default RelationshipsTable
