import { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { footerControlHeight, footerHeight, headerHeight } from '../../assets/mixins'
import { Button, SectionTitle } from '../../components'
import FooterControl from '../../components/FooterControl'
import { Text } from '../../components/Text'
import { PageContent } from '../../containers'
import { SearchContainer } from '../../containers/StoreProduct/SearchContainer'
import { selectAppError } from '../../features/app/selectors'
import { storeActions } from '../../features/storeProducts'
import { selectSearchRequirements } from '../../features/storeProducts/selectors'
import { GenericLayout } from '../../layouts'
import { AppRoutes } from '../constants'

const StoreProductsSearch: FC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const appError = useSelector(selectAppError)
  const isActiveButton = useSelector(selectSearchRequirements)

  const resetFilters = () => {
    dispatch(storeActions.resetTempFilters())
  }

  const searchProducts = () => {
    dispatch(storeActions.tempToFilter({ resetPageData: true }))
    navigate(AppRoutes.STORE_PRODUCTS)
  }

  return (
    <GenericLayout error={appError}>
      <PageContent
        footerHeight={footerHeight + footerControlHeight}
        headerHeight={headerHeight}
        justifyContent="flex-start"
      >
        <SectionTitle value="Product search" isBold />
        <Text text="Fill in the fields and start the search" variant="h5" />
        <SearchContainer />
        <FooterControl>
          <Button label="Reset" variant="link" onClick={resetFilters} />
          <Button
            label="Search"
            onClick={searchProducts}
            disabled={!isActiveButton}
            enableKeyPress
          />
        </FooterControl>
      </PageContent>
    </GenericLayout>
  )
}

export default StoreProductsSearch
