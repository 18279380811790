import { isEmpty } from 'lodash'
import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { footerHeight, headerHeight } from '../../assets/mixins'
import { PageContent } from '../../containers'
import ResultSection from '../../containers/Product/ResultSection'
import { actions as productsActions } from '../../features/products'
import { getFilters, selectProductsError } from '../../features/products/selectors'
import { GenericLayout } from '../../layouts'
import { AppRoutes } from '../constants'

const ProductRTGResult: FC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const productsError = useSelector(selectProductsError)
  const filters = useSelector(getFilters)

  useEffect(() => {
    if (isEmpty(filters)) {
      navigate(AppRoutes.VAULT_PRODUCTS_SEARCH)
    } else {
      dispatch(productsActions.searchProducts())
    }
    return () => {
      dispatch(productsActions.clearError())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <GenericLayout error={productsError}>
      <PageContent
        footerHeight={footerHeight}
        headerHeight={headerHeight}
        justifyContent="flex-start"
      >
        <ResultSection />
      </PageContent>
    </GenericLayout>
  )
}

export default ProductRTGResult
