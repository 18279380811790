import { ItemType } from 'antd/lib/menu/hooks/useItems'
import _get from 'lodash/get'
import type { MenuClickEventHandler } from 'rc-menu/lib/interface'
import { useCallback } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import { StyledMenu } from './styled'
import { IHeaderMenu } from './types'

// NOTE - possible improvement, once Flower is removed from Header component -
// Move HeaderMenu as Default children of Header
const HeaderMenu = ({ menus = [] }: IHeaderMenu) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const onClick = useCallback<MenuClickEventHandler>(
    (e) => {
      navigate(e.key)
    },
    [navigate]
  )

  const getPath: () => string = () => {
    const path = menus.find((menu: ItemType) =>
      pathname.includes(_get(menu, 'key', '').toString())
    )
    return _get(path, 'key', '').toString()
  }

  return (
    <StyledMenu
      activeKey={getPath()}
      className="menu-ant"
      onClick={onClick}
      style={{ background: '#fff' }}
      mode="horizontal"
      items={menus}
    />
  )
}

export default HeaderMenu
