import { Form } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { InfoCell, SectionTitle } from '../../../components'
import { BoxWrapper } from '../../../components/BoxWrapper'
import { Divider } from '../../../components/Divider'
import { storeActions } from '../../../features/storeProducts'
import {
  applicationAreaDrpDwn,
  collectionDropDown,
  colorDropDown,
  productFormDrpDwn
} from '../../../features/storeProducts/constants'
import { IStoreProdFilters } from '../../../features/storeProducts/model'
import { getTempFilters } from '../../../features/storeProducts/selectors'
import { selectPermissions } from '../../../features/users/selectors'
import { checkEntitySearchFieldVisible } from '../../../utils/orderHelpers'

export const SearchContainer = () => {
  const dispatch = useDispatch()
  const permissions = useSelector(selectPermissions)
  const tempFilters = useSelector(getTempFilters)

  const updateFilterHandler = (key: keyof IStoreProdFilters, value: any) => {
    dispatch(storeActions.updateTempFilter({ key, value }))
  }

  const checkFieldVisibility = (key: keyof IStoreProdFilters) => {
    let isVisible = true
    isVisible = isVisible && checkEntitySearchFieldVisible(key, permissions)
    return isVisible
  }

  return (
    <Form layout="vertical">
      <SectionTitle value="General Data" />
      <Divider />
      <BoxWrapper enablePadding>
        {checkFieldVisibility('industrialCode') && (
          <InfoCell
            label="Industrial Code"
            value={tempFilters.industrialCode || ''}
            inputConfig={{
              onChange: (value: string) => updateFilterHandler('industrialCode', value)
            }}
            isEditing
          />
        )}
        {checkFieldVisibility('labCode') && (
          <InfoCell
            label="Lab Code"
            value={tempFilters.labCode || ''}
            inputConfig={{
              onChange: (value: string) => updateFilterHandler('labCode', value)
            }}
            isEditing
          />
        )}
        {checkFieldVisibility('productCode') && (
          <InfoCell
            label="Product Code"
            value={tempFilters.productCode || ''}
            inputConfig={{
              onChange: (value: string) => updateFilterHandler('productCode', value)
            }}
            isEditing
          />
        )}
        {checkFieldVisibility('productForm') && (
          <InfoCell
            label="Product Form"
            value={tempFilters.productForm || ''}
            inputType="select"
            inputConfig={{
              items: productFormDrpDwn,
              onChange: ({ value }: Record<string, any>) =>
                updateFilterHandler('productForm', value)
            }}
            isEditing
          />
        )}
        {checkFieldVisibility('finalName') && (
          <InfoCell
            label="Product Name"
            value={tempFilters.finalName || ''}
            inputConfig={{
              onChange: (value: string) => updateFilterHandler('finalName', value)
            }}
            isEditing
          />
        )}
        {checkFieldVisibility('groupCode') && (
          <InfoCell
            label="Multipack Code"
            value={tempFilters.groupCode || ''}
            inputConfig={{
              onChange: (value: string) => updateFilterHandler('groupCode', value)
            }}
            isEditing
          />
        )}
        {checkFieldVisibility('color') && (
          <InfoCell
            label="Color"
            value={tempFilters.color || ''}
            inputType="select"
            inputConfig={{
              items: colorDropDown,
              onChange: ({ value }: Record<string, any>) =>
                updateFilterHandler('color', value)
            }}
            isEditing
          />
        )}
        {checkFieldVisibility('finish') && (
          <InfoCell
            label="Finish"
            value={tempFilters.finish || ''}
            inputConfig={{
              onChange: (value: string) => updateFilterHandler('finish', value)
            }}
            isEditing
          />
        )}
      </BoxWrapper>
      <SectionTitle value="Detail Info" />
      <Divider />
      <BoxWrapper enablePadding>
        {checkFieldVisibility('applicationArea') && (
          <InfoCell
            label="Application Area"
            value={tempFilters.applicationArea || ''}
            inputType="select"
            inputConfig={{
              items: applicationAreaDrpDwn,
              onChange: ({ value }: Record<string, any>) =>
                updateFilterHandler('applicationArea', value)
            }}
            isEditing
          />
        )}
        {checkFieldVisibility('packagingRef') && (
          <InfoCell
            label="PK Intercos Code"
            value={tempFilters.packagingRef || ''}
            inputConfig={{
              onChange: (value: string) => updateFilterHandler('packagingRef', value)
            }}
            isEditing
          />
        )}
      </BoxWrapper>
      <SectionTitle value="Formula Schema Data" />
      <Divider />
      <BoxWrapper enablePadding>
        {checkFieldVisibility('formulaSchemaCode') && (
          <InfoCell
            label="Formula Schema"
            value={tempFilters.formulaSchemaCode || ''}
            inputConfig={{
              onChange: (value: string) => updateFilterHandler('formulaSchemaCode', value)
            }}
            isEditing
          />
        )}
        {checkFieldVisibility('launchYear') && (
          <InfoCell
            label="Launch Year"
            value={tempFilters.launchYear || ''}
            inputConfig={{
              onChange: (value: string) => updateFilterHandler('launchYear', value)
            }}
            isEditing
          />
        )}
        {checkFieldVisibility('collection') && (
          <InfoCell
            label="Collection"
            value={tempFilters.collection || ''}
            inputType="select"
            inputConfig={{
              items: collectionDropDown,
              onChange: ({ value }: Record<string, any>) =>
                updateFilterHandler('collection', value)
            }}
            isEditing
          />
        )}
        {checkFieldVisibility('family') && (
          <InfoCell
            label="Family"
            value={tempFilters.family || ''}
            inputConfig={{
              onChange: (value: string) => updateFilterHandler('family', value)
            }}
            isEditing
          />
        )}
      </BoxWrapper>
    </Form>
  )
}
