import { Dropdown } from 'antd'
import styled from 'styled-components'

export const StyledAvatar = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  .name {
    display: contents;
    font-size: medium;
  }
  .avatar {
    color: #fff;
    background-color: ${({ theme }) => theme.palette.primary.main};
  }
`

export const StyledDropdown = styled(Dropdown)`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 6px;
  color: black;
`
