import { Form } from 'antd'
import { get } from 'lodash'
import { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, InfoCell } from '../../../components'
import { BoxWrapper } from '../../../components/BoxWrapper'
import { Divider } from '../../../components/Divider'
import { Modal } from '../../../components/Modal'
import { getAttachmentContentTypesList } from '../../../features/packaging/selectors'
import { IUpdateAttachProps } from './types'

const UpdateAttachModal: FC<IUpdateAttachProps> = ({ data, onSave, modalKey }) => {
  const dispatch = useDispatch()
  const attachmentContentTypesList = useSelector(getAttachmentContentTypesList)

  const [isSapCode, setIsSapCode] = useState(false)
  const [tempData, setTempData] = useState({})

  const saveHandler = () => {
    dispatch({ type: 'app/closeModal', payload: { modalKey } })
    onSave(tempData)
  }

  const updateHandler = (key: string, value: any) => {
    setTempData({
      ...tempData,
      [key]: value
    })
  }
  const initModal = () => {
    setTempData(data)
    const selectedContTypeId = get(data, 'attachmentContentTypeId', '')
    const contentTypeValue = attachmentContentTypesList.find(
      ({ id }: any) => id === selectedContTypeId
    )
    const isEnable = get(contentTypeValue, 'sapCodeEnabled', false)
    setIsSapCode(!isEnable)
  }

  return (
    <Modal
      modalKey={modalKey}
      btnIcon="EditOutlined"
      btnType="link"
      title="Update the attachment"
      size="medium"
      isSmall
      centered
      btnClicked={initModal}
      footer={[<Button key="save" label="Save" onClick={saveHandler} />]}
    >
      <Form layout="vertical">
        <Divider disableBorder />
        <BoxWrapper columnCount={2} enablePadding>
          <InfoCell
            label="Name"
            value={get(tempData, 'name', '')}
            isEditing
            inputConfig={{
              onChange: (value: any) => updateHandler('name', value)
            }}
          />
          <InfoCell
            label="Description"
            value={get(tempData, 'Description', '')}
            isEditing
            inputConfig={{
              onChange: (value: any) => updateHandler('Description', value)
            }}
          />
        </BoxWrapper>
        <BoxWrapper columnCount={2}>
          <InfoCell
            label="SAP Codes"
            dataType="tags"
            isEditing
            value={get(tempData, 'SAPCodes', [])}
            inputType="input-tag"
            inputConfig={{
              onChange: (value: any) => updateHandler(value, 'SAPCodes'),
              disabled: isSapCode
            }}
          />
        </BoxWrapper>
      </Form>
    </Modal>
  )
}

export default UpdateAttachModal
