import { Tabs } from 'antd'
import { get } from 'lodash'
import { FC, useCallback, useEffect } from 'react'
import { useCookies } from 'react-cookie'
import { useDispatch, useSelector } from 'react-redux'
import { footerHeight, headerHeight } from '../../assets/mixins'
import { Title } from '../../components/Title'
import { OrderListContent, PageContent } from '../../containers'
import { actions as appActions } from '../../features/app'
import { selectAppError } from '../../features/app/selectors'
import {
  LATEST_ORDER_COOKIE_NAME,
  ORDER_LIST_TABS
} from '../../features/orderList/constants'
import { OrderStatus } from '../../features/orderList/models'
import { orderListActions } from '../../features/orderList/reducer'
import {
  selectLatestForwarded,
  selectLatestProcessed,
  selectLatestRefused,
  selectSelectedTab
} from '../../features/orderList/selectors'
import { selectPermissions, selectUserEmail } from '../../features/users/selectors'
import { GenericLayout } from '../../layouts'

const StoreOrderList: FC = () => {
  const [cookies, setCookie] = useCookies()
  const userPermission = useSelector(selectPermissions)
  const userEmail = useSelector(selectUserEmail)
  const dispatch = useDispatch()
  const currentTab = useSelector(selectSelectedTab)
  const appError = useSelector(selectAppError)

  const latestForwarded = useSelector(selectLatestForwarded)
  const latestRefused = useSelector(selectLatestRefused)
  const latestProcessed = useSelector(selectLatestProcessed)

  const isSalaCampioni = userPermission.is?.salaCampioni || userPermission.is?.admin

  const openLastOrderPopup = (status: string, latestOrderId: string) => {
    if (latestOrderId) {
      let message = `Your last order ${latestOrderId} has been refused. Go to Refused, propose changes and send again`
      if (status === OrderStatus.FORWARDED)
        message = `A new order ${latestOrderId} has been forwarded. Go to Forwarded to handle it`
      if (status === OrderStatus.PROCESSED)
        message = `A new order ${latestOrderId} has been processed.`
      dispatch(
        appActions.openPopUp({
          key: 'last-order-advice',
          title: 'A New Order!',
          message
        })
      )
    }
  }

  const checkLatestOrder = useCallback(
    () => {
      const latestOrderCookie = get(cookies, `${LATEST_ORDER_COOKIE_NAME}`, {})

      // is user is salaCampioni, check among all forwarded orders
      if (isSalaCampioni) {
        // check latest forwarded order
        const lateForwardedCKUpdateDate = get(
          latestOrderCookie,
          `${OrderStatus.FORWARDED}.updatedDate`,
          ''
        )

        // if no cookie or different update date, update cookie
        if (
          !Object.keys(latestOrderCookie).length ||
          lateForwardedCKUpdateDate !== latestForwarded.updatedDate
        ) {
          setCookie(
            LATEST_ORDER_COOKIE_NAME,
            {
              ...latestOrderCookie,
              [OrderStatus.FORWARDED]: {
                updatedDate: latestForwarded.updatedDate
              }
            },
            {
              expires: new Date('01/01/2200'),
              path: '/'
            }
          )
          openLastOrderPopup(OrderStatus.FORWARDED, latestForwarded.id)
        }
      }

      // if user is not admin, checks for refused or processed orders
      if (!isSalaCampioni) {
        // check latest refused order
        const lateRefusedCKUpdateDate = get(
          latestOrderCookie,
          `${OrderStatus.REFUSED}.updatedDate`,
          ''
        )

        if (
          !Object.keys(latestOrderCookie).length ||
          lateRefusedCKUpdateDate !== latestRefused.updatedDate
        ) {
          setCookie(
            LATEST_ORDER_COOKIE_NAME,
            {
              ...latestOrderCookie,
              [OrderStatus.REFUSED]: {
                updatedDate: latestRefused.updatedDate
              }
            },
            {
              expires: new Date('01/01/2200'),
              path: '/'
            }
          )
          if (latestRefused.ownerEmail === userEmail) {
            openLastOrderPopup(OrderStatus.REFUSED, latestRefused.id)
          }
        }

        // check latest processed order
        const lateProcessedCKUpdateDate = get(
          latestOrderCookie,
          `${OrderStatus.PROCESSED}.updatedDate`,
          ''
        )

        if (
          !Object.keys(latestOrderCookie).length ||
          lateProcessedCKUpdateDate !== latestProcessed.updatedDate
        ) {
          setCookie(
            LATEST_ORDER_COOKIE_NAME,
            {
              ...latestOrderCookie,
              [OrderStatus.PROCESSED]: {
                updatedDate: latestProcessed.updatedDate
              }
            },
            {
              expires: new Date('01/01/2200'),
              path: '/'
            }
          )
          if (latestProcessed.ownerEmail === userEmail) {
            openLastOrderPopup(OrderStatus.PROCESSED, latestProcessed.id)
          }
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [openLastOrderPopup, userEmail]
  )

  useEffect(() => {
    checkLatestOrder()
  }, [checkLatestOrder, isSalaCampioni])

  useEffect(() => {
    dispatch(orderListActions.searchOrderList())
    dispatch(orderListActions.getLatestOrder())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const changeTabHandler = (activeStatus: string) => {
    dispatch(orderListActions.changeTab({ activeStatus }))
  }

  const activeKey = ORDER_LIST_TABS.reduce((acc, { value }, idx) => {
    if (value === currentTab) acc = idx
    return acc
  }, 0)

  return (
    <GenericLayout error={appError}>
      <PageContent
        footerHeight={footerHeight}
        headerHeight={headerHeight}
        justifyContent="flex-start"
      >
        <Title title="Order List" variantTitle="h3" />
        <Tabs onTabClick={changeTabHandler} activeKey={`${activeKey}`}>
          {ORDER_LIST_TABS.map(({ label }, index) => (
            <Tabs.TabPane tab={label} key={`${index}`}>
              <OrderListContent />
            </Tabs.TabPane>
          ))}
        </Tabs>
      </PageContent>
    </GenericLayout>
  )
}

export default StoreOrderList
