import styled from 'styled-components'

export const AttachmentCardContainer = styled.div`
  width: 100%;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  padding: 12px;
`

export const TitleWrapper = styled.div`
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
