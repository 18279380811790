import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { packagingActions } from '../../features/packaging'
import { PackagingDto } from '../../features/packaging/model'
import { selectRelatedOnDelete } from '../../features/packaging/selectors'
import { BoxInfo } from '../BoxInfo'
import { Divider } from '../Divider'
import { FlexSection } from '../FlexSection'
import { Loader } from '../Loader'
import { Modal } from '../Modal'
import Button from '../NewButton'
import { Text } from '../Text'
import TooltipBtn from '../TooltipBtn'
import { ContentWrapper } from './styeld'
import { IDeletePackaging } from './types'

export const DeletePackaging = ({
  rec,
  message,
  btnType,
  btnLabel,
  succcesCb = () => {}
}: IDeletePackaging) => {
  const dispatch = useDispatch()
  const status = rec.status
  const reletedProducts = useSelector(selectRelatedOnDelete)

  const checkPackagingStatus = useCallback(() => {
    dispatch(packagingActions.checkStatusOnDelete({ value: rec as PackagingDto }))
  }, [dispatch, rec])

  const handleClose = useCallback(() => {
    dispatch({ type: 'app/setModalKey' })
    dispatch({ type: 'packaging/resetRelatedProducts' })
  }, [dispatch])

  const handleDelete = useCallback(() => {
    dispatch(packagingActions.removePackaging({ value: rec, successCallback: succcesCb }))
    handleClose()
  }, [dispatch, handleClose, rec, succcesCb])
  return (
    <>
      {status === 'AVAILABLE' ? (
        <Modal
          btnName={btnLabel}
          btnClicked={checkPackagingStatus}
          modalKey={`${rec.id}-check-modal`}
          btnIcon="DeleteOutlined"
          size="medium"
          isSmall
          btnType={btnType}
          title=""
          centered
          footer={[
            <Button key="ghost" variant="ghost" label="Cancel" onClick={handleClose} />,
            <Button key="delete" label="Delete" onClick={handleDelete} />
          ]}
        >
          {reletedProducts ? (
            <FlexSection flexDirection="column" alignItems="center">
              <Divider disableBorder />
              {reletedProducts.length ? (
                <>
                  <Text
                    text="Changing the packaging status the products below won’t be RTG anymore."
                    variant="h4"
                  />
                  <Text
                    text="Are you sure do you want to delete the packaging?"
                    variant="h4"
                  />
                  <ContentWrapper>
                    <BoxInfo
                      label="List of products"
                      value={reletedProducts.toString().replaceAll(',', ', ')}
                      position="left"
                    />
                  </ContentWrapper>
                </>
              ) : (
                <Text
                  text="Are you sure do you want to delete the packaging?"
                  variant="h4"
                />
              )}
            </FlexSection>
          ) : (
            <Loader />
          )}
        </Modal>
      ) : (
        <TooltipBtn
          iconPosition="left"
          hasIcon
          btnLabel={btnLabel}
          iconName="DeleteOutlined"
          message={message}
          onConfirm={handleDelete}
          btnVariant="text"
        />
      )}
    </>
  )
}
