import styled from 'styled-components'

const filterBtnWidth = 68
export const FilterButtonWrapper = styled.div`
  width: ${filterBtnWidth}px;
`

export const addBtnWidth = 70
export const AddBtnWrapper = styled.div`
  width: ${addBtnWidth}px;
`
export const TagsWrapper = styled.div<{ hasAdd?: boolean }>`
  width: ${({ hasAdd }) =>
    `calc(100% - ${filterBtnWidth}px - ${hasAdd ? addBtnWidth : 0}px)`};
  padding: 0 10px;
`

export const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const EmptyWrapper = styled.div`
  min-height: 250px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
