import { Tabs } from 'antd'
import { get } from 'lodash'
import { FC } from 'react'
import { Button, FormulaSchemaTable, InfoCell, SectionTitle } from '../../../components'
import AddFSchemaModal from '../../../components/AddFSchemaModal'
import { BoxWrapper } from '../../../components/BoxWrapper'
import { Divider } from '../../../components/Divider'
import { EmptyState } from '../../../components/EmptyState'
import { FlexSection } from '../../../components/FlexSection'
import { FSCHEMA_TABLE_CONFIG } from '../../../components/FormulaSchema/FormulaSchemaTable/constants'
import { PACKAGE_TYPE } from '../../../features/packaging/model'
import { IDataFSchemaProps } from './types'

const DataFormulaSchema: FC<IDataFSchemaProps> = ({
  onRemoveFSchema = () => {},
  onSelectFormula = () => {},
  onSelectFSchema = () => {},
  packageData = {},
  formulaCodes = [],
  fSchemaSelected = [],
  isFSchemaSingleSelect = false,
  isSingleDeletion = false,
  formulaSelected = [],
  formulaTableConfig
}) => {
  const packageFamilies = get(packageData, 'packageFamilies', [])
  const isMulti = get(packageData, 'type', '').toLowerCase() === PACKAGE_TYPE.MULTI

  const deleteFSchemaHandler = (sideIdx: number, data?: Record<string, any>) => {
    onRemoveFSchema(sideIdx, data)
  }

  const selectHandler = (idx: number, selectedFormulas: any[]) => {
    onSelectFormula(idx, selectedFormulas)
  }

  const addFSchemaHandler = (idx: number, selected: any[]) => {
    onSelectFSchema(idx, selected)
  }

  const formulaSchemaDataRender = (sideIdx: number) => {
    const hasSchema =
      fSchemaSelected && !!fSchemaSelected[sideIdx] && !!fSchemaSelected[sideIdx].length

    const selectedFomulaList = formulaSelected[sideIdx]

    return (
      <>
        {!hasSchema && (
          <>
            <SectionTitle value="Formula Schema" />
            <Divider />
          </>
        )}
        {!hasSchema && (
          <EmptyState title={'No data'}>
            <FlexSection justifyContent="center" flexDirection={undefined}>
              <AddFSchemaModal
                onAddFSchema={(selected) => addFSchemaHandler(sideIdx, selected)}
                modalKey={`product-add-fschema-${sideIdx}`}
                isSingleSelect={isFSchemaSingleSelect}
              />
            </FlexSection>
          </EmptyState>
        )}
        {hasSchema && (
          <>
            {!!packageFamilies.length && (
              <BoxWrapper columnCount={4} enablePadding>
                <InfoCell
                  label="Category"
                  value={get(packageFamilies, `${sideIdx}.category`, '')}
                />
                <InfoCell
                  label="Subcategory"
                  value={get(packageFamilies, `${sideIdx}.subcategory`, '')}
                />
              </BoxWrapper>
            )}
            <Divider />
            <SectionTitle
              value="Selected Formula Schema"
              addon={
                hasSchema &&
                !isSingleDeletion && (
                  <Button
                    label="Delete"
                    iconName="DeleteOutlined"
                    onClick={() => deleteFSchemaHandler(sideIdx)}
                    variant="text"
                  />
                )
              }
            />

            <FormulaSchemaTable
              tableConfig={FSCHEMA_TABLE_CONFIG}
              dataList={fSchemaSelected[sideIdx] || []}
              isEditing={isSingleDeletion}
              hasDelBtn={isSingleDeletion}
              onDelete={(data) => deleteFSchemaHandler(sideIdx, data)}
            />
            <Divider disableBorder />
            <Divider disableBorder />
            <FormulaSchemaTable
              tableConfig={formulaTableConfig}
              dataList={formulaCodes ? formulaCodes[sideIdx] : []}
              enableSelect
              onSelect={(selected) => selectHandler(sideIdx, selected)}
              selectedRows={selectedFomulaList}
            />
          </>
        )}
      </>
    )
  }

  return (
    <>
      {!isMulti ? (
        formulaSchemaDataRender(0)
      ) : (
        <Tabs>
          {packageFamilies.map(({ id }: any, idx: number) => (
            <Tabs.TabPane tab={`Side ${idx + 1}`} key={`${id || idx}`}>
              {formulaSchemaDataRender(idx)}
            </Tabs.TabPane>
          ))}
        </Tabs>
      )}
    </>
  )
}

export default DataFormulaSchema
