import { TablePaginationConfig } from 'antd'
import { get } from 'lodash'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from '../../../components'
import { Divider } from '../../../components/Divider'
import { FlexSection } from '../../../components/FlexSection'
import { Modal } from '../../../components/Modal'
import Table from '../../../components/NewTable'
import { TOP_RIGHT } from '../../../components/NewTable/constants'
import { TagsContainer } from '../../../components/TagsContainer'
import { storeActions } from '../../../features/storeProducts'
import { PRODUCTS_TABLE_CONFIG } from '../../../features/storeProducts/constants'
import {
  getFilters,
  selectLoader,
  selectProductPagination,
  selectProducts,
  selectSearchRequirements
} from '../../../features/storeProducts/selectors'
import { columnsGeneratorHelper, rowsGeneratorHelperNew } from '../../../utils'
import { SearchContainer } from '../SearchContainer'

export const ResultContainer = () => {
  const selectedFilters = useSelector(getFilters)
  const items = useSelector(selectProducts)
  const isLoading = useSelector(selectLoader)
  const pagination = useSelector(selectProductPagination)
  const { page = 1, pageSize = 10, total = 10 } = pagination
  const isActiveButton = useSelector(selectSearchRequirements)

  const columnsGenerator = () => {
    const generatedColums = columnsGeneratorHelper(PRODUCTS_TABLE_CONFIG)
    return generatedColums
  }

  const dataRowGenerator = () => rowsGeneratorHelperNew(items, PRODUCTS_TABLE_CONFIG)

  const columns = columnsGenerator()
  const dataRow = items.length ? dataRowGenerator() : []
  const dispatch = useDispatch()

  const openModal = useCallback(() => {
    dispatch(storeActions.filterToTemp())
  }, [dispatch])

  const resetFilters = () => {
    dispatch(storeActions.resetTempFilters())
  }

  const searchProducts = () => {
    dispatch(storeActions.tempToFilter({ resetPageData: true }))
    dispatch(storeActions.searchStoreProducts())
    dispatch({ type: 'app/closeModal', payload: { modalKey: 'store-products' } })
  }

  const changePageHandler = (pageData: TablePaginationConfig) => {
    dispatch(
      storeActions.setPagination({
        page: get(pageData, 'current', 1),
        pageSize: get(pageData, 'pageSize', 10),
        total: get(pageData, 'total', 10)
      })
    )
    dispatch(storeActions.searchStoreProducts())
  }
  const removeTagHandler = (path: string) => {
    dispatch(storeActions.removeFilter({ path }))
    dispatch(storeActions.searchStoreProducts())
  }

  return (
    <>
      <Divider disableBorder />
      <FlexSection flexDirection="row" alignItems="center" justifyContent="start">
        <Modal
          modalKey="store-products"
          btnName="Filter"
          size="large"
          title="Products Search"
          btnClicked={openModal}
          footer={[
            <Button key="reset" label="Reset" variant="link" onClick={resetFilters} />,
            <Button
              key="search"
              label="Search"
              onClick={searchProducts}
              disabled={!isActiveButton}
              enableKeyPress
            />
          ]}
        >
          <SearchContainer />
        </Modal>
        <TagsContainer
          tags={selectedFilters}
          childId={'storeProducts'}
          inner={false}
          reducer={'storeProducts'}
          flowName={'storeProducts'}
          prefix={'filters'}
          allowNoFilters
          basePath={'filters'}
          onRemoveTag={removeTagHandler}
        />
      </FlexSection>
      <Table
        forceHeight
        pagination={{
          current: page,
          pageSize,
          total
        }}
        columns={columns}
        items={dataRow}
        rowKey="id"
        enablePagination
        paginationPosition={TOP_RIGHT}
        onChangePage={changePageHandler}
        isLoading={isLoading}
        actionColumnConfig={{
          detail: {
            btnLabel: 'Products Detail',
            pathKey: 'id'
          }
        }}
      />
    </>
  )
}
