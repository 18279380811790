import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SortOrder } from 'antd/lib/table/interface'
import { get, isArray, isEmpty, keyBy, set, unset } from 'lodash'
import { createAction } from 'redux-actions'
import { IError } from '../../containers/Error/types'
import { getCleanError } from '../../utils'
import { filterFormulaByStatus } from '../../utils/formulaSchemaHelpers'
import {
  PRODUCTS_CHANGE_PAGE,
  PRODUCTS_DELETE,
  PRODUCTS_GET_DETAILS,
  PRODUCTS_INIT_TEMP,
  PRODUCTS_SEARCH,
  PRODUCTS_UPDATE,
  PRODUCTS_EXPORT_EXCEL
} from './constants'
import { ISuccessInfo, ProductDto, ProductsState } from './model'

const initialState: ProductsState = {
  items: [],
  itemsByIds: {},
  detail: undefined,
  temp: undefined,
  tempFilters: {},
  filters: {},
  pagination: {
    totalPages: 0,
    page: 1,
    pageSize: 10,
    total: 0
  },
  successInfo: undefined,
  hasActionsAddon: true,
  isLoading: false,
  error: {}
}

const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    setProducts: (
      state: ProductsState,
      { payload }: PayloadAction<{ data: ProductDto[] }>
    ) => {
      const { data } = payload
      state.items = data.map((rec) => ({
        ...rec,
        // @ts-ignore
        _productsIndustrialCode: rec.productSides.map((ps) => ps.industrialCode).flat(),
        // @ts-ignore
        _productsIndustrialCodeFinish: rec.productSides
          .map((ps: any) => ps.industrialCodeFinish)
          .flat()
      }))
      state.itemsByIds = keyBy(state.items, 'id')
    },
    setDetail: (state: ProductsState, { payload }: PayloadAction<ProductDto | {}>) => {
      if (isEmpty(payload)) {
        state.detail = payload
        return
      }
      state.detail = {
        ...payload,
        // @ts-ignore
        productSides: payload.productSides.map((side) => ({
          ...side,
          formulaSchema: {
            ...side.formulaSchema,
            formula: filterFormulaByStatus(side.formulas)
          }
        }))
      }
      state.detail = {
        ...state.detail,
        // @ts-ignore
        industrialCode: state.detail.productSides[0].industrialCode,
        // @ts-ignore
        industrialCodeFinish: state.detail.productSides[0].industrialCodeFinish,
        // @ts-ignore
        colors: state.detail.productSides
          .flatMap(
            // @ts-ignore
            (ps) =>
              ps.formulaSchema.formula
                // @ts-ignore
                .map((f) => f.color)
                .filter(Boolean)
          )
          .join(', ')
      }
    },
    resetPagination: (state) => {
      state.pagination = initialState.pagination
    },
    setPagination: (
      state,
      {
        payload
      }: PayloadAction<{
        page: number
        pageSize?: number
        totalPages?: number
        total?: number
      }>
    ) => {
      state.pagination = { ...state.pagination, ...payload }
    },
    clearFilters: (state: ProductsState) => {
      state.filters = initialState.filters
      state.tempFilters = initialState.tempFilters
      state.pagination = initialState.pagination
    },
    removeFilter: (state: ProductsState, { payload }: any) => {
      unset(state.filters, payload)
    },
    applyActionsAddon: (state: ProductsState) => {
      state.hasActionsAddon = true
    },
    removeActionsAddon: (state: ProductsState) => {
      state.hasActionsAddon = false
    },
    tempToFilter: (state: ProductsState) => {
      const data = Object.entries(state.tempFilters).filter(([k, v]) =>
        v !== '' ? [k, v] : null
      )
      const filters = data.reduce((acc, [k, v]) => {
        return { ...acc, [k]: v }
      }, {})
      state.filters = filters
    },
    filterToTemp: (state: ProductsState) => {
      state.tempFilters = state.filters
    },
    updateTempFilter: (
      state: ProductsState,
      { payload }: PayloadAction<{ key: string; value: any }>
    ) => {
      const { key, value } = payload
      state.tempFilters[key] = value
    },
    applyFilters: (state: ProductsState) => {
      state.filters = state.tempFilters
    },
    cancelFilter: (
      state: ProductsState,
      { payload }: PayloadAction<{ path: string }>
    ) => {
      const { path } = payload
      const pathArray = path.split('.')
      const pathKey = pathArray.pop()
      const parentPath = pathArray.toString().replaceAll(',', '.') || ''
      const stateCopy = { ...state }
      const parentValue = get(stateCopy, parentPath)
      if (isArray(parentValue) && pathKey) {
        parentValue.splice(+pathKey, 1)
        set(state, parentPath, parentValue)
      } else {
        unset(state, path)
      }
    },
    setTemp: (state: ProductsState, { payload }: PayloadAction<{ data: ProductDto }>) => {
      state.temp = payload.data
    },
    updateTemp: (
      state: ProductsState,
      { payload }: PayloadAction<{ key: keyof ProductDto; value: any }>
    ) => {
      const { key, value } = payload
      if (!state.temp) {
        state.temp = {}
      }
      state.temp[key] = value
    },
    setSuccess: (state: ProductsState, { payload }: PayloadAction<ISuccessInfo>) => {
      state.successInfo = payload
    },
    clearSuccess: (state: ProductsState) => {
      state.successInfo = initialState.successInfo
    },
    clearUpdated: (state: ProductsState) => {
      state.successInfo = initialState.successInfo
    },
    clearTemp: (state: ProductsState) => {
      state.temp = initialState.temp
    },
    setLoader: (state: ProductsState, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload
    },
    setError: (state: ProductsState, { payload }: PayloadAction<IError | {}>) => {
      state.error = getCleanError(payload)
    },
    clearError: (state: ProductsState) => {
      state.error = initialState.error
    }
  }
})

export const actions = {
  ...productsSlice.actions,
  searchProducts: createAction(PRODUCTS_SEARCH),
  changePage: createAction<{ page: number; field: string; order: SortOrder }>(
    PRODUCTS_CHANGE_PAGE
  ),
  getDetails: createAction<{ id: string }>(PRODUCTS_GET_DETAILS),
  initTemp: createAction(PRODUCTS_INIT_TEMP),
  updateProduct: createAction(PRODUCTS_UPDATE),
  deleteProduct: createAction(PRODUCTS_DELETE),
  exportExcel: createAction(PRODUCTS_EXPORT_EXCEL)
}

export default productsSlice.reducer
