import { createSelector } from 'reselect'
import type { RootState } from '../store'

const selectMasterSpec = (state: RootState) => state.masterSpec

export const selectMasterSpecList = createSelector(
  selectMasterSpec,
  (masterSpec) => masterSpec.items
)

export const selectProjectSpecList = createSelector(
  selectMasterSpec,
  (masterSpec) => masterSpec.projectSpecificationList
)

export const selectMasterSpecAddNewList = createSelector(
  selectMasterSpec,
  (masterSpec) => masterSpec.addNewList
)

export const selectMasterSpecDetail = createSelector(
  selectMasterSpec,
  (masterSpec) => masterSpec.detail
)

export const selectProjectSpecDetail = createSelector(
  selectMasterSpec,
  (masterSpec) => masterSpec.projectSpecificationDetail
)

export const selectCharacteristicList = createSelector(
  selectMasterSpec,
  (masterSpec) => masterSpec.characteristicList
)

export const selectNewMasterSpec = createSelector(
  selectMasterSpec,
  (masterSpec) => masterSpec.newMasterSpecification
)

export const selectNewProjectSpec = createSelector(
  selectMasterSpec,
  (masterSpec) => masterSpec.newProjectSpecification
)

export const selectNewCharacteristic = createSelector(
  selectMasterSpec,
  (masterSpec) => masterSpec.newCharacteristic
)

export const selectNewProjectCharacteristic = createSelector(
  selectMasterSpec,
  (masterSpec) => masterSpec.newProjectCharacteristic
)

export const selectSpecificationSections = createSelector(
  selectMasterSpec,
  (masterSpec) => masterSpec.specificationSections
)

export const selectAlertMessage = createSelector(
  selectMasterSpec,
  (masterSpec) => masterSpec.alert
)

export const selectIsLoading = createSelector(
  selectMasterSpec,
  (masterSpec) => masterSpec.isLoading
)

export const selectMasterSpecError = createSelector(
  selectMasterSpec,
  (masterSpec) => masterSpec.error
)
