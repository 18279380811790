import { Tooltip } from 'antd'
import { filter } from 'lodash'
import { FC, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import { Table } from '../../../components'
import { Modal } from '../../../components/Modal'
import Button from '../../../components/NewButton'
import { TOP_RIGHT } from '../../../components/NewTable/constants'
import { ATTACHMENTS_IN_MASTER_SPECIFICATIONS_TABLE_CONFIG } from '../../../components/Packaging/AttachmentsTable/constants'
import { IMasterSpecSearchFields } from '../../../features/masterSpec/model'
import { masterSpecActions } from '../../../features/masterSpec/reducer'
import { masterSpecStatus } from '../../../pages/MasterSpecificationDetail/constants'
import { columnsGeneratorHelper } from '../../../utils'
import { IAddMasterSpeciModalProps } from './types'

export const AddProjectSpecificationModal: FC<IAddMasterSpeciModalProps> = ({
  packageId,
  masterSpecificationList
}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [searchParameters, setSearchParameters] = useState<IMasterSpecSearchFields>({})
  const [isSearch, setIsSearch] = useState<boolean>(false)
  const columns = columnsGeneratorHelper(
    ATTACHMENTS_IN_MASTER_SPECIFICATIONS_TABLE_CONFIG
  )

  const modalKey = 'add-new-project-spec'

  const filteredList = filter(
    masterSpecificationList,
    (elem) => elem.status === masterSpecStatus.COMPLETED
  )

  const backHandler = () => {
    setIsSearch(false)
  }

  const closeModalHandler = () => {
    dispatch({ type: 'app/closeModal', payload: { modalKey: modalKey } })
  }

  const searchHandler = () => {
    dispatch(masterSpecActions.getList({ filters: searchParameters, isAddNew: true }))
  }

  const { packageVariantId } = useParams()

  const actionConfig = {
    columnTitle: 'Actions',
    actionRenderer: ({ data }: any) => (
      <Tooltip title="Detail">
        <Button
          onClick={() => {
            const { masterSpecificationCharacteristics, ...newProjSpecDetails } = data
            const newProjectSpecDetails = {
              ...newProjSpecDetails,
              status: masterSpecStatus.DRAFT,
              concernedSapCodes: [],
              projectSpecificationCharacteristics:
                masterSpecificationCharacteristics.filter(
                  ({ qualityInspection }: any) => !!qualityInspection
                )
            }
            dispatch(
              masterSpecActions.setProjectSpecificationDetail(newProjectSpecDetails)
            )
            closeModalHandler()
            if (packageVariantId)
              navigate(
                `/wikipack/packaging/${packageId}/variant/${packageVariantId}/project-specifications/new/${data.id}`
              )
            else navigate(`/wikipack/packaging/${packageId}/project-specifications/new/`)
          }}
          iconName="FormOutlined"
          variant="link"
        />
      </Tooltip>
    )
  }

  const footer = isSearch
    ? [
        <Button key="back" label="Back" variant="link" onClick={backHandler} />,
        <Button
          key="cancel"
          label="Cancel"
          variant="ghost"
          onClick={closeModalHandler}
        />,
        <Button key="search" label="Search" onClick={searchHandler} enableKeyPress />
      ]
    : [<Button key="cancel" label="Cancel" variant="ghost" onClick={closeModalHandler} />]

  return (
    <Modal
      btnName="Add"
      btnClicked={() => setSearchParameters({})}
      btnType="ghost"
      title="Select Master specification"
      modalKey={modalKey}
      size={'medium'}
      centered
      isSmall
      footer={footer}
    >
      <>
        <Table
          enablePagination
          paginationPosition={TOP_RIGHT}
          columns={columns}
          items={filteredList}
          rowKey="id"
          actionColumnConfig={actionConfig}
          forceHeight
        />
      </>
    </Modal>
  )
}
