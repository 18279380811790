import { Result } from 'antd'
import { ReactNode } from 'react'

type Status = 'success' | 'warning' | '403' | '404' | '500'

type Props = {
  children?: ReactNode
  status: Status
  title?: string
  subTitle?: string
}

export const Advice = ({ children, status, title, subTitle }: Props) => {
  return <Result status={status} title={title} subTitle={subTitle} extra={children} />
}
