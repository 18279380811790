import styled from 'styled-components'
import { IStyledInfoBox } from './types'

export const StyledInfoBox = styled.div<IStyledInfoBox>`
  padding: 10px 10px;
  display: flex;
  flex-direction: column;
  .label {
    font-size: medium;
    font-weight: 600;
    margin-bottom: 0;
  }
  .value {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 2px 10px 0 0;

    & > * {
      font-size: medium;
      font-weight: lighter;
      margin: 0;
    }
    > span {
      margin-left: 10px;
    }
  }
  ${({ isEditable }) => isEditable && { border: '1px solid grey', cursor: 'pointer' }}
`
export const StyledLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 6px;
  & svg {
    transform: translateY(3px);
  }
`
