import { IDropdown } from '../types'

export const ENTITY_ADD_TOOLING = 'entity/ENTITY_ADD_TOOLING'
export const ENTITY_CREATE = 'entity/ENTITY_CREATE'

export const entityTypeDropdown: IDropdown[] = [
  {
    name: 'PRODUCT WITH PACKAGE',
    value: 'PRODUCT_WITH_PACKAGE'
  },
  {
    name: 'MULTIBULK WITH PACKAGE',
    value: 'MULTIBULK_WITH_PACKAGE'
  },
  {
    name: 'PALETTE',
    value: 'PALETTE'
  },
  {
    name: 'PACKAGE',
    value: 'PACKAGE'
  },
  {
    name: 'BULK WITHOUT PACKAGE',
    value: 'BULK_WITHOUT_PACKAGE'
  },
  {
    name: 'MULTIBULK WITHOUT PACKAGE',
    value: 'MULTIBULK_WITHOUT_PACKAGE'
  }
]
