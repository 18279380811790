import { get } from 'lodash'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { Tag } from '../Tag'
import {
  checkIsClosable,
  getChipsValueLabel,
  mapChipsLabel,
  normalizeTags
} from './keyMapChips'
import { StyledTagsContainer, StyledTagsContainerInternal } from './styled'

type Props = {
  tags: Record<string, any> | null
  prefix?: string
  basePath?: string
  childId: string
  inner: boolean
  reducer: string
  flowName: string
  blocked?: string
  firstCall?: boolean
  allowNoFilters?: boolean
  onRemoveTag?: (path: string) => void
}

export const TagsContainer = ({
  tags,
  inner,
  childId,
  basePath,
  prefix,
  reducer,
  flowName,
  blocked = '',
  firstCall = true,
  allowNoFilters,
  onRemoveTag
}: Props) => {
  const Container = inner ? StyledTagsContainerInternal : StyledTagsContainer
  const dispatch = useDispatch()

  const onRemove = useCallback(
    (path: string) => {
      if (onRemoveTag) {
        return onRemoveTag(path)
      }
      dispatch({
        type: `${basePath ? flowName : reducer}/removeFilterSaga`,
        payload: { reducer, flowName, path: `${path.replace(reducer + '.', '')}` }
      })
    },
    [onRemoveTag, dispatch, basePath, flowName, reducer]
  )
  if (!tags) return null
  const normalizedTags = Object.entries(normalizeTags(tags, basePath || prefix)).filter(
    ([k, { value }]: any) =>
      value !== null && value !== undefined && k !== '_type' && k !== 'id'
  )

  return (
    <Container>
      {normalizedTags &&
        normalizedTags.map(([k, v]: any) =>
          v.value !== '' && Array.isArray(v.value) ? (
            v.value.map(
              (item: string, i: string) =>
                item && (
                  <Tag
                    key={`${k}${i}`}
                    closable={checkIsClosable(normalizedTags, reducer, k, allowNoFilters)}
                    value={`${get(mapChipsLabel, `${flowName}.${k}`, k)} : ${
                      getChipsValueLabel(flowName, k, item) || item
                    }`}
                    path={`${v.path}.${i}`}
                    onRemove={onRemove}
                  />
                )
            )
          ) : (
            <Tag
              key={k}
              closable={checkIsClosable(normalizedTags, reducer, k, allowNoFilters)}
              value={`${get(mapChipsLabel, `${flowName}.${k}`, k)} : ${
                getChipsValueLabel(flowName, k, v.value) || v.value
              }`}
              path={v.path}
              onRemove={onRemove}
            />
          )
        )}
    </Container>
  )
}
