import { useNavigate } from 'react-router'
import { Button } from '../../../components'
import { AppRoutes } from '../../../pages/constants'
import { EmptyBodyContainer, Message } from './styled'

const EmptyBody = () => {
  const navigate = useNavigate()

  return (
    <EmptyBodyContainer>
      <Message>You don’t have any order in forwarded list.</Message>
      <Button
        label="Create New One"
        variant="ghost"
        shape="square"
        onClick={() => navigate(AppRoutes.STORE_PRODUCTS_SEARCH)}
      />
    </EmptyBodyContainer>
  )
}

export default EmptyBody
