import styled from 'styled-components'
import {
  flexDirection,
  FlexDirectionProps,
  justifyContent,
  JustifyContentProps,
  borderBottom,
  BorderBottomProps,
  borderTop,
  BorderTopProps,
  DisplayProps,
  PaddingProps
} from 'styled-system'
import { largeSizeWidth, mediumSizeWidth, smallSizeWidth } from '../../assets/mixins'

export interface ISize {
  sm: number
  md: number
  lg: number
}

export type BoxWrapperProps = {
  columnCount?: number
  enablePadding?: boolean
  stickyBottom?: boolean
  sticky?: 'top' | 'bottom'
  enableMargin?: boolean
  size?: ISize
} & FlexDirectionProps &
  JustifyContentProps &
  BorderBottomProps &
  BorderTopProps &
  DisplayProps &
  PaddingProps

export const StyledBoxWrapper = styled.div<BoxWrapperProps>`
  display: flex;
  align-items: center;
  ${flexDirection}
  ${justifyContent}
  ${borderBottom}
  ${borderTop}
  flex-wrap: wrap;
  z-index: 2;
  width: 100%;
  ${({ enableMargin }) => enableMargin && { marginTop: '8%' }}

  > div {
    min-width: auto;
    ${({ columnCount }) => !!columnCount && `width: ${100 / columnCount}%`};

    ${({ enablePadding }) => enablePadding && { padding: '1%' }}
    @media (min-width: ${smallSizeWidth}px) {
      ${({ size }) => `width: ${100 / (size?.sm || 2)}%`};
    }
    @media (min-width: ${mediumSizeWidth}px) {
      ${({ size }) => `width: ${100 / (size?.md || 3)}%`};
    }
    @media (min-width: ${largeSizeWidth}px) {
      ${({ size }) => `width: ${100 / (size?.lg || 4)}%`};
    }
  }

  > .ant-form-item {
    ${({ enablePadding }) => enablePadding && { padding: '0 1%' }}
  }

  ${({ sticky }) =>
    !!sticky &&
    `
      position: sticky;
      left: 0;
      z-index: 2;
  `}

  ${({ sticky }) => sticky === 'top' && 'top: 16px;'}
  ${({ sticky }) => sticky === 'bottom' && 'bottom: 0;'}
`
