import { createSelector } from 'reselect'
import type { RootState } from '../store'

const selectUser = (state: RootState) => state.user

export const getUser = createSelector(selectUser, (user) => user)

export const getUserData = createSelector(selectUser, (user) => user.data)

export const selectUserDisplayName = createSelector(selectUser, ({ data }) => {
  return data.displayName
})

export const selectUserMail = createSelector(selectUser, ({ data }) => {
  return `${data.mail ? data.mail : ''}`
})

export const selectUserEmail = createSelector(selectUser, ({ data }) => {
  return data.userPrincipalName
})

export const selectUserRole = createSelector(selectUser, (data) => {
  return data.roles || []
})

export const selectPermissions = createSelector(selectUser, (data) => {
  return data.permissions
})
