import { AnyAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { saveAs } from 'file-saver'
import { get } from 'lodash'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import { actions as productActions } from '../product/reducer'
import {
  searchTooling,
  getToolingById,
  createTooling,
  deleteToolingById,
  updateToolingById,
  getToolingTaxonomies,
  exportToolings
} from './api'
import {
  TOOLING_GET,
  TOOLING_GET_DETAILS,
  TOOLING_DELETE,
  TOOLING_UPDATE,
  TOOLING_CREATE,
  TOOLING_EXPORT
} from './constants'
import {
  ToolingDto,
  ToolingResultSearch,
  ToolingTaxonomy,
  ToolingTaxonomyDto
} from './model'
import { toolingActions } from './reducer'
import { getToolingFilters } from './selectors'

// use this value because the pagination is disabled
const customPageSize = 1000
const { clearTemp } = productActions

function* fetchToolingsSaga({ payload: { flowName, noNext } }: AnyAction) {
  const filters: ToolingTaxonomy = yield select(getToolingFilters)
  // const { page: selectedPage, pageSize: selectedPageSize }: Pagination = yield select(
  //   selectToolingPagination
  // )

  try {
    yield put(clearTemp())
    const toolings: AxiosResponse<ToolingResultSearch> = yield call(searchTooling, {
      ...filters,
      // page: selectedPage,
      pageSize: customPageSize
    })
    const { results, totalPages, page, pageSize, total } = yield toolings

    yield put(toolingActions.setPagination({ totalPages, page, pageSize, total }))
    yield put(toolingActions.setTooling({ data: results }))
  } catch (err) {}
}

function* getToolingsSaga() {
  yield put(toolingActions.setIsLoading(true))
  const filters: ToolingTaxonomy = yield select(getToolingFilters)

  // const { page: selectedPage, pageSize: selectedPageSize }: Pagination = yield select(
  //   selectToolingPagination
  // )

  try {
    yield put(clearTemp())
    const toolings: AxiosResponse<ToolingResultSearch> = yield call(searchTooling, {
      ...filters,
      // page: selectedPage,
      pageSize: customPageSize
    })
    const { results, totalPages, page, pageSize, total } = yield toolings

    yield put(toolingActions.setPagination({ totalPages, page, pageSize, total }))
    yield put(toolingActions.setTooling({ data: results }))
  } catch (err: any) {
    yield put(toolingActions.setError(err))
  } finally {
    yield put(toolingActions.setIsLoading(false))
  }
}

function* exportToolingsSaga() {
  yield put(toolingActions.setIsLoading(true))
  const filters: ToolingTaxonomy = yield select(getToolingFilters)
  try {
    const response: Blob = yield call(exportToolings, {
      ...filters
    })
    saveAs(
      new Blob([response], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      }),
      'toolings.xlsx'
    )
  } catch (err: any) {
    yield put(toolingActions.setError(err))
  } finally {
    yield put(toolingActions.setIsLoading(false))
  }
}

function* removeFilters({ payload }: AnyAction) {
  yield put(toolingActions.removeFilter(payload.path))
  // @ts-ignore
  yield fetchToolingsSaga({ payload: { flowName: payload.flowName, noNext: true } })
}

function* getToolingDetails({ payload }: AnyAction) {
  yield put(toolingActions.setIsLoading(true))
  try {
    const tooling: ToolingDto = yield call(getToolingById, payload.id)
    yield put(toolingActions.setToolingDetailData(tooling))
  } catch (err: any) {
    yield put(toolingActions.setToolingDetailData({}))
    yield put(toolingActions.setError(err))
  } finally {
    yield put(toolingActions.setIsLoading(false))
  }
}

function* deleteTooling({ payload }: AnyAction) {
  yield put(toolingActions.setIsLoading(true))
  try {
    yield call(deleteToolingById, payload.id)
  } catch (err: any) {
    yield put(toolingActions.setError(err))
  } finally {
    yield put(toolingActions.setIsLoading(false))
  }
}

function* getToolingDropdowns() {
  try {
    const taxonomies: ToolingTaxonomyDto = yield call(getToolingTaxonomies)
    // @ts-ignore
    const mappedTaxonomies = Object.entries(taxonomies).reduce(
      (acc, [k, v]) => ({
        ...acc,
        // @ts-ignore
        [k]: v.map((tax) => ({ name: tax, value: tax }))
      }),
      {}
    )
    yield put(toolingActions.setDropdown(mappedTaxonomies))
  } catch (error) {}
}

function* updateTooling({ payload }: AnyAction) {
  yield put(toolingActions.setIsLoading(true))
  const id = get(payload, 'id', '')
  try {
    yield call(updateToolingById, id, payload)
  } catch (err: any) {
    yield put(toolingActions.setError(err))
  } finally {
    const tooling: ToolingDto = yield call(getToolingById, id)
    yield put(toolingActions.setToolingDetailData(tooling))
    yield put(toolingActions.setIsLoading(false))
    yield call(getToolingDropdowns)
  }
}

function* createToolingSaga({ payload }: AnyAction) {
  yield put(toolingActions.setIsLoading(true))
  try {
    const tooling: ToolingDto = yield call(createTooling, payload)
    yield put(
      toolingActions.setSuccessInfo({
        message: 'Tooling Created succesfully',
        code: tooling.code
      })
    )
    yield put(toolingActions.setToolingDetailData(tooling))
  } catch (err: any) {
    yield put(toolingActions.setError(err))
  } finally {
    yield put(toolingActions.setIsLoading(false))
  }
}

export function* toolingsSaga() {
  yield takeLatest(TOOLING_GET, getToolingsSaga)
  yield takeLatest('tooling/removeFilterSaga', removeFilters)
  yield takeLatest(TOOLING_GET_DETAILS, getToolingDetails)
  yield takeLatest(TOOLING_DELETE, deleteTooling)
  yield takeLatest(TOOLING_UPDATE, updateTooling)
  yield takeLatest(TOOLING_CREATE, createToolingSaga)
  yield takeLatest(TOOLING_EXPORT, exportToolingsSaga)
}
