import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { set, get } from 'lodash'
import { IError } from '../../containers/Error/types'
import { getCleanError } from '../../utils'
import { checkIsFormulaStatusValid } from '../../utils/formulaSchemaHelpers'
import { Formula, FormulaSchemaDto } from '../formulaSchema/model'
import { PackagingDto } from '../packaging/model'
import { ToolingDto } from '../tooling/model'
import {
  PRODUCT_ADD_TOOLING,
  PRODUCT_CREATE,
  PRODUCT_FILTER_TAXONOMIES,
  PRODUCT_SELECT_FSCHEMA,
  productStatusDropdown
} from './constants'
import { IProductDropdown, ISuccessInfo, ProductState } from './model'

type CustomFields = {
  item: {
    formulaCodesDEVEX?: string[]
    colors?: string[]
  }
}

const initialState: ProductState & CustomFields = {
  item: {
    sides: [{ packageVariantId: '' }],
    regulatoryUpdateDates: {
      general: []
    }
  },
  dropDown: {
    status: productStatusDropdown
  },
  packaging: undefined,
  schema: undefined,
  temp: undefined,
  colors: undefined,
  formulaCodesDEVEX: undefined,
  tooling: undefined,
  successInfo: undefined,
  isLoading: false,
  error: {}
}

const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    fetchProductSuccess: (
      state: ProductState,
      { payload }: PayloadAction<ProductState['item']>
    ) => {
      state.item = payload
    },
    updateProduct: (
      state: ProductState,
      { payload }: PayloadAction<{ key: string; value: any }>
    ) => {
      const { key, value } = payload
      state.item[key] = value
    },
    resetItem: (state: ProductState) => {
      state.item = initialState.item
    },
    setPackaging: (state: ProductState, { payload }: PayloadAction<PackagingDto[]>) => {
      set(state.item, 'sides[0].packageVariantId', payload[0].id)
      set(state.item, 'airtightPackagingNeeded', payload[0].airtight)
      state.packaging = payload
    },
    updatePackaging: (
      state: ProductState,
      { payload }: PayloadAction<PackagingDto[]>
    ) => {
      state.item.packaging = { ...payload[0], thumbnail: { url: payload[0].image } }
      state.packaging = payload
    },
    setVariant: (
      state: ProductState,
      { payload }: PayloadAction<{ sideIdx: number; selected: Record<string, any> }>
    ) => {
      const { sideIdx, selected } = payload
      const sides = [...state.item.sides]
      sides[sideIdx] = {
        vialsDetailId: get(selected, 'id', ''),
        packageVariantId: get(selected, 'idPackaging', ''),
        airtightPackagingNeeded: get(selected, 'airtight', false)
      }
      state.item.sides = sides
      state.item.status = 'READY_TO_GO'
    },
    setTooling: (state: ProductState, { payload }: PayloadAction<ToolingDto[]>) => {
      set(state.item, 'sides[0].toolingId', payload[0].id)
      state.tooling = payload
    },
    deletePackaging: (state: ProductState) => {
      set(state.item, 'sides[0].formulaSchemaId', '')
      state.packaging = initialState.packaging
    },
    removePackaging: (state: ProductState) => {
      state.item.packaging = {}
      state.item.sides = initialState.item.sides
      state.schema = initialState.schema
      state.packaging = initialState.packaging
    },
    deleteTooling: (state: ProductState) => {
      state.tooling = initialState.tooling
      set(state.item, 'sides[0].toolingId', '')
    },
    deleteSchema: (state: ProductState) => {
      set(state.item, 'sides[0].formulaSchemaId', '')
      state.schema = initialState.schema
    },
    removeSchema: (
      state: ProductState,
      { payload }: PayloadAction<{ sideIdx: number }>
    ) => {
      const { sideIdx } = payload
      state.item.sides[sideIdx].fSchema = undefined
      const schema = state.schema || []
      schema[sideIdx] = []
      state.schema = schema
    },
    setSchema: (state: ProductState, { payload }: PayloadAction<FormulaSchemaDto[]>) => {
      set(state.item, 'sides[0].formulaSchemaId', payload[0].id)
      state.schema = [payload]
    },
    applySchema: (
      state: ProductState,
      { payload }: PayloadAction<{ fSchema: FormulaSchemaDto; sideIdx: number }>
    ) => {
      const { sideIdx, fSchema } = payload
      state.item.sides[sideIdx].fSchema = { ...fSchema, formula: [] }
      const newSchema = get(state, 'schema', [])
      newSchema[sideIdx] = [fSchema]
      state.schema = newSchema
    },
    applyFormula: (
      state: ProductState,
      { payload }: PayloadAction<{ formulas: Formula[]; sideIdx: number }>
    ) => {
      const { sideIdx, formulas } = payload

      // workaround to get the correct regulatory dates, until now the dates were removed from the object because the selected object was the one returned from the form, having only the displayed properties. Furthermore the used date was updatedAt
      // the formulaCodesDEVEX is nesting it appears to be wrong (only one element in the second nested array, always)
      const formulaRegulatoryLastUpdate =
        state.formulaCodesDEVEX?.[sideIdx][0].regulatoryLastUpdate

      const formulaSchemaRegulatoryLastUpdate =
        state.schema?.[sideIdx][0].regulatoryLastUpdate

      state.item.regulatoryUpdateDates = {
        ...state.item.regulatoryUpdateDates,
        [sideIdx]: [formulaRegulatoryLastUpdate, formulaSchemaRegulatoryLastUpdate],
        general: [
          ...state.item.regulatoryUpdateDates.general,
          formulaRegulatoryLastUpdate,
          formulaSchemaRegulatoryLastUpdate
        ]
      }

      state.item.sides[sideIdx].fSchema = {
        ...get(state, `item.sides[${sideIdx}].fSchema`, {}),
        formula: formulas
      }
    },
    clearTemp: (state: ProductState) => {
      state.temp = initialState.temp
    },
    resetProduct: (state: ProductState) => {
      return {
        ...initialState,
        dropDown: state.dropDown
      }
    },
    setFormulaCodesDevex: (
      state: ProductState,
      { payload }: PayloadAction<Formula[]>
    ) => {
      state.formulaCodesDEVEX = [
        payload.map(({ formulaCodeDEVEX, name, ...args }) => ({
          ...args,
          formulaName: name,
          codeDevex: formulaCodeDEVEX,
          id: formulaCodeDEVEX
        }))
      ]
    },
    setFormulaCodes: (
      state: ProductState,
      { payload }: PayloadAction<{ sideIdx: number; formulas: Formula[] }>
    ) => {
      const { sideIdx, formulas } = payload
      const newFormula = get(state, 'formulaCodesDEVEX', [])
      newFormula[sideIdx] = formulas
        .map(({ formulaCodeDEVEX, name, finish, ...args }) => ({
          ...args,
          id: args.id,
          formulaCodeDEVEX: formulaCodeDEVEX,
          formulaName: name,
          icFinish: finish
        }))
        .filter(({ status }) => checkIsFormulaStatusValid(status))
      state.formulaCodesDEVEX = newFormula
    },
    removeFormulaCodesDEVEX: (
      state: ProductState,
      { payload }: PayloadAction<{ sideIdx: number }>
    ) => {
      const { sideIdx } = payload
      const newFormula = get(state, 'formulaCodesDEVEX', [])
      newFormula[sideIdx] = []
      state.formulaCodesDEVEX = newFormula
    },
    updateDropdown: (
      state,
      { payload }: PayloadAction<{ dropDown: IProductDropdown }>
    ) => {
      // @ts-ignore
      state.dropDown = { ...state.dropDown, ...payload.dropDown }
    },
    setIsLoading: (state: ProductState, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload
    },
    setError: (state: ProductState, { payload }: PayloadAction<IError>) => {
      state.error = getCleanError(payload)
    },
    clearError: (state: ProductState) => {
      state.error = initialState.error
    },
    setSuccess: (state: ProductState, { payload }: PayloadAction<ISuccessInfo>) => {
      state.successInfo = payload
    },
    clearSuccess: (state: ProductState) => {
      state.successInfo = initialState.successInfo
    }
  }
})

export const actions = {
  ...productSlice.actions,
  addTooling: createAction<{ selected: any[] }>(PRODUCT_ADD_TOOLING),
  selectFSchema: createAction<{ idx: number; selected: any[] }>(PRODUCT_SELECT_FSCHEMA),
  createProduct: createAction(PRODUCT_CREATE),
  filterTaxonomies: createAction<{
    filters: Record<string, any>
  }>(PRODUCT_FILTER_TAXONOMIES)
}

export default productSlice.reducer
