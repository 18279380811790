import { Form } from 'antd'
import { get, isEmpty } from 'lodash'
import { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, InfoCell } from '../../../components'
import { BoxWrapper } from '../../../components/BoxWrapper'
import { Divider } from '../../../components/Divider'
import { Modal } from '../../../components/Modal'
import { Text } from '../../../components/Text'
import {
  getAttachmentContentTypesList,
  getDropdownAttachmentStatus,
  getDropdownFilteredContentTypes
} from '../../../features/packaging/selectors'
import { IAddModalProps } from './types'

const AddModal: FC<IAddModalProps> = ({
  modalKey,
  data = {},
  onConfirm = () => {},
  isContentTypeEnable = false,
  btnName,
  btnType = 'text',
  btnIcon,
  title = 'Information',
  subtitle = '',
  uploadTopMess = 'Drop or browse the attachment',
  hasStatus,
  confirmLabel = 'Save',
  hasCancel = false
}) => {
  const dispatch = useDispatch()
  const [tempData, setTempData] = useState({})

  const dropdownAttachmentTypes = useSelector(getDropdownFilteredContentTypes)
  const attachmentContentTypesList = useSelector(getAttachmentContentTypesList)
  const dropdownAttachmentStatus = useSelector(getDropdownAttachmentStatus)

  const resetTempHandler = () => {
    setTempData({})
  }

  const closeModalHandler = () => {
    dispatch({ type: 'app/closeModal', payload: { modalKey } })
    setTempData({})
  }

  const confirmHandler = () => {
    dispatch({ type: 'app/closeModal', payload: { modalKey } })
    onConfirm(tempData)
  }

  const isConfirmDisable =
    !isEmpty(get(tempData, 'file', {})) &&
    !get(tempData, 'name', '') &&
    isContentTypeEnable &&
    !get(tempData, 'contentType', '')

  const updatedTempData = (key: string, value: any) => {
    setTempData({ ...tempData, [key]: value })
  }

  const updateImageHanlder = (value: any) => {
    const tempName = get(tempData, 'name', '')
    const newTempData = {
      ...tempData,
      name: tempName || value.name,
      file: value
    }

    setTempData(newTempData)
  }

  const updateContentType = ({ value }: Record<string, any>) => {
    const selectedContType = attachmentContentTypesList.find(
      ({ id }: { id: string }) => value === id
    )
    const contentTypeLabel = get(selectedContType, 'name', '')
    const newTempData = {
      ...tempData,
      contentType: contentTypeLabel,
      SAPCodes: [],
      contentTypeId: value
    }
    setTempData(newTempData)
  }

  const initTempHandler = () => {
    if (!isEmpty(data)) {
      setTempData({
        id: get(data, 'id', ''),
        contentType: get(data, 'contentType', ''),
        contentTypeId: get(data, 'attachmentContentTypeId', '')
      })
    }
  }

  const checkIfSapDisable: () => boolean = () => {
    const selectedContTypeId = get(tempData, 'contentTypeId', '')
    const contentTypeValue = attachmentContentTypesList.find(
      ({ id }: any) => id === selectedContTypeId
    )
    return !get(contentTypeValue, 'sapCodeEnabled', false)
  }

  const footer = [
    <Button
      key="confirm"
      label={confirmLabel}
      onClick={confirmHandler}
      disabled={isConfirmDisable}
    />
  ]

  if (hasCancel) {
    footer.unshift(
      <Button key="cancel" label="Cancel" variant="ghost" onClick={closeModalHandler} />
    )
  }

  return (
    <Modal
      modalKey={modalKey}
      btnName={btnName}
      btnIcon={btnIcon}
      btnType={btnType}
      title="Add new version of the attachment"
      size="medium"
      isSmall
      centered
      btnClicked={initTempHandler}
      onClose={resetTempHandler}
      footer={footer}
    >
      <Form layout="vertical">
        <Divider disableBorder />
        <Text variant="h4" text={title} />
        {subtitle && <Text variant="h5" text={subtitle} />}
        <Divider />
        <BoxWrapper md={2} lg={2} enablePadding>
          <InfoCell
            value={get(tempData, 'file.value', '')}
            label={'variant-attach'}
            isEditing
            dataType="image"
            inputType="upload"
            inputConfig={{
              fileKey: `variant-attach-${Date.now()}`,
              topMessage: uploadTopMess,
              fileType: 'any',
              bottomMessage: '',
              onChange: updateImageHanlder,
              enableCharRule: true
            }}
          />
        </BoxWrapper>

        <BoxWrapper md={2} lg={3} enablePadding>
          <InfoCell
            label="Content Type"
            value={get(tempData, 'contentType', '')}
            inputType="select"
            isEditing={isContentTypeEnable}
            inputConfig={{
              items: dropdownAttachmentTypes,
              onChange: updateContentType,
              orderItems: true,
              orderKey: 'name'
            }}
          />

          <InfoCell
            label="Name"
            value={get(tempData, 'name', '')}
            isEditing
            inputConfig={{
              onChange: (value: string) => updatedTempData('name', value)
            }}
          />
          <InfoCell
            label="Description"
            value={get(tempData, 'description', '')}
            isEditing
            inputConfig={{
              onChange: (value: string) => updatedTempData('description', value)
            }}
          />
          {hasStatus && (
            <InfoCell
              label="Status"
              value={get(tempData, 'status', '')}
              inputType="select"
              isEditing
              inputConfig={{
                items: dropdownAttachmentStatus,
                onChange: ({ value }: any) => updatedTempData('status', value),
                orderItems: true,
                orderKey: 'name'
              }}
            />
          )}
          <InfoCell
            dataType="tags"
            inputType="input-tag"
            value={get(tempData, 'SAPCodes', [])}
            label="Sap Codes"
            isEditing
            inputConfig={{
              onChange: (value: any) => updatedTempData('SAPCodes', value),
              disabled: checkIfSapDisable()
            }}
          />
        </BoxWrapper>
        <Divider disableBorder />
      </Form>
    </Modal>
  )
}

export default AddModal
