import { apiClient } from '../../api/client'
import { ItemInCart, OrderBody, OrderResponse } from './model'

export const insertInCart = (data: any) => {
  return apiClient.post<any>('cart/cartItem', data)
}

export const getCartItemsByAuthor = (owner: string) => {
  return apiClient.get<ItemInCart[]>(`cart/owner/${owner}`)
}
export const getCartItems = (id: string) => {
  return apiClient.get<ItemInCart[]>(`cart/${id}`)
}
export const resetCartItems = (owner: string) => {
  return apiClient.get<{}>(`cart/reset/${owner}`)
}

export const deleteFromCart = (itemData: any) => {
  return apiClient.delete<{}>('cart/cartItem', itemData)
}

export const createOrder = (newOrder: OrderBody) => {
  return apiClient.post<OrderResponse>('order', newOrder)
}
