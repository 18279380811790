import { uniqueId } from 'lodash'
import { MasterSpecCharacteristic } from '../../features/masterSpec/model'

// filters array of objects, returning another array containing only the elements that match the filter
export const filterCharList = (charArray: any, filterObject: any) => {
  const result = charArray.filter((characteristic: any) =>
    Object.keys(filterObject).every((key) =>
      characteristic[key]?.toLowerCase().includes(filterObject[key].toLowerCase())
    )
  )
  return result
}

export const filterCharListBySectionId = (charArray: any, charCodesToOmit: string[]) => {
  charArray.filter((characteristic: any) => {
    return charCodesToOmit.some((charCode) => charCode === characteristic['controlCode'])
  })
}

export const randomizeCharacteristicId = (characteristic: MasterSpecCharacteristic) => {
  const result = {
    ...characteristic,
    baseSpecificationCharacteristicId: characteristic.id,
    id: uniqueId()
  }
  return result
}
