import { CSSProperties } from 'react'
import styled from 'styled-components'

type StyledFlexSectionProps = {
  flexDirection: CSSProperties['flexDirection']
  justifyContent: CSSProperties['justifyContent']
  alignItems: CSSProperties['alignItems']
  gap?: number
}

export const StyledFlexSection = styled.div<StyledFlexSectionProps>`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent};
  flex-direction: ${({ flexDirection }) => flexDirection};
  align-items: ${({ alignItems }) => alignItems};
  gap: ${({ gap }) => (gap !== undefined ? `${gap}px` : '16px')};
  width: 100%;
`
