import { Form } from 'antd'
import { get } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { packagingActions } from '../../../features/packaging'
import { selectTempAttachmentsFilters } from '../../../features/packaging/selectors'
import { BoxWrapper } from '../../BoxWrapper'
import { Divider } from '../../Divider'
import InfoCell from '../../InfoCell'
import { Modal } from '../../Modal'
import Button from '../../NewButton'
import { ButtonContainer } from './styled'

export default function AttachmentFilterModal({ value: subState }: any) {
  const dispatch = useDispatch()

  const data = useSelector(selectTempAttachmentsFilters)

  const updateData = (key: string, value: string) => {
    const newData = {
      ...data,
      [key]: value
    }
    dispatch(packagingActions.updateAttachmentsFilters({ newData }))
  }

  const applyFilterHandler = () => {
    dispatch({ type: 'packaging/copyAttachmentsFilters' })
  }

  return (
    <>
      <Modal
        btnName={'Filter'}
        modalKey={'attachments-filters'}
        title={'Filter Attachments'}
        size={'medium'}
        isSmall={true}
        btnType={'ghost'}
        footer={[
          <ButtonContainer>
            <Button label={'Search'} onClick={applyFilterHandler} />
          </ButtonContainer>
        ]}
      >
        <Form layout={'vertical'}>
          <Divider disableBorder={true} />
          <BoxWrapper md={2} lg={3} enablePadding>
            <InfoCell
              label="Name"
              value={get(data, 'name', '')}
              inputConfig={{
                onChange: (value: string) => updateData('name', value)
              }}
              isEditing
            />
            <InfoCell
              label="Description"
              value={get(data, 'description', '')}
              inputConfig={{
                onChange: (value: string) => updateData('description', value)
              }}
              isEditing
            />
            <InfoCell
              label="SAP Codes"
              value={get(data, 'SAPCodes', '')}
              inputConfig={{
                onChange: (value: string) => updateData('SAPCodes', value)
              }}
              isEditing
            />
          </BoxWrapper>
        </Form>
      </Modal>
    </>
  )
}
