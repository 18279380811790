import styled, { css } from 'styled-components'
import { IContentContainer } from './types'

export const ContentContainer = styled.div<IContentContainer>`
  .ant-table-empty {
    ${({ isForwarded }) =>
      isForwarded &&
      css`
        color: black;
      `}
  }
`

export const ModalWrapper = styled.div`
  button:first-child {
    min-width: 70px;
  }
`

export const filterActionHeight = 40
export const FilterActionsContainer = styled.div`
  height: ${filterActionHeight}px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`
export const TagsContainerWrapper = styled.div`
  display: flex;
  padding: 0px 10px;
  align-items: center;
  overflow-x: auto;
`
