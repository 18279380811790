import { FC } from 'react'
import { Table } from '../../../components'
import { BOTTOM_RIGHT } from '../../../components/NewTable/constants'
import { columnsGeneratorHelper, rowsGeneratorHelperNew } from '../../../utils'
import { IManagementTableProps } from './types'

const ManagementTable: FC<IManagementTableProps> = ({
  dataList,
  tableConfig,
  actionConfig,
  pageData,
  enablePagination,
  onChangePage
}) => {
  const { page, pageSize, total } = pageData

  const columnGenerator = () => {
    const columnList = columnsGeneratorHelper(tableConfig)
    return columnList
  }

  const rowsGenerator = () => rowsGeneratorHelperNew(dataList, tableConfig)

  const columns = columnGenerator()
  const dataRow = dataList.length ? rowsGenerator() : []
  return (
    <Table
      pagination={{
        current: page,
        pageSize,
        total
      }}
      onChangePage={onChangePage}
      enablePagination={enablePagination}
      paginationPosition={BOTTOM_RIGHT}
      rowKey="id"
      columns={columns}
      items={dataRow}
      forceHeight
      actionColumnConfig={actionConfig}
    />
  )
}

export default ManagementTable
