import styled from 'styled-components'
import { footerHeight } from '../../assets/mixins'

export const SelectTypeWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const SelectTypeContainer = styled.div`
  display: flex;
  h4 {
    margin: 0px;
  }
  div {
    margin: 0px;
    margin-left: 10px;
  }
`

export const buttonWrapperHeight = 44
export const ButtonWrapper = styled.div`
  height: ${buttonWrapperHeight}px;
  width: 100%;
  padding: 5px 20px;
  background-color: white;
  border-top: 1px solid #eee;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  position: fixed;
  bottom: ${footerHeight}px;
`
