import { find, get } from 'lodash'
import { createSelector } from 'reselect'
import { Formula } from '../formulaSchema/model'
import { ATTACHMENT_CONTENT_TYPE } from '../packaging/constants'
import { Attachments } from '../packaging/model'
import { attachmentByRole } from '../packaging/utils'
import type { RootState } from '../store'
import { selectUserRole } from '../users/selectors'

const selectProducts = (state: RootState) => state.products

export const getProduct = createSelector(selectProducts, (products) => products)
export const getFilters = createSelector(selectProducts, (products) => products.filters)
export const selectProductsItem = createSelector(
  selectProducts,
  (products) => products.items
)
export const selectLoader = createSelector(
  selectProducts,
  (products) => products.isLoading
)

export const getProductItemsByIds = createSelector(
  selectProducts,
  (product) => product.itemsByIds
)

export const getProductsById = (id: string) =>
  createSelector(getProductItemsByIds, (itemsByIds) => itemsByIds[id])

export const getProductsDetail = createSelector(
  selectProducts,
  (product) => product.detail
)

export const getProductTemp = createSelector(selectProducts, (product) => product.temp)

export const getProductsDetailSides = createSelector(getProductsDetail, (detail) =>
  get(detail, 'productSides')
)

export const getProductsSideById = (sideId: string) =>
  createSelector(getProductsDetailSides, (sides) => find(sides, { id: sideId }))

export const getProductsDetailName = createSelector(getProductsDetail, (detail) =>
  get(detail, 'name')
)

export const selectMarketingProfileAttachments = createSelector(
  getProductsDetail,
  (detail) => get(detail, 'attachments')
)
export const selectFilteredAttachments = createSelector(
  getProductsDetail,
  selectUserRole,
  (detail, role) => {
    const totalAttachments = get(detail, 'productSides.0.packagingAttachments', [])

    return totalAttachments.filter((attachment: Attachments) => {
      const contentType: ATTACHMENT_CONTENT_TYPE = get(
        attachment,
        'attachmentContentType.name',
        ''
      ).toLowerCase()
      const allowedRoles = attachmentByRole?.[contentType]

      return (
        allowedRoles && role?.some((currentRole) => allowedRoles.includes(currentRole))
      )
    })
  }
)
export const selectPackageAttachments = createSelector(
  getProductsDetailSides,
  (productSides) => get(productSides, 'packagingAttachments')
)

export const selectProductPagination = createSelector(
  selectProducts,
  (state) => state.pagination
)

export const selectHasActionsAddon = createSelector(
  selectProducts,
  (state) => state.hasActionsAddon
)

export const selectICPaoFieldOptions = createSelector(getProductsDetail, (detail) => {
  const formulas: Formula[] = get(detail, 'productSides.0.formulaSchema.formula', [])
  return formulas.reduce<string[]>((acc, { icPao }) => [...acc, icPao || '-'], [])
})
export const selectIcShelfLifeOptions = createSelector(getProductsDetail, (detail) => {
  const formulas: Formula[] = get(detail, 'productSides.0.formulaSchema.formula', [])
  return formulas.reduce<string[]>(
    (acc, { icShelfLife }) => [...acc, icShelfLife || '-'],
    []
  )
})

export const getIndustrialCodeInformationsTable = createSelector(
  getProductsDetail,
  (detail) => {
    const formulas: Formula[] = get(detail, 'productSides.0.formulaSchema.formula', [])
    return formulas.map((formula) => ({
      industrialCode: get(formula, 'formulaCodeDEVEX'),
      color: get(formula, 'color'),
      icFinish: get(formula, 'finish'),
      countryCheckComplianceNotes: get(detail, 'countryCheckComplianceNotes'),
      vegan: get(detail, 'vegan'),
      veganNotes: get(detail, 'veganNotes'),
      RSPO: get(detail, 'RSPO'),
      microplasticFree: get(detail, 'microplasticFree'),
      microplasticFreeNotes: get(detail, 'microplasticFreeNotes'),
      siliconesFree: get(detail, 'siliconesFree'),
      talcFree: get(detail, 'talcFree'),
      pegFree: get(detail, 'pegFree'),
      d5Free: get(detail, 'd5Free'),
      silicaFree: get(detail, 'silicaFree'),
      GMO: get(detail, 'GMO'),
      paraben: get(detail, 'paraben'),
      cleanOfPreservatives: get(detail, 'cleanOfPreservatives'),
      cleanOfMineralOils: get(detail, 'cleanOfMineralOils')
    }))
  }
)

export const selectProductsError = createSelector(selectProducts, (state) => state.error)

export const selectTempFilter = createSelector(
  selectProducts,
  (products) => products.tempFilters
)

export const selectSuccessInfo = createSelector(
  selectProducts,
  (products) => products.successInfo
)
