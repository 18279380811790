import styled from 'styled-components'

export const Label = styled.span`
  font-size: medium;
  font-weight: bold;
`

export const Value = styled.span`
  font-size: medium;
  word-break: break-all;
`
