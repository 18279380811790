import styled from 'styled-components'

export const StyledButtonCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.primary.transparent};
  font-size: large;
  border-radius: 5px;
  height: 180px;
  width: 100%;
  z-index: 3;
  cursor: pointer;
  transition: transform 0.2s;
  :hover {
    transform: scale(1.03);
  }
  > :first-child {
    color: white;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: bold;
  }
  > :last-child {
    color: white;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: bold;
    margin-top: -10px;
  }
`
