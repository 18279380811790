import styled from 'styled-components'

export const TagsWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: auto;
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  > button {
    margin: 0 10px;
  }
`
