import { PACKAGE_TYPE } from '../../../features/packaging/model'
import {
  checkFieldVisibilityByCategory,
  checkFieldVisibilityByRoles,
  checkFieldVisibilityByType
} from '../../../utils/packagingFormHelper'

type fieldVisibilityArguments = {
  key: string
  userRoles: any
  isMulti?: boolean
  isCreating?: boolean
  isEditing?: boolean
  category: string
  subCategory: string
}
export const isFieldVisible = (input: fieldVisibilityArguments) => {
  const {
    key,
    userRoles,
    isMulti = false,
    isCreating = false,
    isEditing = false,
    category,
    subCategory
  } = input
  let visibility = true

  visibility =
    visibility &&
    checkFieldVisibilityByType({
      key,
      type: isMulti ? PACKAGE_TYPE.MULTI : PACKAGE_TYPE.MONO
    })

  visibility =
    visibility &&
    checkFieldVisibilityByCategory({
      key,
      category: category,
      subcategory: subCategory
    })

  visibility =
    visibility &&
    checkFieldVisibilityByRoles({
      key,
      roles: userRoles,
      isOnCreate: isCreating && isEditing,
      isOnEdit: !isCreating && isEditing,
      isOnRead: !isCreating && !isEditing
    })
  return visibility
}
