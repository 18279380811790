import { get } from 'lodash'
import { createSelector } from 'reselect'
import type { RootState } from '../store'

const selectAdministration = (state: RootState) => state.administration

export const selectLastImported = createSelector(
  selectAdministration,
  (lastImport) => lastImport.lastImported
)

export const selectSelectedType = createSelector(
  selectAdministration,
  (administration) => administration.type
)
export const selectCurrentEditId = createSelector(
  selectAdministration,
  (administration) => get(administration, 'currentEdit')
)
export const selectAttachments = createSelector(selectAdministration, (administration) =>
  get(administration, 'attachmentContentTypes')
)
export const selectCurrencyRates = createSelector(
  selectAdministration,
  (administration) => get(administration, 'currencyRates')
)

export const selectNewAttachment = createSelector(
  selectAdministration,
  (administration) => {
    return get(administration, 'newAttachment')
  }
)

export const selectSupplierFilters = createSelector(
  selectAdministration,
  (administration) => {
    return administration.suppliers.filters
  }
)

export const selectUpdateSupplierData = createSelector(
  selectAdministration,
  (administration) => {
    return get(administration, 'suppliers.detail')
  }
)

export const selectAttachmentDetail = createSelector(
  selectAdministration,
  (administration) => {
    return administration.attachments.detail
  }
)

export const selectSupplierPaginationsNew = createSelector(
  selectAdministration,
  (administration) => {
    return administration.suppliers.pagination
  }
)

export const selectSupplierList = createSelector(
  selectAdministration,
  (administration) => {
    return administration.suppliers.items
  }
)

export const selectAdminError = createSelector(
  selectAdministration,
  (administration) => administration.error
)

export const selectAdminSuccess = createSelector(
  selectAdministration,
  (administration) => administration.successInfo
)

export const selectSapCodeIsUsed = createSelector(
  selectAdministration,
  (administration) => administration.suppliers.sapCodeUsed
)

export const selectIsLoading = createSelector(
  selectAdministration,
  (administration) => administration.isLoading
)

export const selectGroupNamesDropdown = createSelector(
  selectAdministration,
  (administration) => administration.groupNamesDropdown
)
