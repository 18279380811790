import styled from 'styled-components'
import {
  FlexDirectionProps,
  borderBottom,
  flexDirection,
  justifyContent,
  BorderBottomProps
} from 'styled-system'
import { Text } from '../Text'

type CustomProps = {
  spaceBetweenTitles: boolean
}

export const StyledTitle = styled.div<
  FlexDirectionProps & BorderBottomProps & CustomProps
>`
  padding: 0px;
  display: flex;
  ${borderBottom}
  ${flexDirection}
  ${justifyContent}
  ${({ flexDirection, spaceBetweenTitles }) =>
    flexDirection === 'row'
      ? {
          flexDirection,
          gap: '10px',
          justifyContent: spaceBetweenTitles ? 'space-between' : 'initial',
          alignItems: 'baseline'
        }
      : 'none'}
`

export const StyledTextTitle = styled(Text)`
  ${({ enableBold }: { enableBold?: boolean }) => enableBold && 'font-weight: bolder'}
`
