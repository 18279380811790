import { FC } from 'react'
import { useDispatch } from 'react-redux'
import { Modal } from '../../../components/Modal'
import Button from '../../../components/NewButton'
import { ButtonsWrapper } from './styled'

export const AlertModal: FC<any> = ({ alertMessage }) => {
  const dispatch = useDispatch()

  const modalKey = 'alert-modal'

  const closeModalHandler = () => {
    dispatch({ type: 'app/closeModal', payload: { modalKey: modalKey } })
  }

  return (
    <Modal title="Alert" modalKey={modalKey} size={'medium'} centered isSmall>
      <div>{alertMessage}</div>
      <ButtonsWrapper>
        <Button label="Ok" onClick={closeModalHandler} />
      </ButtonsWrapper>
    </Modal>
  )
}
