import { createAction } from '@reduxjs/toolkit'
import {
  ADD_OTHER_TESTED_PACKAGING,
  PATCH_OTHER_TESTED_PACKAGING,
  DELETE_OTHER_TESTED_PACKAGING,
  FORMULA_SCHEMA_GET_DETAIL,
  FORMULA_SCHEMA_GET_FORMULA,
  FORMULA_SCHEMA_UPDATE,
  FSCHEMA_CONFIRM_UPDATE_FORMULA,
  GET_PK_DROPDOWN,
  GET_TESTED_CLAIM_TAXONOMY,
  ADD_TESTED_CLAIM,
  PATCH_TESTED_CLAIM,
  DELETE_TESTED_CLAIM
} from './constants'
import { Formula } from './model'
import { partialFSchemaActions } from './reducer'

export const fSchemaActions = {
  ...partialFSchemaActions,
  getFormulaSchema: createAction(FORMULA_SCHEMA_GET_FORMULA),
  getFSchemaDetail: createAction<{ id: string; hasLoader?: boolean }>(
    FORMULA_SCHEMA_GET_DETAIL
  ),
  getPKCodeDropdownItems: createAction<{ pkCode: string }>(GET_PK_DROPDOWN),
  updateFSchema: createAction(FORMULA_SCHEMA_UPDATE),
  saveFSchemaFormula: createAction<{ data: Formula }>(FSCHEMA_CONFIRM_UPDATE_FORMULA),
  addOtherTestedPackaging: createAction<{ data: any }>(ADD_OTHER_TESTED_PACKAGING),
  patchOtherTestedPackaging: createAction<{ data: any }>(PATCH_OTHER_TESTED_PACKAGING),
  deleteOtherTestedPackaging: createAction<string>(DELETE_OTHER_TESTED_PACKAGING),
  addTestedClaim: createAction<{ data: any; attachment: any }>(ADD_TESTED_CLAIM),
  patchTestedClaim: createAction<{ data: any }>(PATCH_TESTED_CLAIM),
  deleteTestedClaim: createAction<string>(DELETE_TESTED_CLAIM),
  getTestedClaimTaxonomy: createAction(GET_TESTED_CLAIM_TAXONOMY)
}
