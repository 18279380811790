import { Modal } from 'antd'
import styled, { CSSProperties } from 'styled-components'

type StyledBtnWrapperProps = {
  margin?: CSSProperties['margin']
}

type StyledModalProps = {
  isSmall: boolean
}
export const StyledModal = styled(Modal)<StyledModalProps>`
  .ant-modal-content {
    height: ${({ isSmall }) => (isSmall ? 'max-content' : '60vh')};
  }
`
export const StyledBtnWrapper = styled.span<StyledBtnWrapperProps>`
  ${({ margin }) => margin && `margin: ${margin};`}
`
