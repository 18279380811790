import { AnyAction } from '@reduxjs/toolkit'
import { get } from 'lodash'
import { call, delay, put, select, takeLatest } from 'redux-saga/effects'
import { selectUserEmail } from '../users/selectors'
import { administrationActions } from './actions'
import {
  createNewSupplier,
  getCurrencyRates,
  getGroupNames,
  getSuppliersByFilters,
  importAnagraphic,
  importAttachments,
  importPrice,
  importRelations,
  importSupplier,
  insertAttachment,
  searchOneSupplier,
  setCurrencyRate,
  updateAttachment,
  updateOneSupplier
} from './api'
import {
  ADMINISTRATION_CHECK_SUPPLIER,
  ADMINISTRATION_CREATE_ATTACHMENTS,
  ADMINISTRATION_CREATE_SUPPLIER,
  ADMINISTRATION_GET_ATTACHMENTS,
  ADMINISTRATION_GET_ATTACHMENTS_DETAIL,
  ADMINISTRATION_GET_CURRENCY_RATES,
  ADMINISTRATION_GET_GROUP_NAMES,
  ADMINISTRATION_GET_SUPPLIER_DETAIL,
  ADMINISTRATION_IMPORT_ANAGRAPHIC,
  ADMINISTRATION_IMPORT_PRICE,
  ADMINISTRATION_IMPORT_RELATIONS,
  ADMINISTRATION_IMPORT_SUPPLIER,
  ADMINISTRATION_SEARCH_SUPPLIER,
  ADMINISTRATION_SET_CURRENCY_RATE,
  ADMINISTRATION_UPDATE_ATTACHMENT,
  ADMINISTRATION_UPDATE_SUPPLIER
} from './constants'
import { AdministrationState, INewAttachment, ISupplierFilters } from './model'
import {
  selectAttachments,
  selectSupplierFilters,
  selectSupplierPaginationsNew
} from './selectors'

const { setImportedFiles } = administrationActions

function* importAnagraphicSaga() {
  try {
    yield put(administrationActions.setIsLoading(true))
    const author: string = yield select(selectUserEmail)
    const form_data = new FormData()
    const anagraphic = window.tmpFiles?.importedAnagraphic
    if (anagraphic) {
      form_data.append('file', anagraphic)
    }
    form_data.append('author', author)
    const importFile: AdministrationState = yield call(importAnagraphic, form_data)
    yield put(setImportedFiles(importFile))
    const failedList = get(importFile, 'failed', [])
    if (!failedList.length) {
      yield put(
        administrationActions.setSuccess({ status: 'success', title: 'IMPORT COMPLETED' })
      )
    } else {
      yield put(
        administrationActions.setSuccess({
          status: 'warning',
          title: 'IMPORT NOT COMPLETED'
        })
      )
    }
  } catch (err: any) {
    yield put(administrationActions.setError(err))
  } finally {
    yield put(administrationActions.setIsLoading(false))
  }
}

function* importPriceSaga() {
  try {
    yield put(administrationActions.setIsLoading(true))
    const author: string = yield select(selectUserEmail)
    const form_data = new FormData()
    const price = window.tmpFiles?.importedPrice
    if (price) {
      form_data.append('file', price)
    }
    form_data.append('author', author)
    const importFile: AdministrationState = yield call(importPrice, form_data)
    yield put(setImportedFiles(importFile))
    const failedList = get(importFile, 'failed', [])
    if (!failedList.length) {
      yield put(
        administrationActions.setSuccess({ status: 'success', title: 'IMPORT COMPLETED' })
      )
    } else {
      yield put(
        administrationActions.setSuccess({
          status: 'warning',
          title: 'IMPORT NOT COMPLETED'
        })
      )
    }
  } catch (err: any) {
    yield put(administrationActions.setError(err))
  } finally {
    yield put(administrationActions.setIsLoading(false))
  }
}

function* importRelationsSaga() {
  try {
    yield put(administrationActions.setIsLoading(true))
    const author: string = yield select(selectUserEmail)
    const form_data = new FormData()
    const relation = window.tmpFiles?.importedRelation
    if (relation) {
      form_data.append('file', relation)
    }
    form_data.append('author', author)
    const importFile: AdministrationState = yield call(importRelations, form_data)
    yield put(setImportedFiles(importFile))
    const failedList = get(importFile, 'failed', [])
    if (!failedList.length) {
      yield put(
        administrationActions.setSuccess({ status: 'success', title: 'IMPORT COMPLETED' })
      )
    } else {
      yield put(
        administrationActions.setSuccess({
          status: 'warning',
          title: 'IMPORT NOT COMPLETED'
        })
      )
    }
  } catch (err: any) {
    yield put(administrationActions.setError(err))
  } finally {
    yield put(administrationActions.setIsLoading(false))
  }
}

function* importSupplierSagaNew({ payload }: AnyAction) {
  yield put(administrationActions.setIsLoading(true))
  try {
    const author: string = yield select(selectUserEmail)
    const form_data = new FormData()
    const supplier = window.tmpFiles?.importedSupplier
    if (supplier) {
      form_data.append('file', supplier)
    }
    form_data.append('author', author)
    const importedSupplier: AdministrationState = yield call(importSupplier, form_data)
    yield put(administrationActions.setImportedFiles(importedSupplier))
    const failedList = get(importedSupplier, 'failed', [])
    if (!failedList.length) {
      yield put(
        administrationActions.setSuccess({ status: 'success', title: 'IMPORT COMPLETED' })
      )
    } else {
      yield put(
        administrationActions.setSuccess({
          status: 'warning',
          title: 'IMPORT NOT COMPLETED'
        })
      )
    }
  } catch (err: any) {
    yield put(administrationActions.setError(err))
    yield
  } finally {
    yield put(administrationActions.setIsLoading(false))
  }
}

function* fetchAttachmentsSaga() {
  yield put(administrationActions.setIsLoading(true))

  try {
    const response: Record<string, any> = yield call(importAttachments)
    const attachmentContentTypes = get(response, 'attachmentContentTypes', [])
    yield put(administrationActions.setImportedAttachments(attachmentContentTypes))
  } catch (err: any) {
    yield put(administrationActions.setError(err))
  } finally {
    yield put(administrationActions.setIsLoading(false))
  }
}

function* fetchAttachmentDetail({ payload }: AnyAction) {
  const { id } = payload
  yield put(administrationActions.setIsLoading(true))
  try {
    const attachmentList: Record<string, any>[] = yield select(selectAttachments)
    const attachment: Record<string, any> = yield attachmentList.find(
      (att: Record<string, any>) => att.id === id
    )
    yield put(administrationActions.setAttachmentDetail(attachment))
  } catch (err: any) {
    yield put(administrationActions.setError(err))
  } finally {
    yield put(administrationActions.setIsLoading(false))
  }
}

function* saveAttachmentSaga({ payload }: AnyAction): Record<string, any> {
  const { attachData, successCb = () => {} } = payload
  yield put(administrationActions.setIsLoading(true))
  try {
    const { id, name, idDbPackage, sapCodeEnabled } = attachData
    const body = {
      name,
      idDbPackage,
      isImage: false,
      sapCodeEnabled
    }
    yield call(updateAttachment, id, body)
  } catch (err: any) {
    yield put(administrationActions.setError(err))
  } finally {
    yield put(administrationActions.setIsLoading(false))
    yield successCb()
  }
}

function* createAttachmentSaga({ payload }: AnyAction) {
  const { attachData } = payload
  yield put(administrationActions.setIsLoading(true))
  try {
    const body: INewAttachment = {
      idDbPackage: attachData.idDbPackage,
      isImage: attachData.isImage,
      name: attachData.name || '',
      sapCodeEnabled: attachData.sapCodeEnabled || false
    }
    yield call(insertAttachment, body)
    yield put(administrationActions.setSuccess({ message: 'Attachment Created' }))
  } catch (err: any) {
    yield put(administrationActions.setError(err))
  } finally {
    yield put(administrationActions.setIsLoading(false))
  }
}

function* searchSupplierSaga() {
  yield put(administrationActions.setIsLoading(false))
  try {
    const filters: ISupplierFilters = yield select(selectSupplierFilters)
    const { page: selectedPage, pageSize: selectedPageSize } = yield select(
      selectSupplierPaginationsNew
    )
    const suppliers: Record<string, any> = yield call(getSuppliersByFilters, {
      ...filters,
      page: selectedPage,
      pageSize: selectedPageSize
    })

    const { results, totalPages, page, pageSize, total } = suppliers
    yield put(
      administrationActions.setSupplierPaginationNew({
        totalPages,
        page,
        pageSize,
        total
      })
    )
    yield put(administrationActions.setSuppliers(results))
  } catch (err: any) {
    yield put(administrationActions.setSuppliers([]))
    yield put(administrationActions.setError(err))
  } finally {
    yield put(administrationActions.setIsLoading(false))
  }
}

function* createSupplierSaga({ payload }: AnyAction) {
  const { supplierData } = payload
  yield put(administrationActions.setIsLoading(true))
  try {
    const author: string = yield select(selectUserEmail)
    yield call(createNewSupplier, { ...supplierData, author })
    yield put(
      administrationActions.setSuccess({
        title: 'Supplier created succesfully'
      })
    )
  } catch (err: any) {
    yield put(administrationActions.setError(err))
  } finally {
    yield put(administrationActions.setIsLoading(false))
  }
}

function* fetchSupplierDetail({ payload }: AnyAction) {
  const { id } = payload
  yield put(administrationActions.setIsLoading(true))
  try {
    const supplierDetail: Record<string, any> = yield call(searchOneSupplier, id)
    yield put(administrationActions.setSupplierDetail(supplierDetail))
  } catch (err: any) {
    yield put(administrationActions.setError(err))
  } finally {
    yield put(administrationActions.setIsLoading(false))
  }
}

function* updateSupplierSaga({ payload }: AnyAction) {
  const { data, id, successCb = () => {} } = payload
  yield put(administrationActions.setIsLoading(true))
  try {
    yield call(updateOneSupplier, {
      data,
      id
    })
  } catch (err: any) {
    yield put(administrationActions.setError(err))
  } finally {
    yield put(administrationActions.setIsLoading(false))
    successCb()
  }
}

function* findSapCode({ payload }: AnyAction): any {
  const { value } = payload
  try {
    yield delay(1500)
    const { results } = yield call(getSuppliersByFilters, { SAPCodes: value })
    const usedSapCodes = results.find(
      ({ SAPCodes }: Record<string, any>) => SAPCodes === value
    )
    yield put(administrationActions.setSapCodeWarning(usedSapCodes))
  } catch (err) {
    console.log(err)
  }
}

function* getCurrencyRatesSaga() {
  yield put(administrationActions.setIsLoading(true))

  try {
    const response: Record<string, any>[] = yield call(getCurrencyRates)
    yield put(administrationActions.setCurrencyRates(response))
  } catch (err: any) {
    yield put(administrationActions.setError(err))
  } finally {
    yield put(administrationActions.setIsLoading(false))
  }
}

function* setCurrencyRateSaga({ payload }: AnyAction): Record<string, any> {
  const { id, data } = payload
  yield put(administrationActions.setIsLoading(true))

  try {
    yield call(setCurrencyRate, {
      id,
      data
    })
    yield call(getCurrencyRatesSaga)
  } catch (err: any) {
    yield put(administrationActions.setError(err))
  } finally {
    yield put(administrationActions.setIsLoading(false))
  }
}

function* getGroupNamesSaga() {
  try {
    const resp: Record<string, any>[] = yield call(getGroupNames)
    const result: Record<string, any>[] = resp.map((item) => {
      return { name: item.groupName, value: item.groupName }
    })
    yield put(administrationActions.setGroupNamesDropdown(result))
  } catch (err: any) {
    yield put(administrationActions.setError(err))
  } finally {
  }
}

export default function* administrationSaga() {
  yield takeLatest(ADMINISTRATION_IMPORT_ANAGRAPHIC, importAnagraphicSaga)
  yield takeLatest(ADMINISTRATION_IMPORT_PRICE, importPriceSaga)
  yield takeLatest(ADMINISTRATION_IMPORT_RELATIONS, importRelationsSaga)
  yield takeLatest(ADMINISTRATION_IMPORT_SUPPLIER, importSupplierSagaNew)
  yield takeLatest(ADMINISTRATION_GET_ATTACHMENTS, fetchAttachmentsSaga)
  yield takeLatest(ADMINISTRATION_CHECK_SUPPLIER, findSapCode)
  yield takeLatest(ADMINISTRATION_GET_ATTACHMENTS_DETAIL, fetchAttachmentDetail)
  yield takeLatest(ADMINISTRATION_UPDATE_ATTACHMENT, saveAttachmentSaga)
  yield takeLatest(ADMINISTRATION_CREATE_ATTACHMENTS, createAttachmentSaga)
  yield takeLatest(ADMINISTRATION_SEARCH_SUPPLIER, searchSupplierSaga)
  yield takeLatest(ADMINISTRATION_CREATE_SUPPLIER, createSupplierSaga)
  yield takeLatest(ADMINISTRATION_GET_SUPPLIER_DETAIL, fetchSupplierDetail)
  yield takeLatest(ADMINISTRATION_UPDATE_SUPPLIER, updateSupplierSaga)
  yield takeLatest(ADMINISTRATION_GET_CURRENCY_RATES, getCurrencyRatesSaga)
  yield takeLatest(ADMINISTRATION_SET_CURRENCY_RATE, setCurrencyRateSaga)
  yield takeLatest(ADMINISTRATION_GET_GROUP_NAMES, getGroupNamesSaga)
}
