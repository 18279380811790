import { get, isEmpty } from 'lodash'
import { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { canCopyOrDeletePackaging } from '../../../auth-msal'
import { packagingActions } from '../../../features/packaging'
import {
  CATEGORY,
  PACKAGE_TYPE,
  PackagingCreationType
} from '../../../features/packaging/model'
import { selectUserRole } from '../../../features/users/selectors'
import { AppRoutes } from '../../../pages/constants'
import { columnsGeneratorHelper } from '../../../utils'
import { visibilityByRolesHelper } from '../../../utils/tableHelpers'
import { DeletePackaging } from '../../DeletePackaging'
import Table from '../../NewTable'
import DetailBtn from '../../NewTable/ActionsCol/DetailBtn'
import { TOP_RIGHT } from '../../NewTable/constants'
import { ITActionColumnConfig } from '../../NewTable/types'
import TooltipBtn from '../../TooltipBtn'
import { getPackagingTableConfigMono } from './constant'
import { IPackagingTableProps } from './types'

const PackagingTable: FC<IPackagingTableProps> = ({
  dataList,
  enablePagination = false,
  onChangePage = () => {},
  colHideRules,
  isLoading,
  pageData = { page: 1, pageSize: 10, total: 10 },
  enableRowSelect = false,
  selectedRows = [],
  onSelectRow = () => {},
  isSingleSelect = false,
  hasAction = false
}) => {
  const dispatch = useDispatch()

  const { page, pageSize, total } = pageData
  const userRoles = useSelector(selectUserRole)
  const navigate = useNavigate()

  const actionConfig: ITActionColumnConfig = {
    columnTitle: 'Actions',
    actionRenderer: ({ data }) => {
      if (data.isChild) {
        return null
      }
      const isMulti = get(data, 'children', []).length > 1
      const isVial = get(data, 'category', '').toLowerCase() === CATEGORY.VIALS
      const flowType = isMulti
        ? PackagingCreationType.MULTI
        : isVial
        ? PackagingCreationType.VIAL
        : PackagingCreationType.NO_VIAL
      return (
        <>
          <DetailBtn
            btnLabel={'Detail'}
            btnVariant={'text'}
            destinationPath={AppRoutes.WIKIPACK_PACKAGING_DETAILS}
            rec={data}
          />
          {canCopyOrDeletePackaging(userRoles) && (
            <>
              <TooltipBtn
                btnLabel={'Copy'}
                message={'Do you want to copy this packaging?'}
                onConfirm={() => {
                  dispatch(packagingActions.copyPackage({ id: data.id }))
                  navigate(`${AppRoutes.WIKIPACK_PACKAGE_CREATION}/${flowType}`)
                }}
                btnVariant={'text'}
              />

              <DeletePackaging
                rec={data}
                message={'Do you want to delete this packaging?'}
                btnType="link"
                succcesCb={() => {
                  dispatch(packagingActions.getPackages({ hasloader: false }))
                }}
              />
            </>
          )}
        </>
      )
    }
  }

  const columnGenerator = () => {
    let columnList = columnsGeneratorHelper(getPackagingTableConfigMono(userRoles))

    if (!isEmpty(colHideRules)) {
      columnList = columnList.filter(({ key }) => {
        const rulesByRole = colHideRules?.[key]?.byRoles || []
        return visibilityByRolesHelper(userRoles, rulesByRole)
      })
    }
    return columnList
  }

  const rowsGenerator = () => {
    const newList = dataList.map((data: any) => {
      const newData: Record<string, any> = {}
      const isMulti = get(data, 'type', '').toLowerCase() === PACKAGE_TYPE.MULTI

      const tableConfig = getPackagingTableConfigMono(userRoles)
      Object.keys(tableConfig).forEach((key: keyof typeof newData) => {
        const dataPath = get(tableConfig, `${key}.dataPath`, '') as string
        newData[key] = get(data, dataPath, '')
      })
      if (isMulti) {
        newData['children'] = get(data, 'packageFamilies', [])
      }
      return newData
    })
    return newList
  }

  const columns = columnGenerator()
  const dataRow = dataList.length ? rowsGenerator() : []

  return (
    <Table
      pagination={{
        current: page,
        pageSize,
        total
      }}
      onChangePage={onChangePage}
      enablePagination={enablePagination}
      paginationPosition={TOP_RIGHT}
      rowKey="id"
      columns={columns}
      items={dataRow}
      actionColumnConfig={hasAction ? actionConfig : undefined}
      forceHeight
      isLoading={isLoading}
      enableRowSelect={enableRowSelect}
      selectedRows={selectedRows}
      onSelectRow={onSelectRow}
      isSingleSelect={isSingleSelect}
    />
  )
}

export default PackagingTable
