import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { StyledBreadCrumb } from './styled'

export type PropsBreadrumbs = {
  items?: { name: string; href?: string; back?: boolean }[]
}

export const Breadcrumb = ({ items }: PropsBreadrumbs) => {
  const navigate = useNavigate()

  const nav = useCallback(
    (path?: string, back?: boolean) => {
      if (!path) return
      if (back) return navigate(-1)
      navigate(path)
    },
    [navigate]
  )
  return (
    <StyledBreadCrumb separator=">">
      {items?.map((i) => {
        return (
          <StyledBreadCrumb.Item
            className={i.href && 'ant-breadcrumb-link-active'}
            onClick={() => nav(i.href, i.back)}
            key={i.href + i.name}
          >
            {i.name.toUpperCase()}
          </StyledBreadCrumb.Item>
        )
      })}
    </StyledBreadCrumb>
  )
}
