import { get } from 'lodash'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { footerHeight, headerHeight } from '../../assets/mixins'
import { Button, SectionTitle } from '../../components'
import { Divider } from '../../components/Divider'
import { Loader } from '../../components/Loader'
import { PageContent } from '../../containers'
import ProjectSpecificationCharacteristicContent from '../../containers/ProjectSpecificationCharacteristicContent'
import { actions } from '../../features/app'
import { masterSpecActions } from '../../features/masterSpec/reducer'
import {
  selectNewProjectCharacteristic,
  selectNewProjectSpec
} from '../../features/masterSpec/selectors'
import { getIsLoading, selectPackagingError } from '../../features/packaging/selectors'
import { GenericLayout } from '../../layouts'
import { masterSpecStatus } from '../MasterSpecificationDetail/constants'
import { ButtonsWrapper } from '../MasterSpecificationDetail/styled'
import {
  FooterContent,
  TextBox,
  WarningIcon,
  WarningWrapper
} from '../ProjectSpecificationDetail/styled'
import {
  cleanProjectSpec,
  createDuplicatesMessage,
  createSendToSapMessage
} from '../ProjectSpecificationDetail/utils'
import { replaceObjectById } from './utils'

const ProjectSpecificationCharacteristicDetail: any = () => {
  // Selector
  const newProjectSpec = useSelector(selectNewProjectSpec)
  const packagingError = useSelector(selectPackagingError)
  const isLoading = useSelector(getIsLoading)
  const newProjCharacteristic = useSelector(selectNewProjectCharacteristic)

  const { specId } = useParams()
  const navigate = useNavigate()

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(masterSpecActions.getDetail({ specId: specId || '' }))
    dispatch(masterSpecActions.getCharacteristics())
    dispatch(masterSpecActions.clearError())
    dispatch(masterSpecActions.clearSuccess())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const cancelHandler = () => {
    navigate(-1)
  }

  // modals:
  const openSendToSapModal = (cleanPayload: any, overrideDuplicates: boolean) => {
    const sapCodes = get(cleanPayload, 'concernedSapCodes', [])
    dispatch(
      actions.openPopUp({
        isOpen: true,
        key: 'saveSendModal',
        title: 'Save & Send to Sap',
        message: createSendToSapMessage(sapCodes),
        footer: [
          <FooterContent>
            <Button
              key="cancel"
              label="Cancel"
              onClick={() => {
                dispatch(actions.closePopUp())
              }}
              variant="ghost"
            />
            <Button
              key="Confirm"
              label="Confirm"
              onClick={() => {
                dispatch(
                  masterSpecActions.updateProjectSpecificationAndSendToSap({
                    data: cleanPayload,
                    associateDuplicatedSapCodes: overrideDuplicates
                  })
                )
                dispatch(actions.closePopUp())
              }}
            />
          </FooterContent>
        ],
        isClosable: true,
        onClose: () => {}
      })
    )
  }

  const openDuplicateModal = (result: any, cleanPayload: any) => {
    const message = createDuplicatesMessage(result)
    dispatch(
      actions.openPopUp({
        title: 'Duplicates found',
        key: 'duplicates-found',
        message: message,
        footer: [
          <FooterContent>
            <Button
              key="ignore"
              label="No"
              onClick={() => {
                openSendToSapModal(cleanPayload, false)
              }}
              variant="ghost"
            />
            <Button
              key="overwrite"
              label="Yes"
              onClick={() => {
                openSendToSapModal(cleanPayload, true)
              }}
            />
          </FooterContent>
        ]
      })
    )
  }

  const isSentToSap = newProjectSpec.status === masterSpecStatus.SENT_TO_SAP
  const hasImageChanged = get(newProjCharacteristic, 'specificationCharacteristicImage')

  const saveHandler = () => {
    let resultProjectSpec = { ...newProjectSpec }
    let currentCharacteristicList = get(
      newProjectSpec,
      'projectSpecificationCharacteristics',
      []
    )

    if (hasImageChanged) {
      dispatch(
        masterSpecActions.uploadAndUpdateProjectChar({
          ...newProjCharacteristic
        })
      )
    } else {
      const charId = get(newProjCharacteristic, 'id', '')
      const resultCharacteristics = replaceObjectById(
        currentCharacteristicList,
        charId,
        newProjCharacteristic
      )
      resultProjectSpec.projectSpecificationCharacteristics = resultCharacteristics
      const payload = cleanProjectSpec(resultProjectSpec)
      if (payload) dispatch(masterSpecActions.updateProjectSpecification(payload))
    }
    navigate(-1)
  }

  const saveAndSendHandler = () => {
    let resultProjectSpec = { ...newProjectSpec }
    let currentCharacteristicList = get(
      newProjectSpec,
      'projectSpecificationCharacteristics',
      []
    )

    if (hasImageChanged) {
      dispatch(
        masterSpecActions.uploadAndUpdateProjectChar({
          ...newProjCharacteristic
        })
      )
    } else {
      const charId = get(newProjCharacteristic, 'id', '')
      const resultCharacteristics = replaceObjectById(
        currentCharacteristicList,
        charId,
        newProjCharacteristic
      )
      resultProjectSpec.projectSpecificationCharacteristics = resultCharacteristics
      const payload = cleanProjectSpec(resultProjectSpec)
      if (payload) {
        dispatch(
          masterSpecActions.checkSapCodes({
            data: payload,
            openDuplicateModal: openDuplicateModal,
            openSendToSapModal: openSendToSapModal,
            isCreateNew: false
          })
        )
      }
    }
    navigate(-1)
  }

  return (
    <GenericLayout error={packagingError}>
      <PageContent
        footerHeight={footerHeight}
        headerHeight={headerHeight}
        justifyContent="flex-start"
      >
        <Divider disableBorder />
        <SectionTitle
          value={''}
          isBold
          addon={
            <ButtonsWrapper>
              <>
                <Button label="Cancel" variant="text" onClick={cancelHandler} />
                {!isSentToSap && <Button label="Save" onClick={saveHandler} />}
                {isSentToSap && (
                  <Button
                    label="Save & Send to Sap"
                    onClick={() => {
                      saveAndSendHandler()
                    }}
                  />
                )}
              </>
            </ButtonsWrapper>
          }
        />
        {newProjectSpec.status === masterSpecStatus.SENT_TO_SAP && (
          <TextBox>
            <WarningWrapper>
              <WarningIcon />
              Please remember to specify the limit value before sending to SAP
            </WarningWrapper>
          </TextBox>
        )}
        {isLoading ? (
          <Loader />
        ) : (
          <ProjectSpecificationCharacteristicContent
            newCharacteristic={newProjCharacteristic}
            concernedSapCodes={newProjectSpec.concernedSapCodes}
          />
        )}
      </PageContent>
    </GenericLayout>
  )
}

export default ProjectSpecificationCharacteristicDetail
