import { Avatar as AvatarAnt } from 'antd'
import { StyledAvatar } from './styled'

type Props = {
  name: string
  surname?: string
}

export const Avatar = ({ name, surname = '' }: Props) => {
  const getInitial = () => {
    if (!name && !surname) return
    return `${name[0]}${surname && surname[0]}`.toUpperCase()
  }
  return (
    <StyledAvatar>
      <AvatarAnt className="avatar" size={28}>
        {getInitial()}
      </AvatarAnt>
    </StyledAvatar>
  )
}
