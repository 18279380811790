import { all } from '@redux-saga/core/effects'
import administrationSaga from './administration/sagas'
import appSaga from './app/sagas'
import cartSaga from './cart/sagas'
import entitySaga from './entity/sagas'
import formulaschema from './formulaSchema/sagas'
import masterSpecSaga from './masterSpec/sagas'
import orderListSaga from './orderList/sagas'
import packagingsSaga from './packaging/sagas'
import productSaga from './product/sagas'
import productsSaga from './products/sagas'
import storeSaga from './storeProducts/sagas'
import { toolingsSaga } from './tooling/sagas'
import usersSaga from './users/sagas'

export function* rootSaga() {
  yield all([
    appSaga(),
    usersSaga(),
    productSaga(),
    packagingsSaga(),
    formulaschema(),
    productsSaga(),
    administrationSaga(),
    storeSaga(),
    orderListSaga(),
    cartSaga(),
    toolingsSaga(),
    entitySaga(),
    masterSpecSaga()
  ])
}
