import { get } from 'lodash'
import {
  BaseSpecificationCharacteristic,
  IMasterSpecDetail,
  MasterSpecCharacteristic,
  ProjectSpecCharacteristic
} from '../../features/masterSpec/model'
import { IImageData } from '../../features/storeProducts/model'

export const convertBaseCharToMasterChar = (
  baseChar: BaseSpecificationCharacteristic & ProjectSpecCharacteristic
): MasterSpecCharacteristic => {
  const masterChar: MasterSpecCharacteristic = {
    aql: baseChar.aql,
    id: baseChar.id,
    catalog: baseChar.catalog,
    characteristicMeasurementUnit: baseChar.characteristicMeasurementUnit,
    measurementUnitRef: baseChar.measurementUnitRef,
    controlCharacteristic: baseChar.controlCharacteristic,
    controlPoint: baseChar.controlPoint,
    displayOrder: baseChar.displayOrder,
    generalStatus: baseChar.generalStatus,
    inspectionLevel: baseChar.inspectionLevel,
    map: baseChar.map,
    measurementUnit: baseChar.measurementUnit,
    method: baseChar.method,
    operationNumber: baseChar.operationNumber,
    plant: baseChar.plant,
    samplingProcedure: baseChar.samplingProcedure,
    skipRules: baseChar.skipRules,

    usage: baseChar.usage,
    workCenter: baseChar.workCenter,

    testingMethod: baseChar.method,

    standard: baseChar.catalogDescription || baseChar.standard || '',
    itemName: baseChar.characteristicDescription || baseChar.itemName,
    overrideItemName:
      baseChar.overrideItemName ||
      baseChar.characteristicDescription ||
      baseChar.itemName,
    overrideStandard:
      baseChar.overrideStandard || baseChar.catalogDescription || baseChar.standard,
    baseSpecificationCharacteristicId:
      baseChar.baseSpecificationCharacteristicId || baseChar.id,
    specificationSectionId: baseChar.specificationSectionId,
    hasUpperLimit: baseChar.hasUpperLimit,
    hasLowerLimit: baseChar.hasLowerLimit,
    allowedDecimalsLimit: baseChar.allowedDecimalsLimit
  }
  return masterChar
}

export const cleanMasterSpec = (
  masterSpec: Partial<IMasterSpecDetail>,
  packageVariantId?: string
) => {
  if (masterSpec.masterSpecificationCharacteristics)
    return {
      id: get(masterSpec, 'id', '').length > 30 ? masterSpec.id : undefined,
      sideCollectorId: masterSpec.sideCollectorId,
      packageVariantId: packageVariantId || masterSpec.packageVariantId,
      thumbnailOverride: masterSpec.thumbnailOverride,
      supplierName: masterSpec.supplierName,
      supplierItemCode: masterSpec.supplierItemCode,
      title: masterSpec.title,
      description: masterSpec.description,
      status: masterSpec.status,
      masterCode: masterSpec.masterCode,
      customerProjectName: masterSpec.customerProjectName,
      nbShade: masterSpec.nbShade,
      remarks: masterSpec.remarks,

      masterSpecificationCharacteristics:
        masterSpec.masterSpecificationCharacteristics.map((characteristic) => {
          const charToClean = !characteristic.itemName
            ? convertBaseCharToMasterChar(characteristic)
            : characteristic
          const cleanChar = {
            id: get(charToClean, 'id', '').length > 30 ? charToClean.id : undefined,
            itemName: charToClean.itemName,
            overrideItemName: charToClean.overrideItemName,
            standard: charToClean.standard,
            overrideStandard: charToClean.overrideStandard,
            lowerLimit: parseFloat(charToClean.lowerLimit),
            upperLimit: parseFloat(charToClean.upperLimit),
            qualityInspection: charToClean.qualityInspection,
            masterHom: charToClean.masterHom,
            customizedDev: charToClean.customizedDev,
            supplierCoa: charToClean.supplierCoa,
            specificationSectionId: charToClean.specificationSectionId,
            baseSpecificationCharacteristicId:
              charToClean.baseSpecificationCharacteristicId,
            specificationCharacteristicImage: charToClean.specificationCharacteristicImage
          }
          return cleanChar
        })
    }
}

export const convertImageDataToImage = (attachData: IImageData) => {
  const formData = new FormData()
  const fileKey = get(attachData, 'value', '')
  const attachmentFile = get(window, `tmpFiles.${fileKey}`, null)
  formData.append('image', attachmentFile)
  return formData
}
