import { canUserSeeFschemaData } from '../../../auth-msal'
import { AppRoutes } from '../../../pages/constants'
import { TABLE_DATA_TYPE } from '../../NewTable/constants'
import { ITableConfig } from '../../NewTable/types'

export const PRODUCTS_RTG_TABLE_CONFIG = (userRoles: any[]): ITableConfig => {
  return {
    id: {
      dataPath: 'id',
      hide: true
    },
    productCode: {
      label: 'Product Code',
      dataType: TABLE_DATA_TYPE.LINK,
      dataPath: 'productCode',
      fixed: 'left',
      pathname: AppRoutes.VAULT_PRODUCT_DETAILS,
      newTab: true,
      showSortable: true,
      warnTextPath: 'productCodeWarn'
    },
    image: {
      label: 'Image',
      dataType: TABLE_DATA_TYPE.IMAGE,
      dataPath: 'thumbnail.url',
      fixed: 'left',
      hasBorder: true
    },
    name: {
      label: 'Final Name',
      dataType: TABLE_DATA_TYPE.TEXT,
      dataPath: 'name',
      showSortable: true
    },
    formulaSchemaCode: {
      label: 'Formula Schema Code',
      dataType: TABLE_DATA_TYPE.NODE,
      dataPath: 'formulaSchemaCode',
      renderNode: (data: any, rec: any) => {
        if (canUserSeeFschemaData(userRoles) || rec.status !== 'LEVEL_2_IN_PROGRESS')
          return data
      }
    },
    industrialCode: {
      label: 'Industrial Code',
      dataType: TABLE_DATA_TYPE.ARRAY,
      dataPath: 'industrialCode'
    },
    labReferenceCode: {
      label: 'Lab reference Code',
      dataType: TABLE_DATA_TYPE.ARRAY,
      dataPath: 'labReferenceCode'
    },
    packagingCode: {
      label: 'Packaging Code',
      dataType: TABLE_DATA_TYPE.TEXT,
      dataPath: 'packagingCode'
    },
    launchYear: {
      label: 'PD Launch Year',
      dataType: TABLE_DATA_TYPE.NODE,
      dataPath: 'pdLaunchYear' || 'launchYear',
      renderNode: (data: any, rec: any) => {
        if (rec?.pdLaunchYear?.length) return rec.pdLaunchYear?.join(', ')
        return data?.join(', ')
      }
    },
    collection: {
      label: 'PD Collection',
      dataType: TABLE_DATA_TYPE.NODE,
      dataPath: 'pdCollection' || 'collection',
      renderNode: (data: any, rec: any) => {
        if (rec?.pdCollection?.length) return rec.pdCollection?.join(', ')
        return data?.join(', ')
      }
    },
    businessSegment: {
      label: 'Business Segment',
      dataType: TABLE_DATA_TYPE.TEXT,
      dataPath: 'businessSegment',
      showSortable: true
    },
    productCategory: {
      label: 'Product Category',
      dataType: TABLE_DATA_TYPE.ARRAY,
      dataPath: 'productCategory'
    },
    format: {
      label: 'Format',
      dataType: TABLE_DATA_TYPE.ARRAY,
      dataPath: 'format'
    },
    productForm: {
      label: 'Product Form',
      dataType: TABLE_DATA_TYPE.ARRAY,
      dataPath: 'productForm'
    },
    createdBy: {
      label: 'Created By',
      dataType: TABLE_DATA_TYPE.TEXT,
      dataPath: 'createdBy',
      showSortable: true
    },
    updatedBy: {
      label: 'Updated By',
      dataType: TABLE_DATA_TYPE.TEXT,
      dataPath: 'updatedBy',
      showSortable: true
    },
    createdAt: {
      label: 'Created At',
      dataType: TABLE_DATA_TYPE.DATE,
      dataPath: 'createdAt',
      showSortable: true
    },
    updatedAt: {
      label: 'Updated At',
      dataType: TABLE_DATA_TYPE.DATE,
      dataPath: 'updatedAt',
      showSortable: true
    }
  }
}
